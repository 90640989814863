.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.BodyCon {
  display: flex;
}

.mainCardDiv {
  width: 100%;
  padding-right: 280px;
  padding-bottom: 80px;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0px 1.8rem 0 1.8rem;
  position: relative;
  /* background: white; */
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  margin-bottom: 20px;
}

.SearchFilterSec {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  padding: 5px 0 20px 0;
  justify-content: space-between;
}

.SearchCon {
  width: 50%;
  padding: 0 10px;
}

.SearchConSec {
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  background: #fff;
}

.SearchConSec input {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  width: 100%;
  border: none;
}

.SearchConSec svg {
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.FilterSec {
  width: 50%;
  display: flex;
}

.FilterCard {
  width: 33%;
  padding: 0 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCard.Select>div>div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.AlertMainCheckSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px 5px 12px;
}

.AlertDeleteBU {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #ff8271;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.AlertDeleteBU:focus {
  outline: none;
  box-shadow: none;
}

.AlertDeleteBU svg {
  width: 20px;
  margin-right: 6px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
}

.NotesRow a {
  width: 100%;
}

.NotesCard {
  width: 100%;
  /* border-radius: 6px; */
  padding: 15px 25px;
  /* margin: 10px 0; */
  position: relative;
  /* box-shadow: 0 0 20px 0 #f5f5f5; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.NotesCard a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .NotesCard:hover {
  box-shadow: 0 0 20px 0 #f2f2f2;
} */

.NotesCard:hover {
  cursor: pointer;
}

.AlertCardFirstSec {
  width: 100%;
  display: flex;
  align-items: center;
}

.AccoTitleHead {
  width: calc(100% - 126px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.DeviceAlertIcon {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  margin-left: 10px;
}

.DeviceAlertIcon.Blue {
  background: #e8f0ff;
}

.DeviceAlertIcon.Orange {
  background: #fff3e8;
}

.DeviceAlertIcon.Red {
  background: #ffe8e8;
}

.DeviceAlertIcon.Green {
  background: #e3ffe4;
}

.AlertTitle {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 150%;
  margin: 0;
  width: 60%;
}

.AlertText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  width: 30%;
}

.AlertText span {
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  position: relative;
  display: inline-block;
}

.AlertText span:last-child {
  margin: 0;
  padding: 0;
}

.AlertText span:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.AlertText span:last-child:after {
  display: none;
}

.AlertCardSecondSec {
  width: 30%;
}

.FilterFieldSec {
  width: 100%;
  display: flex;
}

.Width200 {
  min-width: 200px;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.FilterTitle svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.FilterBu {
  align-items: center;
  padding: 9px 14px;
  background: #502688;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
}

.FilterBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
  background: #230058;
  background-color: #230058;
  color: #fff;
}

.AlertsRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  align-content: space-between;
}

.AlertsCard {
  width: 33.33%;
  padding: 12px 12px;
}

.AlertsCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
}

.AlertsCardHead {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.AlertsCardImg {
  min-width: 60px;
  width: 60px;
  min-height: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.AlertsCardImg.Offline {
  background-color: #FBEDEB;
}

.AlertsCardImg.Online {
  background-color: #E3FFE4;
}

.AlertsCardImg.Lowbatter {
  background-color: #FFF2E3;
}

.AlertsCardImg.Waterleak {
  background-color: #E6E8F4;
}

.AlertsCardImg img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
}

.AlertsCardConSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.AlertsCardTitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  width: 100%;
  margin: 0 0 8px 0;
  color: #505050;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif !important;
}

.AlertsCardTitleSm {
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  width: 100%;
  margin: 0 0 0 0;
  color: #505050;
  font-family: "Inter", sans-serif !important;
}

.AlertsCardBody {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.AlertsCardBody ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.AlertsCardBody ul li {
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
}

.AlertsCardBody ul li .Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
  min-width: 110px;
}

.AlertsCardBody ul li .Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  color: #343434;
}

.AlertsCardFoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.CardDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
}

.CustomCheck {
  position: absolute;
  top: -12px;
  left: 0;
  z-index: 1;
  background: #ffffff;
  border-bottom-right-radius: 5px;
}

.CustomCheck span {
  padding: 2px 4px 4px 2px;
}

.NoDataMain {
  width: calc(100% - 24px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.ArchiveBadge {
  background-color: #dfe2e6;
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0px 0 0px 0;
  height: 25px;
  line-height: normal;
  white-space: nowrap;
}

.AccoMainSec {
  border-top: 1px solid #eeeeee;
  margin: -6px 0 0 48px;
  padding: 12px 0 0 0;
}

.AccoMainSec ul {
  width: 100%;
}

.AccoMainSec ul li {
  list-style: none;
  padding: 5px 0;
}

.AccoMainSec ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.AccoMainSec ul li p span {
  color: #343434;
  font-weight: 500;
}

@media screen and (min-width: 360px) {
  .mainCardDiv {
    padding-right: 0px;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  .AlertsCard {
    width: 100%;
  }

  .AlertsCardHead {
    flex-wrap: wrap;
    justify-content: center;
  }

  .AlertsCardImg {
    border-radius: 50%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .AlertsCardTitle,
  .AlertsCardTitleSm {
    text-align: center;
  }

  .AlertTitle {
    width: 100%;
    font-size: 14px;
  }

  .AlertText {
    width: 100%;
  }

  .DeviceAlertIcon {
    margin-right: 15px;
    margin-left: 0px;
  }

  .AccoTitleHead {
    width: calc(100% - 95px);
  }

  .AccoMainSec {
    margin: -6px 0 0 0px;
  }

  .AccoMainSec ul li p span {
    min-width: 120px;
  }
}

@media screen and (min-width: 576px) {
  .DeviceAlertIcon {
    margin-right: 25px;
    margin-left: 10px;
  }

  .AccoTitleHead {
    width: calc(100% - 95px);
  }

  .AlertTitle {
    font-size: 16px;
  }

  .AccoMainSec {
    margin: -6px 0 0 48px;
  }

  .AccoMainSec ul li p span {
    min-width: 130px;
  }
}

@media screen and (min-width: 768px) {
  .AlertsCard {
    width: 50%;
  }

  .AlertTitle {
    width: 70%;
  }

  .AlertText {
    width: 25%;
  }
}

@media screen and (min-width: 992px) {
  .AlertsCardHead {
    flex-wrap: unset;
    justify-content: unset;
  }

  .AlertsCardImg {
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .AlertsCardTitle,
  .AlertsCardTitleSm {
    text-align: left;
  }

  .AlertTitle {
    width: 60%;
  }

  .AlertText {
    width: 30%;
  }
}

@media screen and (min-width: 1100px) {
  .SearchCon {
    width: 25%;
  }

  .FilterSec {
    width: 75%;
  }

  .FilterCard {
    padding: 0 5px;
  }
}

@media screen and (min-width: 1200px) {
  .SearchCon {
    width: 40%;
  }

  .FilterSec {
    width: 60%;
  }

  .FilterCard {
    padding: 0 10px;
  }

  .FilterTitle {
    display: none;
  }

  .FilterBu {
    display: none;
  }
}

@media screen and (min-width: 1300px) {
  .AlertsCard {
    width: 33.33%;
  }
}

@media screen and (min-width: 1500px) {
  .FilterTitle svg {
    display: none;
  }

  .SearchCon {
    width: 50%;
  }

  .FilterSec {
    width: 50%;
  }
}

@media screen and (min-width: 1600px) {
  .NotesCard {
    padding: 15px 18px;
  }
}

@media screen and (min-width: 1800px) {
  .AlertsCard {
    width: 25%;
  }

  .SearchCon {
    width: 60%;
  }

  .FilterSec {
    width: 40%;
  }
}

@media (max-width: 1199px) {
  .FilterSec {
    width: 72px;
  }

  .SearchCon {
    width: calc(100% - 72px);
  }

  .FilterFieldSec {
    width: 280px;
    height: calc(100% - 64px);
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    background: #fff;
    top: 64px;
    z-index: 98;
    right: -300px;
    align-content: flex-start;
    padding: 40px 15px;
    box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
    border-left: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
  }

  .FilterFieldSec.Open {
    right: 0;
  }

  .FilterCard {
    width: 100%;
    padding: 10px 10px;
  }
}

@media (max-width: 991px) {
  .ProspectCardHeadSection {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ProspectCardHeadSection .HeadConSec {
    width: 100%;
  }

  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 90px;
    width: 90px;
    min-height: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .ProsUserName.LimitName {
    width: 100%;
    margin: 0 0 0 0;
    text-align: center;
  }

  .ProspectNonAppliedBadge,
  .ProspectAppliedBadge,
  .ProspectScreenedBadge,
  .ProspectApprovedBadge,
  .ProspectDeclinedBadge {
    position: absolute;
    top: 0;
    left: 0;
  }

  .ProsUserName {
    justify-content: center;
  }

  .ProsUserMandateSec {
    justify-content: center;
  }

  .ReffStatus {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 959px) {
  .breadcrumb {
    display: none;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem 0.5rem 1.8rem;
  }

  .SearchFilterSec {
    padding: 5px 0 10px 0;
  }

  .FilterFieldSec {
    height: calc(100% - 0px);
    top: 0;
  }
}

@media (max-width: 767px) {
  .AddNoteBu span {
    display: none;
  }

  .AddNoteBu svg {
    margin-right: 0;
  }

  .AddNoteBu,
  .FilterBu {
    margin: 0 5px;
  }

  .BackAddBU {
    display: flex !important;
  }

  .ReqAddCard {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    padding: 40px 40px;
    z-index: 999;
  }
}

@media screen and (max-width: 575px) {
  .FileUploadSmall .ImgFile {
    width: 25%;
    height: 140px;
  }

  .FormHalfSec {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .mainCard {
    padding: 0;
    margin: 0 0.8rem;
    background: transparent;
    box-shadow: unset;
  }

  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 60px;
    width: 70px;
    min-height: 60px;
    height: 70px;
  }

  .ProsIMGSec .ProspectCardImg {
    width: 34px;
  }

  .pageTitleWrap {
    padding: 1rem 0.8rem 0.5rem 0.8rem;
  }

  .pageTitle {
    width: 100%;
    order: 2;
  }

  .breadcrumb {
    width: 100%;
    order: 1;
    padding-bottom: 12px;
  }

  .FileUploadSmall .ImgFile {
    width: 33.33%;
    height: 120px;
  }

  .ReqAddCard {
    padding: 30px;
  }

  .AddDeviceSec {
    flex-wrap: wrap;
  }

  .AddDeviceSec .SearchConSec {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }

  .AddDeviceDatePick {
    width: 100%;
  }

  .OneThirdSec {
    width: 100%;
  }
}