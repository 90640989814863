.RentalAppliArea {
  width: 1000px;
  background-color: #fff;
  margin: -380px auto 0 auto;
  padding: 64px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.RentalAppliHead {
  width: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 50px;
}

.RentalAppliHead img {
  max-width: 240px;
  height: 60px;
  object-fit: contain;
  object-position: center;
  margin-bottom: 30px;
}

.RentalAppliHeadTitle {
  font-weight: 600;
  font-size: 22px;
  line-height: 130%;
  text-align: center;
  color: #2c2c2c;
  margin: 0;
  width: 100%;
}

.ApplicantSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ApplicantSecTitle {
  font-weight: 600;
  font-size: 32px;
  line-height: 130%;
  text-align: center;
  color: #2c2c2c;
  margin-bottom: 12px;
  width: 100%;
}

.ApplicantSecSubTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #a8a8a8;
  margin-bottom: 12px;
  width: 100%;
}

.PayNowSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 80px;
}

.BillingInfoTSection {
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #dfe1e9;
  margin-bottom: 24px;
  padding-bottom: 24px;
  position: relative;
}

.PayNowSecTitle {
  width: 100%;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #2c2c2c;
  margin-bottom: 0px;
  padding-bottom: 0px;
}

.BillingInfoTextSec {
  width: 100%;
  padding: 12px 20px;
  background: #f6f6f6;
  margin: 10px 0 20px 0;
}

.BillingTextList {
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  color: #8a92ad;
  margin: 8px 0 8px 0;
}

.RentallAppliSubmitBU {
  width: 100%;
  height: 64px;
  background: #2a5bee;
  border: none;
  margin-top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  padding: 20px;
}

.FormGroupHalf {
  width: 48%;
  padding: 15px 0;
  font-size: 0.875rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.Width100 {
  width: 100%;
}

.PayNowSec .Width100 {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 38px;
  height: 56px;
  /* padding: 0.375rem 0.75rem; */
  font-weight: 400;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  /* border: 1px solid #dfe1e9; */
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControl:focus {
  box-shadow: 0px 0px 10px 0px #649bdd38;
}

.FooterWrap {
  width: 100%;
  padding: 0 168px;
}

.footerMain {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 95px 0 75px;
  margin-top: 90px;
}

.footerMainLeft {
  width: 20%;
}

.footerMainLeft img {
  max-width: 240px;
  max-height: 100px;
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: left;
}

.footerMainRight {
  width: 75%;
  padding-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.footerMainRight h3 {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 25px;
  line-height: 18px;
  margin: 0;
  letter-spacing: 0.01em;
}

.footerMainRight ul {
  margin: 0;
  padding: 0;
}

.footerMainRight ul li {
  list-style: none;
  color: #2c2c2c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 3px;
  text-transform: none;
}

.footerMainRight ul li a {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 3px;
  text-transform: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
}

.footerMainRight ul li a:hover {
  color: #2a5bee;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin: -4px 0 0 0;
}

.FormLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0 0 8px 0;
    width: 100%;
    /* display: flex;
    justify-content: space-between;
    align-items: center; */
}

.FormLabel span {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    color: #f44336;
    margin: 0 0 0 5px;
}

.FormSubLabel {
    font-size: 13px;
    margin: 0 0 5px 0;
    color: #727272;
}

.FormLabelSmall {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0 0 12px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@media screen and (min-width: 360px) {
  .RentalAppliArea {
    width: 90%;
    padding: 64px 0;
  }

  .RentallAppliSubmitBU img {
    display: none;
  }

  .FormGroupHalf {
    width: 100%;
  }

  .FooterWrap {
    width: 90%;
    padding: 0 0px;
    margin: 0 auto;
  }

  .footerMainLeft,
  .footerMainRight {
    width: 100%;
  }

  .footerMainLeft img {
    object-position: center;
    margin: 0 auto 60px auto;
  }

  .footerMainRight div {
    width: 100%;
    text-align: center;
  }

  .footerMainRight ul {
    padding-bottom: 20px;
    padding-left: 0;
  }
}

@media screen and (min-width: 576px) {
  .RentallAppliSubmitBU img {
    display: block;
  }
}

@media screen and (min-width: 768px) {
  .FormGroupHalf {
    width: 48%;
  }

  .footerMainRight > div:last-of-type {
    padding-top: 40px;
  }
}

@media screen and (min-width: 992px) {
  .RentalAppliArea {
    width: 900px;
    padding: 64px;
  }

  .FooterWrap {
    width: 100%;
    padding: 0 78px;
    margin: 0 auto;
  }

  .footerMainRight div {
    width: auto;
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .RentalAppliArea {
    width: 1000px;
  }

  .FooterWrap {
    width: 100%;
    padding: 0 168px;
  }
}

@media screen and (min-width: 1366px) {
  .footerWrap {
    padding: 0 168px;
  }

  .footerMainLeft {
    width: 20%;
  }

  .footerMainLeft img {
    object-position: left;
    margin: 0;
  }

  .footerMainRight {
    width: 78%;
  }
}

@media screen and (min-width: 1500px) {
  .footerMainLeft {
    width: 20%;
  }

  .footerMainRight {
    width: 75%;
  }
}
