.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0px 1.8rem 0 1.8rem;
  position: relative;
  /* background: white; */
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  margin-bottom: 20px;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #56328c;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.ReferStatusBU {
  padding: 0px 0px 1px 0px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 !important;
  padding-top: 1px !important;
  line-height: unset !important;
  height: 22px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 1px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #ffbb33 !important;
  border-color: #ffbb33 !important;
  color: #000 !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #17dd1b !important;
  border-color: #17dd1b !important;
  color: #fff !important;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: table;
  width: 100%;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  z-index: 2;
}

.ApplicantFilterSec .formControl {
  margin-top: 10px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -13px;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  position: relative;
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.NotesCard:hover {
  cursor: pointer;
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 20px 8px 0;
  /* min-width: 350px; */
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  /* padding-right: 8px; */
  font-weight: 600;
  /* max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; */
  display: inline-block;
  margin-bottom: -4px;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  width: 25%;
  padding: 8px 15px;
}

.InsideConUnit {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  /*width: 25%;*/
  padding: 8px 15px;
  text-align: center;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none !important;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 10px;
}

.FilterBu {
  align-items: center;
  padding: 9px 14px;
  background: #502688;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
}

.FilterBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
  background: #230058;
  background-color: #230058;
  color: #fff;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.BodyCon {
  display: flex;
}

.BodyFilterArea {
  min-width: 280px;
  max-width: 280px;
  background: #fff;
  position: fixed;
  right: 0;
  height: 100vh;
  padding: 90px 30px 120px 30px;
  overflow: auto;
  top: 60px;
  box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
  transition: all ease-in-out 0.5s;
  z-index: 10;
}

.FilterList {
  margin-bottom: 20px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  z-index: 2;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 0px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.ApplicantDiv {
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 25%;
}

/*.ApplicantDiv::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
    top: 25px;
  left: 0;
}*/

.ApplicantDivTitle {
  margin: 0;
  font-size: 14px;
  color: #ff8271;
  font-weight: 400;
  width: auto;
  display: inline-block;
  /* background: #fff; */
  padding: 8px 0;
  position: relative;
  /* z-index: 1; */
}

.ApplicantDiv .InsideConUnit:first-child {
  text-align: left;
}

.ApplicantDiv .InsideConUnit:last-child {
  text-align: right;
}

.ApplicantName {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApplicantNameDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
}

.ApplicantDivHalf {
  width: 30%;
}

.ApplicantDivHalf:last-child {
  width: 65%;
}

.ApplicantAction {
  display: flex;
  align-items: center;
  width: 20%;
}

.mainCardDiv {
  width: 100%;
  padding-right: 280px;
  padding-bottom: 80px;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.FilterTitle svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.FilterList {
  margin-bottom: 20px;
  font-size: 0.875rem;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  z-index: 2;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 70px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.DatePickerCustom {
  position: relative;
  z-index: 1;
}

.DatePickerCustom svg {
  position: absolute;
  top: 12px;
  color: #000;
  width: 18px;
  height: 18px;
  right: 12px;
  z-index: -1;
  opacity: 0.8;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  /* font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666; */
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin: 0 15px 0 0;
  padding: 8px 15px 8px 0;
  position: relative;
}

.MainReqConDe:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
}

.MainReqConDe:last-child::after {
  display: none;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.MainReqConDe span.ConNameTrancate {
  color: #666;
  font-weight: 500;
  padding-right: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
  display: inline-block;
  margin-bottom: -5px;
}

.MainReqConUR .MainReqConDe:last-child {
  padding-right: 0px;
}

.MainReqConDivSmall {
  padding: 0 15px;
  width: 37%;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 37%;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
}

.MainReqConDropBU {
  padding: 0 10px;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.TitleWithTag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.TagDiv {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  background: #999;
  color: #fff;
  padding: 4px 10px;
  border-radius: 24px;
  margin: 3px 3px 3px 3px;
}

.TagDiv.Red {
  background: #fb746a;
}

.TagDiv.Yellow {
  background: #fff3e8;
  color: #e99a37;
}

.TagDiv.Error {
  background: #ffe8e8;
  color: #e23725;
}

.TagDiv.Success {
  background: #e8fff2;
  color: #5ac55e;
}

.TagDiv.Purple {
  background: #5026884d;
  color: #502688;
}

.TagDiv.Archive {
  background: #50505040;
  color: #505050;
}

.NoDataMain {
  width: calc(100% - 24px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
  border-radius: 5px;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

button.TableAddBUSecondary {
  align-items: center;
  padding: 9px 14px;
  background: #fff !important;
  display: flex;
  min-height: 0;
  border: 1px solid #e1e1e1 !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #8482a1 !important;
  margin: 0 !important;
  margin: 0 10px !important;
  border-radius: 8px;
}

button.TableAddBUSecondary:hover,
button.TableAddBUSecondary:active {
  background: #fff !important;
  color: #8482a1 !important;
  border: 1px solid #ced4da !important;
}

button.TableAddBUSecondary:after {
  display: none;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.SearchFilterSec {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  padding: 5px 0 20px 0;
  justify-content: space-between;
}

.SearchCon {
  width: 50%;
  padding: 0 10px;
}

.SearchConSec {
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  background: #fff;
}

.SearchConSec input {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  width: 100%;
  border: none;
}

.SearchConSec svg {
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.FilterSec {
  width: 50%;
  display: flex;
}

.FilterCard {
  width: 33%;
  padding: 0 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCardDP {
  width: 100%;
  padding: 10px 0;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterBUCard {
  width: 30%;
  padding: 0 10px;
  max-width: 150px;
}

.FilterCard.Select>div>div {
  min-height: 42px;
  /* border-color: #e1e1e1;
  border-radius: 8px; */
}

.AcrhivedLabel {
  background: #e5e5e5;
  color: #505050 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0 0 0 15px;
  display: inline-block;
}

.AddSection {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px #f5f5f5;
  border-radius: 8px;
  margin: 15px 0 0 0;
  padding: 0px 0px;
  max-height: 100%;
}

.AddLayoutForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SMSmallSideBody {
  width: 100%;
}

.SMBigSideBodyUnder {
  width: 100%;
  padding-top: 0px;
}

.AddUserMain {
  width: 100%;
  padding: 0px;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  margin: 0 -12px;
}

.FormGroup {
  width: 50% !important;
  padding: 14px 12px !important;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupFull {
  width: 100% !important;
  padding: 14px 12px !important;
  font-size: 0.875rem;
  position: relative;
}

.ButtonDiv {
  width: 100%;
  padding-top: 25px;
  display: flex;
  align-items: center;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 10px 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 5px 15px 5px 20px;
  background-color: transparent;
  font-size: 13px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  line-height: 16px;
  border-radius: 4px;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  /* border: 1px dashed #a069c6; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A069C6FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.UploadLabel div.UpIcon {
  width: 50px;
  background: #a069c6;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.UpIcon img {
  width: 100%;
  height: auto;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadLabel span p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.UpIMGSec {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.ImgFile {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  padding: 10px 0;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFileUnder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 0px #ececec;
  border-radius: 8px;
  padding: 12px 8px 12px 16px;
  /* margin-bottom: 16px; */
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 61px;
}

/* .ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0;
} */

/* .ButtonDiv {
  padding: 0 20px;
} */

.FileName {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0;
  display: flex;
  align-items: center;
  width: 300px;
  min-width: 300px;
}

.FileName span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.FileName svg,
.FileName img {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  color: #2a5bee;
  margin-right: 12px;
  stroke-width: 1.5;
}

.FileSize {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #949494;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  position: relative;
  width: 180px;
  min-width: 180px;
  text-align: center;
}

.FileListRight {
  display: flex;
  align-items: center;
}

.FileListRight p.FileSize:last-of-type {
  margin: 0;
  padding: 0;
}

.FileListRight p::after {
  content: "";
  width: 2px;
  height: 10px;
  background: #cdcdcd;
  position: absolute;
  top: 53%;
  right: -2px;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  border-radius: 0;
}

.FileListRight p:last-of-type:after {
  display: none;
}

/* .FileListRight a,
.FileListRight button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2C2C2C;
  background-color: #fff;
  margin-top: -3px;
  border: none;
  padding: 0;
  margin-left: 8px;
} */

.FileListRight a svg,
.FileListRight button svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.FileListDelete {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #eb534a;
  padding: 6px 11px;
  min-width: 20px;
  transition: all 0.2s ease-in-out;
}

.FileListDelete:hover {
  background: transparent;
}

.FileListDelete {
  opacity: 0;
}

.ImgFileUnder:hover .FileListDelete,
.ImgFileUnder.Active .FileListDelete {
  opacity: 1;
}

.ButtonVoiletSolid {
  padding: 12px 25px;
  background-color: #502688;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.ButtonVoiletLine {
  padding: 12px 25px;
  background-color: #fff;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

.HalfSecHalfRow {
  width: 100%;
  display: flex;
}

.HalfSecHalfRes {
  width: 100%;
}

.FormRowCusCon {
  width: 100%;
  padding: 0 12px;
}

.FormRowCusRow {
  display: flex;
  margin: 0 -12px;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.FilterFieldSec {
  width: 100%;
  display: flex;
}

.ProspectCard {
  width: 100%;
}

.ProspectCard {
  width: 25%;
  padding: 15px 13px;
}

.ProspectCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.ProspectCardUnder:hover {
  box-shadow: 0 0 28px 0 #1f02541f;
}

.ProspectCardHeadSection {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.ProspectCardHeadSection .ProsIMGSec {
  min-width: 90px;
  width: 90px;
  min-height: 90px;
  height: 90px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ProsIMGSec .ProspectCardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ProsIMGSec p {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: #502688;
  letter-spacing: 1px;
}

.ProspectCardHeadSection .HeadConSec {
  width: calc(100% - 106px);
}

.prospectNameActionSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.ProsUserName {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 7px 0;
  color: #505050;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.ProsUserName.LimitName {
  width: calc(100% - 85px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin: 0 12px 0 0;
}

.ProspectCardBodySec {
  display: flex;
  flex-wrap: wrap;
}

.ProspectCardBodySec ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.ProspectCardBodySec ul li {
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
}

.ProspectCardBodySec ul li p.Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
  min-width: 130px;
  display: flex;
}

.ProspectCardBodySec ul li p.Title img {
  width: 16px;
  opacity: .6;
  margin-right: 10px;
}

.ProspectCardBodySec ul li p.Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  color: #343434;
  display: flex;
  flex-wrap: wrap;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.Width220 {
  min-width: 220px;
}

.Width160 {
  min-width: 160px;
}

@media screen and (min-width: 360px) {
  .FileUploadSmall .UpIMGSec {
    margin: 15px 0px 0 0px;
  }

  .FileUploadSmall .UploadLabel {
    padding: 5px 12px 5px 12px;
  }

  .FileUploadSmall .UploadLabel div.UpIcon {
    width: 45px;
    height: 45px;
    margin-bottom: 0;
    margin-right: 12px;
    padding: 12px;
  }

  .FileUploadSmall .UploadLabel span p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
    text-align: left;
  }

  .FileUploadSmall .UploadLabel span p.UploadText {
    margin: 0;
    font-size: 11px;
    line-height: 14px;
  }

  .FileUploadSmall .UploadLabel span {
    align-items: center;
    flex-wrap: nowrap;
  }

  .FileUploadSmall .UploadLabel span>div {
    display: block;
  }

  .FileUploadSmall .FileUpDuBUDiv {
    width: 240px;
    height: 90px;
    margin: 0;
    padding: 0;
  }

  .ApplicantDiv {
    width: 25%;
  }

  .ApplicantAction {
    width: 20%;
  }

  .mainCardDiv {
    padding-right: 0px;
  }

  .FilterOnOffBU {
    display: block;
  }

  .BodyFilterArea {
    right: -280px;
  }

  .ProspectCard {
    width: 100%;
  }

  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  .InsideConName {
    width: 100%;
    padding-right: 0;
  }

  .TitleWithTag {
    justify-content: space-between;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
    padding: 0 5px;
  }

  .BodyFilterArea.Open {
    right: 0px;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 170px;
  }
}

@media screen and (min-width: 480px) {
  .FilterPill span {
    max-width: 100%;
    display: unset;
    margin-bottom: 0px;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 250px;
  }
}

@media screen and (min-width: 576px) {
  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 60%;
    justify-content: flex-start;
    padding: 0px;
  }

  .InsideConName {
    width: auto;
    padding-right: 12px;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 300px;
  }
}

@media screen and (min-width: 768px) {
  .ApplicantDiv {
    width: 35%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 24%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 80%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 20%;
    justify-content: flex-end;
  }

  .TitleWithTag {
    justify-content: unset;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 200px;
  }

  .ProspectCard {
    width: 50%;
  }
}

@media (min-width: 992px) {
  .MainReqConDe span.ConNameTrancate {
    max-width: 250px;
  }
}

@media screen and (min-width: 1100px) {
  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConDivBig {
    width: 34%;
  }

  .MainReqConAction {
    width: 16%;
    justify-content: flex-start;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 200px;
  }

  .SearchCon {
    width: 30%;
  }

  .FilterSec {
    width: 70%;
  }

  .FilterCard {
    padding: 0 5px;
  }
}

@media screen and (min-width: 1200px) {
  .FileName {
    font-weight: 500;
    font-size: 14px;
    line-height: normal;
    color: #2c2c2c;
    margin: 0;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 300px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .FileName svg,
  .FileName img {
    width: 60px;
    height: 60px;
    min-width: 26px;
    min-height: 26px;
    color: #2a5bee;
    margin-right: 12px;
    stroke-width: 1.5;
    margin-bottom: 8px;
  }

  .FileSize {
    font-weight: 400;
    font-size: 14px;
    line-height: normal;
    color: #949494;
    margin: 0 auto;
    padding: 8px 0 0 0;
    position: relative;
    width: 100%;
    min-width: 180px;
    text-align: center;
  }

  .FileListRight {
    display: flex;
    align-items: center;
    position: absolute;
    top: 5px;
    right: 5px;
  }

  .ApplicantDiv {
    width: 50%;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .SearchCon {
    width: 23%;
  }

  .FilterSec {
    width: 77%;
  }

  .FilterCard {
    padding: 0 10px;
  }

  .MainReqConDivSmall {
    width: 54%;
  }

  .MainReqConDivBig {
    width: 30%;
  }

  .MainReqConAction {
    width: 16%;
    justify-content: flex-end;
  }

  .FilterBu {
    display: none;
  }

  .FilterTitle {
    display: none;
  }

  .ProsUserName .LimitName {
    width: 170px;
  }
}

@media screen and (min-width: 1280px) {
  .MainReqConDe span.ConNameTrancate {
    max-width: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .ProsUserName .LimitName {
    width: calc(100% - 80px);
  }

  .ApplicantDiv {
    width: 35%;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1360px) {
  .ProspectCard {
    width: 33.33%;
  }
}

@media screen and (min-width: 1500px) {
  .FileName {
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: #2c2c2c;
    margin: 0;
    display: flex;
    align-items: center;
    width: 300px;
    min-width: 100px;
    flex-wrap: unset;
    justify-content: unset;
  }

  .FileName svg,
  .FileName img {
    width: 26px;
    height: 26px;
    min-width: 26px;
    min-height: 26px;
    color: #2a5bee;
    margin-right: 12px;
    stroke-width: 1.5;
    margin-bottom: 0;
  }

  .FileSize {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #949494;
    margin: 0 0 0 0;
    padding: 0 0 0 0;
    position: relative;
    width: 180px;
    min-width: 180px;
    text-align: center;
  }

  .FileListRight {
    display: flex;
    align-items: center;
    position: unset;
    top: unset;
    right: unset;
  }

  .ApplicantDiv {
    width: 40%;
  }

  .ApplicantDivHalf {
    width: 30%;
  }

  .ApplicantDivHalf:last-child {
    width: 65%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .FilterOnOffBU {
    display: none;
  }

  .BodyFilterArea {
    right: 0px;
  }

  .MainReqConDivSmall {
    width: 55%;
  }

  .MainReqConDivBig {
    width: 30%;
  }

  .MainReqConAction {
    width: 15%;
  }

  .FilterTitle svg {
    display: none;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 240px;
  }

  .SearchCon {
    width: 30%;
  }

  .FilterSec {
    width: 70%;
  }
}

@media screen and (min-width: 1600px) {
  .ApplicantDiv {
    width: 35%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 52%;
  }

  .MainReqConDivBig {
    width: 30%;
  }

  .MainReqConAction {
    width: 18%;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 250px;
  }

  .ProspectCard {
    width: 25%;
  }
}

@media screen and (min-width: 1800px) {
  .ApplicantDiv {
    width: 30%;
  }

  .ApplicantAction {
    width: 15%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .MainReqConDe span.ConNameTrancate {
    max-width: 350px;
  }

  .SearchCon {
    width: 45%;
  }

  .FilterSec {
    width: 55%;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  .FilterSec {
    width: 197px;
  }

  .SearchCon {
    width: calc(100% - 197px);
  }

  .FilterFieldSec {
    width: 280px;
    height: calc(100% - 68px);
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    background: #fff;
    top: 60px;
    z-index: 99;
    right: -300px;
    align-content: flex-start;
    padding: 40px 15px;
    box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
    border-left: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
  }

  .FilterFieldSec.Open {
    right: 0;
  }

  .FilterCard {
    width: 100%;
    padding: 10px 10px;
  }
}

@media (max-width: 991px) {
  .ProspectCardHeadSection {
    flex-wrap: wrap;
    justify-content: center;
  }

  .ProspectCardHeadSection .HeadConSec {
    width: 100%;
  }

  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 90px;
    width: 90px;
    min-height: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 0px;
    margin-bottom: 16px;
  }

  .ProsUserName.LimitName {
    width: 100%;
    margin: 0 0 0 0;
    text-align: center;
  }

  .ProsUserName {
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 959px) {
  .pageTitleWrap {
    padding: 1rem 1.8rem 0.5rem 1.8rem;
  }

  .breadcrumb {
    display: none;
  }

  .FilterFieldSec {
    top: 0;
  }
}

@media (max-width: 767px) {
  .BackAddBU {
    display: flex !important;
  }

  .FilterSec {
    width: 130px;
  }

  .SearchCon {
    width: calc(100% - 130px);
  }

  .AddNoteBu span {
    display: none;
  }

  .AddNoteBu svg {
    margin-right: 0;
  }

  .AddNoteBu,
  .FilterBu {
    margin: 0 5px;
  }
}

@media (max-width: 479px) {
  .pageTitleWrap {
    padding: 1rem 0.8rem 0.5rem 0.8rem;
  }

  .mainCard {
    padding: 0;
    margin: 0 0.8rem;
    background: transparent;
    box-shadow: unset;
  }

  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 60px;
    width: 70px;
    min-height: 60px;
    height: 70px;
  }
}