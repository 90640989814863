.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  /* position: relative; */
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 60px !important;
  /* float: left; */
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: auto;
  /* min-width: 105px; */
  margin-left: 20px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.AddCardMain {
  width: 600px;
  padding: 20px;
}

.DetailsCardMainInside {
  width: 100%;
  /*border: 1px solid #dee2e6;*/
  border-radius: 12px;
  /*padding: 20px 25px;*/
}

.FullFormGroup {
  width: 300px;
  padding-bottom: 15px;
}

.ModalFormGroup {
  width: 100%;
  padding-bottom: 0px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 12px;
  border-radius: 5px;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  border: none;
  background: transparent;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ApplicantFilterSec .formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: auto;
  padding: unset;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ApplicantFilterSec .formControl {
  margin-top: 10px;
}

.MultiFormGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FormGroupBig {
  width: 190px !important;
  padding-bottom: 15px !important;
}

.FormGroupSmall {
  width: 110px !important;
  padding-bottom: 15px !important;
}

.AddUnitHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.AddUnitHead h5 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: #343434;
  display: flex;
  align-items: center;
}

.AddUnitBu {
  align-items: center;
  padding: 9px 14px;
  background: rgb(255, 130, 113);
  display: flex;
  min-height: 0px;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
  font-family: "Inter", sans-serif !important;
}

.AddUnitBu:hover {
  background: rgb(233, 117, 101);
}

.AddUnitBu svg {
  width: 20px;
  height: 20px;
}

.UnitsTableMain {
  border: 1px solid #dee2e6;
  border-radius: 12px;
  margin: 15px 0;
  overflow: hidden;
}

.UnitsTable {
  overflow: auto;
  height: 243px;
}

.UnitsTable table {
  width: 100%;
}

.UnitsTable table tr {
  display: flex;
  flex-wrap: wrap;
}

.UnitsTable table tr:nth-child(odd) td {
  background: #fbf7fc;
}

.UnitsTable table td {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;
  width: 50%;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.UnitsTable table td:last-child {
  border-right: 0px solid #dee2e6;
}

.UnitsTable table tr:last-child td {
  border-bottom: 0px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.UnitsTable table tr:last-child td:last-child {
  border-bottom: 0px solid #dee2e6;
  border-right: 0px solid #dee2e6;
}

.UnitsTable table td p {
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  margin: 0;
  display: flex;
  align-items: center;
}

.UnitsTable table td a {
  color: #333;
}

.UnitsTable table td a svg {
  width: 20px;
  height: 20px;
}

.ButtonDiv {
  width: 100%;
  padding: 10px 0 0 0;
}

.ButtonVoiletSolid {
  padding: 15px 15px;
  background-color: #ff8271;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  font-family: "Inter", sans-serif !important;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.ButtonVoiletLine {
  padding: 15px 25px;
  background-color: #fff;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1;
  text-align: center;
  /* vertical-align: middle; */
  font-family: "Inter", sans-serif !important;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #f1ecf3;
  /* border: 1px solid #230058;
  color: #fff; */
}

.ModalBUCan {
  color: #ff8271;
}

.ModalBUCan:hover {
  background-color: rgb(255 130 113 / 4%);
}

.ModalBUSub {
  color: #230058;
}

.ModalBUSub:hover {
  background-color: rgb(35 0 88 / 4%);
}

.DetailsDTextFull {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.DetailsDTextFull span {
  color: #b2b5cb;
  min-width: 70px;
  display: inline-block;
  font-weight: 500;
}

.DetailsDTextGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .DeTable {
  padding: 15px;
} */

.SMProCusRow {
  margin: 35px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* float: left; */
  width: -webkit-fill-available;
  width: -moz-available;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0 0px;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin-bottom: 0;
  /* position: relative; */
  background: white;
  border: none;
  box-shadow: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
}

.CardSmall {
  width: 100%;
  height: 100%;
  background: #4d7b93;
}

.LoaderCard {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.card .cardbody {
  padding: 1.5rem;
}

.SMProCardHead {
  text-align: left;
  padding: 15px 15px;
  position: relative;
}

.SMProCardHead .avatar {
  margin-bottom: 25px;
  position: absolute;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}

.SMProCardHead .SMProPicMainDubble:hover .avatar {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.SMProCardHead .avatar.active {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  z-index: 1;
}

.SMProCardHead .avatar img {
  position: relative;
  z-index: 2;
  border: 2px solid white;
}

.SMProCardHead .avatar::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  background: #23005833;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.SMProCardHead .avatar:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.SMProCardHead h5 {
  margin: 0;
  line-height: 2.22;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  /* position: absolute; */
  cursor: pointer;
  padding: 0 20px;
  text-align: center;
  left: 0;
  /* display: none; */
}

.SMProCardHead h5 svg {
  width: 21px;
  height: 21px;
  margin-left: 7px;
  color: #5e5e5e;
}

.SMProPicMain {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
}

.ProfileIMG {
  width: 100%;
  border-radius: 4px;
  max-height: 200px;
  object-fit: cover;
  object-position: center;
}

.SMProPicMain a,
.SMProPicMain span {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.SMProPicMain a svg,
.SMProPicMain span svg {
  width: 17px;
  stroke-width: 2;
}

.avatar {
  display: inline-block;
  margin-bottom: 0;
  min-height: 58px;
  min-width: 58px;
  height: 58px;
  width: 58px;
  border-radius: 50%;
  /* border: 2px solid white; */
  /*margin: 0 0 1rem;*/
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar > a > img,
.header form .input-group .input-group-append .avatar > button.btn > img,
.chat-block
  .chat-sidebar
  .chat-sidebar-content
  .list-group
  .avatar
  > .list-group-item.active
  > img,
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
  /*font-size: 19px;*/
}

.SMProCardDe {
  text-align: center;
}

.SMProCardDe ul {
  padding: 0 0 20px 0 !important;
}

.SMProCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 8px 20px 8px 20px;
  font-weight: 400;
  color: #94c5df;
  line-height: 21px;
}

ul:not(.list-unstyled) {
  margin: 0;
  padding: 0;
}

ul:not(.list-unstyled) li {
  list-style-type: none;
}

.SMProCardDe .MuiButton-outlinedPrimary {
  margin: 20px 10px;
}

.SMProCardDe .MuiButton-outlinedPrimary svg {
  margin-right: 8px;
}

.SMProDETag {
  /* color: #b2b5cb; */
  margin-right: 24px;
  display: flex;
  align-items: center;
  min-width: 75px;
  white-space: nowrap;
}

.SMProDE {
  /* margin-left: AUTO; */
  text-align: left;
  color: #f2f2f2;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SMProDE > div {
  text-align: left;
}

.SMProCusBigDiv {
  width: 100%;
  padding: 0 0px;
  /* float: left; */
}

.EditBU {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
  font-family: "Inter", sans-serif !important;
}

.EditBU svg {
  width: 17px;
  stroke-width: 2;
}

.FilesUploadRow {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px;
}

.FilesUploadGrid {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: grid;
}

.FilesUploadCard {
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 10%);
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  border: 1px solid #ebebeb !important;
}

.CardIconSec {
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  line-height: normal;
}

.CardIconSec img {
  width: 40px;
  height: 40px;
  margin-top: 7px;
}

.CardConSec {
  padding: 0.5rem;
}

.CardConSec p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.FilesUpCardDD,
.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0;
  padding: 6px 8px;
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0;
}

.DropDownMain {
  position: absolute;
  right: 0;
  top: 0;
}

.FilesUpCardDD:after {
  display: none;
}

.FilesUpCardDD svg {
  width: 18px;
}

.FileUpDuContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 20px;
  -webkit-align-items: center;
  align-items: center;
}

.FileUpDuContainer h5 {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 0;
  font-weight: 500;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 200px;
  margin: 10px 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 5px 15px 5px 20px;
  background-color: transparent;
  font-size: 13px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  line-height: 16px;
  border-radius: 4px;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  /* border: 1px dashed #a069c6; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A069C6FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

/* .UploadLabel:hover {
  background-color: #4d1f94;
} */

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.UploadLabel div.UpIcon {
  width: 50px;
  background: #a069c6;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.UpIcon img {
  width: 100%;
  height: auto;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadLabel span p.UploadText,
.UploadLabel p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.UpIMGSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

.ImgFile {
  min-width: 100px;
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0;
  padding: 10px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFileUnder {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
}

.ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button:hover {
  background: #df3f05;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0;
}

/* .UploadLabel {
  padding: 5px 15px 5px 15px;
  background-color: #502688;
  font-size: 14px;
  width: auto;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  -webkit-align-items: center;
  align-items: center;
  line-height: 14px;
  border-radius: .5rem;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  border: none;
  transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
  height: 40px;
  text-transform: capitalize;
  cursor: pointer;
}

.UploadLabel:hover {
  background-color: #4d1f94;
}

.UploadLabel button {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.UploadLabel button:focus {
  outline: none;
}

.UploadLabel svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.UploadLabel div {
  display: none;
} */

.NotesSearchArea {
  width: 100%;
  display: flex;
  margin: 5px 0;
}

.NotesSearchMain {
  /* width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px; */
  width: 100%;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px;
  margin-right: 0px;
}

.searchInput {
  /* width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px; */
  border: none;
  width: 100%;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.NotesSearchMain svg {
  /* position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px; */
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.AddNoteBu {
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 0;
  margin-right: 0px;
  font-size: 14px;
  font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
}

.NotesRow a {
  width: 100%;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /* overflow: hidden; */
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  color: #666;
  width: 33.33%;
  padding: 8px 15px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.InsideConText span {
  color: #333;
}

.InsideConUnit {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  width: 33.33%;
  padding: 8px 15px;
  text-align: center;
}

.InsideConCheck {
  width: 33.33%;
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.MainReqConDivBig {
  padding: 0 12px;
  width: 37%;
}

.MainReqConDivBigTwo {
  padding: 0 12px;
  width: 37%;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
  position: relative;
}

.MainReqConDeTwo {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
  position: relative;
  width: 100%;
}

.MainReqConDe a,
.MainReqConDe a:hover {
    color: #ff8271;
    border-bottom: 1px solid;
    padding-bottom: 2px;
}

.MainReqConDe:last-child {
  padding: 8px 0px 8px 0;
}

.MainReqConDe:after {
  content: "";
  position: absolute;
  width: 2px;
  height: 2px;
  background: #999;
  border-radius: 50%;
  right: 7px;
  top: 18px;
}

.MainReqConDivSmall .MainReqConDe:after {
  display: none;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.MainReqConDeTwo span {
  color: #999;
  padding-left: 12px;
}

a.MainReqConDe {
  color: #502688;
}

.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 8px 15px 8px 0;
}

span.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 0px 0px 0px 0;
}

.MainReqConDivSmall {
  padding: 0 12px;
  width: 37%;
}

.MainReqConDivSmallTwo {
  padding: 0 12px;
  width: 37%;
}

.TextEclips {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 20px 8px 0;
  /* min-width: 350px; */
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -4px;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 13%;
  justify-content: flex-end;
  padding: 0 12px;
}

.MainReqConActionTwo {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 13%;
  justify-content: flex-end;
  padding: 0 12px;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 15px 0 0 !important;
  line-height: unset !important;
  /* height: 26px !important; */
  white-space: nowrap;
}

.ReferStatusBUNew {
  padding-right: 10px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 5px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

/* .ReferStatusBU.StatusBuGreen:hover {
  background-color: #5ac55e !important;
  border-color: #5ac55e !important;
  color: #fff !important;
} */

.ReferStatusBU.StatusBuOpen,
.ReferStatusBU.StatusBuOpen:active,
.ReferStatusBU.StatusBuOpen:checked {
  background-color: rgba(161, 105, 198, 0.3) !important;
  border-color: rgba(161, 105, 198, 0.3) !important;
  color: #502688 !important;
}

.ReferStatusBU.StatusBuOpen:hover {
  background-color: #502688 !important;
  border-color: #502688 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.StatusBuYellow:hover {
  background-color: #e99a37 !important;
  border-color: #e99a37 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.ReferStatusBU.StatusBuRed:hover {
  background: #e23725 !important;
  color: #fff !important;
  border-color: #e23725 !important;
}

.ProTrash {
  min-width: auto !important;
}

.ProTrash svg {
  width: 18px;
  height: 18px;
  color: #333;
}

.FileUpDuBUDivBox {
  padding: 15px 15px;
  width: 100%;
}

.FileUpDuBUDivBox input {
  display: none;
}

.UploadLabelBox {
  padding: 5px 15px 5px 15px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 600;
  -webkit-align-items: center;
  align-items: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #666;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #e5e5e5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 180px;
  text-transform: capitalize;
  cursor: pointer;
  /* padding-left: CalC(50% - 50px); */
}

/* .UploadLabel span {
    width: 100%;
} */

/* .UploadLabel:hover {
  background-color: #230058;
} */

.UploadLabelBox p,
.UploadLabelBox span {
  width: 100%;
  margin: 0;
}

.UploadLabelBox p span {
  font-size: 10px;
  font-weight: 500;
  padding-top: 4px;
  display: inline-block;
}

.UploadLabelBox svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
  color: #666;
}

.UploadLabelBox div {
  display: none;
}

.ThemeRow {
  display: flex;
  flex-wrap: wrap;
}

.ThemeRow form {
  width: auto;
}

.DemoIMG {
  width: 380px;
  padding: 15px;
  position: relative;
  margin-left: 100px;
}

.DemoIMG .IMG {
  width: 100%;
  height: auto;
}

.DemoIMG .ThemeLogoIMG {
  position: absolute;
  top: 60px;
  left: 100px;
  max-width: 90px;
  max-height: 90px;
  height: 90px;
}

.ThemeText {
  position: absolute;
  top: 162px;
  left: 67px;
}

.ThemeTextTitle {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 3px;
}

.ThemeTextCon {
  font-size: 7px;
  font-weight: 600;
  margin-bottom: 0;
}

.ThemeIMGBU {
  background: #f2cf23;
  width: 160px;
  height: 30px;
  font-size: 8px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  padding-top: 9px;
  border-radius: 5px;
  position: absolute;
  top: 350px;
  left: 65px;
}

.ColorPicker {
  margin: 20px 0;
}

.ColorPicker input {
  display: block;
}

.ColorPicker p {
  font-size: 13px;
  line-height: 40px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.Permission h5 {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 25px 0;
  font-weight: 500;
  width: 100%;
}

.PermissionSwitch {
  font-size: 14px;
  font-weight: 600;
  color: #7e7e7e;
  margin: 5px 0;
}

.ThemeIMGLabel {
  position: absolute;
  background: #fbd426;
  top: 75px;
  right: 86px;
  font-size: 8px;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  text-align: center;
}

.RequestAddBU .IMG,
.RequestAddBUC .IMG {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.RequestAddBU {
  background: #f8d524;
  margin: 6px 0;
  width: 119px;
  height: 51px;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #333;
  border-radius: 4px;
  white-space: normal;
  text-transform: capitalize;
  text-align: left;
  display: flex;
  align-items: center;
  position: absolute;
  top: 165px;
  left: 55px;
  line-height: 11px;
  padding: 0 14px;
}

.RequestAddBUC {
  background: #f8d524;
  color: #000;
  margin: 6px 0;
  width: 119px;
  height: 51px;
  font-family: "Inter", sans-serif;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0px;
  color: #333;
  border-radius: 4px;
  white-space: normal;
  text-transform: capitalize;
  text-align: left;
  display: flex;
  align-items: center;
  position: absolute;
  top: 165px;
  left: 190px;
  line-height: 11px;
  padding: 0 14px;
}

.ReqMBUR {
  background: #00847f;
  position: absolute;
  top: 264px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 0 5px;
  left: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReqCBUR {
  background: #00847f;
  position: absolute;
  top: 366px;
  width: 34px;
  height: 34px;
  border-radius: 50%;
  padding: 0 5px;
  left: 67px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ReqMBUR .IMG,
.ReqCBUR .IMG {
  width: 18px;
  height: 18px;
}

.ThemeIMGTabImg {
  width: 18px;
  height: 18px;
  fill: #f3d225;
  position: absolute;
  bottom: 69px;
  left: 170px;
}

.ThemeIMGTabText {
  font-size: 9px;
  line-height: 11px;
  font-weight: 600;
  position: absolute;
  bottom: 53px;
  left: 162px;
  color: #f3d225;
}

.ColorPickerList {
  position: relative;
}

.ColorPickerList > div {
  position: absolute;
  right: 2px;
  top: 51px;
  box-shadow: none !important;
}

.NoDataMain {
  width: calc(100% - 24px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.QuickBookDiv {
  display: flex;
  justify-content: flex-end;
  margin-right: 13px;
}

.QuickBookBU {
  background: #3aaa35;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 15px;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
}

.QuickBookBU:hover {
  background: #288624;
}

.QuickBookUnlinkBU {
  background: #ff8271;
  color: #fff;
  border: none;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 15px;
  border-radius: 5px;
}

.PropertyNameSec {
  width: 80%;
  margin: 10px auto;
}

.QuickBookBU svg,
.QuickBookUnlinkBU svg {
  width: auto;
  height: 18px;
  margin-right: 8px;
  fill: #fff;
}

.CustomHeadingSelect {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  width: 80%;
  margin: 0px auto 0 auto;
}

.CustomHeadingSelect > div {
  /* border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0; */
  border: none;
}

/* .CustomHeadingSelect>div>div:nth-child(1)>div:nth-child(1) {
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  color: #505050;
} */

.CustomHeadingSelect
  > div
  > div:nth-child(1)
  > div:nth-child(1).css-1uccc91-singleValue {
  width: 100%;
  max-width: calc(100% - 20px);
}

.CustomHeadingSelect > div > div:nth-child(2) {
  display: block;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button,
button.TableAddBUSecondary {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
  margin: 0 !important;
  height: 38px;
}

.TableAddBUSecondary button:hover,
button.TableAddBUSecondary:hover {
  background: #56328c;
}

button.TableAddBUSecondary:focus,
button.TableAddBUSecondary:active {
  color: #fff !important;
  background-color: #56328c !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

button.TableAddBUSecondary:after {
  display: none;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.QuickbookHeadSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.BackArrow {
  color: #505055 !important;
  background: #fff;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-right: 16px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #333 !important;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 20px;
  height: 20px;
}

.PaddLeft30 {
  padding-left: 30px;
  display: none;
}

.ActionBuSec {
  display: flex;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU span.ActionMobileText {
  display: none;
}

.CalendarActionBU .ActionsBU {
  padding: 11px 20px 11px 15px !important;
  background-color: #ff8271 !important;
}

.CalendarActionBU .ActionsBU:hover {
  background-color: #e16e5f !important;
}

.ActionsBU.WithoutBG {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #333 !important;
  background-color: transparent !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
}

.ActionsBULine {
  width: auto;
  max-width: 180px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0 0 0 20px !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058 !important;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active + .btn-primary.ActionsBU.Red,
.btn-check:checked + .btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: 0px;
  margin-left: 10px;
}

.CalendarActionBU .ActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: 0px;
  margin-left: 0px;
  margin-right: 10px;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none !important;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #f8f8f8;
}

.ActionsBUListItem:last-child {
  border: none;
}

.ActionSec {
  display: flex;
  justify-content: flex-end;
}

.EditApplicantMain {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
}

.ProfilePicArea {
  width: 400px;
  background: #fe8371;
  display: flex;
  align-items: center;
  position: relative;
}

.ProfilePicSec {
  width: 100%;
  padding: 50px 25px;
  position: relative;
}

.ProfilePicArea .ImageArea {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}

.ProfilePicArea .ImageArea img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.UploadIcon {
  width: 40px;
  height: 40px;
  position: absolute;
  background: #fff;
  padding: 0;
  min-width: 40px;
  border-radius: 50%;
  right: 7px;
  bottom: -2px;
}

.UploadIcon:hover {
  background: #e2e2e2;
}

.UploadIcon svg {
  width: 18px;
  height: 18px;
}

.ApplicantName {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 42px;
  margin-top: 30px;
}

.AppliFormArea {
  width: 400px;
  padding: 60px 50px 40px 50px;
  position: relative;
}

.AppliFormArea {
  width: 400px;
  padding: 60px 30px 40px 30px;
  position: relative;
}

.FormGroup {
  width: 100% !important;
  padding-bottom: 10px !important;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupHalfB {
  width: 55% !important;
  padding-bottom: 10px !important;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupHalfS {
  width: 40% !important;
  padding-bottom: 10px !important;
  font-size: 0.875rem;
  position: relative;
}

.ButtonArea {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
}

.ClosePopsBU,
.ClosePopsBU:hover {
  background: no-repeat;
  padding: 0;
  border: none;
  position: absolute;
  top: 18px;
  right: 20px;
  color: #666;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #666;
  margin: 0 0 5px 0;
}

.TabSecMain {
  padding: 20px 0;
}

.TabTitleSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid #230058;
}

.TabTitleSec .Title {
  font-size: 15px;
  color: #666;
  font-weight: 600;
  margin: 0;
}

.TabTitleSec .SubTitle {
  font-size: 13px;
  color: #666;
  font-weight: 400;
  margin: 0;
}

.ButtonVoiletSolidSM {
  padding: 12px 26px;
  background-color: #502688;
  margin: 20px 0 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolidSM:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.FieldM {
  color: #333;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.QuickbookHeadSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 8px;
}

.imageValidationError {
  /* color: #F44336;
  font-size: 12px;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  transform: translate(-50%, 0px);
  width: 280px; */
  position: fixed;
  display: flex;
  align-items: center;
  text-align: center;
  padding: 5px 15px;
  white-space: pre-line;
  min-height: 50px;
  margin-bottom: 15px;
  border-radius: 5px;
  animation-name: propertydetails_fade-in-up__7ssf4;
  animation-duration: 1s;
  animation-fill-mode: both;
  font-size: 15px;
  color: rgb(185, 74, 72);
  background-color: rgb(242, 222, 222);
  border-color: rgb(238, 211, 215);
  top: 75px;
  left: 50%;
  transform: translateX(-50%);
}

.RentalAppliSec {
  width: 100%;
  padding: 12px 0 0 0;
}

.ShareAppliSec {
  width: 100%;
  border-bottom: 1px solid #dfe2e6;
  margin-bottom: 24px;
  padding-bottom: 24px;
}

.ShareAppliTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin: 0 0 8px 0;
}

.ShareAppliCopySec {
  max-width: 650px;
  width: 100%;
  height: 45px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 10px 16px;
}

.ShareAppliCopySec .CopyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #495057;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ShareAppliCopySec .CopyBU {
  border: none;
  padding: 0 0 0 10px;
  background: none;
}

.ShareAppliCopySec .CopyBU:focus {
  outline: none;
}

.ShareAppliQuestionSec {
  width: 100%;
}

.QuestionSecTText {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  margin: 0 0 20px 0;
  color: #333333;
}

.QuestionListSec {
  margin: 0 0 15px 0 !important;
  padding: 0 !important;
  display: flex;
  flex-wrap: wrap;
}

.QuestionListSec li {
  list-style: none;
  width: 50%;
  padding: 8px 24px 4px 24px;
}

.QuestionListSec li:nth-child(odd) {
  padding: 8px 24px 4px 0px;
  border-bottom: 1px solid #dfe2e6;
  border-right: 1px solid #dfe2e6;
}

.QuestionListSec li:nth-child(even) {
  padding: 8px 0px 4px 24px;
  border-bottom: 1px solid #dfe2e6;
  border-right: 0px solid #dfe2e6;
}

/* .QuestionListSec li:last-of-type {
  border-bottom: none;
} */

.QuestionListSec li .QuestionNo {
  font-weight: 600;
  font-size: 12px;
  line-height: 21px;
  margin-bottom: -2px;
  text-transform: uppercase;
  color: #aaaaaa;
}

.QuestionSec {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.QuestionSec .Question {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0 12px 0 0;
  color: #333333;
}

.DisabledSec svg {
  color: #502688;
  width: 20px;
  margin-right: 10px;
}

.FormGroupHalf {
  width: 42% !important;
  padding: 5px 0 !important;
  font-size: 0.875rem;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Dollar .formControl {
  padding: 0.375rem 0.75rem 0.375rem 4.3rem;
}

.AccoDollarSign {
  position: absolute;
  top: 1px;
  left: 1px;
  width: 43px;
  height: 43px;
  background: #f0f0f0;
  color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
}

.AccoDollarSign svg {
  width: 20px;
  height: 20px;
}

.Width100 {
  width: 100%;
  position: relative;
}

.AccoFormSec .Width100 {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
}

.AppliFormArea form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ModalFormGroupH {
  width: 48%;
  padding-bottom: 10px;
  font-size: 0.875rem;
  position: relative;
}

.ReffStatus {
  margin: 25px 10px 0 0;
  font-size: 14px;
  white-space: nowrap;
  /* display: table-cell; */
  display: inline-block;
  vertical-align: middle;
}

.ReffStatus.Archived {
  background: #e5e5e5;
  color: #505050 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  display: inline-block;
  margin: 0 15px 0 0;
}

.ReferStatusBUNew {
  padding: 5px 15px 4px 15px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 15px 0 0 !important;
  line-height: unset !important;
  /* height: 26px !important; */
  display: flex;
}

.ReferStatusBUNew:after {
  display: none !important;
}

.ReferStatusBUNew:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBUNew svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 6px;
  margin-right: 5px;
}

svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 0px;
}

.ReferStatusBUNew.StatusBuRed,
.ReferStatusBUNew.StatusBuRed:active,
.ReferStatusBUNew.StatusBuRed:checked {
  background-color: #eb575766 !important;
  border-color: #eb575766 !important;
  color: #a63434 !important;
}

.ReferStatusBUNew.StatusBuYellow,
.ReferStatusBUNew.StatusBuYellow:active,
.ReferStatusBUNew.StatusBuYellow:checked {
  background-color: #f8d52466 !important;
  border-color: #f8d52466 !important;
  color: #71621b !important;
}

.ReferStatusBUNew.Approved,
.ReferStatusBUNew.Approved:active,
.ReferStatusBUNew.Approved:checked {
  background-color: #00808066 !important;
  border-color: #00808066 !important;
  color: #006060 !important;
}

.ReferStatusBUNew.Prequalified,
.ReferStatusBUNew.Prequalified:active,
.ReferStatusBUNew.Prequalified:checked {
  background-color: #23005866 !important;
  border-color: #23005866 !important;
  color: #230058 !important;
}

.ActionsBUListItemNew {
  font-size: 13px;
  font-weight: 500;
  color: #343434;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
}

.ActionsBUListItemNew.Delete,
.ActionsBUListItemNew.Delete:hover,
.ActionsBUListItemNew.Delete:focus {
  color: #f97665;
}

.ActionsBUListItemNew:hover,
.ActionsBUListItemNew:focus {
  color: #343434;
  background: transparent;
}

.ActionsBUListItemNew:last-child {
  border: none;
}

.CardTiRow {
  display: flex;
  align-items: center;
}

.ProfileEditBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #502688;
  border-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.ProfileEditBU:hover {
  background: #230058;
}

.NoLeaseFound {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.NoLeaseFound div {
  width: 400px;
  display: block;
  text-align: center;
}

.NoLeaseFound img {
  width: 130px;
  height: auto;
  margin-bottom: 30px;
  opacity: 0.35;
}

.NoLeaseText {
  font-weight: 700;
  font-size: 30px;
  line-height: 36px;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 30px 0;
}

.AddLeaseBU {
  padding: 9px 20px 9px 17px;
  background-color: #ff8271;
  margin: 0 auto;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
}

.AddLeaseBU svg {
  width: 22px;
  margin-right: 7px;
}

.AddLeaseBU:hover {
  background-color: #e97565;
  border: 1px solid #e97565;
}

.AddUserMain {
  width: 600px;
  padding: 0px;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  font-size: 0.875rem;
}

.FormGroup {
  width: 48% !important;
  padding-bottom: 15px !important;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupFull {
  width: 100% !important;
  padding-bottom: 15px !important;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupFullPay {
  width: 100% !important;
  padding: 15px 0 !important;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupFullPay .eyeIcon {
  position: absolute;
  top: 27px;
  right: 10px;
  width: 20px;
  color: #4d4d4d;
  cursor: pointer;
}

.FilterLabelLease {
  font-weight: 600;
  font-size: 13px;
  line-height: 30px;
  color: #333333;
  margin-bottom: 2px;
}

.formControlLease {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 46px;
  padding: 0.375rem 0.75rem;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControlLeaseTextArea {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 140px;
  padding: 0.375rem 0.75rem;
  resize: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.AddBUUnit {
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.AddBUUnit:hover {
  background: #e97565;
  color: #fff;
}

.AddBUUnit svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.SMSmallSideBody {
  width: 100%;
}

.SMBigSideBodyUnder {
  width: 100%;
  padding-top: 0px;
}

.CustomSelect > div {
  border-radius: 6px;
  border-color: #e1e1e1;
  min-height: 42px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 0px;
}

.formControlTextArea {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 140px;
  padding: 0.375rem 0.75rem;
  resize: none;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.AddLayoutForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AddSection {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px #f5f5f5;
  border-radius: 8px;
  margin: 15px 0;
  padding: 20px;
  max-height: 100%;
}

.AddLeaseSection {
  width: 100%;
  background: #fff;
  /* box-shadow: 0 0 20px #f5f5f5; */
  border-radius: 8px;
  margin: 15px 0 0 0;
  padding: 10px 0px 0 0;
  max-height: 100%;
}

.FormGroupTextareaFull {
  width: 100%;
  padding-bottom: 15px;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupAddLease {
  width: 100%;
  /* max-width: 600px; */
  padding-bottom: 15px;
  font-size: 0.875rem;
  position: relative;
}

.FilterSec {
  width: 100%;
  display: flex;
}

.ActionsDiv {
  display: flex;
  align-items: center;
}

.ActionsDiv a,
.ActionsDiv button {
  color: #424242;
  margin-right: 10px;
  padding: 0;
  width: 25px;
  min-width: 25px;
}

.ActionsDiv button:hover {
  background-color: transparent;
}

@import url("https://fonts.googleapis.com/css2?family=Parisienne&display=swap");

.ActionsDiv a svg,
.ActionsDiv button svg {
  width: 20px;
  height: 20px;
}

.AddDocTitleSec {
  width: 100%;
  display: flex;
  /* align-items: center; */
  justify-content: space-between;
}

.ResizeIcon {
  width: 60px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  color: #502688;
  border-radius: 8px;
}

.ResizeIcon2 {
  width: 60px;
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: none;
  color: #502688;
  border-radius: 8px;
  display: none;
}

.ResizeIcon svg,
.ResizeIcon2 svg {
  width: 26px;
  height: auto;
}

.AddLeaseSection.Maximize .ResizeIcon2 {
  display: flex;
}

.AddLeaseSection.Maximize .ResizeIcon {
  display: none;
}

.AddLeaseSection.Maximize {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9999;
  width: Calc(100% - 85px);
  height: 100%;
  margin: 0 0 0 85px;
  padding: 25px;
}

.ProEditSec {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.EditActionsBU {
  width: 40px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #39414d !important;
  background-color: rgb(255 255 255 / 90%) !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.EditActionsBU:hover {
  background-color: rgb(255 255 255 / 100%) !important;
  color: #39414d !important;
}

.EditActionsBU.Table {
  background-color: transparent !important;
  color: #502688 !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 0;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.CalendarActionBU {
  position: absolute;
  right: 0;
  top: -4px;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.ModalFormGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AddTourModal {
  display: flex;
  flex-wrap: wrap;
}

.AddTourTimeArea {
  width: 250px;
}

.AddTourTimeArea ul {
  width: 100%;
  margin: 0;
  padding: 0 15px 0 0 !important;
  height: 340px;
  overflow: auto;
}

.AddTourTimeArea ul li {
  list-style: none;
  width: 100%;
  border: 1px solid #502688;
  border-radius: 8px;
  padding: 10px 16px;
  margin: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.AddTourTimeArea ul li:hover {
  background-color: rgb(109 77 159 / 30%);
}

.AddTourTime {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #502688;
  margin: 0;
}

.TimeSlotTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0 0 15px 0;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.AddTourTimeArea ul li.TimeSelected {
  background-color: #502688;
}

.AddTourTimeArea ul li.TimeSlotBooked {
  border: 1px solid #efefef;
  background-color: #efefef;
  cursor: context-menu;
}

.AddTourTimeArea ul li.TimeSelected .AddTourTime {
  color: #fff;
}

.AddTourTimeArea ul li.TimeSlotBooked .AddTourTime {
  color: #666666;
}

.AddTourOpenHouse {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666;
}

.AddTourOpenHouse svg {
  width: 18px;
  color: #efd763;
  fill: #efd763;
  margin-right: 5px;
}

.AddTourTimeArea ul li.TimeSelected .AddTourOpenHouse {
  color: #efefef;
}

.AddTourScheduled {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}

.AddTourScheduled svg {
  width: 18px;
  color: #666666;
  margin-right: 5px;
}

.FilterCard {
  width: 100%;
  padding: 25px 0px 5px 0;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCard.Select > div > div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.Permission h5 {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 25px 0;
  font-weight: 500;
  width: 100%;
}

.PermissionSwitch {
  font-size: 14px;
  font-weight: 600;
  color: #7e7e7e;
  margin: 5px 0;
}

.ProfileEditFormSec .FormGroup {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 20px 0px 20px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileEditFormSec .FormGroup > div {
  width: 100%;
}

.ProfileEditFormSec .FilterLabel {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 80px;
  white-space: nowrap;
}

.ProfileEditFormSec .formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 21px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ProfileEditFormSec .formControl.Error {
  border: 1px solid #f40;
}

.ProfileEditFormSec .ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 0.5rem !important;
  width: 48% !important;
  margin: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
  font-family: "Inter", sans-serif !important;
}

.ProfileEditFormSec .ButtonVoiletSolid:hover {
  background: #e16e5f !important;
}

.ProfileEditFormSec .ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0.5rem !important;
  width: 48% !important;
  margin: 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
  font-family: "Inter", sans-serif !important;
}

.ProfileEditFormSec .ButtonVoiletLine:hover {
  background: #f1ecf3 !important;
}

.BreadcrumbHeadSec {
  width: 100%;
  padding: 0 0px 25px 0px;
}

.pac-container {
  z-index: 999999 !important;
}

.uploadButton {
  display: none;
}

.PageTitleSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0 0;
  position: relative;
}

.PageTitleSec>div {
  display: flex;
  align-items: center;
}

.AddFormTitleSec {
  display: none;
  align-items: center;
  padding-bottom: 25px;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.AddPropertyTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #505050;
  width: 100%;
  margin-bottom: 0px;
}

.EditProfileSec {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 40px;
  background-color: #fff;
  overflow: auto;
}

.ProfileIMGSec {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProfileIMGCard {
  position: relative;
}

.ProfileIMGMobile {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #f0f0f0;
}

.ProfileIMGMobile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ProfileIMGUploadBUMobile {
  width: 32px;
  height: 32px;
  background-color: #ffffff !important;
  color: #6c4c9e !important;
  position: absolute !important;
  bottom: 0;
  right: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ProfileEditFormSecMobile {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0 !important;
}

.EditFormFullSec {
  width: 100% !important;
  padding: 10px 0 !important;
}

.EditFormHalfSec {
  width: 48% !important;
  padding: 10px 0 !important;
}

.EditFormOneTSec {
  width: 68% !important;
  padding: 10px 0 !important;
}

.EditFormOneTSecSm {
  width: 28% !important;
  padding: 10px 0 !important;
}

.ButtonSec {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AddBU {
  padding: 15px 25px;
  background-color: #ff8271;
  margin: 0 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.CancelBU {
  padding: 15px 25px;
  background-color: #fff;
  margin: 0 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.CancelBU:hover {
  background-color: #f1ecf3;
  /* border: 1px solid #230058;
  color: #fff; */
}

.LeaseTemplateRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  align-content: space-between;
}

.LeaseTemplateCard {
  width: 33.33%;
  padding: 12px 12px;
}

.LeaseTemplateCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease-in-out;
}

.LeaseTemplateCardUnder:hover {
  box-shadow: 0 0 28px 0 #1f02541f;
}

.TemplateCardHead {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.LeaseIMGSec {
  min-width: 60px;
  width: 70px;
  min-height: 60px;
  height: 70px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.LeaseIMGSec img {
    width: 35px;
    height: 35px;
    object-fit: contain;
    object-position: center;
}

.LeaseConSec {
  width: calc(100% - 86px);
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.LeaseConTitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  width: calc(100% - 30px);
  margin: 0 0 5px 0;
  color: #505050;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif !important;
}

.TemplateCardFoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.LeaseConDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
}

.PublishBadge {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  width: auto !important;
}

.UnublishBadge {
  background-color: #dfe2e6;
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  width: auto !important;
}

.ActionLeaseBUSec {
  position: absolute !important;
  top: -10px;
  right: -12px;
}

.ActionsLeaseBU {
  width: 40px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #39414d !important;
  background-color: #ffffff !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.ActionsLeaseBU:hover,
.btn-check:active + .btn-primary.ActionsLeaseBU,
.btn-check:checked + .btn-primary.ActionsLeaseBU,
.ActionsLeaseBU.btn-primary.active,
.ActionsLeaseBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsLeaseBU {
  background-color: #fff !important;
  border: none;
}

.ActionsLeaseBU svg {
  width: 20px;
  height: 20px;
}

.ButtonAction {
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.ProfileCardEditSec {
  width: 100%;
  padding: 25px 0 0px 0;
}

.ProfileCardEditSec form {
  width: 100%;
}

.ProfileCardEditSec .FormGroupFull {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 0px 0px 0px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileCardEditSec .FormGroupFull.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ProfileCardEditSec .TimePick {
  width: 48%;
  position: relative;
  margin: 0px 0;
}

.ProfileCardEditSec .TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 7px;
  color: #585858;
  transform: translate(0px, -50%);
}

.ProfileCardEditSec .TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.ProfileCardEditSec .ButtonArea {
  display: flex;
  justify-content: flex-start;
  padding: 0px 0px 10px 0px;
}

.ProfileCardEditSec .ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 0.5rem !important;
  margin: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletSolid:hover {
  background: #e16e5f !important;
}

.ProfileCardEditSec .ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0.5rem !important;
  margin: 0 15px 0 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletLine:hover {
  background: #f1ecf3 !important;
}

.AddUnitSec {
  width: 100%;
  padding-top: 25px;
}

.SettingsTabSec {
  width: 100%;
}

.SettingsTitle {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 12px 0;
  font-weight: 500;
  width: 100%;
}

.ReqBigFilter {
  display: flex;
  align-items: center;
}

.ReqSmallFilter {
  display: none;
  align-items: center;
}

.ReqSmallFilter svg {
  margin: 0;
}

.CheckFilter {
  display: flex;
  flex-wrap: wrap;
}

.CheckFilter label {
  width: 100%;
  margin: 0;
}

.CheckFilter label span {
  font-size: 14px;
  font-weight: 500 !important;
}

@media screen and (min-width: 360px) {
  .AddCardMain {
    width: 100%;
  }

  .FormGroupBig {
    width: 100% !important;
  }

  .FormGroupSmall {
    width: 100% !important;
  }

  .FullFormGroup {
    width: 100% !important;
  }

  .AddCardMain {
    padding: 0px;
  }

  /* .ButtonDiv {
    padding: 0 0px;
  } */
  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: 100%;
    display: inline-block;
  }

  .DetailsDTextGroup .DetailsDTextFull {
    width: 100%;
  }

  /* .SMProCusRow {
    flex-wrap: wrap;
  } */

  .SMProCusSmallDiv {
    min-width: 100%;
    max-width: 100%;
  }

  .SMProCusBigDiv {
    width: 100%;
  }

  .FilesUploadGrid {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConDivSmall {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .MainReqConAction {
    width: 100%;
    padding-top: 10px;
  }

  .DemoIMG {
    width: 250px;
    margin-left: auto;
    margin-right: auto;
  }

  .DemoIMG .ThemeLogoIMG {
    top: 52px;
    left: 117px;
    max-width: 50px;
    max-height: 16px;
    height: 16px;
  }

  .ThemeIMGLabel {
    top: 53px;
    left: 50px;
    font-size: 6px;
    width: 9px;
    height: 9px;
  }

  .RequestAddBU {
    margin: 6px 0;
    width: 78px;
    height: 33px;
    font-size: 7px;
    top: 111px;
    left: 41px;
    line-height: 9px;
    padding: 0 8px;
  }

  .RequestAddBUC {
    margin: 6px 0;
    width: 78px;
    height: 33px;
    font-size: 7px;
    top: 111px;
    left: 129px;
    line-height: 9px;
    padding: 0 8px;
  }

  .RequestAddBU .IMG,
  .RequestAddBUC .IMG {
    width: 13px;
    height: 13px;
    margin-right: 6px;
  }

  .ReqMBUR {
    top: 173px;
    width: 22px;
    height: 22px;
    padding: 0 5px;
    left: 52px;
  }

  .ReqCBUR {
    top: 239px;
    width: 22px;
    height: 22px;
    padding: 0 5px;
    left: 52px;
  }

  .ReqMBUR .IMG,
  .ReqCBUR .IMG {
    width: 12px;
    height: 12px;
  }

  .ThemeIMGTabImg {
    width: 11px;
    height: 11px;
    bottom: 52px;
    left: 116px;
  }

  .ThemeIMGTabText {
    font-size: 5px;
    line-height: 9px;
    bottom: 37px;
    left: 112px;
  }

  .ThemeText {
    top: 100px;
    left: 50px;
  }

  .ThemeTextTitle {
    font-size: 11px;
  }

  .ThemeTextCon {
    font-size: 4px;
  }

  .ThemeIMGBU {
    width: 95px;
    height: 20px;
    font-size: 6px;
    padding-top: 6px;
    top: 230px;
    left: 50px;
  }

  .mainCard {
    margin: 0 0;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .EditApplicantMain {
    width: 100%;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 100%;
  }

  .AddUserMain {
    width: 100%;
  }
  .MainReqConAction {
    width: calc(100% - 90px);
    padding-top: 0px;
    position: absolute;
    top: 4px;
    right: 0;
    justify-content: unset;
  }
  .MainReqConAction>div {
    display: unset;
    justify-content: flex-end;
    width: calc(100% - 68px);
  }
  .MainReqConAction>div .ReferStatusBU {
    margin: 0 15px 0 0 !important;
  }
  .NotesCard {
    padding: 12px 15px;
  }
  .card .cardbody {
    padding: 1rem;
  }
  .MainReqConUR {
    width: auto;
  }
  .BreadcrumbHeadSec {
    padding: 0 15px 25px 15px;
  }
  .MainReqConDivBigTwo {
    width: 100%;
  }
  .MainReqConDivSmallTwo {
    width: 100%;
  }
  .MainReqConActionTwo {
    width: 80%;
    position: absolute;
    top: 4px;
    right: 0;
  }
  .QuestionListSec li {
    width: 100%;
    padding: 8px 0px 4px 0px;
  }
  .QuestionListSec li:nth-child(odd) {
    padding: 8px 0px 4px 0px;
    border-bottom: 1px solid #dfe2e6;
    border-right: 0px solid #dfe2e6;
  }
  .QuestionListSec li:nth-child(even) {
    padding: 8px 0px 4px 0px;
    border-bottom: 1px solid #dfe2e6;
    border-right: 0px solid #dfe2e6;
  }
  .LeaseTemplateCard {
    width: 100%;
  }
  .FileUploadSmall .FileUpDuBUDiv {
    width: 200px;
    height: 90px;
    margin: 0;
    padding: 0;
  }
  .FileUploadSmall .UploadLabel {
    padding: 5px 12px 5px 12px;
  }
  .FileUploadSmall .UploadLabel div.UpIcon {
    width: 45px;
    height: 45px;
    margin-bottom: 0;
    margin-right: 12px;
    padding: 12px;
  }
  .FileUploadSmall .UploadLabel span p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
    text-align: left;
  }
  .FileUploadSmall .UploadLabel span p.UploadText {
    margin: 0;
    font-size: 11px;
    line-height: 14px;
  }
  .FileUploadSmall .FormGroupFull {
    display: flex;
    flex-wrap: wrap;
    padding: 12px 0 25px 0;
  }
  .FileUploadSmall .ImgFile {
    min-width: 100px;
    width: calc(25% - 15px);
    height: 100px;
    position: relative;
    margin: 8px 15px 8px 0;
    padding: 0px;
  }
  .FileUploadSmall .UploadLabel span {
    align-items: center;
    flex-wrap: nowrap;
  }
  .FileUploadSmall .UploadLabel span > div {
    display: block;
  }
  .FileUploadSmall .UpIMGSec {
    margin: 15px 0 0 0;
  }
}

@media screen and (min-width: 480px) {
  .AddCardMain {
    padding: 20px;
  }

  /* .ButtonDiv {
    padding: 0 20px;
  } */
  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: auto;
  }

  .FileUpDuBUDivBox {
    width: 320px;
  }

  .DemoIMG {
    width: 300px;
    margin: 0;
  }

  .DemoIMG .ThemeLogoIMG {
    top: 61px;
    left: 139px;
    max-width: 25px;
    max-height: 25px;
    height: 21px;
  }

  .ThemeIMGLabel {
    top: 60px;
    left: 58px;
    font-size: 8px;
    width: 12px;
    height: 12px;
  }

  .RequestAddBU {
    margin: 6px 0;
    width: 95px;
    height: 51px;
    font-size: 9px;
    top: 125px;
    left: 50px;
    line-height: 11px;
    padding: 0 8px;
  }

  .RequestAddBUC {
    margin: 6px 0;
    width: 95px;
    height: 51px;
    font-size: 10px;
    top: 125px;
    left: 155px;
    line-height: 11px;
    padding: 0 8px;
  }

  .RequestAddBU .IMG,
  .RequestAddBUC .IMG {
    width: 16px;
    height: 16px;
    margin-right: 8px;
  }

  .ReqMBUR {
    top: 210px;
    width: 28px;
    height: 28px;
    padding: 0 5px;
    left: 60px;
  }

  .ReqCBUR {
    top: 290px;
    width: 28px;
    height: 28px;
    padding: 0 5px;
    left: 60px;
  }

  .ReqMBUR .IMG,
  .ReqCBUR .IMG {
    width: 14px;
    height: 14px;
  }

  .ThemeIMGTabImg {
    width: 14px;
    height: 14px;
    bottom: 58px;
    left: 142px;
  }

  .ThemeIMGTabText {
    font-size: 6px;
    line-height: 11px;
    bottom: 42px;
    left: 138px;
  }
}

@media screen and (min-width: 576px) {
  .FormGroupBig {
    width: 36% !important;
  }

  .FormGroupSmall {
    width: 20% !important;
  }

  .FullFormGroup {
    width: 300px;
  }

  .DetailsDTextGroup .DetailsDTextFull {
    width: auto;
  }

  .FilesUploadGrid {
    width: 50%;
  }

  .ThemeText {
    top: 162px;
    left: 67px;
  }

  .ThemeTextTitle {
    font-size: 16px;
  }

  .ThemeTextCon {
    font-size: 7px;
  }

  .ThemeIMGBU {
    width: 160px;
    height: 30px;
    font-size: 8px;
    padding-top: 9px;
    top: 350px;
    left: 65px;
  }

  .mainCard {
    margin: 0 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
  
  .BreadcrumbHeadSec {
    padding: 0 0px 25px 0px;
  }
}

@media screen and (min-width: 768px) {
  .AddCardMain {
    width: 600px;
  }

  .FormGroupBig {
    width: 180px !important;
  }

  .FormGroupSmall {
    width: 110px !important;
  }

  .FileUpDuBUDivBox {
    width: 290px;
  }

  .DemoIMG {
    margin-left: 50px;
    margin-right: 0;
  }

  
  .NotesCard {
    padding: 15px 15px;
  }
  .card .cardbody {
    padding: 1.5rem;
  }
  .MainReqConDivBigTwo {
    width: 45%;
  }
  .MainReqConDivSmallTwo {
    width: 35%;
    display: flex;
    align-items: center;
  }
  .MainReqConActionTwo {
    width: 20%;
    position: unset;
    top: 4px;
    right: 0;
  }
  .QuestionListSec li {
    width: 50%;
    padding: 8px 0px 4px 0px;
  }
  .QuestionListSec li:nth-child(odd) {
    padding: 8px 24px 4px 0px;
    border-bottom: 1px solid #dfe2e6;
    border-right: 1px solid #dfe2e6;
  }
  .QuestionListSec li:nth-child(even) {
    padding: 8px 0px 4px 24px;
    border-bottom: 1px solid #dfe2e6;
    border-right: 0px solid #dfe2e6;
  }
  .LeaseTemplateCard {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .FilesUploadGrid {
    width: 315px;
  }

  .MainReqConDivBig {
    width: 36%;
  }

  .MainReqConDivSmall {
    width: 26%;
  }

  .MainReqConAction {
    width: 12%;
    padding-top: 0;
    position: unset;
    top: 0;
    right: 0;
    justify-content: flex-end;
  }
  .MainReqConAction>div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .MainReqConAction>div .ReferStatusBU {
    margin: 0 0 0 0 !important;
  }
  .MainReqConUR {
    width: 100%;
  }

  .FileUpDuBUDivBox {
    width: 300px;
  }

  .DemoIMG {
    margin-left: 50px;
  }

  .EditApplicantMain {
    width: 800px;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 400px;
  }
  .NotesCard {
    padding: 15px 25px;
  }
}

@media screen and (min-width: 1024px) {
  .FilesUploadGrid {
    width: 320px;
  }
}

@media screen and (min-width: 1100px) {
  /* .SMProCusRow {
    flex-wrap: nowrap;
  } */

  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 330px);
  }

  /* .SMProCusBigDiv {
    width: 100%;
  } */

  .MainReqConDivBig {
    width: 60%;
  }

  .MainReqConDivSmall {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .MainReqConAction {
    width: 85%;
    padding-top: 0px;
    position: absolute;
    top: 4px;
    right: 0;
    justify-content: unset;
  }

  .MainReqConUR {
    width: auto;
  }

  /* .AddUserMain {
    width: 500px;
  } */
  .MainReqConAction>div {
    display: unset;
    justify-content: flex-end;
    width: calc(100% - 68px);
  }
  .MainReqConAction>div .ReferStatusBU {
    margin: 0 15px 0 0 !important;
  }
  .LeaseTemplateCard {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  /* .SMProCusBigDiv {
    width: 100%;
  } */

  /* .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConDivSmall {
    width: 60%;
  }

  .MainReqConAction {
    width: 40%;
    padding-top: 0;
  } */

  .DemoIMG {
    margin-left: 30px;
  }
  
  .LeaseTemplateCard {
    width: 50%;
  }
}

@media screen and (min-width: 1300px) {
  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 330px);
  }

  /* .SMProCusBigDiv {
    width: 100%;
  } */

  .DemoIMG {
    margin-left: 50px;
  }
}

@media screen and (min-width: 1360px) {
  /* .MainReqConAction>div {
    display: unset;
    justify-content: flex-end;
    width: auto;
  } */
}

@media screen and (min-width: 1400px) {
  .SMProCusSmallDiv {
    min-width: 300px;
    max-width: 300px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 300px);
  }

  /* .SMProCusBigDiv {
    width: 100%;
  } */

  .MainReqConAction>div {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  .MainReqConAction>div .ReferStatusBU {
    margin: 0 0 0 0 !important;
  }
  .MainReqConDivBig {
    width: 33%;
  }
  .MainReqConDivSmall {
    width: 24%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .MainReqConAction {
    width: 18%;
    padding-top: 0px;
    position: unset;
    top: 0;
    right: 0;
    justify-content: flex-end;
  }
  .MainReqConUR {
    width: 100%;
  }
}

@media screen and (min-width: 1500px) {
  .SMProCusSmallDiv {
    min-width: 320px;
    max-width: 320px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 320px);
  }

  .MainReqConDivBig {
    width: 32%;
  }

  .MainReqConDivSmall {
    width: 22%;
  }

  .MainReqConAction {
    width: 24%;
  }
  .MainReqConAction>div {
    display: unset;
    justify-content: flex-end;
    width: auto;
  }
  .MainReqConAction>div .ReferStatusBU {
    margin: 0 15px 0 0 !important;
  }
}

@media screen and (min-width: 1600px) {
  .SMProCusSmallDiv {
    min-width: 350px;
    max-width: 350px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }
  .LeaseTemplateCard {
    width: 33.33%;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media screen and (min-width: 1800px) {
  /* .SMProCusBigDiv {
    width: 100%;
  } */

  .MainReqConDivBig {
    width: 30%;
  }

  .MainReqConDivSmall {
    width: 23%;
  }

  .MainReqConAction {
    width: 24%;
  }
}

@media screen and (max-width: 767px) {
  .BreadcrumbHeadSec nav {
    display: none;
  }
  .SMProCusRow {
    margin: 0px 0px 0px 0px;
  }
  .PageTitleSec {
    padding: 25px 0 0 0;
  }
  .ActionsBU span.ActionNormalText {
    display: none;
  }
  .ActionsBU span.ActionMobileText {
    display: block;
  }
  .ActionsBU svg {
    min-width: 16px;
    min-height: 16px;
    width: 24px;
    height: 24px;
    margin-bottom: 0;
    margin-left: 0px;
  }
  .ActionsBU {
    width: 40px;
    height: 40px;
    color: #39414d !important;
    background-color: #ffffff !important;
    padding: 0 !important;
    border-radius: 50% !important;
    align-items: center;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }
  .ActionsBU:hover,
  .btn-check:active + .btn-primary.ActionsBU,
  .btn-check:checked + .btn-primary.ActionsBU,
  .ActionsBU.btn-primary.active,
  .ActionsBU.btn-primary:active,
  .show > .btn-primary.dropdown-toggle.ActionsBU {
    background-color: #eeeeee !important;
    border: none;
  }
  .PageTitleSec>div.Dropdown {
    position: absolute;
    right: 10px;
    bottom: -74px;
    z-index: 1;
  }
  .AddSection {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 999;
    overflow: auto;
    box-shadow: none;
    padding: 40px 40px;  
  }
  .AddFormTitleSec {
    display: flex;
  }
  .SMSmallSideBody {
    width: 100%;
  }
  .SMBigSideBodyUnder {
    width: 100%;
    padding-top: 0px;
  }
  .BackAddBU {
    display: flex !important;
  }
}

@media screen and (max-width: 575px) {
  .PageTitleSec>div.Dropdown {
    right: -5px;
  }
  .AddSection {
    padding: 30px 30px;  
  }
  .FileUploadSmall .ImgFile {
    width: calc(33.33% - 15px);
    height: 140px;
  }
  .ReqBigFilter {
    display: none;
  }
  .ReqSmallFilter {
    display: flex;
  }
  .ActionsBULine {
    padding: 11px 15px 11px 15px !important;
  }
}

@media (max-width: 479px) {
  .FileUploadSmall .ImgFile {
    width: calc(50% - 15px);
    height: 120px;
  }
  .ButtonDiv {
    display: flex;
    justify-content: space-between;
  }
  .ButtonVoiletLine {
    margin: 0;
    width: 48%;
  }
  .ButtonVoiletSolid {
    margin: 0;
    width: 48%;
  }
  .FormRow {
    width: 100%;
  }
  .EditProfileSec {
    padding: 30px;
  }
  .ButtonSec {
    display: flex;
    justify-content: space-between;
  }
  .AddBU {
    margin: 0;
    width: 47%;
  }
  .CancelBU {
    margin: 0;
    width: 47%;
  }
  .EditFormHalfSec {
    width: 100% !important;
  }
  .EditFormOneTSec {
    width: 100% !important;
  }
  .EditFormOneTSecSm {
    width: 100% !important;
  }
}
