.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem .6rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
  text-align: center;
}

.MainCard {
  width: 100%;
  padding-bottom: 70px;
}

.MoreList {
  display: flex;
  flex-wrap: wrap;
  padding: 15px 25px;
  background: #fff;
  margin: 10px 0;
}

.OptionCard {
  width: 120px;
  padding: 0px 0px;
  margin: 10px 0 5px 0;
}

.OptionCardFull {
  width: 100%;
  padding: 14px 20px 30px 20px;
}

.OptionCardUnder {
  width: 100%;
  min-height: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
  padding: 20px 7px 0px 7px;
  border-radius: 6px;
  /* background: rgb(255 255 255 / 40%);
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px; */
}

.OptionCardUnderFull {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 10px;
  border-radius: 6px;
  background: #ff8271;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
}

.OptionCardUnder svg {
  width: 22px;
  height: auto;
  color: #502688;
  stroke-width: 1.5px;
  margin-bottom: 6px;
}

.OptionCardUnderFull svg {
  width: 22px;
  height: auto;
  color: #fff;
  stroke-width: 1.5px;
  margin-right: 12px;
}


.OptionCardUnder p {
  width: 100%;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  color: #343434;
  margin: 0;
}

.OptionCardUnderFull p {
  width: auto;
  text-align: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  margin: 0;
}

.MoreCateTitle {
  margin: 0 0 5px 0;
  color: #505050;
  font-size: 13px !important;
  font-weight: 500 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
  text-align: left;
  width: 100%;
  padding: 0 5px;
}

@media (min-width: 360px) {
  .OptionCard {
    width: 130px;
  }
}

@media (min-width: 400px) {
  .OptionCard {
    width: 117px;
  }
}

@media (min-width: 480px) {
  .OptionCard {
    width: 130px;
  }
}

@media (max-width: 479px) {
  .pageTitleWrap {
    padding: 1.8rem 0.8rem 0.6rem 0.8rem;
  }
  .pageTitle {
    width: 100%;
    order: 2;
  }
  .MoreList {
    padding: 15px;
    background: #fff;
    margin: 10px 0;
  }
}
