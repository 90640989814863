.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 80px !important;
  z-index: 2;
}

.SMProCusBigDiv {
  width: 100%;
  padding: 0 0px;
}

.Card {
  width: 100%;
  padding: 20px;
  box-shadow: none;
  border-radius: 0rem;
  overflow: hidden;
  position: relative;
  background: #fff;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 0rem;
}

.ActionRefMain {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 20px;
}

.TitleSummary {
  font-size: 18px;
  line-height: 40px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 0;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 0px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.SMBigSideBody {
  position: relative;
}

.BorderDivSumm {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 15px 0 30px 0;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.SummFormFull {
  width: 100%;
  padding: 5px 15px;
}

.SummFormSemiHalf {
  width: 25%;
  padding: 5px 15px;
}

.SummFormSemiHalf img {
  border-radius: 10px;
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 5px 0 #3333333b;
}

.SummFormLabel {
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  color: #333;
  margin: 0 0 7px 0;
}

.FormInput {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  position: relative;
  padding: 1px 1px 1px 28px;
}

.FormInput svg {
  position: absolute;
  top: 50%;
  left: 10px;
  transform: translate(0, -50%);
  width: 19px;
  opacity: 0.6;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControlTextArea {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 100px;
  padding: 12px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
}

.formControl.Date > div {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.5;
  color: #495057;
}

.formControl.Date > div:after,
.formControl.Date > div:before {
  display: none;
}

.ReferExpMain {
  display: table;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  margin: 15px 0;
  /* overflow: hidden; */
  width: 100%;
}

.ReferBodySummCommNote {
  display: flex;
  padding-top: 8px;
}

.ReferBodySummCommNote img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.ReferBodySummCommBody {
  width: 100%;
  padding-left: 25px;
}

.NoteListTitle {
  display: flex;
}

.SummCommBodyName {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #230058;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-right: 70px;
}

.SummCommBodyName span {
  color: #9f9f9f;
  padding-left: 8px;
}

.NoteListIcons {
  display: flex;
  position: absolute;
  top: 22px;
  right: 30px;
}

.NoteListIcons svg {
  width: 18px;
  height: 18px;
  color: #333;
  stroke-width: 1;
  transition: all 0.3s ease-in-out;
}

.RateStarDiv svg {
  width: 18px;
  height: 18px;
  color: #333;
  stroke-width: 1;
  transition: all 0.3s ease-in-out;
}

.StarNote:hover svg {
  color: #ff8271;
  fill: #ff8271;
}

.StarNoteActive svg {
  color: #ff8271;
  fill: #ff8271;
}

.RateStarDiv {
  margin-bottom: 15px;
}

.RateStarDiv a {
  margin-right: 3px;
}

.FilesUpCardDD,
.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.DropDownMainFiles .FilesUpCardDD,
.DropDownMainFiles .FilesUpCardDD:active,
.DropDownMainFiles .FilesUpCardDD:checked,
.DropDownMainFiles .FilesUpCardDD:hover,
.DropDownMainFiles .FilesUpCardDD:focus {
  padding: 6px 8px;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0;
}

.FilesUpCardDD:after {
  display: none;
}

.FilesUpCardDD svg {
  width: 18px;
  stroke-width: 2;
}

.DropDownMain {
  background: none;
  border: none;
  padding: 0 0 0 15px;
  margin-top: -4px;
  width: 35px;
}

.SummCommBodyDate {
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #a7abc3;
}

.SummCommBodyText {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
}

.NotesCommentIconsec {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.NotesCommentLink {
  font-size: 13px;
  font-weight: 500;
  color: #230058;
  line-height: normal;
  margin-bottom: 0;
}

.NotesCommentLink svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  stroke-width: 1;
  color: #a9a9a9;
  margin-bottom: 0px;
}

.ReferNoteSec {
  width: 100%;
  flex-wrap: wrap;
  padding: 17px 48px 17px 90px;
}

.ReffScrollDiv {
  width: 100%;
  max-height: 800px;
  overflow: auto;
  margin-bottom: 15px;
  padding-right: 8px;
}

.ReffScrollDiv .ReferBodySummCommNotes {
  width: 100%;
}

.ReferBodySummCommNotes {
  display: flex;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  overflow: hidden;
  padding: 25px;
  width: 100%;
  margin: 15px 0;
  position: relative;
}

.ReferBodySummCommNotes img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.formGroup {
  margin-bottom: 12px;
}

.formGroup p {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #dc3545;
}

.FormGroupCusB p {
  width: 100%;
  font-size: 12px;
  margin-bottom: 0px;
}

.errorSpan {
  color: #ff000d;
  font-weight: bold;
  margin-top: 0.25rem;
  font-size: 13px;
}

.formGroupLabel {
  margin-bottom: 8px;
  display: block;
}

.formGroupTextArea {
  width: 100%;
  resize: none;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.ReferCommentArea {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  height: 80px;
  padding: 6px;
  resize: none;
}

.ReferSubmitBuNotes {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #fff;
  background: #aa66cb;
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ReferSubmitBuNotes:hover {
  background: #a862ca;
}

.ReferCancelBuNotes {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #fff;
  background: #ff8271;
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ReferCancelBuNotes:hover {
  background: #e97565;
}

.HistoryTitle {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.HistoryTitle img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.HistoryTitleCon {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.HistoryTitleCon span {
  font-size: 14px;
  color: #a7abc3;
  font-weight: 400 !important;
  margin-left: auto;
}

.BorderDivSummHis {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 5px 0 15px 0;
}

.BorderDivSummComm {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
}

.StatusChangeBU {
  color: #fff;
  background: #333;
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 5px;
  white-space: nowrap;
}

.StatusChangeBU svg {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.YellowBu {
  background: #fb3;
  color: #000;
}

.GreenBu {
  background: #17dd1b;
  color: #fff;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 0px;
  margin-right: 0px;
  font-size: 14px;
  font-weight: 500;
  font-family: "Inter", sans-serif !important;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.AddNoteBu img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  filter: invert(1);
}

.AddCommDiv {
  padding: 15px 0;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0 0;
}

.CaseSummSideUL {
  padding: 0;
  margin: 0;
}

.CaseSummSideUL li {
  list-style: none;
  border-bottom: 1px solid #3f6478;
  padding: 10px 0px;
}

.CaseSummSideUL li:last-child {
  border-bottom: none;
}

.CaseSummSideUL li.NoBorder {
  border-bottom: 0;
}

.ULtitle {
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #94c5df;
  margin: 0;
}

.ULData {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #f2f2f2;
  margin: 0;
}

.ULData a,
.ULData a:hover {
  color: #ff8271;
  border-bottom: 1px solid;
  padding-bottom: 2px;
}

.TagDiv {
  font-size: 12px;
  background: #999;
  color: #fff;
  padding: 4px 8px;
  margin-left: 15px;
  border-radius: 5px;
}

.TagDiv.Red {
  background: #fb746a;
}

.FileUpDuContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 20px;
  -webkit-align-items: center;
  align-items: center;
}

.FileUpDuContainer h5 {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 0;
  font-weight: 500;
}

.FileUpDuBUDiv input {
  display: none;
}

/* .UploadLabel:hover {
    background-color: #230058;
} */

.UploadLabel button {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.UploadLabel button:focus {
  outline: none;
}

.FilesUploadRow {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px;
}

.FilesUploadGrid {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: grid;
}

.FilesUploadCard {
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 10%);
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  border: 1px solid #ebebeb !important;
}

.CardIconSec {
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  line-height: normal;
}

.CardIconSec img {
  width: 40px;
  height: 40px;
  margin-top: 7px;
}

.DropDownMainFiles {
  position: absolute;
  right: 0;
  top: 0;
}

.CardConSec {
  padding: 0.5rem;
}

.CardConSec p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.ButtonVoiletSolid {
  padding: 14px 25px;
  background-color: #502688;
  margin: 0px;
  font-size: 13px;
  width: 48%;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.ButtonVoiletLine {
  padding: 14px 25px;
  background-color: #fff;
  margin: 0px;
  font-size: 13px;
  width: 48%;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

.ConArea {
  border: 1px solid #e5e8eb;
  border-radius: 5px;
  padding: 0px 0px;
  height: 1152px;
  overflow: auto;
}

.ContentBlock {
  border-bottom: 1px solid #e5e8eb;
  margin: 30px 0;
  padding: 15px 30px 30px 30px;
}

.ContentBlock:last-child {
  border-bottom: none;
  padding: 15px 30px;
}

.LeaseDocumentTitle {
  font-size: 24px;
  font-weight: 700;
  color: #343434;
  line-height: normal;
  margin: 0 0 22px 0;
}

.LeaseDocumentContent {
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  color: #000000de;
}

.ContentBlock ol li,
.ContentBlock ul li {
  font-size: 15px;
  line-height: 27px;
  font-weight: 400;
  color: #000000de;
  padding: 8px 0;
}

.DownLoadBU {
  padding: 5px 15px;
  background-color: #502688;
  font-size: 14px;
  width: auto;
  display: inline-flex;
  font-weight: 500;
  align-items: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  text-transform: capitalize;
  cursor: pointer;
}

.DownLoadBU:hover {
  background-color: #230058;
}

.DownLoadBU svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.BUArea {
  display: flex;
  justify-content: flex-end;
  margin: 30px 0;
}

.BackArrow {
  color: #505055 !important;
  background: #fff;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-right: 16px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #333 !important;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 20px;
  height: 20px;
}

.ActionsBU {
  width: 105px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 14px !important;
  font-weight: 500 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0;
  border: none !important;
  padding: 0 10px 0 15px !important;
  border-radius: 8px !important;
}

.ActionsBU:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU:focus {
  outline: none;
}

.ActionsBU svg {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
}

.ActionsBU:after {
  display: none;
}

.ActionsBUListItem {
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #343434 !important;
  border-bottom: 1px solid #e0e0e0 !important;
  padding: 10px 10px !important;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #e9ecef !important;
}

.ActionsBUListItem:active {
  background: #e9ecef !important;
}

.ActionsBUListItem:last-child {
  border: none !important;
}

.SignLeaseBU {
  width: 130px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  color: #fff;
  background-color: #44bb49;
  margin: 0 0px 0 0;
  border: none;
  padding: 0 15px 0 15px;
  border-radius: 8px;
  animation: pulse-animation 1.5s infinite;
  transition: all 0.3s ease-in-out;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgb(68 187 73 / 40%);
  }

  100% {
    box-shadow: 0 0 0 15px rgb(68 187 73 / 0%);
  }
}

.SignLeaseBU:hover,
.SignLeaseBU:focus {
  background-color: #39ac3e;
  color: #fff;
}

.SignLeaseBU:focus {
  outline: none;
  box-shadow: none;
}

.FormIMGSec {
  width: 100%;
}

.FileIMGDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 12px 0;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 0 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 5px 15px 5px 20px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #a069c6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

/* .UploadLabel div {
    display: none;
} */

.UploadLabel span.FirstSpan {
  width: 100%;
}

.UploadLabel div.UpIcon {
  display: block;
  width: 100%;
}

.UpIcon img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.UploadLabel span.FirstSpan p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.ImgFile {
  min-width: 180px;
  width: 180px;
  height: 180px;
  position: relative;
  margin: 0px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFile a {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  width: 23px;
  height: 23px;
  text-align: center;
}

.ImgFile a svg {
  width: 15px;
  height: 15px;
  margin-top: -5px;
}

.ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.StepperSec {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 10px auto 0px auto;
}

.StepperCard {
  width: Calc(100% / 6);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: relative;
}

.StepperCard:after {
  content: "";
  position: absolute;
  width: 50%;
  height: 1px;
  background: #dedfe3;
  left: 0;
  top: 14px;
  opacity: 0;
}

.StepperCard::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 1px;
  background: #dedfe3;
  left: 50%;
  top: 14px;
}

.StepperSec .StepperCard:first-child::after {
  display: none;
}

.StepperSec .StepperCard:last-of-type::before {
  display: none;
}

.StepperCard.Completed::after,
.StepperCard.Completed::before {
  background: #27c27b;
}

.StepperCard.Active::after {
  background: #27c27b;
}

.StepperCard.Failed::after {
  background: #27c27b;
}

.StepIcon {
  width: 28px;
  height: 28px;
  background-color: #fbf7fc;
  border: 1px solid #e1e1e1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: #fff solid 18px;
  margin-bottom: 20px;
  position: relative;
  z-index: 2;
}

.StepperCard.Completed .StepIcon {
  background-color: #27c27b;
  border: none;
}

.StepperCard.Active .StepIcon {
  width: 20px;
  height: 20px;
  background-color: #ffbc00;
  margin-top: 4px;
  margin-bottom: 24px;
  border: none;
}

.StepperCard.Failed .StepIcon {
  width: 20px;
  height: 20px;
  background-color: #f44336;
  margin-top: 4px;
  margin-bottom: 24px;
  border: none;
}

.StepperCard.Active .StepIcon:after {
  content: "";
  width: 28px;
  height: 28px;
  background: transparent;
  border: 1.5px solid #ffbc00;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.StepperCard.Failed .StepIcon:after {
  content: "";
  width: 28px;
  height: 28px;
  background: transparent;
  border: 1px solid #f44336;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.StepperCard.Failed .StepIcon:before {
  content: "!";
  font-size: 20px;
  font-weight: 500;
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.StepIcon svg {
  width: 20px;
  display: none;
  color: #fff;
}

.StepperCard.Completed .StepIcon svg {
  display: block;
}

.StepTitle {
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  color: #343434;
  margin: 0;
}

.StepTitle span {
  /* width: 120px; */
  width: 100%;
  text-align: center;
  display: inline-block;
}

/* .StepperCard.Completed .StepTitle,
.StepperCard.Active .StepTitle {
  color: #343434;
} */

.StepperCard.Failed .StepTitle {
  color: #f44336;
}

.FormAccoHead {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.FormAccoHeadTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  color: #ffffff;
  margin: 0;
}

.BodyPadd {
  padding: 0px 5px;
  width: 100%;
}

.HistoryCard {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #3f6478;
}

.HistoryCard:last-of-type {
  border-bottom: none;
}

.HistoryCardHead {
  width: 100%;
  display: flex;
  align-items: center;
}

.HistoryIcon {
  min-width: 30px;
  min-height: 30px;
  width: 30px;
  height: 30px;
  background: #27c27b;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-right: 20px;
}

.HistoryIcon.Failed {
  background: #f44336;
}

.HistoryIcon svg {
  width: 18px;
  height: 18px;
}

.HistoryCardDE {
  width: 100%;
}

.HistoryCardTitle {
  font-size: 15px;
  font-weight: 500;
  color: #fff;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  line-height: 20px;
  width: 100%;
}

.HistoryCardTitle span {
  font-size: 14px;
  color: #94c5df;
  font-weight: 400 !important;
  margin-left: auto;
  white-space: nowrap;
}

.HistoryCardBody {
  width: 100%;
  padding-left: 50px;
  padding-top: 8px;
}

.HistoryText {
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  color: #daf2ff;
  margin: 0;
}

.HistoryRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.BUSec {
  padding: 15px 0 0 0 !important;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.EditBU {
  border: none;
  background: transparent;
  margin-top: -5px;
  margin-left: 15px;
  opacity: 0.6;
}

.EditBU svg {
  width: 18px;
}

.CustomSelect {
  width: 100%;
}

.CustomSelect > div {
  border-radius: 8px;
  border-color: #e1e1e1;
  min-height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 5px;
  /* color: #BFBFBF; */
}

.LeaseDDSec {
  width: calc(100% - 145px) !important;
  max-width: 400px;
  align-items: center;
  display: flex;
  padding: 0 !important;
}

.LeaseDDSec p {
  font-size: 16px;
  line-height: 40px;
  font-weight: 500;
  color: #343434;
  margin-bottom: 0;
  margin-right: 15px;
}

.LeaseCardSec {
  width: 100%;
}

.LeaseCard {
  width: 100%;
  background-color: transparent;
  border-radius: 8px;
  padding: 5px 0px 0px 0px;
  margin: 0px 0;
  display: flex;
  align-items: center;
}

.LeaseIcon {
  width: 40px;
  height: 40px;
  margin-right: 18px;
}

.LeaseTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #e5e5e5;
  margin: 0;
}

.LeaseTitleSmall {
  font-size: 12px;
  line-height: 18px;
  font-weight: 500;
  text-transform: uppercase;
  color: #a2a2a2;
  margin: 0;
}

.LeaseDownloadBU {
  --padding-top: 3px;
  margin: 0 !important;
  margin-left: auto !important;
  width: 50px;
  height: 50px !important;
  background: transparent;
  --box-shadow: none;
  --color: #e5e5e5;
  color: #e5e5e5;
  border: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.LeaseDownloadBU svg {
  width: 18px;
  stroke-width: 1.5;
  opacity: 0.9;
}

.LoaderCard {
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.SignatureSec {
  display: grid;
}

.SignatureSec img {
  width: 260px;
  height: auto;
  margin-bottom: 15px;
}

.SignatureSec img:last-child {
  margin-bottom: 0px;
}

.HideElement {
  display: none;
}

.BreadcrumbHeadSec {
  width: 100%;
  padding: 0 0px 25px 0px;
}

.PageTitleSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0 0;
  position: relative;
}

.PageTitleSec>div {
  display: flex;
  align-items: center;
}

.SMProCusRow {
  margin: 35px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* float: left; */
  width: -webkit-fill-available;
  width: -moz-available;
}

.CardSmall {
  width: 100%;
  height: 100%;
  background: #4d7b93;
}

.SMProCardHead {
  text-align: left;
  padding: 25px 15px 15px 15px;
  position: relative;
}

.SMProCardHead .avatar {
  margin-bottom: 25px;
  position: absolute;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  background-color: #fbf7fc;
  transition: all 0.2s ease-in-out;
}

.SMProCardHead .SMProPicMainDubble:hover .avatar {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.SMProCardHead .avatar.active {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  z-index: 3 !important;
}

.SMProCardHead .avatar img {
  position: relative;
  z-index: 2;
  /* border: 2px solid white; */
}

.SMProCardHead .avatar p {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbf7fc;
  margin: 0;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  color: #502688;
  letter-spacing: 1px;
  border: 2px solid #fff;
}

/* .SMProCardHead .avatar::after {
  content: "";
  position: absolute;
  width: 106px;
  height: 106px;
  background: #10071d61;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.SMProCardHead .avatar:hover::after {
  transform: translate(-50%, -50%) scale(1);
} */

.SMProCardHead h5 {
  margin: 0;
  line-height: 2.22;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  /* position: absolute; */
  cursor: pointer;
  padding: 0 20px;
  text-align: center;
  left: 0;
  /* display: none; */
}

.SMProCardHead img.Varified {
  width: 16px;
  margin-left: 3px;
}

.SMProCardHead h5 svg {
  width: 21px;
  height: 21px;
  margin-left: 7px;
  color: #5e5e5e;
}

.SMProCardHeadTab {
  text-align: center;
  padding: 25px 15px;
  position: relative;
  width: 170px;
}

.SMProCardHeadTab .avatar {
  margin-bottom: 15px;
}

.SMProPicMain {
  position: relative;
  width: 130px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  height: 105px;
}

.SMProPicMain a,
.SMProPicMain span.UpPicBU {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.SMProPicMain a svg,
.SMProPicMain span.UpPicBU svg {
  width: 17px;
  stroke-width: 2;
}

.ProEditSec {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.EditActionsBU {
  width: 40px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  background-color: rgb(255 255 255 / 20%) !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.EditActionsBU:hover {
  background-color: rgb(255 255 255 / 100%) !important;
  color: #39414d !important;
}

.EditActionsBU.Table {
  background-color: transparent !important;
  color: #502688 !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 0;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif !important;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem img {
  width: 17px;
  height: auto;
  margin-right: 12px;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.avatar {
  display: inline-block;
  margin-bottom: 0;
  min-height: 58px;
  min-width: 58px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fbf7fc;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid white; */
  /*margin: 0 0 1rem;*/
  padding: 2px;
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar > a > img,
.header form .input-group .input-group-append .avatar > button.btn > img,
.chat-block
  .chat-sidebar
  .chat-sidebar-content
  .list-group
  .avatar
  > .list-group-item.active
  > img,
.avatar > img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: center;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
  /*font-size: 19px;*/
}

.StepperCardMainSec {
  width: 100%;
  background-color: #fff;
  padding: 30px 20px;
  margin: 0 0 30px 0;
}

.ViewDetailsBU {
  width: auto;
  font-size: 14px;
  font-weight: 400;
  color: #39414d;
  padding: 8px 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ffffff;
  background: #ffffff;
  border-radius: 6px;
  margin-top: 12px;
  transition: all .2s ease-in-out;
}

.ViewDetailsBU:hover {
  color: #fff;
  background: #ff8271;
  border: 1px solid #ff8271;
}

.ViewDetailsBU svg {
  width: 18px;
  height: auto;
  margin-right: 10px;
  margin-top: 2px;
}

.IDImageCloseBU {
  position: absolute;
  top: 2px;
  right: 5px;
  padding: 5px;
  background: #fff;
  border-radius: 50%;
}

.IDImageSec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 150px;
}

.IDImage {
  width: auto;
  min-width: 50px;
  max-width: 500px;
  height: auto;
  min-height: 50px;
  max-height: 500px;
  object-fit: contain;
  object-position: center;
  margin: 0;
}

.ProspectCardImg {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
  object-position: center !important;
  border-radius: 50%;
}

@media screen and (min-width: 360px) {
  .BreadcrumbHeadSec {
    padding: 0 15px 25px 15px;
  }
  .ReferExpMain {
    display: block;
  }

  .ReferBodySummCommNote,
  .ReferBodySummCommNotes {
    flex-wrap: wrap;
  }

  .ReferBodySummCommNotes {
    padding: 10px;
  }

  .ReferBodySummCommBody {
    padding-left: 0px;
    padding-top: 25px;
  }

  .SummCommBodyName {
    padding-right: 0px;
  }

  .ReferNoteSec {
    padding: 17px 17px 17px 17px;
  }

  .SMProCusSmallDiv {
    min-width: 100%;
    max-width: 100%;
  }

  .SMProCusBigDiv {
    width: 100%;
  }

  .SummFormSemiHalf {
    width: 100%;
  }

  .HistoryTitleCon {
    display: grid;
  }

  .HistoryTitleCon span {
    margin-left: unset;
  }

  .HistoryTitle {
    align-items: unset;
  }

  .FilesUploadGrid {
    width: 100%;
  }

  /* .ButtonVoiletSolid,
    .ButtonVoiletLine {
        width: 100%;
        display: inline-block;
    } */

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .mainCard {
    margin: 0 0rem;
  }
  .ContentBlock {
    padding: 15px 15px 30px 15px;
  }
  .ContentBlock:last-child {
    padding: 15px 15px;
  }
}

@media screen and (min-width: 576px) {
  .ContentBlock {
    padding: 15px 30px 30px 30px;
  }
  .ContentBlock:last-child {
    padding: 15px 30px;
  }
  .BreadcrumbHeadSec {
    padding: 0 0px 25px 0px;
  }
  .ReferBodySummCommNotes {
    padding: 15px;
  }

  .SummFormSemiHalf {
    width: 50%;
  }

  .HistoryTitleCon {
    display: flex;
  }

  .HistoryTitleCon span {
    margin-left: auto;
  }

  .HistoryTitle {
    align-items: center;
  }

  .FilesUploadGrid {
    width: 50%;
  }

  /* .ButtonVoiletSolid,
    .ButtonVoiletLine {
        width: 100%;
    } */

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }

  .mainCard {
    margin: 0 1.8rem;
  }
}

@media screen and (min-width: 768px) {
  .StepperCardMainSec {
    padding: 20px 10px;
  }
  .StepIcon {
    margin-bottom: 10px;
    outline: #fff solid 14px;
  }
  .StepperCard.Active .StepIcon {
    margin-bottom: 14px;
  }
  .ReferBodySummCommNote,
  .ReferBodySummCommNotes {
    flex-wrap: nowrap;
  }

  .ReferBodySummCommBody {
    padding-left: 25px;
    padding-top: 0px;
  }

  .SummCommBodyName {
    padding-right: 70px;
  }

  .ReferNoteSec {
    padding: 17px 48px 17px 90px;
  }

  .SummFormSemiHalf {
    width: 33.33%;
  }
}

@media screen and (min-width: 992px) {
  .ReferExpMain {
    display: table;
  }

  .FilesUploadGrid {
    width: 315px;
  }
}

@media screen and (min-width: 1024px) {
  .FilesUploadGrid {
    width: 320px;
  }
}

@media screen and (min-width: 1100px) {
  .SummFormSemiHalf {
    width: 25%;
  }
  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }
  .SMProCusBigDiv {
    width: Calc(100% - 330px);
  }
  .StepperCardMainSec {
    padding: 30px 20px;
  }
  .StepIcon {
    margin-bottom: 20px;
    outline: #fff solid 18px;
  }
  .StepperCard.Active .StepIcon {
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1200px) {
  .ReferBodySummCommNotes {
    padding: 20px;
  }
}

@media screen and (min-width: 1280px) {
  .SMReferDeBigDiv {
    width: 68%;
  }

  .SummFormSemiHalf {
    width: 33.33%;
  }
}

@media screen and (min-width: 1300px) {
  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }
  .SMProCusBigDiv {
    width: Calc(100% - 330px);
  }
}

@media screen and (min-width: 1400px) {
  .SMReferDeBigDiv {
    width: 70%;
  }

  .SMProCusSmallDiv {
    min-width: 300px;
    max-width: 300px;
  }
  .SMProCusBigDiv {
    width: Calc(100% - 300px);
  }
}

@media screen and (min-width: 1500px) {
  .SMReferDeBigDiv {
    width: 74%;
  }

  .SMProCusSmallDiv {
    min-width: 320px;
    max-width: 320px;
  }
  .SMProCusBigDiv {
    width: Calc(100% - 320px);
  }

  .SummFormSemiHalf {
    width: 25%;
  }
}

@media screen and (min-width: 1600px) {
  .SMReferDeBigDiv {
    width: 75%;
  }

  .SMProCusSmallDiv {
    min-width: 350px;
    max-width: 350px;
  }
  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }
}

@media screen and (min-width: 1800px) {
  .SMReferDeBigDiv {
    width: 76%;
  }
}

/* @media */

@media screen and (max-width: 767px) {
  .BreadcrumbHeadSec nav {
    display: none;
  }
  .PageTitleSec {
    padding: 25px 0 0 0;
  }
  .SMProCusRow {
    margin: 0px 0px 0px 0px;
  }
  .StepperSec {
    flex-wrap: wrap;
  }
  .StepperCard {
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    padding: 15px 0;
  }
  .StepperCard::before {
    width: 1px;
    height: 100%;
    left: 13px;
    top: 14px;
  }
  .StepIcon {
    outline: #fff solid 8px;
    margin-bottom: 0px;
    margin-right: 20px;
  }
  .StepTitle {
    width: auto;
    font-size: 14px;
  }
  .StepperCard.Active .StepIcon {
    width: 28px;
    height: 28px;
    margin-top: 4px;
    margin-bottom: 4px;
  }
  .StepperCard.Active .StepIcon:after {
    width: 36px;
    height: 36px;
  }
  .StepperCardMainSec {
    padding: 10px 30px;
  }
}

@media screen and (max-width: 479px) {
  .Card {
    padding: 20px 12px;
  }
}
