.jBsdIA {
  background: #ea983e !important;
}

.dnkfYM {
  background-color: #230058 !important;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.kOQDp,
.iUmMgf,
.cDsdtM,
.djbTRM,
.cvEpHl,
.scrollbar-container,
.scrollbar-container ul {
  background-color: #230058 !important;
}

.iJiWPf.active,
.iJiWPf:hover,
.leFvPr:hover,
.fbvfJr.active,
.fbvfJr:hover,
.gDvSIr:hover,
.taxeg.active,
.dZGFyf.active,
.taxeg:hover,
.dZGFyf:hover,
.gZKVsl:hover,
.imRBAx.active,
.dncNJx.active,
.dncNJx:hover,
.Sidebar__Category-ljtos8-4:hover,
.Sidebar__Link-ljtos8-8:hover,
.SideNav .MuiListItem-button:hover,
.SideNav .MuiListItem-button.active {
  background-color: #32037a !important;
}

.gheZhN {
  background-color: #585858 !important;
}

.gheZhN {
  background-color: #230058 !important;
}

.avataarMainNameWrap {
  display: flex;
  align-items: center;
}

.avataarMainNameText {
  font-size: 14px;
  color: #eeeeee;
  /* white-space: normal; */
}

#noPadding {
  background-color: #230058 !important;
}

.MuiAvatar-colorDefault {
  background-color: #ff8271;
}

.MuiListItem-button.taxeg {
  padding-top: 12px;
  padding-bottom: 12px;
}

.MuiDrawer-paper {
  z-index: 1110;
  box-shadow: 3px 0 10px 2px #0000006b;
}

.SidebarAvatar {
  width: 40px !important;
  height: 40px !important;
  min-width: 40px !important;
  min-height: 40px !important;
}

.SidebarAvatar img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

@media screen and (min-width: 360px) {
  #avataarBack {
    background-color: #230058 !important;
    /* padding: 10px 17px 10px 32px !important; */
    padding: 10px 17px 10px 22px !important;
    box-sizing: border-box !important;
    width: 100% !important;
  }

  #noPadding {
    padding: 10px 0 0 0 !important;
  }
}

@media screen and (min-width: 960px) {
  .MenuAvatar .MuiGrid-container {
    flex-wrap: nowrap;
  }

  .SideNav {
    width: 258px;
  }

  .SideNav ul .MuiListItem-root svg {
    min-width: 20px;
    min-height: 20px;
  }

  .SideNav ul .MuiListItem-root svg.MuiSvgIcon-root {
    visibility: hidden;
  }

  .SideNav ul .MuiListItem-root .MuiListItemText-root {
    white-space: nowrap;
    visibility: hidden;
  }

  .avataarMainNameWrap {
    white-space: nowrap;
    visibility: hidden;
  }

  .MuiDrawer-paper {
    width: 85px !important;
    overflow: hidden;
    transition: all ease-in-out 0.4s;
  }

  #avataarBack {
    padding: 10px 17px 10px 22px !important;
    transition: all ease-in-out 0.4s;
  }

  .SideNav ul .MuiCollapse-container {
    max-height: 0px;
    overflow: hidden;
    transition: all ease-in-out 0.4s;
  }

  /**********************hover************************/
  .MuiDrawer-paper:hover {
    width: 258px !important;
  }

  .MuiDrawer-paper .avataarMainNameWrap {
    /* display: none; */
  }

  .MuiDrawer-paper:hover .SideNav ul .MuiListItem-root svg.MuiSvgIcon-root,
  .MuiDrawer-paper:hover .SideNav ul .MuiListItem-root .MuiListItemText-root,
  .MuiDrawer-paper:hover .avataarMainNameWrap {
    visibility: visible;
  }

  .MuiDrawer-paper:hover #avataarBack {
    padding: 10px 17px 10px 32px !important;
  }

  .MuiDrawer-paper:hover .SideNav ul .MuiCollapse-container {
    max-height: 100%;
    overflow: hidden;
    transition: all ease-in-out 0.4s;
  }

  /**********************hover************************/
}
