/* @import url('https://api.fontshare.com/v2/css?f[]=satoshi@1&display=swap');

*,
body {
  font-family: 'Satoshi', sans-serif;
} */

.Header {
  width: 100%;
}

.Container {
  width: 1360px;
  margin: 0 auto;
}

.HeadRow {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.Logo {
  max-width: 240px;
  max-height: 50px;
}

.Logo img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.RightSec {
  display: flex;
  align-items: center;
}

.MenuArea {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.MenuArea ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.MenuArea ul li {
  list-style: none;
}

.MenuArea ul li a {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #000;
  display: block;
  padding: 7px 20px;
}

.MenuHamBU {
  width: 30px;
  height: 30px;
  color: #000;
}

.MenuHamBU svg {
  width: 30px;
  height: 30px;
}

.ContactBUHead {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #ff8271;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 8px 0;
  transition: all .3s ease-in-out;
  margin-left: 15px;
}

.ContactBUHead svg {
  width: 20px;
  margin-left: 8px;
}

.ContactBUHead:hover {
  color: #fff;
  background: #e87767;
}

.BannerArea {
  width: 100%;
  padding: 80px 0;
  /* top: 82px; */
  position: relative;
  overflow: hidden;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AlignCenter {
  align-items: center;
}

.BannerTextArea {
  width: 44%;
  padding: 50px 0 0 0;
}

.BannerTitleText {
  font-size: 65px;
  font-weight: 800;
  line-height: 76px;
  color: #13052a;
  letter-spacing: -3px;
  margin-bottom: 30px;
}

.BannerConText {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
}

.LearnMBUBanner {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #32007e;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 8px 28px 8px 0;
  position: relative;
  transition: all .3s ease-in-out;
  margin-top: 40px;
}

.LearnMBUBanner svg {
  width: 20px;
  margin-left: 8px;
  position: absolute;
  right: 30px;
  transition: all .3s ease-in-out;
}

.LearnMBUBanner:hover {
  color: #fff;
  /* background: #2a0068; */
}

.LearnMBUBanner:hover svg {
  right: 20px;
}

.BannerIMGArea {
  width: auto;
  position: absolute;
  right: 0;
}

.BannerMainIMG {
  width: auto;
  height: auto;
}

.BannerMainIMG2 {
  width: auto;
  height: auto;
  position: absolute;
  right: 230px;
  top: 50px;
}

.BannerMainIMG3 {
  width: auto;
  height: auto;
  position: absolute;
  left: 380px;
  top: -15px;
}

.WelcomeArea {
  width: 100%;
  padding: 60px 0 40px 0;
  position: relative;
}

.WelcomRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.PositionRelative {
  position: relative;
}

.WelcomeIMGSec {
  width: 52%;
  position: absolute;
  left: -63px;
}

.WelcomeIMGSec img {
  width: 100%;
  height: auto;
}

.WelcomeConSec {
  width: 60%;
  background: #f8f8f9;
  border-radius: 8px;
  padding: 80px 60px 80px 180px;
  display: flex;
  flex-wrap: wrap;
}

.WelcomSmallHead {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  opacity: .5;
}

.WelcomBigHead {
  font-size: 65px;
  font-weight: 800;
  line-height: 74px;
  color: #13052a;
  letter-spacing: -1px;
  margin-bottom: 30px;
  width: 100%;
}

.LearnMLink {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #32007e;
  letter-spacing: -1px;
  transition: all .3s ease-in-out;
  position: relative;
  padding: 5px 0;
}

.LearnMLink svg {
  width: 20px;
  margin-left: 8px;
}

.LearnMLink:hover {
  color: #2a0068;
}

.LearnMLink:after {
  position: absolute;
  transition: 0.3s;
  content: "";
  width: 0;
  right: 0;
  left: auto;
  bottom: 0;
  height: 2px;
  background: #2a0068;
}

.LearnMLink:hover:after {
  width: 100%;
  left: 0;
}

.scrollReminderTick {
  animation: scrollTick 2.5s;
  animation-iteration-count: infinite;
  background-color: transparent;
  display: block;
  height: 62px;
  margin-left: -2px;
  position: absolute;
  width: 31px;
  background-image: url(./mouseScroll.png);
  z-index: 2;
}

@keyframes scrollTick {
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(29px);
  }
}

.ScrollDiv {
  height: 100px;
  margin: 50px 0 0 0;
  position: relative;
}

.ScrollDiv:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background: #b9b7be;
  left: 12px;
  top: 0;
  z-index: 1;
  border-radius: 50%;
  opacity: .7;
}

.features {
  width: 100%;
  padding: 40px 0;
  overflow: hidden;
}

.featuresTitleSec {
  width: 100%;
  max-width: 770px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.featuresTitleB {
  font-size: 72px;
  font-weight: 800;
  line-height: 80px;
  color: #13052a;
  letter-spacing: -3px;
  margin-bottom: 50px;
  text-align: center;
}

.featuresTitleS {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 40px;
  text-align: center;
}

.featuresGraSec {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 80px 0 180px 0;
}

.featuresGraImgSec {
  width: 100%;
  max-width: 780px;
  height: auto;
  position: relative;
}

.featuresGraImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.featuresSmallSec {
  width: 240px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
}

.featuresSmallSec.IconSec1 {
  top: 0px;
  left: 30px;
}

.featuresSmallSec.IconSec2 {
  bottom: 80px;
  left: 60px;
}

.featuresSmallSec.IconSec3 {
  top: 0px;
  right: 30px;
}

.featuresSmallSec.IconSec4 {
  bottom: 80px;
  right: 60px;
}

.IconSec {
  width: 90px;
  height: 90px;
  background-color: #ff8271;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.IconSec img {
  max-width: 48px;
  max-height: 50px;
  object-fit: contain;
  object-position: center;
}

.featuresSmallTitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.featuresCardRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  justify-content: center;
}

.featuresCardSec {
  width: 100%;
  padding: 100px 0 40px 0;
}

.featuresCard {
  width: 25%;
  padding: 15px;
}

.featuresCardUnder {
  width: 100%;
  height: 100%;
  border: 1px solid #dadada;
  overflow: hidden;
  border-radius: 8px;
  transition: all .3s ease-in-out;
}

.featuresCardUnder:hover {
  box-shadow: 0 50px 35px 8px #33333330;
  transform: translate(0px, -70px);
}

.featuresCardImgSec {
  width: 100%;
  height: 280px;
  position: relative;
}

.featuresCardImgSec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.featuresCardImgSec:after {
  content: '';
  width: 100%;
  height: 125px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 48%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.featuresCardImgText {
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
  color: #fff;
  letter-spacing: -1px;
  margin-bottom: 0px;
  text-align: left;
  width: auto;
  position: absolute;
  bottom: 18px;
  left: 25px;
  right: 25px;
  z-index: 1;
  padding: 0 0 12px 0;
}

.featuresCardImgText:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #ff8271;
  left: 0;
  bottom: 0;
}

.featuresCardBodySec {
  width: 100%;
  padding: 25px 25px;
  position: relative;
}

.featuresCardBodyList {
  width: 100%;
  padding: 10px 0;
  display: flex;
}

.featuresCardBodyList img {
  min-width: 22px;
  width: 22px;
  min-height: 22px;
  height: 22px;
  object-fit: contain;
  object-position: center;
  margin-right: 12px;
}

.featuresCardBodyList .ListText {
  font-size: 17px;
  font-weight: 600;
  line-height: normal;
  color: #000;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.featuresFull {
  width: 100%;
  position: relative;
  height: 620px;
  margin: 40px 0 0 0;
  overflow: hidden;
}

.featuresFull img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.featuresFullRow {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.Height100 {
  height: 100%;
}

.featuresFullCon {
  width: 100%;
  max-width: 450px;
}

.featuresFullTitle {
  font-size: 68px;
  font-weight: 800;
  line-height: 71px;
  color: #13052a;
  letter-spacing: -1px;
  margin-bottom: 30px;
  text-align: left;
}

.featuresFullText {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
}

.featuresFullBU {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #ff8271;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 8px 28px 8px 0;
  position: relative;
  transition: all .3s ease-in-out;
  margin-top: 30px;
}

.featuresFullBU svg {
  width: 20px;
  margin-left: 8px;
  position: absolute;
  right: 30px;
  transition: all .3s ease-in-out;
}

.featuresFullBU:hover {
  color: #fff;
  /* background: #e87767; */
}

.featuresFullBU:hover svg {
  right: 20px;
}

.OurBlog {
  width: 100%;
  padding: 80px 0;
}

.BlogTitleSmall {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 20px;
  opacity: .5;
  text-align: center;
}

.BlogTitleBig {
  font-size: 72px;
  font-weight: 800;
  line-height: 80px;
  color: #13052a;
  letter-spacing: -3px;
  margin-bottom: 50px;
  text-align: center;
}

.OurBlogRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.OurBlogCard {
  width: 33.33%;
  padding: 10px;
}

.OurBlogCardUnder {
  width: 100%;
  height: auto;
}

.BlogImg {
  width: 100%;
  height: 310px;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
}

.OurBlogCon {
  width: 100%;
  padding: 25px 0;
}

.OurBlogDate {
  font-size: 12px;
  font-weight: 700;
  line-height: 35px;
  color: #7f7f7f;
  letter-spacing: 2px;
  margin-bottom: 5px;
  text-align: center;
  background: #fff;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  border-radius: 20px;
  width: fit-content;
  padding: 0 0px;
  transition: all .3s ease-in-out;
}

.OurBlogDate svg {
  width: 18px;
  margin-right: 8px;
}

/* .OurBlogCardUnder:hover .OurBlogDate {
  background: #32007e;
} */

.OurBlogText {
  font-size: 26px;
  font-weight: 800;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 20px;
  text-align: left;
  padding-right: 15px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  height: 105px;
}

.FooterRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  border-top: 1px solid #e5e5e5;
  padding: 100px 0 80px 0;
}

.FooterLogoSec {
  width: 30%;
  padding: 15px;
}

.FooterLogoSec img {
  width: 100%;
  max-width: 240px;
  height: auto;
}

.FooterConSec {
  width: 70%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FooterConSecSmall {
  width: 22%;
}

.FooterConSecBig {
  width: 32%;
  display: flex;
  align-items: center;
  justify-content: right;
}

.FootListTitle {
  font-size: 21px;
  font-weight: 800;
  line-height: normal;
  color: #13052a;
  letter-spacing: -1px;
  margin-bottom: 10px;
  text-align: left;
}

.FootListLink {
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  color: #13052a;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;
  opacity: .8;
  width: 100%;
  display: block;
  transition: all .3s ease-in-out;
}

.FootListLink:hover {
  color: #000;
  opacity: 1;
}

.FootListLinkBig {
  font-size: 22px;
  font-weight: 800;
  line-height: normal;
  color: #13052a;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
  display: block;
  transition: all .3s ease-in-out;
}

.LastFootRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.CopyRightText {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  color: #13052a;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;
  opacity: .8;
}

.SocialIcon a {
  padding: 0 6px;
}

.SocialIcon img {
  width: 18px;
}

.active {
  color: red !important;
}

.Padd40 {
  position: relative;
  padding-top: 82px;
}

@media (min-width: 360px) {
  .Container {
    width: Calc(100% - 30px);
  }

  .MenuArea {
    position: fixed;
    width: 300px;
    right: 0;
    height: Calc(100% - 80px);
    z-index: 999;
    background: #f00;
    top: 80px;
    align-items: baseline;
    align-content: baseline;
  }

  .MenuArea ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }

  .MenuArea ul li {
    width: 100%;
  }

  .FooterLogoSec {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
  }

  .FooterConSec {
    width: 100%;
  }

  .FooterConSecSmall {
    width: 100%;
    margin-bottom: 15px;
  }

  .FooterConSecBig {
    width: 100%;
    justify-content: center;
  }

  .FootListLinkBig {
    font-size: 20px;
    text-align: center;
  }

  .FootListLinkBig {
    font-size: 20px;
  }

  .FootListTitle {
    text-align: center;
  }

  .FootListLink {
    text-align: center;
  }

  .featuresCard {
    width: 100%;
    padding: 15px 8px;
  }

  .OurBlogCard {
    width: 100%;
    padding: 10px;
  }

  .BannerIMGArea {
    right: -520px;
    opacity: .06;
    filter: grayscale(1);
  }

  .BannerTitleText {
    font-size: 48px;
    line-height: 60px;
  }

  .BannerTextArea {
    width: 100%;
    padding: 30px 0 0 0;
    position: relative;
    z-index: 1;
  }

  .BannerMainIMG2 {
    right: 340px;
  }

  .BannerMainIMG3 {
    left: 250px;
  }

  .WelcomeIMGSec {
    display: none;
  }

  .WelcomeConSec {
    width: 100%;
    padding: 40px 40px 40px 40px;
  }

  .WelcomBigHead {
    font-size: 44px;
    line-height: 58px;
  }

  .featuresGraImgSec {
    display: none;
  }

  .featuresSmallSec.IconSec1 {
    top: 0px;
    left: 0px;
  }

  .IconSec {
    width: 50px;
    height: 50px;
  }

  .IconSec img {
    max-width: 28px;
    max-height: 30px;
  }

  .featuresSmallTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .featuresSmallSec {
    width: 100%;
    position: relative;
    max-width: 250px;
    background: #f6f6f6;
    margin: 8px;
    padding: 20px;
    border-radius: 4px;
  }

  .featuresSmallSec.IconSec3 {
    top: 0px;
    right: 0px;
  }

  .featuresSmallSec.IconSec2 {
    bottom: 0px;
    left: 0px;
  }

  .featuresSmallSec.IconSec4 {
    bottom: 0px;
    right: 0px;
  }

  .featuresTitleB,
  .BlogTitleBig {
    font-size: 54px;
    line-height: 72px;
  }

  .OurBlogText {
    font-size: 22px;
    line-height: 30px;
  }

  .featuresFullTitle {
    font-size: 54px;
  }

  .LastFootRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .CopyRightText {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }

  .OurBlogText {
    height: auto;
  }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {
  .CopyRightText {
    font-size: 17px;
    margin-bottom: 0px;
    text-align: left;
    width: auto;
  }

  .LastFootRow {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .featuresFullTitle {
    font-size: 68px;
  }

  .featuresSmallSec {
    width: 50%;
  }

  .featuresCard {
    width: 50%;
    padding: 15px 8px;
  }

  .OurBlogCard {
    width: 50%;
    padding: 10px;
  }

  .OurBlogText {
    height: 90px;
  }
}

@media (min-width: 768px) {
  .OurBlogText {
    font-size: 26px;
    line-height: 35px;
  }

  .BannerIMGArea {
    right: -570px;
    opacity: 1;
    filter: grayscale(0);
  }

  .BannerTitleText {
    font-size: 48px;
    line-height: 60px;
  }

  .BannerTextArea {
    width: 60%;
    padding: 30px 0 0 0;
  }

  .BannerMainIMG2 {
    right: 340px;
  }

  .BannerMainIMG3 {
    left: 250px;
  }

  .WelcomBigHead {
    font-size: 48px;
    line-height: 60px;
  }

  .WelcomeConSec {
    width: 70%;
    padding: 40px 40px 40px 120px;
  }

  .WelcomeIMGSec {
    width: 90%;
    left: -340px;
    display: block;
  }

  .WelcomeArea {
    padding: 30px 0 40px 0;
  }

  .featuresGraImgSec {
    max-width: 350px;
    display: block;
  }

  .featuresSmallSec.IconSec1 {
    top: 0px;
    left: 0px;
  }

  .IconSec {
    width: 50px;
    height: 50px;
  }

  .IconSec img {
    max-width: 28px;
    max-height: 30px;
  }

  .featuresSmallTitle {
    font-size: 18px;
    line-height: 22px;
  }

  .featuresSmallSec {
    width: 190px;
    position: absolute;
    max-width: unset;
    background: unset;
    margin: unset;
    padding: unset;
    border-radius: unset;
  }

  .featuresSmallSec.IconSec3 {
    top: 0px;
    right: 0px;
  }

  .featuresSmallSec.IconSec2 {
    bottom: 0px;
    left: 20px;
  }

  .featuresSmallSec.IconSec4 {
    bottom: 0px;
    right: 20px;
  }

  .featuresTitleB,
  .BlogTitleBig {
    font-size: 58px;
    line-height: 74px;
  }

  .featuresCardRow {
    margin: 0 -8px;
  }

  .OurBlogText {
    height: 105px;
  }
}

@media (min-width: 992px) {
  .featuresSmallSec {
    width: 240px;
  }

  .featuresSmallTitle {
    font-size: 24px;
    line-height: 26px;
  }

  .IconSec img {
    max-width: 48px;
    max-height: 50px;
  }

  .IconSec {
    width: 90px;
    height: 90px;
  }

  .FootListTitle {
    text-align: left;
  }

  .FootListLink {
    text-align: left;
  }

  .BannerIMGArea {
    right: -450px;
  }

  .BannerTitleText {
    font-size: 54px;
    line-height: 68px;
  }

  .BannerTextArea {
    width: 55%;
    padding: 30px 0 0 0;
  }

  .BannerMainIMG2 {
    right: 320px;
  }

  .BannerMainIMG3 {
    left: 310px;
  }

  .WelcomBigHead {
    font-size: 54px;
    line-height: 68px;
  }

  .WelcomeConSec {
    width: 60%;
    padding: 40px 40px 40px 80px;
  }

  .WelcomeIMGSec {
    width: 60%;
    left: -140px;
  }

  .WelcomeArea {
    padding: 30px 0 40px 0;
  }

  .featuresGraImgSec {
    max-width: 480px;
  }

  .featuresSmallSec.IconSec1 {
    top: 0px;
    left: 0px;
  }

  .featuresSmallSec.IconSec3 {
    top: 0px;
    right: 0px;
  }

  .featuresSmallSec.IconSec2 {
    bottom: 0px;
    left: 20px;
  }

  .featuresSmallSec.IconSec4 {
    bottom: 0px;
    right: 20px;
  }

  .featuresTitleB,
  .BlogTitleBig {
    font-size: 66px;
    line-height: 74px;
  }

  .featuresCardRow {
    margin: 0 -8px;
  }

  .featuresCard {
    width: 33.33%;
    padding: 15px 8px;
  }

  .OurBlogCard {
    width: 33.33%;
    padding: 10px;
  }

  .FooterLogoSec {
    width: 25%;
    display: unset;
    padding: 0 15px;
  }

  .FooterConSec {
    width: 75%;
  }

  .FooterConSecSmall {
    width: 20%;
    margin-bottom: 0;
  }

  .FooterConSecBig {
    width: 36%;
    justify-content: right;
  }

  .FootListLinkBig {
    font-size: 20px;
    text-align: left;
  }
}

@media (min-width: 1100px) {}

@media (min-width: 1200px) {
  .Container {
    width: 1140px;
  }

  .BannerIMGArea {
    right: -240px;
  }

  .BannerTextArea {
    width: 44%;
    padding: 30px 0 0 0;
  }

  .BannerMainIMG2 {
    right: 300px;
  }

  .BannerMainIMG3 {
    left: 320px;
  }

  .WelcomeArea {
    padding: 60px 0 40px 0;
  }

  .WelcomeConSec {
    padding: 80px 60px 80px 150px;
  }

  .WelcomeIMGSec {
    width: 52%;
    left: -20px;
  }

  .featuresGraImgSec {
    max-width: 600px;
  }

  .featuresSmallSec.IconSec1 {
    top: 0px;
    left: 30px;
  }

  .featuresSmallSec.IconSec3 {
    top: 0px;
    right: 30px;
  }

  .featuresSmallSec.IconSec2 {
    bottom: 30px;
    left: 60px;
  }

  .featuresSmallSec.IconSec4 {
    bottom: 30px;
    right: 60px;
  }

  .featuresCard {
    width: 25%;
  }

  .featuresTitleB,
  .BlogTitleBig {
    font-size: 72px;
    line-height: 80px;
  }

  .FooterLogoSec {
    width: 30%;
  }

  .FooterConSec {
    width: 70%;
  }

  .FooterConSecSmall {
    width: 22%;
  }

  .FooterConSecBig {
    width: 32%;
  }

  .FootListLinkBig {
    font-size: 22px;
  }
}

@media (min-width: 1300px) {}

@media (min-width: 1400px) {
  /* .Container {
    width: 1360px;
  } */

  /* .BannerIMGArea {
    right: -110px;
  } */

  .WelcomeIMGSec {
    left: -10px;
  }

  .BannerTitleText {
    font-size: 65px;
    line-height: 76px;
  }

  .BannerTextArea {
    width: 52%;
    padding: 50px 0 0 0;
  }

  .BannerMainIMG2 {
    right: 230px;
  }

  .BannerMainIMG3 {
    left: 380px;
  }

  /* .WelcomeConSec {
    padding: 80px 60px 80px 180px;
  }

  .WelcomBigHead {
    font-size: 65px;
    line-height: 76px;
  } */

  /* .featuresGraImgSec {
    max-width: 780px;
  }

  .featuresSmallSec.IconSec2 {
    bottom: 80px;
  }

  .featuresSmallSec.IconSec4 {
    bottom: 80px;
  }

  .featuresCardRow {
    margin: 0 -15px;
  }

  .featuresCard {
    padding: 15px;
  } */
}

@media (min-width: 1500px) {
  .WelcomeIMGSec {
    left: -50px;
  }
}

@media (min-width: 1600px) {
  .BannerIMGArea {
    right: -40px;
  }

  .WelcomeIMGSec {
    left: -63px;
  }
}

@media (min-width: 1700px) {
  .BannerIMGArea {
    right: 0px;
  }
}

@media (min-width: 1800px) {}