.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.pageTitle.Mobile svg {
  width: 22px;
  height: auto;
  margin-left: 4px;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0px 1.8rem 0 1.8rem;
  position: relative;
  /* background: white; */
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  margin-bottom: 20px;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #56328c;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.ReferStatusBU {
  padding: 0px 0px 1px 0px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 5px !important;
  font-size: 12px !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 !important;
  padding-top: 1px !important;
  line-height: unset !important;
  height: 22px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 4px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 2px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #ffbb33 !important;
  border-color: #ffbb33 !important;
  color: #000 !important;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: table;
  width: 100%;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 42px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ApplicantFilterSec .formControl {
  margin-top: 10px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -13px;
}

.ProspectCard {
  width: 100%;
}

.NotesCard {
  width: 100%;
  /* border: 1px solid #e5e8eb; */
  border-radius: 8px;
  padding: 18px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  /* box-shadow: 0 4px 8px 0 rgba(51, 51, 51, .06); */
  box-shadow: 0px 0px 20px 0px rgba(245, 245, 245, 1);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0px 0px 20px 0px rgb(217 217 217);
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  width: 20%;
  margin-bottom: 0;
  padding: 8px 15px;
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -4px;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  width: 25%;
  padding: 8px 15px;
}

.InsideConUnit {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  /*width: 25%;*/
  padding: 8px 15px;
  text-align: center;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px 0;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff !important;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none !important;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 10px;
}

.FilterBu {
  align-items: center;
  padding: 9px 14px;
  background: #502688;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
}

.FilterBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
  background: #230058;
  background-color: #230058;
  color: #fff;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: flex-end;
  margin: 0 -12px;
}

.BodyCon {
  display: flex;
}

.FilterList {
  margin-bottom: 20px;
  font-size: 0.875rem;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 0px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.ApplicantDiv {
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 25%;
}

/*.ApplicantDiv::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
    top: 25px;
  left: 0;
}*/

.ApplicantDivTitle {
  margin: 0;
  font-size: 14px;
  color: #ff8271;
  font-weight: 400;
  width: auto;
  display: inline-block;
  /* background: #fff; */
  padding: 8px 0;
  position: relative;
  /* z-index: 1; */
}

.ApplicantDiv .InsideConUnit:first-child {
  text-align: left;
}

.ApplicantDiv .InsideConUnit:last-child {
  text-align: right;
}

.ApplicantName {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
}

.ApplicantNameDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
}

.ApplicantNameTel {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  min-width: 120px;
}

.ApplicantNameMail {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 240px;
}

.ApplicantDivHalf {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ApplicantAction {
  display: flex;
  align-items: center;
  width: 20%;
}

.mainCardDiv {
  width: 100%;
  padding-right: 280px;
  padding-bottom: 80px;
}

.BodyFilterArea {
  min-width: 280px;
  max-width: 280px;
  background: #fff;
  position: fixed;
  right: 0;
  height: 100vh;
  padding: 90px 30px 120px 30px;
  overflow: auto;
  top: 60px;
  box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
  transition: all ease-in-out 0.5s;
  z-index: 10;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.FilterTitle svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.FilterList {
  margin-bottom: 20px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 70px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.DatePickerCustom {
  position: relative;
  z-index: 1;
}

.DatePickerCustom svg {
  position: absolute;
  top: 12px;
  color: #000;
  width: 18px;
  height: 18px;
  right: 12px;
  z-index: -1;
  opacity: 0.8;
}

.NoDataMain {
  width: calc(100% - 24px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.ArchivedBadge {
  background-color: #dfe2e6;
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  white-space: nowrap;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 12px;
  border-radius: 5px;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

.ReffStatus {
  margin: 0 10px 0 0;
  font-size: 14px;
  white-space: nowrap;
  /* display: table-cell; */
  display: inline-block;
  vertical-align: middle;
}

.ReffStatus.Archived {
  background: #87858b;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
}

.ListConSec {
  width: 30%;
  padding: 0 15px;
}

.ApartmentName {
  font-size: 18px;
  line-height: normal;
  color: #b7b7b7;
  font-weight: 500;
  margin-bottom: 0;
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
}

img.Varified {
  width: 16px;
  margin-left: 8px;
}

.ApartmentName span {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  margin-bottom: 0;
}

.TenantNameSec {
  width: 100%;
  display: flex;
  padding: 5px 0;
}

.TenantName {
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  position: relative;
}

.TenantName:last-child {
  margin: 0;
  padding: 0;
}

.TenantName:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  transform: translate(0px, -50%);
}

.TenantName:last-child::after {
  display: none;
}

.ListIconSec {
  width: 35%;
  padding: 0 15px;
  display: flex;
  flex-wrap: wrap;
}

.IconDiv {
  min-width: 20%;
  padding: 5px 15px 5px 0;
  display: flex;
  justify-content: flex-end;
}

.IconDiv:last-child {
  padding-right: 0 !important;
}

.IconDiv img {
  width: 20px;
  height: 20px;
  object-fit: contain;
  object-position: center;
}

.IconDiv p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #818181;
  margin: 0;
  padding: 0 0 0 12px;
}

.AcrhivedLabel {
  background: #e5e5e5;
  color: #505050 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0 0 0 10px;
  display: inline-block;
}

.SearchFilterSec {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  padding: 5px 0 20px 0;
  justify-content: space-between;
}

.SearchCon {
  width: 50%;
  padding: 0 10px;
}

.SearchConSec {
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  background: #fff;
}

.SearchConSec input {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  width: 100%;
  border: none;
}

.SearchConSec svg {
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.FilterSec {
  width: 50%;
  display: flex;
}

.FilterCard {
  width: 33%;
  padding: 0 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterBUCard {
  width: 30%;
  padding: 0 10px;
  max-width: 150px;
}

.FilterCard.Select > div > div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.AddTenant {
  width: 100%;
  border-radius: 8px;
  padding: 0px 0px;
  margin: 10px 0;
  /*overflow: hidden;*/
  /* box-shadow: 0 4px 8px 0 rgba(51, 51, 51, .06); */
  /* box-shadow: 0px 0px 20px 0px rgba(245, 245, 245, 1); */
}

.AddTenantRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  position: relative;
  padding-bottom: 0px;
  /* min-height: 568px; */
}

.AddTenantHalfSec {
  width: 48%;
}

.AddTenantHalfSecBig {
  width: 48%;
}

.AddTenantHalfSecRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  padding-top: 0px;
  padding-bottom: 10px;
}

.AddTenantHalfSecRow.PaddCus {
  padding-top: 0px;
  padding-bottom: 0px;
}

.HalfSecFull {
  width: 100% !important;
  padding: 14px 12px !important;
}

.SecFull {
  width: 100% !important;
  padding: 0px 12px !important;
}

.HalfSecHalf {
  width: 50% !important;
  padding: 14px 12px !important;
}

.HalfSecHalf.PaddCus {
  width: 100% !important;
  padding: 0px 12px !important;
}

.HalfSecHalfRow {
  width: 100%;
  display: flex;
}

.AddTenantFormLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2c2c2c;
  margin-bottom: 0px;
}

.CustomSelect > div:first-child,
.CustomSelect > div:nth-child(2),
.CustomSelect > div:nth-child(3) {
  border-color: #e1e1e1 !important;
  border-radius: 8px !important;
  min-height: 44px !important;
  font-weight: 400;
  font-size: 14px;
}

.FormField {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  padding: 10px 16px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

.AddTenantBU {
  display: flex;
}

.AddTenantBU button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #ff8271;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  margin-right: 15px;
}

.AddTenantBU button svg {
  width: 20px;
  margin-right: 12px;
}

.AddTenantBU button:hover {
  background: #e16e5f;
}

.AddTenantBU button.Delete {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  background: #fff;
  border-radius: 8px;
  border: 1px solid #f44336;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #f44336;
  transition: all 0.3s ease-in-out;
}

.AddTenantBU button.Delete:hover {
  background: #f44336;
  color: #fff;
}

.TimePick {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 38px;
  position: relative;
}

.TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 10px;
  right: 7px;
  color: #585858;
  /* z-index: 1; */
}

.TimePick > div {
  position: relative;
  /* z-index: 2; */
  padding-right: 18px;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 0px 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 5px 15px 5px 20px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  line-height: 14px;
  border-radius: 4px;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  /* border: 1px dashed #a069c6; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A069C6FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

/* .UploadLabel:hover {
  background-color: #4d1f94;
} */

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.UploadLabel div.UpIcon {
  width: 40px;
  background: #a069c6;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.UpIcon img {
  width: 20px;
  height: 20px;
  margin-bottom: 0;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadLabel span p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.UpIMGSec {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
  justify-content: space-between;
}

.ImgFile {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0;
  padding: 10px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFileUnder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px #ececec;
  border-radius: 8px;
  padding: 16px 8px 16px 16px;
  /* margin-bottom: 16px; */
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 67px;
}

.FileListDelete {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #eb534a !important;
  padding: 6px 11px !important;
  min-width: 20px !important;
  transition: all 0.2s ease-in-out !important;
}

.FileListDelete:hover {
  background: transparent;
}

.FileListDelete {
  opacity: 0;
}

.ImgFileUnder:hover .FileListDelete,
.ImgFileUnder.Active .FileListDelete {
  opacity: 1;
}

.FileName {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0;
  display: flex;
  align-items: center;
  width: 300px;
  min-width: 300px;
}

.FileName span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.FileName svg,
.FileName img {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  color: #2a5bee;
  margin-right: 12px;
  stroke-width: 1.5;
}

.FileSize {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #949494;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  position: relative;
  width: 180px;
  min-width: 180px;
  text-align: center;
}

.FileListRight {
  display: flex;
  align-items: center;
}

.FileListRight p.FileSize:last-of-type {
  margin: 0;
  padding: 0;
}

.FileListRight p::after {
  content: "";
  width: 2px;
  height: 10px;
  background: #cdcdcd;
  position: absolute;
  top: 53%;
  right: -2px;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  border-radius: 0;
}

.FileListRight p:last-of-type:after {
  display: none;
}

/* .FileListRight a,
.FileListRight button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2C2C2C;
  background-color: #fff;
  margin-top: -3px;
  border: none;
  padding: 0;
  margin-left: 8px;
} */

.FileListRight a svg,
.FileListRight button svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.ImgFileRow {
  display: flex;
  flex-wrap: wrap;
  margin: -10px -15px;
}

.ImgFileCard {
  padding: 18px 15px;
}

.ButtonSec {
  width: 100%;
  padding-top: 50px;
  padding-bottom: 10px;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 0;
}

.NextBU {
  padding: 8px 16px;
  background-color: #ff8271;
  margin: 5px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
}

.NextBU:hover {
  background-color: #ff8271;
  border: 1px solid #ff8271;
}

.BackBU {
  padding: 8px 16px;
  background-color: #fff;
  margin: 5px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #ff8271;
  border: 1px solid #ff8271;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
}

.BackBU:hover {
  background-color: #ff8271;
  color: #fff;
}

.NextBU svg,
.BackBU svg {
  width: 20px;
}

.NextBU svg {
  margin-left: 8px;
}

.BackBU svg {
  margin-right: 8px;
}

.AddBU {
  padding: 15px 25px;
  background-color: #ff8271;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.CancelBU {
  padding: 15px 25px;
  background-color: #fff;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.CancelBU:hover {
  background-color: #f1ecf3;
  /* border: 1px solid #230058;
  color: #fff; */
}

.LeaseUpload {
  width: 100%;
  /* border: 1px dashed #a069c6;
  border-radius: 0.5rem; */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23A069C6FF' stroke-width='3' stroke-dasharray='8%2c 12' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  padding: 1px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 10px;
  margin-top: 10px;
}

.LeaseUpload img {
  position: absolute;
  top: 70px;
  left: 50%;
  /* width: 60px; */
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  margin-top: 0;
  width: 40px;
  background: #a069c6;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
  padding: 10px;
}

.LeaseUpload > div {
  border: none;
  display: flex;
  /* align-items: center; */
  justify-content: center;
  background: transparent;
}

.LeaseUpload > div > div {
  position: relative;
  width: 350px;
  padding-top: 115px;
}

.LeaseUpload > div > div svg {
  display: none;
}

.LeaseUpload > div > div p {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #343434;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 44px;
}

.LeaseUpload > div > div p::after {
  content: "File size upto 15 MB PDF files only";
  position: absolute;
  bottom: 0px;
  width: 40%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.LeaseUpload > div > div p::before {
  content: "(Upload PDF files in the correct order)";
  position: absolute;
  bottom: 0;
  width: 100%;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
  display: none;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.EsignNote {
  font-size: 13px;
  font-weight: 500;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #666;
  margin: 5px 0 5px 0;
  background: #a069c61c;
  padding: 10px 12px;
  border-radius: 5px;
}

.EsignNote svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  color: #ff8271;
}

.AddTenantTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #505050;
  width: 100%;
  margin-bottom: 0px;
}

.MenuItemSub {
  padding-left: 8px;
  font-style: italic;
  font-size: 13px;
  font-weight: 600;
}

.FilterFieldSec {
  width: 100%;
  display: flex;
}

.ProspectCard {
  width: 25%;
  padding: 15px 13px;
}

.ProspectCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
  height: 100%;
  transition: all 0.3s ease-in-out;
}

.ProspectCardUnder:hover {
  box-shadow: 0 0 28px 0 #1f02541f;
}

.ProspectCardHeadSection {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.ProspectCardHeadSection .ProsIMGSec {
  min-width: 90px;
  width: 90px;
  min-height: 90px;
  height: 90px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ProsIMGSec .ProspectCardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ProsIMGSec p {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: #502688;
  letter-spacing: 1px;
}

.ProspectCardHeadSection .HeadConSec {
  width: calc(100% - 106px);
}

.prospectNameActionSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 7px;
}

.ProsUserName {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 7px 0;
  color: #505050;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ProsUserName.LimitName {
  width: calc(100% - 85px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin: 0 12px 0 0;
}

.ProspectNonAppliedBadge {
  background-color: #dfe2e6;
  color: #595959 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  white-space: nowrap;
}

.ProsUserMandateSec {
  width: 100%;
  display: flex;
  padding-top: 4px;
}

.MandateCheckSec {
  width: 26px;
  height: 26px;
  background-color: #dfe2e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.MandateCheckSec:last-child {
  margin-right: 0;
}

.MandateCheckSec img {
  width: 14px;
  height: 14px;
  object-fit: contain;
  object-position: center;
}

.MandateCheckSec.Active {
  background-color: #27c27b;
}

.MandateCheckSec.Due,
.MandateCheckSec.Ongoing {
  background-color: #FFBC00;
}

.MandateCheckSec.Fail {
  background-color: #ff8271;
}

.MandateCheckSec svg {
  width: 14px;
  height: auto;
  color: #fff;
  stroke-width: 3px;
}

.ProspectCardBodySec {
  display: flex;
  flex-wrap: wrap;
}

.ProspectCardBodySec ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

 .ProspectCardBodySec ul li {
  list-style: none;
  padding: 5px 0;
  display: flex;
  align-items: flex-start;
}

.ProspectCardBodySec ul li p.Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 28px;
  margin: 0;
  color: #666666;
  min-width: 140px;
  display: flex;
}

.ProspectCardBodySec ul li p.Title img {
  width: 16px;
  opacity: .6;
  margin-right: 10px;
}

.ProspectCardBodySec ul li p.Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: #343434;
  display: flex;
  flex-wrap: wrap;
}

.ProspectCardBodySec ul li p.Content.Hover {
  font-size: 14px;
  font-weight: 500;
  line-height: 28px;
  margin: 0;
  color: #343434;
  position: relative;
  transition: all .3s ease-in-out;
}

.ProspectCardBodySec ul li p.Content.Hover:hover {
  color: #f48272;
}

.ProspectCardBodySec ul li p.Content.Hover::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  background: #fff;
  bottom: 0;
  left: 0%;
  /* transform: translate(-50%, 0); */
  transition: all .3s ease-in-out;
}

.ProspectCardBodySec ul li p.Content.Hover:hover::after {
  width: 100%;
  background: #f48272;
}

.AddFormTitleSec {
  display: flex;
  align-items: center;
  width: 100%;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.InfoStatusBadge {
  font-weight: 500;
  font-size: 12px;
  padding: 5px 12px;
  border-radius: 15px;
  margin: 0 3px 3px 0;
  height: 25px;
  line-height: normal;
}

.InfoStatusBadge.Ongoing {
  background-color: #fff3e8;
  color: #e99a37;
}

.InfoStatusBadge.Open {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688;
}

.InfoStatusBadge.Resolved {
  background-color: rgb(232, 255, 242);
  color: #5ac55e;
}

.InfoStatusBadge.Cancel {
  background-color: #ffe8e8;
  color: #e23725;
}

.DropdownPageTitleMenu {
  padding: 0;
}

.DropdownPageTitleMenu a {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
  padding: 8px 15px;
  font-family: "Inter", sans-serif !important;
  width: 120px;
}

.pageTitle.Mobile {
  display: none;
}

.pageTitle.Big {
  display: block;
}

.ButtonAction {
    width: 100%;
    padding-top: 35px;
    display: flex;
    align-items: center;
}

@media screen and (min-width: 360px) {
  .mainCardDiv {
    padding-right: 0px;
  }
  .ProspectCard {
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .ProspectCard {
    width: 50%;
  }
  .AddTenantHalfSec {
    width: 100%;
    padding-bottom: 25px;
  }
  .AddTenantHalfSecBig {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {
  .SearchCon {
    width: 35%;
  }
  .FilterSec {
    width: 65%;
  }
  .FilterBu {
    display: none;
  }
  .FilterTitle {
    display: none;
  }
  .ProsUserName .LimitName {
    width: 170px;
  }
}

@media screen and (min-width: 1300px) {
  .SearchCon {
    width: 40%;
    padding-bottom: 0;
  }
  .FilterSec {
    width: 60%;
  }
  .ProsUserName .LimitName {
    width: calc(100% - 80px);
  }
  .AddTenantHalfSec {
    width: 48%;
  }
  .AddTenantHalfSecBig {
    width: 48%;
  }
}

@media screen and (min-width: 1360px) {
  .ProspectCard {
    width: 33.33%;
  }
}

@media screen and (min-width: 1400px) {
  .SearchCon {
    width: 45%;
  }
  .FilterSec {
    width: 55%;
  }
}

@media screen and (min-width: 1600px) {
  .SearchCon {
    width: 45%;
  }
  .FilterSec {
    width: 55%;
  }
  .ProspectCard {
    width: 25%;
  }
}

@media screen and (min-width: 1800px) {
  .SearchCon {
    width: 50%;
  }
  .FilterSec {
    width: 50%;
  }
}

@media (max-width: 1199px) {
  .FilterSec {
    width: 197px;
  }
  .SearchCon {
    width: calc(100% - 197px);
  }
  .FilterFieldSec {
    width: 280px;
    height: calc(100% - 68px);
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    background: #fff;
    top: 60px;
    z-index: 99;
    right: -300px;
    align-content: flex-start;
    padding: 40px 15px;
    box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
    border-left: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
  }
  .FilterFieldSec.Open {
    right: 0;
  }
  .FilterCard {
    width: 100%;
    padding: 10px 10px;
  }
}

@media (max-width: 991px) {
  .ProspectCardHeadSection {
    flex-wrap: wrap;
    justify-content: center;
  }
  .ProspectCardHeadSection .HeadConSec {
    width: 100%;
  }
  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 90px;
    width: 90px;
    min-height: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .ProsUserName.LimitName {
    width: 100%;
    margin: 0 0 0 0;
    text-align: center;
  }
  .ProsUserName {
    justify-content: center;
  }
  .ProspectNonAppliedBadge {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ProsUserMandateSec {
    justify-content: center;
  }
  .ArchivedBadge {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 959px) {
  .pageTitle.Mobile {
    display: flex;
  }

  .pageTitle.Big {
    display: none;
  }
  .breadcrumb {
    display: none;
  }
  .pageTitleWrap {
    padding: 1rem 1.8rem 0.5rem 1.8rem;
  }
  .FilterFieldSec {
    top: 0;
  }
}

@media (max-width: 767px) {
  .AddNoteBu span {
    display: none;
  }
  .AddNoteBu svg {
    margin-right: 0;
  }
  .AddNoteBu,
  .FilterBu {
    margin: 0 5px;
  }
  .FilterSec {
    width: 130px;
  }
  .SearchCon {
    width: calc(100% - 130px);
  }
  .BackAddBU {
    display: flex !important;
  }
  .AddTenantHalfSec {
    width: 100%;
    padding-bottom: 25px;
  }
  .AddTenantHalfSecBig {
    width: 100%;
  }
  .AddTenant::-webkit-scrollbar {
    display: none;
  }
  .AddTenant {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
  .DatePick {
    width: 100%;
  }
}

@media (max-width: 575px) {}

@media (max-width: 479px) {
  .mainCard {
    padding: 0;
    margin: 0 0.8rem;
    background: transparent;
    box-shadow: unset;
  }
  .pageTitleWrap {
    padding: 1rem 0.8rem 0.5rem 0.8rem;
  }
  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 60px;
    width: 70px;
    min-height: 60px;
    height: 70px;
  }
  .AddTenantBU {
    flex-wrap: wrap;
  }
  .AddTenantBU button {
    width: 100%;
    margin: 0 0 15px 0;
  }
  .HalfSecHalf {
    width: 100% !important;
  }
  .HalfSecHalfRow {
    display: block;
  }
}
