.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  margin-bottom: 50px !important;
}

.mainCard > div {
  height: 100% !important;
}

.mainCard > div > div > div:first-child {
  padding: 24px;
  position: relative;
}

.mainCard > div > div > div.ApartmentSarchSec > div > div {
  padding: 0;
}

.mainCard > div > div > div.ApartmentSarchSec > div > div button,
.ApartmentSarchSec button {
  border: none;
  width: 40px;
  height: 40px;
  margin: -3px 2px 0 0;
  background: transparent;
  font-size: 28px;
  font-weight: 300;
  color: #343434;
  line-height: 10px;
}

.mainCard > div > div > div.ApartmentSarchSec svg {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translate(0px, -50%);
  width: 20px;
  height: 20px;
  color: #666;
}

.mainCard > div > div > div:first-child input {
  border: 1px solid #e1e1e1;
  width: 100% !important;
  padding: 21px 50px 21px 15px !important;
  height: 30px;
  border-radius: 8px;
}

.mainCard > div > div > div.ApartmentSarchSec input {
  border: 1px solid #e1e1e1;
  width: 100% !important;
  padding: 10px 10px 10px 46px !important;
  height: 45px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 21px;
}

.mainCard > div > div > div:first-child input:focus,
.mainCard > div > div > div.ApartmentSarchSec input:focus {
  outline: none;
  box-shadow: none;
}

.mainCard > div > div > div.ApartmentSarchSec input::placeholder {
  color: #bfbfbf;
  opacity: 1;
  /* Firefox */
}

.mainCard > div > div > div.ApartmentSarchSec input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

.mainCard > div > div > div.ApartmentSarchSec input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.PropertyGroupSec {
  width: 100%;
  padding: 0 0 15px 24px;
  overflow: auto;
  height: 69vh;
}

.PropertyGroup {
  padding: 0 0 15px 0;
}

.PropertyGroupHead {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  padding: 5px 0;
}

.PropertyImageSec {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}

.PropertyImageSec.Avatar {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PropertyImageSec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

/* .PropertyImageSec.Avatar img {
  width: 22px;
  height: auto;
} */

.GroupAvatarSec {
  width: auto;
  /* border: 1px solid #FF8271; */
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: auto;
}

.GroupAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  background: #ff8271;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -18px;
  border: 1px solid #fff;
}

.GroupAvatar:nth-child(1) {
  z-index: 5;
  margin: 0;
}

.GroupAvatar:nth-child(2) {
  z-index: 4;
}

.GroupAvatar:nth-child(3) {
  z-index: 3;
}

.GroupAvatar:nth-child(4) {
  z-index: 2;
}

.GroupAvatar:nth-child(5) {
  z-index: 1;
}

.GroupAvatar:nth-child(6) {
  z-index: 0;
}

.GroupAvatar img {
  width: 16px;
  height: auto;
}

.GroupAvatarCount {
  font-size: 15px;
  margin: 0 0 0 10px;
  color: #666;
  font-weight: 500;
}

.PropertyGroupTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 4px 0;
}

.PropertyGroupMember {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0;
}

.PropertyGroupNotiCount {
  font-size: 12px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background: #ef8977;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  margin-left: auto;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.PropertyTenantNotiCount {
  font-size: 12px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background: #ef8977;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0px;
  margin-left: auto;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.PropertyGroupBody {
  width: 100%;
  min-height: 0px;
  max-height: 0px;
  overflow: hidden;
  /* transition: all .5s ease-in-out; */
}

.PropertyGroup.Open .PropertyGroupBody {
  min-height: 50px;
  max-height: 100%;
}

.PropertyGroup.Open .PropertyGroupBody ul {
  max-height: 100% !important;
  overflow: unset !important;
}

.PropertyGroup .Icon {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #495057;
}

.PropertyGroup .Icon svg {
  width: 22px;
  height: 22px;
  stroke-width: 1.5px;
}

.TenantNotiCount {
  width: 30px;
  height: 30px;
  position: relative;
}

.TenantNotiCount svg {
  width: 22px;
  height: 22px;
  color: #9e9e9e;
}

.ConLoaderS {
  height: 500px;
}

.ConLoaderS svg {
  width: 100%;
  height: 100%;
}

.mainCard > div {
  box-shadow: none;
}

.ChatSecHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #dfe2e6;
}

.ChatSecHeaderTenantSec {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.ChatSecHeaderTenantSecTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 0 0;
}

.ChatSecHeaderTenantSecMember {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  border-right: 1px solid #dfe2e6;
}

.ChatSecHeaderTenantSecMember:last-child {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border-right: 0px solid #dfe2e6;
}

.ChatSecSearch {
  width: 44px;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.ChatSecSearch:hover {
  background-color: #ff8271;
}

.ChatSecSearch.Open {
  width: 400px;
}

.ChatSecSearch svg {
  width: 22px;
  height: 22px;
  color: #ff8271;
  transition: all 0.2s ease-in-out;
}

.ChatSecSearch:hover svg {
  color: #fff;
}

.ChatSecSearch input {
  border: none !important;
  width: 100% !important;
  padding: 0 15px 0 5px !important;
  font-size: 14px;
  display: none;
}

.ChatSecSearch.Open input {
  display: block;
}

.ChatSecStartMessage {
  min-width: 44px;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px 16px;
  height: 44px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ff8271;
  margin: 0 0 0 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.ChatSecStartMessage:hover {
  background-color: #ff8271;
  color: #fff;
}

.ChatSecStartMessage svg {
  width: 22px;
  height: 22px;
  color: #ff8271;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

.ChatSecStartMessage:hover svg {
  color: #fff;
}

.ChatSecHeaderActions {
  display: flex;
  align-items: center;
}

.ChatNewPopHead {
  padding: 18px 32px;
  background-color: #fff;
}

.ChatNewPopHead h2 {
  height: auto !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em;
  color: #343434 !important;
}

.ChatNewPopHead .CloseBU {
  background: #fff !important;
  border: none !important;
  color: #343434 !important;
  padding: 0 !important;
  width: 50px;
  height: 40px;
  text-align: right;
}

.ChatNewPopHead .CloseBU svg {
  width: 24px;
  height: 24px;
}

.ChatNewPopBody {
  overflow: unset;
  height: 375px;
  padding: 0 32px 12px 32px;
}

.ChatBody {
  width: 540px;
}

.ChatBody > div > div > div:first-child input {
  border: 1px solid #e1e1e1;
  width: 100% !important;
  padding: 21px 50px 21px 15px !important;
  border-radius: 8px;
}

.ChatBody > div > div > div:first-child input:focus {
  outline: none;
  box-shadow: none;
}

.PopForm {
  width: 100%;
  margin-bottom: 25px;
}

.PopFormTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 8px;
}

.PopFormTextArea {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 12px 16px;
  height: 140px;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.PopFormSendBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background-color: #ff8271;
  border: none;
  border-radius: 8px;
  margin-bottom: 32px;
}

.ChatSecMenu {
  min-width: 44px;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ChatSecMenu svg {
  width: 22px;
  height: 22px;
  color: #ff8271;
}

.ChatDropMenu li {
  padding: 0;
}

.ClearPro {
  padding: 10px 20px !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #343434;
  background-color: #fff !important;
}

.ClearPro img,
.DeleteChat img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.DeleteChat {
  padding: 10px 20px !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #eb534a;
  background-color: #fff !important;
}

.ClearPro:hover,
.DeleteChat:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ChatInputSec {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 8px;
}

.ChatInputField {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #bfbfbf;
  padding: 6px 10px;
  width: 100%;
  border: none;
}

.ChatInputSec textarea {
  font-weight: 500;
  font-size: 15px;
  line-height: 26px;
  color: #343434;
  padding: 9px 10px 7px 10px;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  resize: none;
  height: 39px !important;
}

.ChatInputSec textarea::-webkit-scrollbar {
  width: 5px;
}

.ChatInputSec textarea::-webkit-scrollbar-track {
  background: transparent;
}

.ChatInputSec textarea::-webkit-scrollbar-thumb {
  background: #ced0d1;
}

.ChatInputSec textarea::-webkit-scrollbar-thumb:hover {
  background: #ced0d1;
}

.SendBUMSG {
  width: 42px;
  height: 42px;
  background: #ff8271;
  border: 1px solid #ff8271;
  border-radius: 8px;
  display: flex !important;
  color: #fff;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.SendBUMSG:hover {
  background: #e97565;
  border: 1px solid #e97565;
}

.SendBUMSG svg,
.AttatchFileBUMSG svg {
  width: 22px;
  height: 22px;
}

.AttatchFileBUMSG {
  width: 42px;
  height: 42px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex !important;
  color: #ff8271;
  align-items: center;
}

.ChatMessages {
  overflow: auto !important;
}

.SendIcon {
  width: 18px;
  height: auto;
  object-fit: contain;
}

.HeadSearchSec {
  width: 100%;
  background: #fff;
  padding: 15px 35px;
  box-shadow: 0px 14px 18px 0 rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  position: absolute;
  z-index: -1;
  top: 50px;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.HeadSearchSec.Open {
  z-index: 2;
  top: 96px;
  opacity: 1;
}

.HeadSearchSec input {
  width: 100%;
  border: none;
  font-size: 14px;
}

.HeadSearchSec svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.ChatNotificationSec {
  width: 440px;
  height: auto !important;
  position: absolute;
  bottom: 30px;
  right: 30px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(62, 85, 120, 0.1);
  border-radius: 8px;
  padding: 16px 20px;
}

.ChatNotificationSecTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 4px 0;
}

.ChatNotiBodySec {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 25px 0 5px 0;
}

.ChatNotiBodyIcon {
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  border-radius: 50%;
  overflow: hidden;
  background: #fbf7fc;
  margin-right: 15px;
}

.ChatNotiBodyIcon img {
  width: 100%;
  height: 100%;
}

.ChatNotificationSecSubTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0;
}

.ChatNotiBodyTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 5px 0;
}

.ChatNotiBodyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #7a7a7a;
  margin: 0 0 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.ChatNotiBodyTextSec {
  padding: 6px 0 0 0;
}

.CloseChatNotiBU {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 8px;
  right: 6px;
  border: none;
  background: none;
}

.CloseChatNotiBU svg {
  width: 22px;
  height: 22px;
  stroke-width: 1.5;
  color: #343434;
}

.WelcomeMessageSec {
  width: 100%;
  height: Calc(100% - 93px);
  position: absolute;
  background: #fff;
  top: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WelcomeMessageSec.NoTop {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WelcomeMessageBody {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.WelcomeMessageBody img {
  width: 160px;
  height: auto;
  margin-bottom: 40px;
}

.WelcomeMessageTitle {
  color: #343434;
  font-size: 48px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.48px;
  width: 100%;
  text-align: center;
}

.WelcomeMessageText {
  color: #666;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.13px;
}

.TenantNBU {
  padding: 0;
  background-color: transparent !important;
  color: #666 !important;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
  border: none !important;
}

.TenantNBU:hover,
.TenantNBU:active {
  background-color: transparent !important;
  color: #666 !important;
}

.TenantNBU:after {
  display: none;
}

.TenantNBU svg {
  width: 16px;
  height: 16px;
  margin: -2px 0 0 5px;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 8px 10px !important;
  min-width: 158px;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #f8f8f8;
}

.PositionRelative {
  position: relative;
}

.AvatarSec {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.AvatarHalfSec {
  width: Calc(50% - 1.2px);
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.AvatarSec.Full .AvatarHalfSec {
  width: 100%;
}

.AvatarHalfIcon {
  width: 100%;
  height: Calc(50% - 1px);
  display: flex;
}

.AvatarHalfSec.Full .AvatarHalfIcon,
.AvatarSec.Full .AvatarHalfIcon {
  height: 100%;
}

.AvatarHalfIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.AvatarHalfIcon span {
  width: 100%;
  height: 100%;
  background: #f48272;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 600;
  color: #fff;
  text-transform: uppercase;
}

.MessageFileSec {
  padding: 16px;
  background: #fbf7fc;
  border-radius: 6px;
  min-width: 300px;
  position: relative;
}

.MessageFileSec p {
  color: #343434;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  margin: 0 0 8px 0;
  padding-right: 0px;
  max-width: 250px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.MessageFileSec > div > div {
  width: 550px !important;
  height: auto !important;
}

.MessageFileSec > div > div.ImgFile {
  width: auto !important;
  height: auto !important;
  cursor: pointer;
}

.MessageFileSec video {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}

.MessageFileSec img {
  width: 300px;
  height: auto;
  border-radius: 6px;
  margin: 5px 0;
}

.MessageFileSec p.NoFileTT {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.NoFileDrBU {
  border: 1px solid #230058;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  color: #230058;
  padding: 8px 8px;
  border-radius: 6px;
  margin-top: 8px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.NoFileDrBU:hover {
  border: 1px solid #230058;
  background-color: #230058;
  color: #fff;
}

.MessageFileSec > div > div.ImgFilePops {
  position: fixed;
  width: 100% !important;
  height: 100% !important;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  z-index: -99999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.MessageFileSec > div.ActiveIMG > div.ImgFilePops {
  opacity: 1;
  z-index: 99999;
}

.MessageFileSec > div > div.ImgFilePops img {
  width: 90%;
  height: 90%;
  border-radius: 6px;
  margin: 5px 0;
  object-fit: contain;
}

.CancelImgBU {
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
}

.CancelImgBU:focus {
  outline: none;
}

.CancelImgBU svg {
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
}

.DRBU {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  right: 9px;
  top: 8px;
}

.DRBU {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent !important;
  border: none;
  color: #343434;
  transition: all 0.3s ease-in-out;
}

.DRBU:hover,
.DRBU:focus {
  background-color: transparent !important;
  border: none;
  color: #f48272;
  outline: none;
}

.DRBU svg {
  width: 20px;
}

.ConStarted {
  font-size: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ConStarted img {
  margin: 0;
  background: transparent;
  display: block;
  height: 100px;
  width: auto;
  position: relative;
  top: 5px;
}

.ProspectChatHeadIcon {
  background-color: #ff8271;
  width: 100%;
  height: 100%;
  color: #fff;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.TagDiv {
  font-size: 13px;
  line-height: 17px;
  font-weight: 500;
  background: #999;
  color: #fff;
  padding: 4px 10px;
  border-radius: 24px;
  margin: 0 0 0 8px;
}

.TagDiv.Success {
  background: #e8fff2;
  color: #5ac55e;
}

.TagDiv.Purple {
  background: #5026884d;
  color: #502688;
}

.GroupName {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(100% - 30px);
}

@media screen and (min-width: 360px) {
  .mainCard {
    margin: 0 1rem;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }
}

@media screen and (min-width: 576px) {
  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

.activeList {
  background-color: rgba(0, 0, 0, 0.04);
}

.PropertyAvatarNew {
  height: 44px;
  width: 44px;
}
