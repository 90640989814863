.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.mainCard {
  padding: 0 15px;
  width: 100%;
  float: left;
  position: relative;
  z-index: 1;
  margin-bottom: 100px !important;
}

.SMReferDeBigDiv {
  width: 100%;
  position: relative;
  padding: 0 15px;
  float: left;
}

.Card {
  width: 100%;
  padding: 20px;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 10%);
  border-radius: 0.5rem;
  /* overflow: hidden; */
  position: relative;
  background: #fff;
  border: none;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  margin-bottom: 1.875rem;
}

.ActionRefMain {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-bottom: 20px;
}

.TitleSummary {
  margin-bottom: -10px;
  max-width: 100%;
  align-items: center;
  display: flex;
  flex-wrap: wrap;
}

.ReqDeTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: CalC(100% - 180px);
  display: inline-block;
  font-size: 18px;
  line-height: 28px;
  font-weight: 500;
  color: #343434;
  margin: 0;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
  min-width: 200px;
  align-items: center;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.SMBigSideBody {
  position: relative;
}

.BorderDivSumm {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 15px 0 30px 0;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.SummFormFull {
  width: 100%;
  padding: 5px 15px;
}

.SummFormFull p {
  font-size: 0.875rem;
  margin: 0;
}

.SummFormSemiHalf {
  width: 25%;
  padding: 15px 15px;
  position: relative;
}

.SummFormSemiHalf button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: 5px;
  right: 5px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.SummFormSemiHalf button svg {
  width: 15px;
  height: 15px;
  margin-top: 0;
}

.SummFormSemiHalf img {
  border-radius: 10px;
  width: 100%;
  height: 180px;
  object-fit: cover;
  object-position: center;
  box-shadow: 0 0 5px 0 #3333333b;
}

.SummFormLabel {
  font-size: 14px;
  line-height: 28px;
  font-weight: 600;
  color: #333;
  margin: 0 0 7px 0;
}

.SummFormLabelForm {
  font-size: 13px;
  line-height: 28px;
  font-weight: 500;
  color: #333;
  margin: 0 0 5px 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.formControlTextArea {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 100px;
  padding: 12px;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  resize: none;
}

.ReferExpMain {
  display: table;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  margin: 15px 0;
  /* overflow: hidden; */
  width: 100%;
}

.ReferBodySummCommNote {
  display: flex;
  padding-top: 8px;
}

.ReferBodySummCommNote img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.ReferBodySummCommBody {
  width: 100%;
  padding-left: 25px;
}

.NoteListTitle {
  display: flex;
}

.SummCommBodyName {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #230058;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
  padding-right: 70px;
}

.SummCommBodyName span {
  color: #9f9f9f;
  padding-left: 8px;
}

.NoteListIcons {
  display: flex;
  position: absolute;
  top: 22px;
  right: 30px;
}

.NoteListIcons svg {
  width: 18px;
  height: 18px;
  color: #333;
  stroke-width: 1;
  transition: all 0.3s ease-in-out;
}

.RateStarDiv svg {
  width: 18px;
  height: 18px;
  color: #333;
  stroke-width: 1;
  transition: all 0.3s ease-in-out;
}

.StarNote:hover svg {
  color: #ff8271;
  fill: #ff8271;
}

.StarNoteActive svg {
  color: #ff8271;
  fill: #ff8271;
}

.RateStarDiv {
  margin-bottom: 15px;
}

.RateStarDiv a {
  margin-right: 3px;
}

.FilesUpCardDD,
.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0;
  padding: 0;
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0;
}

.FilesUpCardDD:after {
  display: none;
}

.FilesUpCardDD svg {
  width: 18px;
  stroke-width: 2;
}

.DropDownMain {
  background: none;
  border: none;
  padding: 0 0 0 15px;
  margin-top: -4px;
  width: 35px;
}

.SummCommBodyDate {
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #a7abc3;
}

.SummCommBodyText {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.NotesCommentIconsec {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

.NotesCommentLink {
  font-size: 13px;
  font-weight: 500;
  color: #230058;
  line-height: normal;
  margin-bottom: 0;
}

.NotesCommentLink svg {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  stroke-width: 1;
  color: #a9a9a9;
  margin-bottom: 0px;
}

.ReferNoteSec {
  width: 100%;
  flex-wrap: wrap;
  padding: 17px 48px 17px 90px;
}

.ReffScrollDiv {
  width: 100%;
  max-height: 800px;
  overflow: auto;
  margin-bottom: 15px;
  padding-right: 8px;
}

.ReffScrollDiv .ReferBodySummCommNotes {
  width: 100%;
}

.ReferBodySummCommNotes {
  display: flex;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  /* overflow: hidden; */
  padding: 25px;
  width: 100%;
  margin: 15px 0;
  position: relative;
}

.ReferBodySummCommNotes img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.formGroup {
  margin-bottom: 12px;
}

.formGroup p {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #dc3545;
}

.FormGroupCusB p {
  width: 100%;
  font-size: 12px;
  margin-bottom: 0px;
}

.ErrorM {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  color: #dc3545;
  margin-top: 4px;
}

.errorSpan {
  color: #ff000d;
  font-weight: bold;
  margin-top: 0.25rem;
  font-size: 13px;
}

.formGroupLabel {
  margin-bottom: 8px;
  display: block;
}

.formGroupTextArea {
  width: 100%;
  resize: none;
  height: 150px;
  border-radius: 5px;
  border: 1px solid #ccc;
  padding: 5px 10px;
}

.ReferCommentArea {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  height: 80px;
  padding: 6px;
  resize: none;
}

.ReferSubmitBuNotes {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #fff;
  background: #aa66cb;
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ReferSubmitBuNotes:hover {
  background: #a862ca;
}

.ReferCancelBuNotes {
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 40px;
  color: #fff;
  background: #ff8271;
  border-radius: 8px;
  border: none;
  padding: 0 20px;
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.ReferCancelBuNotes:hover {
  background: #e97565;
}

.HistoryTitle {
  padding: 10px 0px;
  display: flex;
  align-items: center;
}

.HistoryTitle img {
  width: 30px;
  height: 30px;
  margin-right: 15px;
}

.HistoryTitleCon {
  font-size: 15px;
  font-weight: 500;
  color: #333;
  margin-top: 0;
  margin-bottom: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 100%;
}

.HistoryTitleCon span {
  font-size: 14px;
  color: #a7abc3;
  font-weight: 400 !important;
  margin-left: auto;
}

.BorderDivSummHis {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 5px 0 15px 0;
}

.BorderDivSummComm {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
}

.StatusChangeBU {
  color: #fff;
  background: #333;
  padding: 4px 12px;
  border-radius: 5px;
  font-size: 12px;
  margin: 0 5px;
  white-space: nowrap;
}

.StatusChangeBU svg {
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.YellowBu {
  background: #fb3;
  color: #000;
}

.GreenBu {
  background: #17dd1b;
  color: #fff;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 20px;
  height: 20px;
}

.AddCommDiv {
  margin: 15px 0;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px 20px 25px;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0 15px;
  float: left;
}

.CaseSummSideUL {
  padding: 0;
  margin: 0;
}

.CaseSummSideUL li {
  list-style: none;
  border-bottom: 1px solid #e5e8eb;
  padding: 10px 5px;
}

.CaseSummSideUL li.Dropdown {
  padding: 10px 5px 20px 5px;
  font-size: 0.875rem;
}

.CaseSummSideUL li:last-child {
  border-bottom: none;
}

.ULtitle {
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  text-transform: uppercase;
  color: #999999;
  margin: 0;
}

.ULData {
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.ULData a {
  color: #230058;
}

.TagDiv {
  font-size: 14px;
  font-weight: 500;
  background: #999;
  color: #fff;
  padding: 5px 8px;
  margin-left: 8px;
  border-radius: 24px;
  line-height: 17px;
  letter-spacing: 0.14px;
  margin: 0;
}

.TagDiv.Red {
  background: #ffe8e8;
  color: #e23725;
}

.ButtonVoiletSolid {
  padding: 14px 25px;
  background-color: #502688;
  margin: 0px 15px 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.ButtonVoiletLine {
  padding: 14px 25px;
  background-color: #fff;
  margin: 0px 15px 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

.DeEditBU {
  margin-left: 15px;
  margin-top: -5px;
}

.DeEditBU svg {
  width: 18px;
  height: 18px;
  color: #8a8a8a;
}

.UploadLabel {
  padding: 5px 15px 5px 15px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  -webkit-align-items: center;
  align-items: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #ff8271;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #ff82717a;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 180px;
  text-transform: capitalize;
  cursor: pointer;
  /* padding-left: CalC(50% - 50px); */
  position: relative;
}

/* .UploadLabel span {
    width: 100%;
} */

/* .UploadLabel:hover {
  background-color: #230058;
} */

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
}

.UploadLabel div.UpIcon {
  display: block;
}

.UploadText {
  margin: 0 0 5px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  font-size: 10px;
}

.FileUpDuBUDiv {
  padding: 15px 15px;
  width: 25%;
}

.FileUpDuBUDiv input {
  display: none;
}

.NoDataMain {
  width: 100%;
  margin: 50px 0;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 45px;
  margin: auto;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 30px 0 !important;
  font-weight: 700;
  color: #e5e5e5;
  line-height: unset;
  text-transform: capitalize;
}

.LoaderCard {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.AppointmentsHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.TimePick {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  width: 100%;
  height: 38px;
  position: relative;
}

.TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 10px;
  right: 7px;
  color: #585858;
  /* z-index: 1; */
}

.TimePick > div {
  position: relative;
  /* z-index: 2; */
  width: 100%;
  padding-right: 18px;
}

/* .TimePick > div button svg {
    display: none;
} */

.AppoAddTab {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AppoDropDown {
  width: 32%;
  margin-bottom: 15px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 37%;
  display: flex;
  flex-wrap: wrap;
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 0px 8px 0;
  /* min-width: 350px; */
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

.MainReqConDivSmall {
  padding: 0 15px;
  width: 37%;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
  width: 50%;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.MainReqConUR .MainReqConDe:last-child {
  padding-right: 0px;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
  padding: 0 15px;
}

.NotesRow a,
.NotesRow a:hover,
.NotesRow button,
.NotesRow button:hover {
  margin-left: auto;
  color: #666;
  padding: 0;
  min-width: 10px;
}

.NotesRow a svg,
.NotesRow button svg {
  width: 18px;
  height: 18px;
}

.DesText {
  font-size: 14px;
  line-height: 25px;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 20px;
  margin-top: 2px !important;
}

.QuickbookHeadSec {
  display: flex;
  justify-content: space-between;
}

.BackArrow {
  color: #502688;
  font-size: 14px;
  font-weight: 600;
  margin-right: 16px;
  position: relative;
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.BackArrow:focus {
  outline: none;
  box-shadow: none;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #230058;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 18px;
  margin: -3px 3px 0 0;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 22px !important;
  font-weight: 600 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0;
  border: none !important;
  padding: 0 16px 0 16px !important;
  border-radius: 8px !important;
}

.ActionsBU:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU:focus {
  outline: none;
}

.ActionsBU svg {
  min-width: 20px;
  min-height: 20px;
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.ActionsBU:after {
  display: none;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: transparent;
}

.ActionsBUListItem:last-child {
  border: none;
}

@media screen and (min-width: 360px) {
  .ReferExpMain {
    display: block;
  }

  .ReferBodySummCommNote,
  .ReferBodySummCommNotes {
    flex-wrap: wrap;
  }

  .ReferBodySummCommNotes {
    padding: 10px;
  }

  .ReferBodySummCommBody {
    padding-left: 0px;
    padding-top: 25px;
  }

  .SummCommBodyName {
    padding-right: 0px;
  }

  .ReferNoteSec {
    padding: 17px 17px 17px 17px;
  }

  .SMProCusSmallDiv {
    width: 100%;
  }

  .SummFormSemiHalf {
    width: 100%;
  }

  .HistoryTitleCon {
    display: grid;
  }

  .HistoryTitleCon span {
    margin-left: unset;
  }

  .HistoryTitle {
    align-items: unset;
  }

  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: 100%;
    display: inline-block;
  }

  .FileUpDuBUDiv {
    width: 100%;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
  }

  .NotesRow a,
  .NotesRow button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  .AppoDropDown {
    width: 100%;
  }

  .ButtonVoiletLine {
    margin: 15px 0px 15px 0;
  }

  .InsideConName {
    padding: 8px 30px 8px 0;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .InsideConCheck {
    padding: 15px 0 0 0;
    justify-content: flex-start;
    min-width: 100%;
  }

  .ReqDeTitle {
    white-space: normal;
    overflow: unset;
    text-overflow: unset;
    max-width: CalC(100% - 0px);
    margin: 0 15px 0px 0;
  }

  .TagDiv {
    margin-left: 0px;
  }

  .mainCard {
    padding: 0 0px;
  }

  .TitleSummary {
    margin-bottom: -10px;
  }
}

@media screen and (min-width: 576px) {
  .ReferBodySummCommNotes {
    padding: 15px;
  }

  .SummFormSemiHalf {
    width: 50%;
  }

  .HistoryTitleCon {
    display: flex;
  }

  .HistoryTitleCon span {
    margin-left: auto;
  }

  .HistoryTitle {
    align-items: center;
  }

  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: auto;
  }

  .FileUpDuBUDiv {
    width: 50%;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .ButtonVoiletLine {
    margin: 0px 15px 0 0;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }

  .mainCard {
    padding: 0 15px;
  }
}

@media screen and (min-width: 768px) {
  .ReferBodySummCommNote,
  .ReferBodySummCommNotes {
    flex-wrap: nowrap;
  }

  .ReferBodySummCommBody {
    padding-left: 25px;
    padding-top: 0px;
  }

  .SummCommBodyName {
    padding-right: 70px;
  }

  .ReferNoteSec {
    padding: 17px 48px 17px 90px;
  }

  .SummFormSemiHalf {
    width: 33.33%;
  }

  .FileUpDuBUDiv {
    width: 33.33%;
  }

  .MainReqConDivBig {
    width: 40%;
  }

  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConAction {
    width: 10%;
  }

  .NotesRow a,
  .NotesRow button {
    position: relative;
    top: 0;
    right: 5px;
  }

  .AppoDropDown {
    width: 32%;
  }

  .InsideConName {
    padding: 8px 0px 8px 0;
  }

  .InsideConCheck {
    padding: 0px 0 0 0;
    justify-content: flex-end;
    min-width: 165px;
  }

  .ReqDeTitle {
    max-width: 300px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0 15px 0 0;
  }

  .TitleSummary {
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 992px) {
  .ReferExpMain {
    display: table;
  }

  .MainReqConDivBig {
    width: 37%;
  }

  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConAction {
    width: 13%;
  }

  .ReqDeTitle {
    max-width: 400px;
  }
}

@media screen and (min-width: 1100px) {
  .SummFormSemiHalf {
    width: 25%;
  }

  .FileUpDuBUDiv {
    width: 25%;
  }

  .NotesCard {
    width: 49%;
  }

  .ReqDeTitle {
    max-width: 500px;
  }
}

@media screen and (min-width: 1200px) {
  .ReferBodySummCommNotes {
    padding: 20px;
  }

  .ReqDeTitle {
    max-width: 600px;
  }
}

@media screen and (min-width: 1280px) {
  .SMReferDeBigDiv {
    width: 68%;
  }

  .SMProCusSmallDiv {
    width: 32%;
    position: sticky;
    top: 155px;
  }

  .SummFormSemiHalf {
    width: 33.33%;
  }

  .FileUpDuBUDiv {
    width: 33.33%;
  }

  .NotesCard {
    width: 100%;
  }

  .ReqDeTitle {
    max-width: 350px;
  }
}

@media screen and (min-width: 1300px) {
}

@media screen and (min-width: 1400px) {
  .SMReferDeBigDiv {
    width: 70%;
  }

  .SMProCusSmallDiv {
    width: 30%;
  }

  .ReqDeTitle {
    max-width: 450px;
  }
}

@media screen and (min-width: 1500px) {
  .SMReferDeBigDiv {
    width: 74%;
  }

  .SMProCusSmallDiv {
    width: 26%;
  }

  .SummFormSemiHalf {
    width: 25%;
  }

  .FileUpDuBUDiv {
    width: 25%;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .NotesCard {
    width: 49%;
  }

  .ReqDeTitle {
    max-width: 550px;
  }
}

@media screen and (min-width: 1600px) {
  .SMReferDeBigDiv {
    width: 75%;
  }

  .SMProCusSmallDiv {
    width: 25%;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .ReqDeTitle {
    max-width: 650px;
  }
}

@media screen and (min-width: 1800px) {
  .SMReferDeBigDiv {
    width: 76%;
  }

  .SMProCusSmallDiv {
    width: 24%;
  }
}
