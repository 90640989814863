.pageTitleWrap {
  width: 100%;
  /* background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07); */
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  /* z-index: 1; */
  /*margin-bottom: 30px;*/
  /* top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999; */
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0px 1.8rem 0 1.8rem;
  position: relative;
  /* background: white; */
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  margin-bottom: 80px;
}

.imgDiv img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: center;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -14px;
}

.CaseDiv {
  padding: 14px;
}

.ChartFullDiv {
  width: 100%;
  padding: 14px;
}

.CardDivTwo {
  width: 50%;
  padding: 14px;
}

.CardDivTwo > div {
  margin-bottom: 0;
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
}

.IfnoCard {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  /* overflow: hidden; */
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
  border-radius: 6px;
  /* margin-bottom: 12px; */
  height: 100%;
}

.IfnoCardTwo {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #fff;
  /* overflow: hidden; */
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
  border-radius: 6px;
  /* margin-bottom: 12px; */
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  /* align-content: space-between; */
  justify-content: space-between;
}

.InsideCard {
  width: 100%;
  padding: 22px 16px;
}

.InsideCardTwo {
  width: 100%;
  padding: 22px 16px 10px 16px;
}

.InsideCardTitle {
  font-size: 18px;
  text-transform: capitalize;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  color: #333;
}

.FullSize.Collapse .InsideCardTitle {
  font-size: 13px;
}

.FullSize.Active .InsideCardTitle {
  font-size: 14px;
}

.FullSize {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  padding: 22px 30px;
  height: 118px;
  overflow: hidden;
  transition: all .3s ease-in-out;
  cursor: pointer;
}

.FullSize .InsideCardContentSec {
  opacity: 0;
}

.FullSize.Active .InsideCardContentSec {
  opacity: 1;
}

.FullSize:last-child {
  border: none;
}

.FullSize.Collapse {
  height: 70px;
  padding: 10px 10px;
}

.FullSize.Active {
  height: 220px;
  align-items: flex-start;
  padding: 0px 0px;
  overflow: unset;
  transition: all .3s ease-in-out;
}

.Size55 {
  display: flex;
  height: 60%;
  padding: 0 0 40px 0;
}

.Size45 {
  display: flex;
  height: 40%;
  padding: 0px;
}

.InsideCardTitle2 {
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333;
  margin-bottom: 10px;
}

.InsideCardTitle2 span {
  color: #afafaf;
  font-size: 14px;
  letter-spacing: 0.18px;
  margin-left: 15px;
  padding-left: 15px;
  border-left: 1px solid #d3d3d3;
}

.InsideCardTitle span {
  text-transform: capitalize;
  color: #757575;
  font-weight: 300;
}

.InsideCardTitleAmount {
  font-size: 38px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #333;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.FullSize.Collapse .InsideCardTitleAmount,
.FullSize.Active .InsideCardTitleAmount {
  font-size: 16px;
}

.InsideCardTitleAmount span {
  color: #505050;
  font-size: 16px;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.16px;
}

.InsideCardTitle span.Chip {
  text-transform: capitalize;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  background: #fb3;
  line-height: normal;
  padding: 3px 5px;
  border-radius: 6px;
}

.PrograssDiv {
  display: flex;
  margin-top: 30px;
  flex-wrap: wrap;
  margin: 18px 0 0 0;
}

.PrograssDivCollected,
.PrograssDivProcessing,
.PrograssDivDue {
  /* position: relative; */
  padding: 0 0px;
}

.PrograssDivCollected div,
.PrograssDivProcessing div,
.PrograssDivDue div {
  width: 100%;
  box-shadow: 0 0 0 3px #fff;
  transition: all 0.2s ease-in-out;
}

.PrograssDivCollected div:hover,
.PrograssDivProcessing div:hover,
.PrograssDivDue div:hover {
  box-shadow: 0 0 0 3px #f5f5f5;
}

.ProgressDivHoverBox {
  width: auto !important;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  position: absolute;
  box-shadow: none !important;
  padding: 4px 8px;
  border-radius: 4px;
  bottom: -32px;
  left: 50%;
  white-space: nowrap;
  transform: translate(-50%, 10px) !important;
  opacity: 0;
  transition: all 0.2s ease-in-out !important;
}

.PrograssDivCollected:hover .ProgressDivHoverBox.Collected,
.PrograssDivProcessing:hover .ProgressDivHoverBox.Processing,
.PrograssDivDue:hover .ProgressDivHoverBox.Due {
  transform: translate(-50%, 0px) !important;
  opacity: 1;
}

.ProgressDivHoverBox div {
  width: 13px;
  height: 13px;
  box-shadow: none !important;
  border: 2px solid #fff;
  background: #f40;
  margin-right: 5px;
}

.ProgressDivHoverBox.Collected div {
  background: #5ac55e;
}

.ProgressDivHoverBox.Processing div {
  background: #ffbc00;
}

.ProgressDivHoverBox.Due div {
  background: #eaecef;
}

.ProgressDivHoverBox p {
  width: auto !important;
  margin: 0 !important;
  font-size: 12px !important;
  font-weight: 500;
  color: #fff;
}

.PrograssDiv p {
  font-size: 14px;
  margin-top: 10px;
  width: 100%;
  margin-bottom: 0px;
  text-align: center;
}

.InsideCardFlex {
  position: relative;
  display: flex;
  align-items: center;
  align-content: space-between;
  justify-content: space-between;
}

.InsideCardFlex svg.ActionArrow {
  position: absolute;
  width: 22px;
  right: 0px;
  top: 50%;
  transform: translate(0, -50%);
}

.FullSize.Active .InsideCardFlex {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 8px;
  padding-top: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.InsideCardContentSec {
  width: 100%;
  height: 282px;
  overflow: auto;
}

.InsideCardSec {
  display: flex;
  align-items: center;
  padding: 10px 10px;
  border-bottom: 1px solid #e5e5e5;
}

.InsideCardSec:last-child {
  border-bottom: none;
}

.InsideCardSec p {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
  width: 30%;
  text-align: center;
}

.InsideCardSec p.Name {
  width: 40%;
  text-align: left;
}

.RentDropdown {
  width: 160px !important;
  margin: 0;
  padding: 0 !important;
}

.InsideCardFlex.Flex {
  flex-wrap: wrap;
  width: 100%;
}

.InsideCardFlexCon {
  width: 100%;
}

.InsideCardFlexConTwo {
  width: 100%;
  padding-top: 6px;
}

.PrograssIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 4px;
  background: #f9efff;
  margin-right: 24px;
}

/* .PrograssIcon img {
  width: 65px;
  height: 65px;
}

.PrograssIcon svg {
  stroke-width: 1;
  width: 50px;
  height: 50px;
} */

.ScrollCard a {
  width: 100%;
  display: inline-block;
  border-top: 1px solid #ececec;
}

.ScrollCard a:first-child {
  border-top: none;
}

.CaseCard {
  width: 100%;
  /* border-top: 1px solid #ececec; */
  padding: 15px 0px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

/* .CaseCard:nth-child(odd) {
  background: #fcf7fd;
} */

/* .CaseCard:first-child {
  border-top: none;
} */

.CaseCardTitle {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin-bottom: 0px;
  width: 100%;
  position: relative;
}

.IconBadgeSec {
  position: absolute;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}

.MandateCheckSec {
  width: 22px;
  height: 22px;
  background-color: #dfe2e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.MandateCheckSec.Active {
  background-color: #27c27b;
}

.MandateCheckSec.Fail {
  background-color: #ff8271;
}

.MandateCheckSec:last-child {
  margin: 0;
}

.MandateCheckSec svg {
  width: 12px;
  height: auto;
  color: #fff;
  stroke-width: 3px;
}

.HeadFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ViewAllBU {
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
  color: #502688;
  margin-bottom: 10px;
  transition: all 0.2s ease-in-out;
}

.ViewAllBU:hover,
.ViewAllBU:focus,
.ViewAllBU:active {
  color: #230058;
}

.TitleText {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #343434;
  margin-bottom: 8px;
  width: 100%;
  display: flex;
}

.TitleText span {
  color: #333;
  font-weight: 600;
  max-width: 80%;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #dcdcdc;
}

.TitleText span.NoWrap {
  margin: 0;
  padding: 0;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.TitleText span.NoWrapTwo {
  margin: 0;
  padding: 0;
  border: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 100%;
}

.DateText {
  font-size: 13px;
  line-height: normal;
  font-weight: 400;
  color: #b7b7b7;
  margin-left: 0px;
  margin-bottom: 0;
  min-width: auto;
  white-space: nowrap;
  width: 100%;
  text-align: right;
}

.TitleName {
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: #505050;
  margin-bottom: 0;
  white-space: nowrap;
}

.ScrollCard {
  height: 290px;
  overflow: auto;
  border: 0px solid #efefef;
  border-radius: 0px;
  padding-right: 8px;
}

.ScrollCard::-webkit-scrollbar {
  width: 6px;
}

.ScrollCard::-webkit-scrollbar-track {
  background: #fff;
}

.ScrollCard::-webkit-scrollbar-thumb {
  background: #dfe1e9;
  border-radius: 8px;
}

.ScrollCard::-webkit-scrollbar-thumb:hover {
  background: #dfe1e9;
}

.IconCardDiv {
  width: 33.33%;
  display: flex;
  flex-wrap: wrap;
}

.IconCardDivTwo {
  width: 33.33%;
  display: flex;
  flex-wrap: wrap;
}

.IconCardFullDiv {
  width: 100%;
  padding: 14px;
}

.SwitchDiv {
  width: 100%;
  padding: 14px;
  display: flex;
  align-items: center;
}

.SwitchDiv p {
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  margin-right: 15px;
}

.SwitchDiv > div {
  width: 100%;
  font-size: 0.875rem;
}

.NoDataText {
  margin: auto;
  font-size: 22px;
  font-weight: 600;
  color: #c9c9c9;
}

.DahhboardHead {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
}

.ActionsBU {
  width: auto !important;
  height: 42px;
  display: flex !important;
  justify-content: space-between;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background: #ff8271 !important;
  margin: 0 0 0 0px !important;
  border: none !important;
  padding: 11px 15px 11px 15px !important;
  border-radius: 8px !important;
}

.ActionsBU:hover,
.ActionsBU:active,
.ActionsBU:focus {
  background: #e97565 !important;
  background-color: #e97565;
  color: #fff;
}

.ActionsBU:after {
  display: none;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none !important;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active + .btn-primary.ActionsBU.Red,
.btn-check:checked + .btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
}

.ActionsBU svg.Drop,
.ActionsBULine svg.Drop {
  margin-left: 10px;
}

.ActionsBU svg.Add,
.ActionsBULine svg.Add {
  margin-right: 10px;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  display: flex !important;
  align-items: center !important;
}

.ActionsBUListItem img {
  margin-right: 12px !important;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665 !important;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.ActionsBUListItem:last-child {
  border: none !important;
}

.DeviceAlertIcon {
  width: 44px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
}

.DeviceAlertTT {
  width: Calc(100% - 60px);
}

.StatusPill {
  padding: 2px 8px 2px 8px;
  background: #e2e2e2;
  border: 0px solid #d5d5d5;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 500;
  margin: 0 0 5px 0;
  line-height: unset;
  display: inline-block;
}

.CardPillSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  width: 35%;
  align-content: center;
}

.CaseCardLeftSec {
  width: 70%;
}

.StatusPill.Red,
.StatusPill.Red:active,
.StatusPill.Red:checked {
  background: #ffe8e8;
  color: #e23725;
  border-color: #ffe8e8;
}

.StatusPill.NotApplied,
.StatusPill.NotApplied:active,
.StatusPill.NotApplied:checked {
  background: #dfe2e6;
  color: #595959;
  border-color: #dfe2e6;
}

.StatusPill.Open,
.StatusPill.Open:active,
.StatusPill.Open:checked {
  background: #5026884d;
  color: #502688;
  border-color: #5026884d;
}

.StatusPill.Yellow,
.StatusPill.Yellow:active,
.StatusPill.Yellow:checked {
  background-color: #fff3e8;
  border-color: #fff3e8;
  color: #e99a37;
}

.StatusPill.Green,
.StatusPill.Green:active,
.StatusPill.Green:checked {
  background-color: #e8fff2;
  border-color: #e8fff2;
  color: #5ac55e;
}

.NoDataSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
  height: 275px;
}

.NoDataIMG {
  width: 100px;
  height: 100px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 22px;
  margin: 0 auto 20px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 32px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.PaymentText {
  font-size: 14px;
  line-height: normal;
  font-weight: 600;
  color: #505050;
  margin-bottom: 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.RentCardNSec {
  width: 30%;
  padding: 0 8px 0 0;
}

.RentCardPSec {
  width: 50%;
  padding: 0 8px;
}

.RentCardTSec {
  width: 22%;
  padding: 0 0 0 8px;
}

.SwitchDiv.Select > div > div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.CloseAddTenantDash {
  color: #343434;
  position: absolute;
  top: 18px;
  right: 20px;
  width: 50px;
  height: 40px;
  background: transparent;
  border: none;
}

.CloseAddTenantDash:hover,
.CloseAddTenantDash:focus {
  outline: none;
  box-shadow: none;
}

.CloseAddTenantDash svg {
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
}

.TextWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 130px;
  display: block;
}

.DeviceTextWrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 180px;
  display: block;
  min-width: 180px;
  margin-right: 10px;
}

.RentCollectBigSec {
  width: 60%;
  padding-right: 0;
  border-right: 1px solid #e5e5e5;
  padding: 20px 30px;
}

.RentCollectSmallSec {
  width: 40%;
  display: flex;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.FormGroupSelect {
  width: 250px !important;
  padding: 0 !important;
}

.BatteryHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.BatteryHead3D {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 22px 15px 0 15px; 
}

.BatteryCardTitle {
  font-size: 18px;
  text-transform: capitalize;
  margin-top: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #333;
  margin-bottom: 10px;
}

.BetteryChartSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
}

.BetteryCount {
  width: calc(100% / 12);
  height: 40px;
  padding: 5px;
}

.BetteryCountUnder {
  width: 100%;
  height: 100%;
  background-color: #dfe2e6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
}

.BetteryCountUnder p {
  margin: 0;
  display: none;
}

.BetteryCount.Green .BetteryCountUnder {
  background-color: #5ac55e;
  color: #5ac55e;
}

.BetteryCount.Orange .BetteryCountUnder {
  background-color: #e99a37;
  color: #e99a37;
}

.BetteryCount.Red .BetteryCountUnder {
  background-color: #e23725;
  color: #e23725;
}

.BatteryChartRow {
  display: flex;
  align-items: center;
  padding-top: 10px;
}

.BetteryChartTitle {
  width: 120px;
  font-size: 15px;
  padding: 2px 0;
  margin: 0;
}

.RentCollectDE {
  width: 100%;
  position: absolute;
  top: 0;
  right: -100%;
  background-color: #fff;
  height: 100%;
  transition: all .2s ease-in-out;
}

.RentCollectDE.Active {
  right: 0;
}

.RentCollectDEHead {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  border-bottom: 1px solid #f5f5f5;
}

.RentCollectDEHead .Back {
  color: #343434;
  padding: 6px 6px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  margin: 0 15px 0 0;
  transition: all 0.3s ease-in-out;
}

.RentCollectDEHead .Back svg {
  width: 28px;
  height: 28px;
  stroke-width: 1.5px;
}

.RentCollectDEHead .Back:hover {
  background: #f1ecf3;
}

.DEHeadTitle {
  font-size: 14px;
  text-transform: capitalize;
  margin: 0px;
  display: flex;
  justify-content: space-between;
  color: #333;
}

.DEHeadSubTitle {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #333;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.ScoreCards {
  width: 25%;
}

.ScoreCardsTwo {
  width: 240px;
}

.ScoreCardsFull {
  width: 100%;
  height: 100%;
  padding: 14px;
  position: relative;
}

.ScoreCardsSwip {
  width: 100%;
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
  border-radius: 6px;
  overflow: hidden;
  position: relative;
}

.ScoreCardsUnder {  
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
  border-radius: 6px;
  width: 100%;
  height: 100%;
  background-color: #502688;
  padding: 20px 25px;
  display: flex;
  align-items: center;
}

.ScoreCardsUnder.Swipe {  
  box-shadow: 0px 0px 0px 0px rgba(62, 85, 120, 0);
  border-radius: 0px;
  position: relative;
}

.ScoreCardsUnder.Swipe.New {
  left: -100%;
  transition: all .3s ease-in-out;
}

.ScoreCardsUnder.Swipe.New.Active {
  left: 0%;
}

.ScoreCardsUnder.Swipe.Com {
  position: absolute;
  top: 0;
  left: 100%;
  transition: all .3s ease-in-out;
}

.ScoreCardsUnder.Swipe.Com.Active {
  left: 0%;
}

.ScoreCardIcon {
  width: 70px;
  height: 70px;
  background-color: #f9efff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  margin-right: 15px;
}

.ScoreCardIcon img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.ScoeCardTitle {
  font-size: 18px;
  text-transform: capitalize;
  margin: 0px;
  color: #fff;
}

.ScoeCardTitleTwo {
  font-size: 17px;
  text-transform: capitalize;
  margin: 0px;
  color: #b299d2;
}

.ScoeCardCon {
  font-size: 30px;
  line-height: 30px;
  font-weight: 600;
  margin-bottom: 0px;
  color: #ffffff;
}

.ScoeCardConThree {
  font-size: 30px;
  line-height: normal;
  font-weight: 600;
  margin-bottom: 12px;
  color: #ffffff;
  width: 100%;
}

.ScoeCardConTwo {
  font-size: 14px;
  line-height: 22px;
  font-weight: 400;
  margin: 0 0 0 auto;
  color: #b299d2;
}

.Map3D {
  width: 100%;
  height: 500px;
  position: relative;
}

.Map3D button {
  position: absolute;
  top: 60px;
  left: 10px;
  z-index: 2;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  font-size: 14px;
  color: #343434;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.Map3D button svg {
  width: 20px;
  margin-right: 5px;
}

.Map3SCanvas {
  width: 100%;
  height: 100%;
  /* margin-top: -40px; */
  border: 1px solid #ececec;
  border-radius: 6px;
  overflow: hidden;
  background: rgb(255, 255, 255);
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 60%, rgba(0, 197, 237, .7) 100%);
}

.ReloadMapBU {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  /* border: 1px solid #e1e1e1 !important; */
  /* border-radius: 8px !important; */
  padding: 10px 16px;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ReloadMapBU svg {
  width: 20px;
  height: auto;
  margin-right: 8px;
}

.MapOccupancyLagendSec {
  width: 100%;
  max-width: 400px;
  display: flex;
  align-items: center;
  margin: 20px auto -8px auto;
  justify-content: space-between;
}

.MapOccuLagend {
  width: 25%;
  display: flex;
  flex-wrap: wrap;
}

.MapOccuLagend p {
  width: 100%;
  text-align: center;
  font-size: 13px;
  margin: 2px 0 0 0;
}

.OccuLagend1 {
  width: 100%;
  height: 10px;
  background-color: #3676D1;
}

.OccuLagend2 {
  width: 100%;
  height: 10px;
  background-color: #42DE5C;
}

.OccuLagend3 {
  width: 100%;
  height: 10px;
  background-color: #FF8439;
}

.OccuLagend4 {
  width: 100%;
  height: 10px;
  background-color: #d5d8d5;
}

.FloorTitle {
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  margin-bottom: 0px;
  position: absolute;
  z-index: 1;
  top: 1px;
  left: 0%;
  padding: 10px 20px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  width: 100%;
  background: #333;
  opacity: .6;
}

.FloorWiseBU {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0;
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(0px, -50%);
  z-index: 1;
}

.FloorWiseBU button.Actions {
  position: unset !important;
  left: unset !important;
  left: unset !important;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 12px;
  font-size: 14px;
  color: #343434;
  border-radius: 4px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: 0 20px;
}

.FloorWiseBU button.Actions svg {
  width: 20px;
  margin-right: 0px;
}

.FloorWiseBU button.Actions.PreBU {
  margin-right: auto;
}

.FloorWiseBU button.Actions.NextBU {
  margin-left: auto;
}

.AppliSwitchBUSec {
  display: flex;
  position: absolute;
  bottom: 14px;
  right: 16px;
}

.AppliSwitchBU {
  background-color: transparent;
  border: none;
  width: 28px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(109 77 159);
  padding: 0;
}

.AppliSwitchBU svg {
  width: 18px;
  height: auto;
}

.ScoreCardCon {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.ScoreCardConTwo {
  width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-around;
  position: relative;
}

.ScoreCardConTwo:after {
  content: '';
  position: absolute;
  width: 1px;
  height: 100%;
  top: 50%;
  left: 50%;
  background-color: #aa6aff;
  transform: translate(-50%, -50%);
}

.ScorePrograssBar {
  width: 100%;
  height: 4px;
  background-color: #fff;
  border-radius: 12px;
  margin: 12px 0 10px 0;
  overflow: hidden;
}

.ScorePrograssBar .PrograssScore {
  background-color: #aa6aff;
  height: 100%;
}

.ScoreMiddleArea {
  width: 100%;
  display: flex;
  align-items: flex-end;
  margin: 8px 0 0 0;
}

.ScoreUpDOwnArrow {
  margin-left: 12px;
}

.ScoreUpDOwnArrow.Green svg {
  width: 30px;
  height: auto;
  color: #5ac55e;
}

.ScoreUpDOwnArrow.Red svg {
  width: 30px;
  height: auto;
  color: #e23725;
}

.ScoreCardLMsg {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 12px 0 0 0;
  color: #b299d2;
}

.ScoreCardLMsg span {
  color: #5ac55e;
  padding-right: 2px;
}

.ScoreCardDevice {
  width: 100%;
  max-width: 120px;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.OnlineDText {
  width: 100%;
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
  margin: 0 0 5px 0;
  color: #5ac55e;
  text-align: center;
}

.OfflineDText {
  width: 100%;
  font-size: 48px;
  line-height: 60px;
  font-weight: 300;
  margin: 0 0 5px 0;
  color: #e23725;
  text-align: center;
}

.DeviceStatusText {
  width: 100%;
  font-size: 17px;
  line-height: 24px;
  font-weight: 600;
  margin: 0 0 0 0;
  color: #b299d2;
  text-align: center;
}

.RequestCardDiv {
  width: 33.33%;
  padding: 14px;
}

.RequestCardDivUnder {
  width: 100%;
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #502688;
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
  border-radius: 6px;
  height: 100%;
  overflow: hidden;
}

.RequestCardHead {
  width: 100%;
  display: flex;
  background-color: #3f1d6c;
  padding: 15px 25px;
  justify-content: space-between;
  position: relative;
}

.RequestCradNTitle {
  font-size: 36px;
  line-height: 42px;
  font-weight: 300;
  margin-bottom: 0px;
  color: #ffffff;
  padding-top: 8px;
}

.RequestCardBody {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.RequestCardList {
  width: 50%;
  height: 124px;
  padding: 19px 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #7d48c4;
}

.RequestCardList:nth-child(odd) {
  border-right: 1px solid #7d48c4;
}

.RequestCardList:last-child {
  border-bottom: 0px;
}

.RequestCardList.Red {
  color: #e23725;
}

.RequestCardList.High {
  color: #e99a37;
}

.RequestCardList.Medium {
  color: #feea63;
}

.RequestCardList.Green {
  color: #b299d2;
}

.ReqCardListTitleSec {
  display: flex;
  align-items: center;
}

.ReqCardListTitleSec svg {
  width: 22px;
  height: 22px;
  margin-right: 6px;
  stroke-width: 1.5px;
}

.ReqCardListTitleSec p {
  font-size: 18px;
  line-height: 38px;
  font-weight: 400;
  margin-bottom: 0px;
}

.CardListScore {
  font-size: 38px;
  line-height: 42px;
  font-weight: 300;
  margin-bottom: 0px;
}

.ReffStatus {
  margin: 0 12px 5px 0;
  font-size: 12px;
  white-space: nowrap;
  /* display: table-cell; */
  display: flex;
  align-items: center;
  font-weight: 500;
}

.ReffStatus svg {
  width: 16px;
  height: 16px;
  margin-bottom: 0px;
  margin-right: 5px;
}

.ReffStatus.Low svg {
  transform: rotate(180deg);
}

.ReffStatus.High {
  color: #f44336;
}

.ReffStatus.Medium {
  color: #ff9800;
}

.ReffStatus.Low {
  color: #4caf50;
}

.RequestInfoText {
  font-size: 13px;
  line-height: 24px;
  font-weight: 500;
  margin: 0;
  color: #b299d2;
  position: absolute;
  right: 25px;
  bottom: 15px;
}

.FilterCard {
  width: 200px;
  padding: 0 15px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.DashFilterSec {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.LoaderDash {
  width: 100%;
  border-radius: 8px;
  background: #FFF;
  padding: 22px 16px;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
}

.LoaderDash2 {
  width: 100%;
  border-radius: 0px;
  background: #FFF;
  padding: 0 0 0px 0;
  overflow: hidden;
}

.LoaderDash3 {
  width: 100%;
  padding: 14px;
}

.LoaderDash4 {
  width: 100%;
  padding: 14px;
}

.LoaderDash5 {
  width: 100%;
  padding: 0px;
}

.LoaderDash3>div {
  width: 100%;
  border-radius: 8px;
  background: #FFF;
  padding: 22px 16px;
  overflow: hidden;
  box-shadow: 0px 0px 20px 0px rgba(62, 85, 120, 0.1);
}

.LoaderDash svg,
.LoaderDash2 svg {
  width: 100%;
  height: 340px;
}

.LoaderDash3>div svg,
.LoaderDash3 svg,
.LoaderDash5 svg {
  width: 100%;
  height: 115px;
}

.LoaderDash4 svg {
  width: 100%;
  height: 320px;
}

@media screen and (min-width: 360px) {
  .InsideCardTitleAmount {
    font-size: 28px;
  }
  /* .RentDropdown {
    padding: 10px 0 !important;
  } */
  .InsideCardTitle {
    font-size: 16px;
  }
  .CardDivTwo {
    width: 100%;
  }

  .IconCardDiv {
    width: 100%;
  }

  .IconCardDivTwo {
    width: 100%;
  }

  .IconCardFullDiv {
    width: 100%;
  }

  .CaseDiv {
    width: 100%;
  }

  .CardDivTwo {
    width: 100%;
  }

  .TitleText span {
    max-width: 100px;
  }

  .SwitchDiv {
    display: block;
  }

  .SwitchDiv p {
    margin-right: 0px;
    margin-bottom: 10px;
  }
  .RentCollectBigSec {
    width: 100%;
    padding: 15px 15px;
    border-right: 0px solid #e5e5e5;
    border-bottom: 1px solid #e5e5e5;
  }

  .RentCollectSmallSec {
    width: 100%;
  }
  
  .BatteryChartRow {
    flex-wrap: wrap;
  }
  .BetteryChartTitle {
    width: 100px;
    font-size: 13px;
  }
  .BetteryCount {
    height: 35px;
    padding: 3px;
  }
  .ScoreCards {
    width: 100%;
  }
  .ScoreCardsUnder {
    padding: 15px 15px;
  }
  .ScoreCardIcon {
    width: 60px;
    height: 60px;
    padding: 13px;
    margin-right: 20px;
  }
  .ScoeCardTitle {
    font-size: 16px;
    text-align: left;
  }
  .ScoreCardsTwo {
    width: 100%;
  }
  .RequestCardDiv {
    width: 100%;
  }
}

@media screen and (min-width: 480px) {
  .TitleText span {
    max-width: 200px;
  }

  .SwitchDiv {
    display: flex;
  }

  .SwitchDiv p {
    margin-right: 15px;
    margin-bottom: 0;
  }

  .SwitchDiv > div {
    width: 250px;
  }
}

@media screen and (min-width: 576px) {
  .TitleText span {
    max-width: 300px;
  }
  .ScoreCardsUnder {
    padding: 20px 20px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .ScoreCardIcon {
    width: 60px;
    height: 60px;
    padding: 13px;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 768px) {
  .ScoreCards {
    width: 50%;
  }
  .InsideCardTitleAmount {
    font-size: 38px;
  }
  .RentDropdown {
    padding: 0px 0 !important;
  }
  .InsideCardTitle {
    font-size: 18px;
  }
  .BatteryChartRow {
    flex-wrap: nowrap;
  }
  .BetteryChartTitle {
    width: 120px;
    font-size: 15px;
  }
  .BetteryCount {
    height: 40px;
    padding: 5px;
  }
  .IconCardFullDiv {
    width: 100%;
  }  

  .TitleText span {
    max-width: 450px;
  }
  .DeviceTextWrap {
    max-width: 300px;
    min-width: 300px;
  }
  .RentCollectBigSec {
    width: 60%;
    padding: 20px 30px;
    border-right: 1px solid #e5e5e5;
    border-bottom: 0;
  }
  .RentCollectSmallSec {
    width: 40%;
  }
  .ScoreCardsUnder {
    padding: 20px 20px;
    flex-wrap: unset;
    justify-content: unset;
  }
  .ScoreCardIcon {
    width: 60px;
    height: 60px;
    padding: 13px;
    margin-right: 15px;
    margin-bottom: 0;
  }
  .ScoeCardTitle {
    font-size: 16px;
    text-align: left;
  }
}

@media screen and (min-width: 992px) {    
  .DeviceTextWrap {
    max-width: 180px;
    min-width: 180px;
  }
  .ScoreCards:nth-child(4),
  .ScoreCards:nth-child(5) {
    width: calc(50% - 110px);
  }
  .ScoreCardsTwo {
    width: 220px;
  }
}

@media screen and (min-width: 1100px) {
  .InsideCardTitleAmount {
    font-size: 34px;
  }
  .CaseDiv {
    width: 50%;
  }
  .CardDivTwo {
    width: 50%;
  }
  .IconCardFullDiv {
    width: 100%;
  }

  .TitleText span {
    max-width: 90px;
  }

  .ChartFullDiv {
    width: 100%;
  }
  .ScoreCardsUnder {
    padding: 15px 12px;
    flex-wrap: wrap;
    justify-content: center;
  }
  .ScoreCardIcon {
    width: 60px;
    height: 60px;
    padding: 14px;
    margin-right: 0px;
    margin-bottom: 15px;
  }
}

@media screen and (min-width: 1200px) {
  .TextWrap {
    max-width: 180px;
  }
  .TitleText span {
    max-width: 130px;
  }

  .CaseDiv {
    width: 50%;
  }

  .IconCardDivTwo {
    width: 50%;
  }

  .CardDivTwo {
    width: 50%;
  }

  .RentCollectBigSec {
    width: 62%;
    padding: 20px 15px;
  }

  .RentCollectSmallSec {
    width: 38%;
  }

  .FullSize {
    padding: 22px 15px;
  }

  /* .RentDropdown {
    width: 220px !important;
  } */
  .RentCardNSec {
    width: 40%;
  }

  .RentCardPSec {
    width: 40%;
  }

  .RentCardTSec {
    width: 20%;
  }
  .DeviceTextWrap {
    max-width: 250px;
    min-width: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .TextWrap {
    max-width: 200px;
  }

  .InsideCardTitle {
    font-size: 18px;
  }

  .InsideCardTitleAmount {
    font-size: 38px;
  }

  .TitleText span {
    max-width: 150px;
  }
  .ScoreCards {
    width: calc(25% - 40px) !important;
  }
  .ScoreCardsTwo {
    width: 160px;
  }
  .ScoeCardCon {
    font-size: 23px;
    line-height: 23px;
  }
  .ScoreUpDOwnArrow {
    margin-left: 8px;
  }
  .ScoreUpDOwnArrow.Green svg {
    width: 22px;
  }
  .ScoeCardConTwo {
    font-size: 12px;
    line-height: 20px;
  }
  .ScoreCardLMsg {
    font-size: 12px;
    line-height: 18px;
    margin: 12px 0 0 0;
  }
  .OfflineDText,
  .OnlineDText {
    font-size: 36px;
    line-height: 54px;
  }
  .DeviceStatusText {
    font-size: 14px;
    line-height: 20px;
  }
  .ScoeCardConThree {
    font-size: 28px;
    margin-bottom: 10px;
  }
  .ScoeCardTitleTwo {
    font-size: 15px;
  }
}

@media screen and (min-width: 1400px) {
  .TextWrap {
    max-width: 220px;
  }

  .TitleText span {
    max-width: 180px;
  }
  .CaseDiv {
    width: 33.33%;
  }

  .IconCardDiv {
    width: 100%;
  }

  .CardDivTwo {
    width: 33.33%;
  }

  .ChartFullDiv {
    width: 50%;
  }
  .ChartFullDiv.Full {
    width: 50%;
  }

  .RentCollectBigSec {
    width: 60%;
    padding: 20px 30px;
  }

  .RentCollectSmallSec {
    width: 40%;
  }

  .FullSize {
    padding: 22px 15px;
  }

  /* .RentDropdown {
    width: 200px !important;
  } */
  .RentCardNSec {
    width: 38%;
  }

  .RentCardPSec {
    width: 42%;
  }

  .RentCardTSec {
    width: 20%;
  }
  .DeviceTextWrap {
    max-width: 180px;
    min-width: 180px;
  }
  .ScoreCardsUnder {
    padding: 12px 12px;
    flex-wrap: unset;
    justify-content: unset;
  }
  .ScoreCardIcon {
    width: 60px;
    height: 60px;
    padding: 14px;
    margin-right: 12px;
    margin-bottom: 0;
  }
  .ScoeCardTitle {
    font-size: 16px;
    text-align: left;
  }
  .IconCardDiv {
    width: 66.66%;
  }
  .RequestCardDiv {
    width: 33.33%;
  }
}

@media screen and (min-width: 1500px) {
  .CaseDiv {
    width: 33.33%;
  }
  .CardDivTwo {
    width: 33.33%;
  }
  .ScoreCardsUnder {
    padding: 15px 15px;
  }
  .ScoreCardIcon {
    width: 60px;
    height: 60px;
    padding: 15px;
    margin-right: 15px;
  }
  .ScoeCardTitle {
    font-size: 16px;
  }
}

@media screen and (min-width: 1600px) {
  .DeviceTextWrap {
    min-width: 220px;
  }
  .InsideCardTitle,
  .InsideCardTitle2 {
    font-size: 18px;
  }
  .InsideCardTitle2 span {
    font-size: 14px;
    margin-left: 15px;
    padding-left: 15px;
  }
  .ViewAllBU {
    font-size: 16px;
  }
  .TextWrap {
    max-width: 200px;
  }
  .TitleText span {
    max-width: 240px;
  }
  .ScoreCardsUnder {
    padding: 10px 12px;
  }
  .ScoreCardIcon {
    width: 70px;
    height: 70px;
    padding: 15px;
    margin-right: 15px;
  }
  .ScoeCardTitle {
    font-size: 18px;
  }
  .ScoreCards {
    width: calc(25% - 45px) !important;
  }
  .ScoreCardsTwo {
    width: 180px;
  }
  .ScoreCardLMsg {
    font-size: 13px;
  }
  .ScoeCardCon {
    font-size: 30px;
    line-height: 30px;
  }
  .ScoreUpDOwnArrow {
    margin-left: 12px;
  }
  .ScoreUpDOwnArrow.Green svg {
    width: 30px;
  }
  .ScoeCardConTwo {
    font-size: 14px;
    line-height: 22px;
  }
  .ScoreCardLMsg {
    font-size: 14px;
    line-height: 24px;
    margin: 12px 0 0 0;
  }
  .OfflineDText,
  .OnlineDText {
    font-size: 48px;
    line-height: 60px;
  }
  .DeviceStatusText {
    font-size: 17px;
    line-height: 24px;
  }
  .ScoeCardConThree {
    font-size: 30px;
    margin-bottom: 12px;
  }
  .ScoeCardTitleTwo {
    font-size: 17px;
  }
}

@media screen and (min-width: 1700px) {
  .ScoreCardLMsg {
    font-size: 14px;
  }
  .ScoreCardsUnder {
    padding: 15px 20px;
  }
  .ScoreCards {
    width: calc(25% - 50px) !important;
  }
  .ScoreCardsTwo {
    width: 200px;
  }
  .DeviceTextWrap {
    min-width: 240px;
  }
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 125px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media screen and (min-width: 1800px) {
  .DeviceTextWrap {
    min-width: 180px;
  }
  .TextWrap {
    max-width: 130px;
  }
  .IconBadgeSec {
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
  }
  .TitleText span {
    max-width: 300px;
  }

  .CardSmartDe .CaseCardTitle,
  .CardReq .CaseCardTitle,
  .CardRent .CaseCardTitle {
    flex-wrap: unset;
  }

  .CardSmartDe .TitleText {
    margin-bottom: 8px;
  }

  .CardSmartDe .DateText {
    text-align: right;
    margin-bottom: 0px;
  }

  .CardReq .CaseCardLeftSec {
    width: 70%;
  }

  .CardReq .CardPillSec {
    width: 30%;
    justify-content: flex-end;
    align-items: unset;
    margin-top: 0;
  }

  .CardReq .CardPillSec .DateText {
    width: 100%;
  }
  .CardReq .CardPillSec .StatusPill {
    margin: 0 0 5px 0;
  }
  .CardRent .RentCardNSec {
    width: 35%;
    display: unset;
    align-items: unset;
    margin-bottom: 0px;
    padding: 0 8px 0 0;
  }
  .CardRent .RentCardPSec {
    width: 46%;
    display: unset;
    align-items: unset;
    margin-bottom: 0px;
    padding: 0 8px;
  }
  .CardRent .RentCardTSec {
    width: 20%;
    padding: 0 0 0 8px;
  }
  .CardRent .TitleText,
  .CardRent .PaymentText {
    width: 100%;
    margin-bottom: 8px;
  }
  .CardRent .DateText {
    text-align: right;
  }
  .CardRent .TitleName {
    padding-left: 0px;
    margin-left: 0px;
    border-left: 0px solid #dcdcdc;
  }
  .CaseDiv {
    width: 33.33%;
  }
  .CardDivTwo {
    width: 33.33%;
  }
}

@media (max-width: 959px) {
  .pageTitle.Mobile {
    display: flex;
  }

  .pageTitle.Big {
    display: none;
  }

  .breadcrumb {
    display: none;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem 0.5rem 1.8rem;
  }
}

@media (max-width: 767px) {
  .BackAddBU {
    display: flex;
  }
}

@media (max-width: 570px) {
  .CaseCardTitle {
    flex-wrap: wrap;
  }
  .TitleText {
    margin-bottom: 3px;
  }
  .CardRent .TitleText {
    margin-bottom: 8px;
  }
  .DateText {
    margin-bottom: 3px;
    text-align: left;
    font-weight: 300;
    order: 1;
  }
  .DeviceTextWrap {
    max-width: 100%;
  }
  .CardPillSec {
    margin-top: 5px;
    justify-content: flex-start;
    width: 100%;
  }
  .StatusPill {
    order: 2;
    margin: 5px 0 0 0;
  }
  .CaseCardLeftSec {
    width: 100%;
  }
  .IconBadgeSec {
    position: absolute;
    top: 10px;
    right: 0;
    left: unset;
    transform: unset;
    display: flex;
    align-items: center;
  }
  .RentCardNSec {
    width: 50%;
    padding: 0 0px 0 0;
  }
  .RentCardPSec {
    width: 50%;
    padding: 0 0px;
  }
  .RentCardTSec {
    width: 100%;
    padding: 8px 0 0 0px;
  }
}

@media (max-width: 479px) {
  .RentDropdown.Rent {
    padding: 10px 0 !important;
  }
  .mainCard {
    padding: 0;
    margin: 0 0.8rem;
    background: transparent;
    box-shadow: unset;
    margin-bottom: 80px;
  }
  .pageTitleWrap {
    padding: 1rem 0.8rem 0.5rem 0.8rem;
  }

  .pageTitle {
    width: 100%;
    order: 2;
  }

  .breadcrumb {
    width: 100%;
    order: 1;
    padding-bottom: 12px;
  }
  .RequestCardList {
    width: 100%;
    height: 80px;
    padding: 19px 25px;
  }
}