.pageTitleWrap {
    width: 100%;
    padding: 1.8rem 1.8rem 1.2rem 1.8rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.pageTitle {
    margin: 0;
    color: #505050;
    font-size: 18px !important;
    font-weight: 600 !important;
    line-height: 27px !important;
    font-family: "Inter", sans-serif !important;
    letter-spacing: 0.01em !important;
}

.breadcrumb {
    background: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    border-radius: 0.25rem;
    font-size: 13px;
    color: #ff8271;
    align-self: center;
    font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 25ch;
    color: #505050;
    font-weight: 400;
}

.breadcrumbItem a {
    text-decoration: none;
    color: #505050;
}

.breadcrumbItem a:hover {
    text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
    padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
    /* font-size: 14px; */
    display: inline-block;
    padding-right: 0.5rem;
    color: #6c757d;
    content: "/";
}

.breadcrumb_menuIconsSize {
    width: 14px;
    height: auto;
    padding-right: 5px;
}

.breadcrumbItem span svg {
    width: 16px;
    height: 16px;
    stroke-width: 2;
    margin-right: 8px;
    margin-top: -4px;
}

.BodyCon {
    display: flex;
}

.mainCardDiv {
    width: 100%;
    padding-right: 280px;
    padding-bottom: 80px;
}

.mainCard {
    padding: 0px;
    border-radius: 0.5rem;
    margin: 0px 1.8rem 0 1.8rem;
    position: relative;
    /* background: white; */
    border: none;
    /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
    margin-bottom: 20px;
}

.SearchFilterSec {
    /* width: 100%; */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: 0 -10px;
    padding: 5px 0 20px 0;
    justify-content: space-between;
}

.SearchCon {
    width: 50%;
    padding: 0 10px;
}

.SearchConSec {
    width: 100%;
    border: 1px solid #e1e1e1;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 8px 8px;
    background: #fff;
}

.SearchConSec input {
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    width: 100%;
    border: none;
}

.SearchConSec svg {
    width: 20px;
    opacity: 0.8;
    margin-right: 10px;
}

.FilterSec {
    width: 50%;
    display: flex;
}

.FilterFieldSec {
    display: flex;
}

.FilterTitle {
    font-size: 14px;
    line-height: 30px;
    font-weight: 600;
    color: #340380;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 10px;
}

.FilterTitle svg {
    width: 20px;
    height: 20px;
    margin-bottom: 4px;
}

.FilterCard {
    width: 33%;
    padding: 0 10px;
    font-size: 0.875rem !important;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
}

.FilterCard.Select>div>div {
    min-height: 42px;
    border-color: #e1e1e1;
    border-radius: 8px;
}

.Width200 {
    min-width: 200px;
}

.FilterBu {
    align-items: center;
    padding: 9px 14px;
    background: #502688;
    display: flex;
    min-height: 0;
    color: #fff;
    border-radius: 8px;
    transition: all 0.3s;
    outline: none;
    border: none;
    height: 42px;
    margin-left: 10px;
    margin-right: 10px;
}

.FilterBu svg {
    min-width: 20px;
    height: 20px;
    margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
    background: #230058;
    background-color: #230058;
    color: #fff;
}

.AddNoteBu {
    -webkit-align-items: center;
    align-items: center;
    padding: 9px 14px;
    background: #ff8271;
    display: -webkit-flex;
    display: flex;
    min-height: 0;
    color: #fff !important;
    border-radius: 8px;
    transition: all 0.3s ease-in-out;
    outline: none !important;
    border: none;
    height: 42px;
    margin-left: 10px;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
    background: #e97565;
    background-color: #e97565;
    color: #fff;
}

.AddNoteBu svg {
    min-width: 20px;
    height: 20px;
    margin-right: 10px;
}

.NotesRow {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -13px;
}

.NotesRow a {
    width: 100%;
}

.ProspectCard {
    width: 25%;
    padding: 15px;
}

.ProspectCardUnder {
    width: 100%;
    background: #fff;
    box-shadow: 0 0 20px 0 #1f025412;
    border-radius: 8px;
    padding: 16px;
    transition: all 0.3s ease-in-out;
}

.ProspectCardUnder:hover {
    box-shadow: 0 0 28px 0 #1f02541f;
}

.ProspectCardHeadSection {
    display: flex;
    border-bottom: 1px solid #dfe2e6;
    padding-bottom: 16px;
    margin-bottom: 8px;
    position: relative;
}

.ProspectCardHeadSection .ProsIMGSec {
    align-items: center;
    background-color: #fbf7fc;
    border-radius: 8px;
    display: flex;
    flex-wrap: wrap;
    height: 70px;
    justify-content: center;
    margin-right: 16px;
    min-height: 60px;
    min-width: 60px;
    overflow: hidden;
    width: 70px;
}

.ProsIMGSec .ProspectCardImg {
    height: 35px;
    object-fit: contain;
    object-position: center;
    width: 35px;
}

.ProsIMGSec p {
    font-size: 24px;
    font-weight: 600;
    line-height: 150%;
    margin: 0;
    color: #502688;
    letter-spacing: 1px;
}

.ProspectCardHeadSection .HeadConSec {
    width: calc(100% - 86px);
    display: flex;
    flex-wrap: wrap;
    align-content: center;
}

.ActionLeaseBUSec {
    position: absolute !important;
    top: -10px;
    right: -12px;
}

.ActionsLeaseBU {
    width: 40px !important;
    height: 40px;
    display: flex !important;
    justify-content: center;
    align-items: center !important;
    font-size: 14px !important;
    line-height: 20px !important;
    font-weight: 400 !important;
    color: #39414d !important;
    background-color: #ffffff !important;
    margin: 0 !important;
    border: none !important;
    padding: 0 !important;
    border-radius: 8px !important;
}

.ActionsLeaseBU:hover,
.btn-check:active+.btn-primary.ActionsLeaseBU,
.btn-check:checked+.btn-primary.ActionsLeaseBU,
.ActionsLeaseBU.btn-primary.active,
.ActionsLeaseBU.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsLeaseBU {
    background-color: #fff !important;
    border: none;
}

.ActionsLeaseBU svg {
    width: 20px;
    height: 20px;
}

.ActionsBUListItem {
    font-size: 14px;
    font-weight: 500;
    color: #343434;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 10px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
    color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
    color: #343434;
    background: #f8f8f8;
}

.ActionsBUListItem:last-child {
    border: none;
}

.EditActionsBUListItem {
    font-size: 14px;
    font-weight: 500 !important;
    color: #343434 !important;
    /* border-bottom: 1px solid #e0e0e0; */
    padding: 10px 10px !important;
    min-width: 80px;
    display: flex;
    align-items: center;
}

.EditActionsBUListItemSmall {
    font-size: 13px;
    padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
    color: #f44336 !important;
}

.EditActionsBUListItem svg {
    width: 17px;
    height: auto;
    margin-right: 12px;
    stroke-width: 2px;
    color: #343434;
}

.EditActionsBUListItem.Delete svg {
    color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
    color: #343434 !important;
    background: #f8f8f8 !important;
}

.ProsUserName {
    font-size: 17px;
    font-weight: 600;
    line-height: 150%;
    width: calc(100% - 30px);
    margin: 0 0 6px 0;
    color: #505050;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: "Inter", sans-serif !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ProsUserName.LimitName {
    width: calc(100% - 85px);
    display: inline-block;
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
    margin: 0 12px 0 0;
}

.ProsUserName .UserName {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: calc(100% - 0px);
    width: 100%;
}

.ProspectCardBodySec {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #dfe2e6;
    padding-bottom: 8px;
    margin-bottom: 8px;
}

.ProspectCardBodySec ul {
    margin: 0;
    padding: 0;
    width: 100%;
}

.ProspectCardBodySec ul li {
    list-style: none;
    padding: 8px 0;
    display: flex;
    align-items: center;
}

.ProspectCardBodySec ul li p.Title {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    color: #666666;
    min-width: 90px;
}

.ProspectCardBodySec ul li p.Content {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    margin: 0;
    color: #343434;
}

.TemplateCardFoot {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 5px;
}

.LeaseConDate {
    font-size: 14px;
    font-weight: 400;
    line-height: 21px;
    margin: 0;
    color: #666666;
}

.PublishBadge {
    background-color: rgba(161, 105, 198, 0.3);
    color: #502688 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    width: auto !important;
}

.UnublishBadge {
    background-color: #dfe2e6;
    color: #595959 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    width: auto !important;
}

.UpcomingBadge {
    background-color: #fff3e8;
    color: #e99a37 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    width: auto !important;
}

.ActiveBadge {
    background-color: rgb(232, 255, 242);
    color: #5ac55e !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    width: auto !important;
}

.ExpiredBadge {
    background-color: #ffe8e8;
    color: #e23725 !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 5px 12px !important;
    border-radius: 15px;
    margin: 0;
    height: 25px;
    line-height: normal;
    width: auto !important;
}

.MorePropertyBU {
    background-color: #502688;
    color: #ffffff !important;
    font-weight: 500 !important;
    font-size: 12px;
    padding: 4px 8px !important;
    border-radius: 15px;
    margin: 0px 0 0px 10px;
    cursor: pointer;
    line-height: normal;
    white-space: nowrap;
}

.BackAddBU {
    width: 35px;
    height: 35px;
    display: none;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    border-radius: 4px;
    color: #505050 !important;
    background: #f0f0f0 !important;
}

.BackAddBU svg {
    width: 20px !important;
    height: auto !important;
    stroke-width: 2 !important;
}

.ModalFormGroup {
    width: 100%;
    padding-bottom: 0px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.ModalFormGroupH {
    width: 48%;
    padding-bottom: 10px;
    font-size: 0.875rem;
    position: relative;
}

.AddLeaseSection {
    width: 100%;
    background: #fff;
    border-radius: 8px;
    margin: 15px 0 0 0;
    padding: 10px 0px 0 0;
    max-height: 100%;
}

.AddLeaseSection.Maximize .ResizeIcon2 {
    display: flex;
}

.AddLeaseSection.Maximize .ResizeIcon {
    display: none;
}

.AddLeaseSection.Maximize {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: Calc(100% - 85px);
    height: 100%;
    margin: 0 0 0 85px;
    padding: 25px;
}

.AddDocTitleSec {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.FormGroupAddLease {
    width: 100%;
    padding-bottom: 25px !important;
    font-size: 0.875rem !important;
    position: relative;
}

.FormGroupTextareaFull {
    width: 100%;
    padding-bottom: 15px;
    font-size: 0.875rem;
    position: relative;
}

.FilterLabel {
    font-size: 13px;
    line-height: 30px;
    font-weight: 600;
    color: #666;
    margin: 0 0 5px 0;
}

.FilterLabelLease {
    font-weight: 600;
    font-size: 13px;
    line-height: 30px;
    color: #333333;
    margin-bottom: 2px;
}

.ProfileEditFormSec .FilterLabel {
    color: #919191;
    font-size: 14px;
    font-weight: 400;
    margin-right: 10px;
    display: flex;
    align-items: center;
    min-width: 80px;
    white-space: nowrap;
}

.formControlTextArea {
    display: block;
    width: 100%;
    min-width: 80px;
    height: 140px;
    padding: 0.375rem 0.75rem;
    resize: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e1e1e1;
    border-radius: 6px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ButtonAction {
    width: 100%;
    padding-top: 20px;
    display: flex;
    align-items: center;
}

.DatepickerRow {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
}

.HalfSecHalf {
    width: calc(50% - 15px) !important;
}

.NoDataMain {
    width: calc(100% - 24px);
    height: 500px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    background: #fff;
    position: relative;
    margin: auto;
}

.NoDataIMG {
    width: 150px;
    height: 150px;
    background: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 50%;
    padding: 35px;
    margin: 0 auto 40px auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.NoDataIMG img {
    width: 100%;
    opacity: .4;
    max-height: 80px;
    object-fit: contain;
    object-position: center;
}

.NoDataText {
    text-align: center;
    font-size: 36px;
    padding: 0px 0 !important;
    font-weight: 700;
    color: rgba(0, 0, 0, 0.5);
    line-height: unset;
    text-transform: capitalize;
    width: 100%;
}

.NoDataTextSub {
    text-align: center;
    font-size: 22px;
    padding: 0px 0 !important;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.3);
    line-height: unset;
    text-transform: capitalize;
    width: 100%;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  width: 100%;
  margin-top: 4px;
}

@media screen and (min-width: 360px) {
    .mainCardDiv {
        padding-right: 0px;
    }

    .ProspectCard {
        width: 100%;
    }
}

@media screen and (min-width: 768px) {
    .ProspectCard {
        width: 50%;
    }
}

@media screen and (min-width: 1100px) {
    .SearchCon {
        width: 25%;
    }

    .FilterSec {
        width: 75%;
    }

    .FilterCard {
        padding: 0 5px;
    }
}

@media screen and (min-width: 1200px) {
    .SearchCon {
        width: calc(100% - 530px);
    }

    .FilterSec {
        width: 530px;
    }

    .FilterTitle {
        display: none;
    }

    .FilterCard {
        padding: 0 10px;
    }

    .FilterBu {
        display: none;
    }
}

@media screen and (min-width: 1360px) {
    .ProspectCard {
        width: 33.33%;
    }
}

@media screen and (min-width: 1500px) {
    .FilterTitle svg {
        display: none;
    }
}

@media screen and (min-width: 1600px) {
    .ProspectCard {
        width: 25%;
    }
}

@media (max-width: 1199px) {
    .SearchCon {
        width: calc(100% - 200px);
    }

    .FilterSec {
        width: 200px;
    }

    .FilterFieldSec {
        width: 280px;
        height: calc(100% - 64px);
        display: flex;
        flex-wrap: wrap;
        position: fixed;
        background: #fff;
        top: 64px;
        z-index: 98;
        right: -300px;
        align-content: flex-start;
        padding: 40px 15px;
        box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
        border-left: 1px solid #ebebeb;
        transition: all 0.2s ease-in-out;
    }

    .FilterFieldSec.Open {
        right: 0;
    }

    .FilterCard {
        width: 100%;
        padding: 10px 10px;
    }
}

@media (max-width: 991px) {
    .ProspectCardHeadSection {
        flex-wrap: wrap;
        justify-content: center;
    }

    .ProspectCardHeadSection .HeadConSec {
        width: 100%;
    }

    .ProspectCardHeadSection .ProsIMGSec {
        min-width: 90px;
        width: 90px;
        min-height: 90px;
        height: 90px;
        border-radius: 50%;
        margin-right: 0px;
        margin-bottom: 16px;
    }

    .ProsUserName.LimitName {
        width: 100%;
        margin: 0 0 0 0;
        text-align: center;
    }

    .ProsUserName {
        justify-content: center;
    }

    .ProsUserName {
        width: calc(100% - 0px);
        text-align: center;
    }
}

@media (max-width: 959px) {
    .pageTitleWrap {
        padding: 1rem 1.8rem 0.5rem 1.8rem;
    }

    .breadcrumb {
        display: none;
    }

    .SearchFilterSec {
        padding: 5px 0 10px 0;
    }

    .FilterFieldSec {
        height: calc(100% - 0px);
        top: 0;
    }
}

@media (max-width: 767px) {
    .SearchCon {
        width: calc(100% - 130px);
    }

    .FilterSec {
        width: 130px;
    }

    .AddNoteBu span {
        display: none;
    }

    .AddNoteBu svg {
        margin-right: 0;
    }

    .AddNoteBu,
    .FilterBu {
        margin: 0 5px;
    }

    .BackAddBU {
        display: flex !important;
    }
}

@media (max-width: 479px) {
    .pageTitleWrap {
        padding: 1rem 0.8rem 0.5rem 0.8rem;
    }

    .pageTitle {
        width: 100%;
        order: 2;
    }

    .breadcrumb {
        width: 100%;
        order: 1;
        padding-bottom: 12px;
    }

    .mainCard {
        padding: 0;
        margin: 0 0.8rem;
        background: transparent;
        box-shadow: unset;
    }

    .AddDeviceSec .SearchConSec {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }

    .ProspectCardHeadSection .ProsIMGSec {
        min-width: 60px;
        width: 70px;
        min-height: 60px;
        height: 70px;
    }

    .HalfSecHalf {
        width: 100% !important;
    }
}