::placeholder {
  color: #757779;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #757779;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #757779;
}

.PopupContent {
  height: calc(440px - 130px);
  overflow-y: auto;
  padding-bottom: 15px;
}

.ChatInputSec {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 8px;
}

.SendBUMSG {
  width: 42px;
  height: 42px;
  background: #ff8271;
  border: 1px solid #ff8271;
  border-radius: 8px;
  display: flex !important;
  color: #fff;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 40px !important;
  z-index: 2;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.SMProCusRow {
  margin: 0 -15px;
  /* display: flex; */
  float: left;
  width: -webkit-fill-available;
  width: -moz-available;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0 15px;
  display: table;
  flex-wrap: wrap;
  float: left;
  top: 156px;
  position: sticky;
  z-index: 1;
}

.SMProCusBigDiv {
  width: 100%;
  padding: 0 15px;
  float: left;
}

.card {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
  /* overflow: hidden; */
}

.card .cardbody {
  padding: 1.5rem;
}

.SMProCardHead {
  text-align: left;
  padding: 25px 15px 15px 15px;
  position: relative;
}

.SMProCardHead .avatar {
  margin-bottom: 25px;
  position: absolute;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}

.SMProCardHead .SMProPicMainDubble:hover .avatar {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.SMProCardHead .avatar.active {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  z-index: 1;
}

.SMProCardHead .avatar img {
  position: relative;
  z-index: 2;
  border: 2px solid white;
}

.SMProCardHead .avatar::after {
  content: "";
  position: absolute;
  width: 70px;
  height: 70px;
  background: #23005833;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.SMProCardHead .avatar:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.SMProCardHead h5 {
  margin: 0;
  line-height: 2.22;
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  color: #505050;
  /* position: absolute; */
  cursor: pointer;
  padding: 0 20px;
  text-align: center;
  left: 0;
  /* display: none; */
}

.SMProCardHead h5 svg {
  width: 21px;
  height: 21px;
  margin-left: 7px;
  color: #5e5e5e;
}

.SMProPicMain {
  position: relative;
  width: 90px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  height: 75px;
}

.SMProPicMainDubble {
  position: relative;
  width: 90px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  height: 75px;
  transition: all 0.3s ease-in-out;
}

.SMProPicMainDubble:hover {
  width: 130px;
}

.SMProPicMainDubble .avatar:nth-child(1) {
  left: 0;
}

.SMProPicMainDubble .avatar:nth-child(2) {
  right: 0;
}

.SMProPicMain a,
.SMProPicMain span.UpPicBU {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.SMProPicMain a svg,
.SMProPicMain span.UpPicBU svg {
  width: 17px;
  stroke-width: 2;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  min-height: 58px;
  min-width: 58px;
  height: 62px;
  width: 62px;
  border-radius: 50%;
  /* border: 2px solid white; */
  /*margin: 0 0 1rem;*/
}

.avatar p {
  font-size: 18px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: #fbf7fc;
  letter-spacing: 0px;
  position: relative;
  z-index: 2;
  width: 62px;
  height: 62px;
  background: #502688;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar > a > img,
.header form .input-group .input-group-append .avatar > button.btn > img,
.chat-block
  .chat-sidebar
  .chat-sidebar-content
  .list-group
  .avatar
  > .list-group-item.active
  > img,
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
  /*font-size: 19px;*/
}

.roundedcircle {
  border-radius: 50% !important;
}

.SMProCardDe {
  text-align: center;
  padding-bottom: 0px;
}

.SMProCardDe ul {
  margin: 0;
  padding: 0 0 10px 0;
}

.SMProCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 0 20px 15px 20px;
  font-weight: 400;
  color: #919191;
  line-height: 21px;
}

.SMProCardDe .MuiButton-outlinedPrimary {
  margin: 20px 10px;
}

.SMProCardDe .MuiButton-outlinedPrimary svg {
  margin-right: 8px;
}

ul:not(.list-unstyled) {
  margin: 0;
  padding: 0;
}

ul:not(.list-unstyled) li {
  list-style-type: none;
}

.SMProDETag {
  /* color: #b2b5cb; */
  margin-right: 24px;
  display: flex;
  align-items: center;
  min-width: 75px;
  white-space: nowrap;
}

.SMProDE {
  /* margin-left: AUTO; */
  text-align: left;
  color: #343434;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SMProDE > div {
  text-align: left;
}

.SMBigSideHead {
  display: flex;
  /*justify-content: space-between;*/
  margin-bottom: 20px;
  height: 33px;
  align-items: center;
}

.SMBigSideHead h5 {
  margin-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 0;
  margin-right: 25px;
  color: #343434;
}

.SMBigSideBody {
  position: relative;
}

.EditBU {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
}

.EditBU svg {
  width: 17px;
  stroke-width: 2;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 38px;
  padding: 0.375rem 0.4rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 13px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 15px 0 0 !important;
  line-height: unset !important;
  /* height: 26px !important; */
}

.ReferStatusBUNew {
  padding-right: 7px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.Approved,
.ReferStatusBU.Approved:active,
.ReferStatusBU.Approved:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Prequalified,
.ReferStatusBU.Prequalified:active,
.ReferStatusBU.Prequalified:checked {
  background-color: #5026884d !important;
  border-color: #5026884d !important;
  color: #502688 !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Open,
.ReferStatusBU.Open:active,
.ReferStatusBU.Open:checked {
  background-color: #eb575766 !important;
  border-color: #eb575766 !important;
  color: #a63434 !important;
}

.ReferStatusBU.InProgress,
.ReferStatusBU.InProgress:active,
.ReferStatusBU.InProgress:checked {
  background-color: #f8d52466 !important;
  border-color: #f8d52466 !important;
  color: #71621b !important;
}

.ReferStatusBU.Resolved,
.ReferStatusBU.Resolved:active,
.ReferStatusBU.Resolved:checked {
  background-color: #00808066 !important;
  border-color: #00808066 !important;
  color: #006060 !important;
}

.ReferStatusBU.Cancelled,
.ReferStatusBU.Cancelled:active,
.ReferStatusBU.Cancelled:checked {
  background-color: #eb575766 !important;
  border-color: #eb575766 !important;
  color: #a63434 !important;
}

.ApplicationTabText1 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #343434;
  margin: 0;
}

.ApplicationTabText2 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #666666;
  margin: 0;
}

.ApplicationDetab ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.ApplicationDetab ul li {
  width: 33.33%;
  padding: 15px;
}

.FilesUploadRow {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px;
}

.FilesUploadGrid {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: grid;
}

.FilesUploadCard {
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 10%);
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  border: 1px solid #ebebeb !important;
}

.CardIconSec {
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  line-height: normal;
}

.CardIconSec img {
  width: 40px;
  height: 40px;
  margin-top: 7px;
}

.CardConSec {
  padding: 0.5rem;
}

.CardConSec p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.FilesUpCardDD,
.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0;
  padding: 6px 8px;
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0;
}

.DropDownMain {
  position: absolute;
  right: 0;
  top: 0;
}

.FilesUpCardDD:after {
  display: none;
}

.FilesUpCardDD svg {
  width: 18px;
}

.FileUpDuContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 10px;
  -webkit-align-items: center;
  align-items: center;
}

.FileUpDuContainer h5 {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 0;
  font-weight: 500;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 5px 15px 5px 15px;
  background-color: #502688;
  font-size: 14px;
  width: auto;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  -webkit-align-items: center;
  align-items: center;
  line-height: 14px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  vertical-align: middle;
  border: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  text-transform: capitalize;
  cursor: pointer;
  margin: 0;
}

/* .UploadLabel:hover {
  background-color: #4d1f94 !important;
  border: none;
} */

.UploadLabel:after {
  display: none;
}

.UploadLabel button {
  border: none;
  background: transparent;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.UploadLabel button:focus {
  outline: none;
}

.UploadLabel svg {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.UploadLabel div {
  display: none;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
}

.NotesRow a {
  width: 100%;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /* overflow: hidden; */
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.NotesCardInside {
  display: flex;
  padding-top: 5px;
}

.NotesCardInside img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.CardInsideCon {
  width: 100%;
  padding-left: 25px;
  position: relative;
}

.InsideConName {
  font-size: 15px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 3px;
  padding: 0px 20px 0px 0;
  /* min-width: 350px; */
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -4px;
}

.InsideConDate {
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #a7abc3;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #505050;
  margin-bottom: 0;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0 12px 0;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border: 1px solid #e1e1e1;
  margin-bottom: 0;
  margin-top: 0;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 11px 12px;
  margin-right: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: relative;
  left: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 20px;
  margin-right: 10px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 20px;
  height: 20px;
}

.AppliSwitchBUMain {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.AppliSwitchBUMain button {
  color: #ff8271;
}

.AppliSwitchBUMain button svg {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

/* .AppliTwo .AppliSwitchBUMain {
    justify-content: start;
} */

.AppliTwo .AppliSwitchBUMain button svg {
  margin-left: 0px;
  margin-right: 8px;
}

.AppliSwitchRow {
  width: 100%;
  display: flex;
  /* overflow: hidden; */
  /* min-height: 85vh; */
}

.AppliOne {
  width: 100%;
  /* overflow: hidden; */
  transition: all 0.5s ease-in-out;
  opacity: 1;
  left: 0%;
  position: relative;
}

.AppliTwo {
  width: 100%;
  /* overflow: hidden; */
  transition: all 0.5s ease-in-out;
  opacity: 1;
  left: -110%;
  position: absolute;
  z-index: -2;
}

.NextAppli .AppliSwitchRow .AppliOne {
  width: 100%;
  left: -110%;
  position: absolute;
  z-index: -2;
}

.NextAppli .AppliSwitchRow .AppliTwo {
  width: 100%;
  left: 0%;
  position: relative;
  z-index: 0;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.MainReqConDivSmall {
  padding: 0 15px;
  width: 37%;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 37%;
}

.MainReqTitle {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 0;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.MainReqConUR .MainReqConDe:last-child {
  padding-right: 0px;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
}

.MainReqConDropBU {
  padding: 0 10px;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.InsideConCheck img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.TitleWithTag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 6px;
}

.TagDiv {
  font-size: 12px;
  background: #999;
  color: #fff;
  padding: 2px 8px;
  border-radius: 5px;
}

.TagDiv.Red {
  background: #fb746a;
}

.TagDiv.Yellow {
  background: #ffbb33;
}

.TagDiv.Common {
  background: #eee4ff;
  color: #230058;
}

.TagDiv.Error {
  background: #f44336;
}

.TagDiv.Success {
  background: #17dd1b;
}

.SMProCardTab {
  display: flex;
  flex-wrap: wrap;
  width: 550px;
}

.SMProCardHeadTab {
  text-align: center;
  padding: 25px 15px;
  position: relative;
  width: 170px;
}

.SMProCardHeadTab .avatar {
  margin-bottom: 15px;
}

.SMProPicMainTab {
  position: relative;
  width: 90px;
  /* margin: 0px auto; */
}

.SMProPicMainTab a {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
}

.SMProPicMainTab a svg {
  width: 17px;
  stroke-width: 2;
}

.SMProCardDeTab {
  text-align: center;
  padding: 25px 15px;
  width: 380px;
}

.SMProCardDeTab ul li {
  display: flex;
  font-size: 14px;
  padding: 0 0px 15px 0px;
  position: relative;
}

.SMProCardDeTab ul li h5 {
  font-size: 17px;
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.SMProDETagTab {
  color: #b2b5cb;
  margin-right: 15px;
  display: flex;
  align-items: center;
  width: 100px;
}

.SMProDETab {
  margin-left: 0;
  text-align: left;
  color: #9f9f9f;
}

.EditBUTab {
  position: absolute;
  top: -6px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
}

.EditBUTab svg {
  width: 17px;
  stroke-width: 2;
}

.LoaderCard {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 8px 15px 8px 0;
}

span.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 0px 0px 0px 0;
}

.TextEclips {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.ReffStatus {
  margin: 0 10px 0 0;
  font-size: 14px;
  white-space: nowrap;
  /* display: table-cell; */
  display: inline-block;
  vertical-align: middle;
}

.ReffStatus svg {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.ReffStatus.Low svg {
  transform: rotate(180deg);
}

.ReffStatus.High {
  color: #f44336;
}

.ReffStatus.Medium {
  color: #ff9800;
}

.ReffStatus.Low {
  color: #4caf50;
}

.ReffStatus.Archived {
  background: #87858b;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
}

.uploadButton {
  display: none;
}

.UploadLabel div {
  display: none;
}

.NoDataMain {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
}

.NoDataMain div {
  width: 300px;
  display: block;
  text-align: center;
}

.NoDataIMG {
  width: 130px;
  height: auto;
  margin-bottom: 40px;
  opacity: 0.35;
}

.NoDataText {
  font-weight: 700;
  font-size: 38px;
  line-height: normal;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 25px;
}

.AddLeaseBU {
  padding: 9px 25px 9px 21px;
  background-color: #ff8271;
  margin: 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddLeaseBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.AddLeaseBU svg {
  width: 22px;
  margin-right: 7px;
}

.Permission h5 {
  font-size: 15px;
  color: #505050;
  text-align: left;
  margin: 10px 0;
  font-weight: 500;
  width: 100%;
}

.PermissionSwitch {
  font-size: 14px;
  font-weight: 600;
  color: #7e7e7e;
  margin: 5px 0;
}

.ButtonVoiletSolid {
  padding: 14px 25px;
  background-color: #502688;
  margin: 0px 15px 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.ButtonVoiletLine {
  padding: 14px 25px;
  background-color: #fff;
  margin: 0px 15px 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

.DateInfo {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
}

.DateInfo span {
  color: #3aaa35;
}

.DateInfo2 span {
  color: #ffbb33;
}

.formGroup {
  margin-bottom: 12px;
}

.ReferCommentArea {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  height: 220px;
  padding: 10px 15px;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.ColorDark {
  color: #000 !important;
}

.CustomHeadingSelect {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  width: 80%;
  margin: 0px auto 0 auto;
}

.CustomHeadingSelect > div {
  /* border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0; */
  border: none;
}

/* .CustomHeadingSelect>div>div:nth-child(1)>div:nth-child(1) {
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  color: #505050;
} */

.CustomHeadingSelect
  > div
  > div:nth-child(1)
  > div:nth-child(1).css-1uccc91-singleValue {
  width: 100%;
  max-width: calc(100% - 20px);
}

.CustomHeadingSelect > div > div:nth-child(2) {
  display: block;
}

.QuickbookHeadSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.BackArrow {
  color: #502688;
  font-size: 14px;
  font-weight: 600;
  margin-right: 16px;
  position: relative;
  background: transparent;
  transition: all 0.2s ease-in-out;
}

.BackArrow:focus {
  outline: none;
  box-shadow: none;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #230058;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 18px;
  margin: -3px 3px 0 0;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU.WithoutBG {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #333 !important;
  background-color: transparent !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
}

.ActionsBULine {
  width: 200px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active + .btn-primary.ActionsBU.Red,
.btn-check:checked + .btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #f8f8f8;
}

.ActionsBUListItem:last-child {
  border: none;
}

.ActionSec {
  display: flex;
  justify-content: flex-end;
}

.EditApplicantMain {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
}

.ProfilePicArea {
  width: 400px;
  background: #fe8371;
  display: flex;
  align-items: center;
  position: relative;
}

.ProfilePicSec {
  width: 100%;
  padding: 50px 25px;
  position: relative;
}

.ProfilePicArea .ImageArea {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}

.ProfilePicArea .ImageArea img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.UploadIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #ffffff;
  padding: 0;
  min-width: 30px;
  border-radius: 50%;
  right: -6px;
  bottom: -2px;
  z-index: 2;
  box-shadow: 1px 1px 7px 0 #3333334a;
  display: none;
}

.SMProCardHead .avatar.active .UploadIcon {
  display: flex;
}

.UploadIcon:hover {
  background: #e2e2e2;
}

.UploadIcon svg {
  width: 15px;
  height: 15px;
}

.ApplicantName {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 42px;
  margin-top: 30px;
}

.AppliFormArea {
  width: 400px;
  padding: 60px 50px 40px 50px;
  position: relative;
}

.FormGroup {
  width: 100%;
  padding-bottom: 10px;
  font-size: 0.875rem;
  position: relative;
}

.ButtonArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.ClosePopsBU,
.ClosePopsBU:hover {
  background: no-repeat;
  padding: 0;
  border: none;
  position: absolute;
  top: 18px;
  right: 20px;
  color: #666;
}

.TabSecMain {
  padding: 10px 0 20px 0;
}

.TabTitleSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid #230058;
}

.TabTitleSec .Title {
  font-size: 15px;
  color: #666;
  font-weight: 600;
  margin: 0;
}

.TabTitleSec .SubTitle {
  font-size: 13px;
  color: #666;
  font-weight: 400;
  margin: 0;
}

.ButtonVoiletSolidSM {
  padding: 12px 26px;
  background-color: #502688;
  margin: 20px 0 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolidSM:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.imageValidationError {
  color: #f44336;
  font-size: 12px;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  transform: translate(-50%, 0px);
  width: 280px;
}

.ProfileEditBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #502688;
  border-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.ProfileEditBU:hover {
  background: #230058;
}

.cardUnderSBody {
  width: 100%;
}

.cardUnderSBody ul {
  margin: 0;
  padding: 0;
}

.cardUnderSBody ul li {
  list-style: none;
  padding-bottom: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #343434;
  display: flex;
  position: relative;
}

.cardUnderSBody ul li:last-child {
  padding-bottom: 0;
}

.cardUnderSBody ul li:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  /* background: #000; */
  top: 0;
  left: 12px;
  border: 1px dashed #e5e5e5;
}

.cardUnderSBody ul li.Completed:after {
  border: 1px dashed #4caf50;
}

.cardUnderSBody ul li a {
  /* color: #502688 !important;
  border-bottom: 1px solid; */
  color: #343434 !important;
}

.cardUnderSBody ul li a svg {
  width: 18px;
  margin-left: 8px;
  color: #343434;
}

.cardUnderSBody ul li span {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  border-radius: 50%;
  color: #fff;
  margin-right: 18px;
  position: relative;
  z-index: 1;
  border: 2px solid;
  font-size: 14px;
  line-height: 14px;
}

.cardUnderSBody ul li.Completed span {
  background: #4caf50;
}

.cardUnderSBody ul li.Failed span {
  background: #f40;
}

.cardUnderSBody ul li.Ongoing span {
  background: #ffbc00;
}

.cardUnderSBody ul li span svg {
  width: 14px;
}

.SummeryList {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #666666;
  margin-bottom: 16px;
}

.SummeryList:last-of-type {
  margin-bottom: 0;
}

.SummeryList.Green {
  color: #4caf50;
}

.SummeryList.Red {
  color: #f44336;
}

.SummeryList span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #666666;
  width: 150px;
  display: inline-block;
  margin-right: 10px;
}

.FormAccoHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormAccoHeadTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #505050;
  margin: 0;
}

.AccoCheck {
  width: 24px;
  height: 24px;
  border: 1px solid #dfe1e9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccoCheck.Active {
  border: none;
  background: #2fa42b;
}

.AccoCheck svg {
  width: 16px;
  height: 20px;
  display: none;
  color: #fff;
  stroke-width: 3px;
}

.AccoCheck.Active svg {
  display: block;
}

.FooterWrap {
  width: 100%;
  padding: 0 168px;
}

.footerMain {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 95px 0 75px;
  margin-top: 90px;
}

.footerMainLeft {
  width: 20%;
}

.footerMainLeft img {
  max-width: 240px;
  max-height: 100px;
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: left;
}

.footerMainRight {
  width: 75%;
  padding-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.footerMainRight h3 {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 25px;
  line-height: 18px;
  margin: 0;
  letter-spacing: 0.01em;
}

.footerMainRight ul {
  margin: 0;
  padding: 0;
}

.footerMainRight ul li {
  list-style: none;
  color: #2c2c2c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 3px;
  text-transform: none;
}

.footerMainRight ul li a {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 3px;
  text-transform: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
}

.footerMainRight ul li a:hover {
  color: #2a5bee;
}

.CussAccoRow {
  display: flex;
  flex-wrap: wrap;
}

.AccoFormSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FormGroupHalf {
  width: 48%;
  padding: 15px 0;
  font-size: 14px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.FormGroupFull {
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  position: relative;
}

.FormGroupFull > div > div > div,
.FormGroupHalf > div > div > div {
  font-size: 14px !important;
}

.ToggleBUHalf {
  width: 48%;
  padding: 15px 0;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.FormLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 8px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormLabel span {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #bec1ca;
}

.FormLabelSmall {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 12px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 38px;
  height: 56px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8b8b8b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe1e9;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Dollar .formControl {
  padding: 0.375rem 0.75rem 0.375rem 4.3rem;
}

.formControl:focus,
.formControlTextarea:focus {
  box-shadow: 0px 0px 10px 0px #649bdd38;
}

.formControlTextarea {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 38px;
  height: 56px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe1e9;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 150px;
  resize: none;
}

.ErrorM {
  color: #f44336;
  font-size: 13px;
  /* margin-top: 0;
  margin-bottom: 0;
  position: absolute;
  bottom: -23px; */
}

.AppliFormToggleBU {
  width: 50%;
  height: 56px;
  background: #fff;
  border: 1px solid #dfe1e9;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  cursor: auto !important;
}

.AppliFormToggleBU.Active {
  width: 50%;
  height: 56px;
  background: #f2f2f2;
  border: 1.5px solid #b9b8b8;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #b9b8b8;
}

.CalendarIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 44px;
  height: 44px;
  background: #f2f2f2;
  color: #bab9b9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarIcon svg {
  width: 20px;
  height: 20px;
}

.ResendLink {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 78px;
  height: 44px;
  background: #eff3ff;
  color: #2a5bee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.applicantNextBU.Disabled,
.applicantNextBU.Disabled:hover {
  width: 180px;
  height: 56px;
  background: #d4e3fe;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  cursor: auto;
}

.applicantNextBU {
  width: 180px;
  height: 56px;
  background: #2a5bee;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.applicantNextBU:hover {
  background: #1e47c5;
}

.ShowHideIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 44px;
  height: 44px;
  background: #fff;
  color: #bec1ca;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.ShowHideIcon svg {
  width: 20px;
  height: 20px;
}

.AccoFormBUsec {
  margin-top: 20px;
  width: 100%;
}

.FormFieldMessage {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #2c2c2c;
  margin-top: 8px;
  margin-bottom: 0;
}

.AccoDollarSign {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  background: #f0f0f0;
  color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
}

.AccoDollarSign svg {
  width: 20px;
  height: 20px;
}

.Width100 {
  width: 100%;
}

.AccoFormSec .Width100 {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
}

.DivFull {
  width: 100%;
}

.FileUpTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 6px 0;
}

.TermText {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 0 0;
  padding-bottom: 20px;
}

.TermTextTitle {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 0 0;
  color: #000;
  padding-bottom: 20px;
}

.TermText li {
  padding: 5px 0;
}

.DivFullTerm {
  border: 3px solid #1a1a1a;
  height: 600px;
  overflow: auto;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.TermCheckBox {
  margin-bottom: 40px;
  width: 100%;
}

.FileUpList {
  margin: 0;
  padding: 0;
}

.FileUpList li {
  list-style: none;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  padding: 6px 0 6px 25px;
}

.FileUpList li::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #2a5bee;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.FileListRow {
  display: flex;
  flex-wrap: wrap;
  /* margin: 0 -15px; */
  justify-content: space-between;
  width: 100%;
}

.UpFileList {
  width: 50%;
  width: CalC(50% - 15px);
  padding: 15px 0;
}

.UpFileListUnder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px #ececec;
  border-radius: 8px;
  padding: 16px 8px 16px 16px;
  /* margin-bottom: 16px; */
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 67px;
}

.UpFileListUnder:hover {
  background-color: rgba(42, 91, 238, 0.1);
}

.FileName {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0;
  display: flex;
  align-items: center;
  width: 300px;
  min-width: 300px;
}

.FileName span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.FileName svg {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  color: #2a5bee;
  margin-right: 12px;
  stroke-width: 1.5;
}

.FileSize {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #949494;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  position: relative;
  width: 180px;
  min-width: 180px;
  text-align: center;
}

.FileListRename {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #343434;
  padding: 6px 11px;
  min-width: 20px;
  transition: all 0.2s ease-in-out;
}

.FileListDelete {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #eb534a;
  padding: 6px 11px;
  min-width: 20px;
  transition: all 0.2s ease-in-out;
}

.FileListCancel {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #343434;
  padding: 6px 11px;
  min-width: 20px;
  transition: all 0.2s ease-in-out;
}

.FileListCancel svg {
  width: 22px !important;
  height: 22px !important;
}

.FileListRename:hover,
.FileListDelete:hover,
.FileListCancel:hover {
  background: transparent;
}

.FileListRename,
.FileListDelete {
  opacity: 0;
}

.UpFileListUnder:hover .FileListRename,
.UpFileListUnder:hover .FileListDelete,
.UpFileListUnder.Active .FileListRename,
.UpFileListUnder.Active .FileListDelete {
  opacity: 1;
}

.FileRenameField {
  width: 100%;
  background: #ffffff;
  /* border: 1px solid #E1E1E1; */
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #2c2c2c;
  /* padding: 8px 10px; */
}

.FileRenameField input {
  height: 24px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #2c2c2c;
  padding: 8px 10px;
}

.FileRenameField fieldset {
  border-color: #e1e1e1;
  border-radius: 8px;
  top: 0px;
  border-width: 1px !important;
}

.FileListRight {
  display: flex;
  align-items: center;
}

.FileListRight p.FileSize:last-of-type {
  margin: 0;
  padding: 0;
}

.FileListRight p::after {
  content: "";
  width: 2px;
  height: 10px;
  background: #cdcdcd;
  position: absolute;
  top: 53%;
  right: -2px;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  border-radius: 0;
}

.FileListRight p:last-of-type:after {
  display: none;
}

/* .FileListRight a,
.FileListRight button {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #2C2C2C;
  background-color: #fff;
  margin-top: -3px;
  border: none;
  padding: 0;
  margin-left: 8px;
} */

.FileListRight a svg,
.FileListRight button svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.SignButton {
  width: 182px;
  height: 56px;
  background: #2a5bee;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 0px;
  margin-right: 15px;
}

.SignedButton {
  width: 241px;
  height: 56px;
  background: #2fa42b;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.SignedButton .Icon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: #fff;
  border-radius: 50%;
  color: #2fa42b;
}

.SignedButton .Icon svg {
  width: 16px;
  height: auto;
  margin-top: 2px;
  stroke-width: 3px;
}

.RentallAppliSubmitBU {
  width: 100%;
  height: 64px;
  background: #d4e3fe;
  border: none;
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  padding: 20px;
  cursor: auto !important;
}

.RentallAppliSubmitBU.Active {
  background: #2a5bee;
  cursor: pointer !important;
}

.CusRadioRow {
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 0px;
}

.CusRadio {
  width: 50%;
  cursor: auto;
}

.checkmark {
  width: 100%;
  height: 56px;
  background: #fff;
  border: 1px solid #dfe1e9;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8c8c8c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CusRadio input:checked ~ .checkmark {
  background: #e2e2e2;
  border: 1.5px solid #b9b8b8;
  color: #b9b8b8;
}

.CusRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.SignarureBody {
  width: 100%;
}

.SignedBGBUGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

form {
  width: 100%;
}

.FilterSearchSec {
  width: 600px;
  padding-right: 15px;
  display: flex;
}

.CheckFilter {
  display: flex;
  flex-wrap: wrap;
}

.CheckFilter label {
  width: 100%;
  margin: 0;
}

.ReqNo {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #b7b7b7;
  margin: 0 0 6px 0;
  width: 100%;
  display: flex;
}

.ReqNo img {
  width: 15px;
  margin-right: 8px;
}

.ReqAddCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px #f5f5f5;
  border-radius: 8px;
  /* margin: 0px 0;
  padding: 0px;
  max-height: 0; */
  margin: 10px 0;
  padding: 20px;
  max-height: 100%;
  /* overflow: hidden; */
}

.ReqAddCard.Open {
  max-height: 100%;
  margin: 10px 0;
  padding: 20px;
}

.ReqAddCardTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.CustomSelect > div {
  border-radius: 8px;
  border-color: #e1e1e1;
  min-height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 5px;
  /* color: #BFBFBF; */
}

.ReqAddRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ReqAddFormSec {
  width: 100%;
  max-width: 600px;
}

.FormIMGSec {
  width: CalC(100% - 650px);
}

.FormSec {
  width: 100%;
  padding: 8px 0;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.FormFieldArea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  height: 160px;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 16px;
}

.FormSecRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.FormHalfSec {
  width: 50%;
  padding: 0 8px;
}

.FormField {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 16px;
}

.FormSec.WithSign .FormField {
  padding: 11px 16px 11px 40px;
}

.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.TimePick {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 0.45rem 16px;
  width: 43%;
  height: 44px;
  position: relative;
  margin: 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.TimePick2 {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 0.45rem 16px;
  width: 100%;
  height: 44px;
  position: relative;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.TimePick svg.ClockIcon,
.TimePick2 svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 13px;
  right: 7px;
  color: #585858;
  /* z-index: 1; */
}

.TimePick > div,
.TimePick2 > div {
  position: relative;
  /* z-index: 2; */
  padding-right: 10px;
}

.TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.FileIMGDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 12px;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 0 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #a069c6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  height: 100%;
  padding: 5px 15px 5px 20px;
  display: inline-flex;
  flex-wrap: wrap;
  align-content: center;
}

.UploadLabel div.UpIcon {
  display: block;
  width: 100%;
}

.UpIcon img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadText,
.UploadLabel span p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.ImgFile {
  /* min-width: 180px; */
  width: 180px;
  height: 180px;
  position: relative;
  margin: 0px;
  padding: 10px 0 5px 0;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  max-height: 180px;
  object-fit: contain;
  object-position: center;
  border-radius: 6px;
}

.ImgFile a {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  width: 23px;
  height: 23px;
  text-align: center;
}

.ImgFile a svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: 0px;
  right: 0px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.ButtonDiv {
  padding: 20px 0px 10px 0;
}

.ButtonVoiletSolid {
  padding: 15px 30px;
  background-color: #ff8271;
  margin: 0px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.ButtonVoiletLine {
  padding: 15px 30px;
  background-color: #fff;
  margin: 0px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #f1ecf3;
  color: #343434;
}

.ErrorMIcon {
  color: #f44336;
  font-size: 12px;
  width: auto;
  position: absolute;
  right: 30px;
  top: 50%;
  background: #fff;
  padding-left: 10px;
  transform: translateY(-50%);
}

.ErrorMIcon svg {
  width: 20px;
}

.HeadTable {
  width: 100%;
  padding: 15px 14px 0 46px;
}

.PaymentListHead {
  width: 100%;
}

.HeadTable table,
.PaymentListHead table {
  width: 100%;
}

.HeadTable table td {
  width: 20%;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #333333;
}

.PaymentListHead table td {
  width: 20%;
}

.DateInfo {
  margin: 0;
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
}

.DateInfo span {
  color: #3aaa35;
  font-weight: 500;
}

.DateInfo2 {
  margin: 0;
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
  line-height: 24px;
}

.DateInfo2 span {
  color: #ffbb33;
  font-weight: 500;
}

.ActionBuArea {
  display: flex;
}

.ActionSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ActionBuArea button {
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  color: #5b5a5b;
  margin: 0 3px;
}

.ActionBuArea button svg {
  width: 18px;
}

.PaymentBodyTableArea {
  width: 100%;
  padding: 0 0px 0 32px;
}

.PaymentBodyTableArea table {
  width: 100%;
}

.PaymentBodyTableArea table td {
  width: 20%;
  vertical-align: top;
}

.HideInfo {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  padding: 10px 0;
  white-space: normal;
}

.HideInfo span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  display: inline-block;
}

.FormSec.WithSign {
  position: relative;
}

.FormSec.WithSign svg {
  position: absolute;
  width: 18px;
  height: 18px;
  color: #4a4a4a;
  top: 22px;
  left: 13px;
}

.ProfileEditFormSec .FormGroup {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 20px 0px 20px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileEditFormSec.ScoreCard .FormGroup div > div::before,
.ProfileEditFormSec.ScoreCard .FormGroup div > div::after {
  display: none;
}

.ProfileEditFormSec .FormGroup > div {
  width: 100%;
}

.ProfileEditFormSec .FilterLabel {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 80px;
  white-space: nowrap;
}

.ProfileEditFormSec.ScoreCard .FilterLabel {
  min-width: 150px;
}

.ProfileEditFormSec .formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 21px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ProfileEditFormSec .formControl.Error {
  border: 1px solid #f40;
}

.ProfileEditFormSec .ButtonVoiletSolid {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #ff8271;
  border: 1px solid #ff8271;
  border-radius: 0.5rem;
  width: 48%;
  margin: 0;
  border-bottom-right-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.ProfileEditFormSec .ButtonVoiletSolid:hover {
  background: #e16e5f;
}

.ProfileEditFormSec .ButtonVoiletLine {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #343434;
  padding: 10px 16px;
  background: #fff;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  width: 48%;
  margin: 0;
  border-bottom-left-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.ProfileEditFormSec .ButtonVoiletLine:hover {
  background: #f1ecf3;
}

.AppliAccoFormDateTenantDE > div > div {
  font-size: 14px !important;
  color: #8b8b8b !important;
}

.UpIMGSec {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.Failed {
  padding: 6px 13px 6px 13px !important;
  background: #eb575766 !important;
  border: 0 solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #a63434 !important;
  margin: 0 0 0 10px;
}

.Completed {
  padding: 6px 13px 6px 13px !important;
  background: #00808066 !important;
  border: 0 solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #006060 !important;
  margin: 0 0 0 10px;
}

.Pending {
  padding: 6px 13px 6px 13px !important;
  background: #f8d52466 !important;
  border: 0 solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #71621b !important;
  margin: 0 0 0 10px;
}

.PaymentIcon {
  width: 15px;
  margin-right: 8px;
}

.UploadImgSign {
  width: 250px;
  height: 150px;
}

.UploadImgSign img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.PopupBody {
  padding: 10px 30px 30px 30px;
  overflow: unset;
}

.ApartmentName {
  font-weight: 600;
  font-size: 17px;
  line-height: 21px;
  color: #343434;
  margin-bottom: 24px;
}

.MergeDropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.MergeSelected {
  width: 250px;
  display: flex;
  flex-wrap: wrap;
}

.MergeSelected div {
  width: 100%;
  height: 38px;
  display: flex;
  border: 1px solid #e1e1e1;
  border-radius: 4px;
  padding: 0 10px;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #8d8d8d;
}

.MergeSelect {
  width: 270px;
  font-size: 14px;
  font-weight: 400;
  line-height: 17px;
  color: #3f3f40;
}

.MergeLabel {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  color: #343434;
  margin-bottom: 8px;
}

.MergeDropdown img {
  margin-top: 24px;
}

.MergeAppText {
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #999999;
  text-align: center;
  margin-bottom: 0;
}

.ButtonArea {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
}

.ActionsSec {
  margin-left: auto;
}

.ProsUserMandateSec {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 5px;
  padding-bottom: 15px;
}

.MandateCheckSec {
  width: 20px;
  height: 20px;
  background-color: #dfe2e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 5px;
}

.MandateCheckSec.Active {
  background-color: #27c27b;
}

.MandateCheckSec svg {
  width: 14px;
  height: auto;
  color: #fff;
  stroke-width: 3px;
}

.ProEditSec {
  position: absolute !important;
  right: 7px;
  top: 10px;
}

.EditActionsBU {
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #ffffff !important;
  margin: 0;
  border: none !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.EditActionsBU.Table {
  background-color: transparent !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  margin-left: 0;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.cardUnderS {
  width: 100%;
}

.cardUnderSHead {
  display: flex;
  align-items: center;
  padding: 20px;
  position: relative;
}

.cardUnderSHead .HeadIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f1ecf3;
  border-radius: 4px;
  margin-right: 16px;
}

.cardUnderSHead .HeadIcon img {
  width: 26px;
  height: auto;
}

.cardUnderSHead .HeadConTitle {
  font-weight: 600;
  font-size: 17px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #505050;
  margin-bottom: 2px;
}

.cardUnderSHead .HeadConText {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin-bottom: 0;
  color: #aaaaaa;
}

.SMScoreCardDe {
  text-align: center;
  padding: 12px 20px;
}

.SMScoreCardDe ul {
  margin: 0;
  padding: 0;
}

.SMScoreCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 8px 0;
  font-weight: 400;
  color: #919191;
  line-height: 21px;
  list-style: none;
}

.SMScoreCardDe ul li .SMProDETag {
  /* color: #b2b5cb; */
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 150px;
  white-space: nowrap;
}

.SMScoreCardDe ul li .SMProDE {
  /* margin-left: AUTO; */
  text-align: left;
  color: #343434;
  width: 120px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SMScoreCardDeTitle {
  font-size: 17px;
  padding: 0 0 8px 0;
  font-weight: 600;
  color: #505050;
  line-height: 150%;
  margin: 0;
  text-align: left;
}

.ProspectTaskTable,
.ProspectTaskTable table {
  width: 100%;
}

.ProspectTaskTable table tr th {
  padding: 11px 10px;
  font-size: 12px;
  font-size: 500;
  line-height: 18px;
  color: #230058;
  text-transform: uppercase;
}

.ProspectTaskTable table tr th:nth-child(1) {
  width: 50%;
}

.ProspectTaskTable table tr th:nth-child(2),
.ProspectTaskTable table tr th:nth-child(3) {
  width: 25%;
}

.ProspectTaskTable table tr td {
  height: 40px;
  border-top: 1px solid #e3e3e4;
  padding: 10px 10px;
  position: relative;
}

.ProspectTaskTable table tr:nth-child(even) td {
  background-color: #fbf7fc;
}

.ProspectTaskTable table tr td p {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0;
}

.ProspectTaskTable table tr td p span {
  margin-left: 15px;
}

.ProspectTaskTable table tr td p.Emoji {
  width: 40px;
  height: 40px;
  background-color: #ef8977;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
  text-transform: uppercase;
}

.ProsCheckSec {
  margin: 0 !important;
}

.AddTourModal {
  display: flex;
  flex-wrap: wrap;
}

.ModalFormGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AddTourTimeArea {
  width: 250px;
}

.AddTourTimeArea ul {
  width: 100%;
  margin: 0;
  padding: 0 15px 0 0;
  height: 340px;
  overflow: auto;
}

.AddTourTimeArea ul li {
  list-style: none;
  width: 100%;
  border: 1px solid #502688;
  border-radius: 8px;
  padding: 10px 16px;
  margin: 0 0 14px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.AddTourTimeArea ul li:hover {
  background-color: rgb(109 77 159 / 30%);
}

.AddTourTime {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  color: #502688;
  margin: 0;
}

.TimeSlotTitle {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
  margin: 0 0 15px 0;
}

.AddTourPopTT {
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  color: #343434;
  margin: 20px 0 0 0;
}

.AddTourTimeArea ul li.TimeSelected {
  background-color: #502688;
}

.AddTourTimeArea ul li.TimeSlotBooked {
  border: 1px solid #efefef;
  background-color: #efefef;
  cursor: context-menu;
}

.AddTourTimeArea ul li.TimeSelected .AddTourTime {
  color: #fff;
}

.AddTourTimeArea ul li.TimeSlotBooked .AddTourTime {
  color: #666666;
}

.AddTourOpenHouse {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666;
}

.AddTourOpenHouse svg {
  width: 18px;
  color: #efd763;
  fill: #efd763;
  margin-right: 5px;
}

.AddTourTimeArea ul li.TimeSelected .AddTourOpenHouse {
  color: #efefef;
}

.AddTourScheduled {
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #666666;
}

.AddTourScheduled svg {
  width: 18px;
  color: #666666;
  margin-right: 5px;
}

.ChatMainSec {
  box-shadow: 0 0 20px 0 #f5f5f5;
  margin: 20px 0 0 0;
  height: auto;
}

.ApartmentSarchSec {
  padding: 24px 24px 24px 24px;
  position: relative;
}

.ApartmentSarchSec > div > div {
  padding: 0 !important;
}

.ApartmentSarchSec > div > div button,
.ApartmentSarchSec button {
  border: none;
  width: 40px;
  height: 40px;
  margin: -3px 2px 0 0;
  background: transparent;
  font-size: 28px;
  font-weight: 300;
  color: #343434;
  line-height: 10px;
}

.ApartmentSarchSec svg {
  position: absolute;
  top: 50%;
  left: 40px;
  transform: translate(0px, -50%);
  width: 20px;
  height: 20px;
  color: #666;
}

.ApartmentSarchSec input {
  border: 1px solid #e1e1e1;
  width: 100% !important;
  padding: 10px 10px 10px 46px !important;
  height: 45px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 21px;
}

.ApartmentSarchSec:first-child input:focus,
.ApartmentSarchSec input:focus {
  outline: none;
  box-shadow: none;
}

.ApartmentSarchSec input::placeholder {
  color: #bfbfbf;
  opacity: 1;
  /* Firefox */
}

.ApartmentSarchSec input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

.ApartmentSarchSec input::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

.PropertyGroupSec {
  width: 100%;
  padding: 20px 0 15px 0px;
  overflow: auto;
  height: 69vh;
}

.PropertyGroupBody {
  width: 100%;
  min-height: 50px;
  max-height: 100%;
}

.PropertyGroupBody ul {
  max-height: 100% !important;
  overflow: unset !important;
}

.activeList {
  background-color: rgba(0, 0, 0, 0.04);
}

.ProspectComIcon {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #fff;
  background-color: #ef8977;
  text-transform: uppercase;
  margin: 0;
}

.ChatSecHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  border-bottom: 1px solid #dfe2e6;
}

.ChatSecHeaderTenantSec {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.ChatSecHeaderTenantSecTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 6px 0;
}

.ChatSecHeaderTenantSecMember {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  border-right: 1px solid #dfe2e6;
}

.ChatSecHeaderTenantSecMember:last-child {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border-right: 0px solid #dfe2e6;
}

.ChatSecHeaderActions {
  display: flex;
  align-items: center;
}

.PropertyImageSec {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
}

.PropertyImageSec.Avatar {
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.PropertyImageSec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.GroupAvatarSec {
  width: auto;
  /* border: 1px solid #FF8271; */
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-right: 20px;
  margin-left: auto;
}

.GroupAvatar {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  overflow: hidden;
  background: #ff8271;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: -18px;
  border: 1px solid #fff;
}

.GroupAvatar:nth-child(1) {
  z-index: 5;
  margin: 0;
}

.GroupAvatar:nth-child(2) {
  z-index: 4;
}

.GroupAvatar:nth-child(3) {
  z-index: 3;
}

.GroupAvatar:nth-child(4) {
  z-index: 2;
}

.GroupAvatar:nth-child(5) {
  z-index: 1;
}

.GroupAvatar:nth-child(6) {
  z-index: 0;
}

.GroupAvatar img {
  width: 16px;
  height: auto;
}

.GroupAvatarCount {
  font-size: 15px;
  margin: 0 0 0 10px;
  color: #666;
  font-weight: 500;
}

.PropertyGroupTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 4px 0;
}

.PropertyGroupMember {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0;
}

.PropertyGroupNotiCount {
  font-size: 11px;
  width: 22px;
  height: 22px;
  min-width: 15px;
  min-height: 15px;
  background: #ef8977;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-right: 25px; */
  /* margin-left: auto; */
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.PropertyTenantNotiCount {
  font-size: 12px;
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background: #ef8977;
  color: #fff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 9px;
  margin-left: auto;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.01em;
}

.PropertyGroup.Open .PropertyGroupBody {
  min-height: 50px;
  max-height: 100%;
}

.PropertyGroup.Open .PropertyGroupBody ul {
  max-height: 100% !important;
  overflow: unset !important;
}

.PropertyGroup .Icon {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #495057;
}

.PropertyGroup .Icon svg {
  width: 22px;
  height: 22px;
  stroke-width: 1.5px;
}

.TenantNotiCount {
  width: 30px;
  height: 30px;
  position: relative;
}

.TenantNotiCount svg {
  width: 22px;
  height: 22px;
  color: #9e9e9e;
}

.ConLoaderS {
  height: 500px;
}

.ConLoaderS svg {
  width: 100%;
  height: 100%;
}

.mainCard > div {
  box-shadow: none;
}

.ChatSecHeader {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px 24px;
  border-bottom: 1px solid #dfe2e6;
}

.ChatSecHeaderTenantSec {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
}

.ChatSecHeaderTenantSecTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 6px 0;
}

.ChatSecHeaderTenantSecMember {
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  border-right: 1px solid #dfe2e6;
}

.ChatSecHeaderTenantSecMember:last-child {
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  border-right: 0px solid #dfe2e6;
}

.ChatSecSearch {
  width: 44px;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.ChatSecSearch:hover {
  background-color: #ff8271;
}

.ChatSecSearch.Open {
  width: 400px;
}

.ChatSecSearch svg {
  width: 22px;
  height: 22px;
  color: #ff8271;
  transition: all 0.2s ease-in-out;
}

.ChatSecSearch:hover svg {
  color: #fff;
}

.ChatSecSearch input {
  border: none !important;
  width: 100% !important;
  padding: 0 15px 0 5px !important;
  font-size: 14px;
  display: none;
}

.ChatSecSearch.Open input {
  display: block;
}

.ChatSecStartMessage {
  min-width: 44px;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px 16px;
  height: 44px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ff8271;
  margin: 0 0 0 16px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}

.ChatSecStartMessage:hover {
  background-color: #ff8271;
  color: #fff;
}

.ChatSecStartMessage svg {
  width: 22px;
  height: 22px;
  color: #ff8271;
  margin-right: 10px;
  transition: all 0.2s ease-in-out;
}

.ChatSecStartMessage:hover svg {
  color: #fff;
}

.ChatSecHeaderActions {
  display: flex;
  align-items: center;
}

.ChatNewPopHead {
  padding: 18px 32px;
  background-color: #fff;
}

.ChatNewPopHead h2 {
  height: auto !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 27px !important;
  letter-spacing: 0.01em;
  color: #343434 !important;
}

.ChatNewPopHead .CloseBU {
  background: #fff !important;
  border: none !important;
  color: #343434 !important;
  padding: 0 !important;
  width: 50px;
  height: 40px;
  text-align: right;
}

.ChatNewPopHead .CloseBU svg {
  width: 24px;
  height: 24px;
}

.ChatNewPopBody {
  overflow: unset;
  height: 375px;
  padding: 0 32px 12px 32px;
}

.ChatBody {
  width: 540px;
}

.ChatBody > div > div > div:first-child input {
  border: 1px solid #e1e1e1;
  width: 100% !important;
  padding: 21px 50px 21px 15px !important;
  border-radius: 8px;
}

.ChatBody > div > div > div:first-child input:focus {
  outline: none;
  box-shadow: none;
}

.PopForm {
  width: 100%;
  margin-bottom: 25px;
}

.PopFormTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #333333;
  margin-bottom: 8px;
}

.PopFormTextArea {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 12px 16px;
  height: 140px;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.PopFormSendBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background-color: #ff8271;
  border: none;
  border-radius: 8px;
  margin-bottom: 32px;
}

.ChatSecMenu {
  min-width: 44px;
  border: 1px solid #ff8271;
  border-radius: 8px;
  padding: 10px;
  height: 44px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ChatSecMenu svg {
  width: 22px;
  height: 22px;
  color: #ff8271;
}

.ChatDropMenu li {
  padding: 0;
}

.ClearPro {
  padding: 10px 20px !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #343434;
  background-color: #fff !important;
}

.ClearPro img,
.DeleteChat img {
  width: 20px;
  height: 20px;
  margin-right: 8px;
}

.DeleteChat {
  padding: 10px 20px !important;
  font-weight: 500;
  font-size: 14px !important;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #eb534a;
  background-color: #fff !important;
}

.ClearPro:hover,
.DeleteChat:hover {
  background-color: rgba(0, 0, 0, 0.04) !important;
}

.ChatInputSec {
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  width: 100%;
  display: flex;
  align-items: flex-end;
  padding: 8px;
}

.ChatInputField {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  color: #bfbfbf;
  padding: 6px 10px;
  width: 100%;
  border: none;
}

.ChatInputSec textarea {
  font-weight: 400;
  font-size: 14px;
  line-height: 26px;
  color: #343434;
  padding: 9px 10px 7px 10px;
  width: 100%;
  border: none;
  display: flex;
  align-items: center;
  resize: none;
  height: 39px !important;
}

.ChatInputSec textarea::-webkit-scrollbar {
  width: 5px;
}

.ChatInputSec textarea::-webkit-scrollbar-track {
  background: transparent;
}

.ChatInputSec textarea::-webkit-scrollbar-thumb {
  background: #ced0d1;
}

.ChatInputSec textarea::-webkit-scrollbar-thumb:hover {
  background: #ced0d1;
}

.SendBUMSG {
  width: 42px;
  height: 42px;
  background: #ff8271;
  border: 1px solid #ff8271;
  border-radius: 8px;
  display: flex !important;
  color: #fff;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in-out;
}

.SendBUMSG:hover {
  background: #e97565;
  border: 1px solid #e97565;
}

.SendBUMSG svg,
.AttatchFileBUMSG svg {
  width: 22px;
  height: 22px;
}

.AttatchFileBUMSG {
  width: 42px;
  height: 42px;
  background: #fff;
  border: 1px solid #fff;
  border-radius: 8px;
  display: flex !important;
  color: #ff8271;
  align-items: center;
}

.ChatMessages {
  overflow: auto !important;
}

.SendIcon {
  width: 16px;
  height: auto;
  object-fit: contain;
}

.HeadSearchSec {
  width: 100%;
  background: #fff;
  padding: 15px 35px;
  box-shadow: 0px 14px 18px 0 rgb(0 0 0 / 4%);
  display: flex;
  align-items: center;
  position: absolute;
  z-index: -1;
  top: 50px;
  left: 0;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.HeadSearchSec.Open {
  z-index: 2;
  top: 96px;
  opacity: 1;
}

.HeadSearchSec input {
  width: 100%;
  border: none;
  font-size: 14px;
}

.HeadSearchSec svg {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.ChatNotificationSec {
  width: 440px;
  height: auto !important;
  position: absolute;
  bottom: 30px;
  right: 30px;
  background: #fff;
  box-shadow: 0px 0px 20px rgba(62, 85, 120, 0.1);
  border-radius: 8px;
  padding: 16px 20px;
}

.ChatNotificationSecTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 4px 0;
}

.ChatNotiBodySec {
  width: 100%;
  display: flex;
  align-items: flex-start;
  padding: 25px 0 5px 0;
}

.ChatNotiBodyIcon {
  width: 55px;
  height: 55px;
  min-width: 55px;
  min-height: 55px;
  border-radius: 50%;
  overflow: hidden;
  background: #fbf7fc;
  margin-right: 15px;
}

.ChatNotiBodyIcon img {
  width: 100%;
  height: 100%;
}

.ChatNotificationSecSubTitle {
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: #666;
  margin: 0;
}

.ChatNotiBodyTitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  color: #343434;
  margin: 0 0 5px 0;
}

.ChatNotiBodyText {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.01em;
  color: #7a7a7a;
  margin: 0 0 0 0;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
}

.ChatNotiBodyTextSec {
  padding: 6px 0 0 0;
}

.CloseChatNotiBU {
  position: absolute;
  width: 40px;
  height: 40px;
  top: 8px;
  right: 6px;
  border: none;
  background: none;
}

.CloseChatNotiBU svg {
  width: 22px;
  height: 22px;
  stroke-width: 1.5;
  color: #343434;
}

.WelcomeMessageSec {
  width: 100%;
  height: Calc(100% - 93px);
  position: absolute;
  background: #fff;
  top: 93px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WelcomeMessageSec.NoTop {
  width: 100%;
  height: 100%;
  position: absolute;
  background: #fff;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.WelcomeMessageBody {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.WelcomeMessageBody img {
  width: 120px;
  height: auto;
  margin-bottom: 30px;
}

.WelcomeMessageTitle {
  color: #343434;
  font-size: 38px;
  font-weight: 300;
  line-height: 52px;
  letter-spacing: 0.48px;
  width: 100%;
  text-align: center;
}

.WelcomeMessageText {
  color: #666;
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.13px;
}

.TenantNBU {
  padding: 0;
  background-color: transparent !important;
  color: #666 !important;
  font-size: 14px;
  font-family: Inter;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.14px;
  border: none !important;
}

.TenantNBU:hover,
.TenantNBU:active {
  background-color: transparent !important;
  color: #666 !important;
}

.TenantNBU:after {
  display: none;
}

.TenantNBU svg {
  width: 16px;
  height: 16px;
  margin: -2px 0 0 5px;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 8px 10px !important;
  min-width: 158px;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #f8f8f8;
}

.PositionRelative {
  position: relative;
  height: 350px;
}

.AvatarSec {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
}

.AvatarHalfSec {
  width: Calc(50% - 1.2px);
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.AvatarSec.Full .AvatarHalfSec {
  width: 100%;
}

.AvatarHalfIcon {
  width: 100%;
  height: Calc(50% - 1px);
  display: flex;
}

.AvatarHalfSec.Full .AvatarHalfIcon,
.AvatarSec.Full .AvatarHalfIcon {
  height: 100%;
}

.AvatarHalfIcon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.AvatarHalfIcon span {
  width: 100%;
  height: 100%;
  background: #f48272;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  text-transform: uppercase;
}

.MessageFileSec {
  padding: 0;
  background: #fbf7fc;
  border-radius: 6px;
  /* min-width: 300px; */
  position: relative;
}

.MessageFileSec p {
  color: #343434;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.14px;
  margin: 0 0 8px 0;
  padding-right: 0px;
  max-width: 250px;
  overflow: hidden;
  white-space: normal;
  text-overflow: ellipsis;
}

.MessageFileSec > div > div {
  width: 550px !important;
  height: auto !important;
}

.MessageFileSec > div > div.ImgFile {
  width: auto !important;
  height: auto !important;
  cursor: pointer;
}

.MessageFileSec > div > div.ImgFile div {
  width: 100% !important;
  height: auto !important;
}

.MessageFileSec video {
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
}

/* .MessageFileSec img {
  width: 300px;
  height: auto;
  border-radius: 6px;
  margin: 5px 0;
} */

.MessageFileSec p.NoFileTT {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
}

.NoFileDrBU {
  border: 1px solid #230058;
  background-color: transparent;
  font-size: 12px;
  font-weight: 500;
  color: #230058;
  padding: 8px 8px;
  border-radius: 6px;
  margin-top: 8px;
  display: inline-block;
  transition: all 0.3s ease-in-out;
}

.NoFileDrBU:hover {
  border: 1px solid #230058;
  background-color: #230058;
  color: #fff;
}

.MessageFileSec > div > div.ImgFilePops {
  position: fixed;
  width: 100% !important;
  height: calc(100% - 60px)!important;
  background-color: rgba(0, 0, 0, 0.8);
  bottom: 0;
  left: 0;
  z-index: -99999;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.MessageFileSec > div > div.ImgFilePops.Active {
  opacity: 1;
  z-index: 1;
}

.MessageFileSec > div > div.ImgFilePops img {
  width: 90%;
  height: 90%;
  max-width: 1200px;
  border-radius: 6px;
  margin: 5px 0;
  object-fit: contain;
}

.CancelImgBU {
  width: 50px;
  height: 50px;
  background: #fff;
  border: none;
  border-radius: 50%;
  position: absolute;
  top: 20px;
  right: 20px;
  color: #502688;
  transition: all 0.3s ease-in-out;
}

.CancelImgBU:hover {
  background: #ff8271;
  color: #fff;
}

.CancelImgBU:focus {
  outline: none;
}

.CancelImgBU svg {
  width: 30px;
  height: 30px;
  stroke-width: 1.5;
}

.DRBU {
  position: absolute;
  width: 30px !important;
  height: 30px !important;
  right: 9px;
  top: 8px;
}

.DRBU {
  width: 30px;
  height: 30px;
  padding: 0;
  background-color: transparent !important;
  border: none;
  color: #343434;
  transition: all 0.3s ease-in-out;
}

.DRBU:hover,
.DRBU:focus {
  background-color: transparent !important;
  border: none;
  color: #f48272;
  outline: none;
}

.DRBU svg {
  width: 20px;
}

.ConStarted {
  font-size: 28px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.ConStarted img {
  margin: 0;
  background: transparent;
  display: block;
  height: 100px;
  width: auto;
  position: relative;
  top: 5px;
}

.CommunicationPopupSec {
  width: 100%;
  max-width: 400px;
  position: fixed;
  bottom: 44px;
  right: 28px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  z-index: 998;
}

.CommunicationPopupSec .ChatPopBox {
  width: 100%;
  position: relative;
  z-index: -9;
  bottom: 0;
  right: 0;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1) !important;
  background: #fff;
  /* transition: all 0.3s ease-in-out; */
  border-radius: 12px;
  overflow: hidden;
  display: none;
  animation: slideup 0.4s 1;
}
@keyframes slideup {
  0% {
    bottom: 0;
  }

  100% {
    bottom: 20px;
  }
}

.CommunicationPopupSec.Open .ChatPopBox {
  z-index: 999;
  display: block;
  bottom: 20px;
}

.CommunicationPopupSec .PopupSecHead {
  background-color: #502688;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 18px 20px 18px 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
}

.PopupSecHeadTabSec {
  width: 100%;
  display: flex;
  border: 2px solid #fff;
  border-radius: 6px;
  margin: 16px 0 0 0;
  /* overflow: hidden; */
  position: relative;
}

.PopupSecHeadTabSec button {
  width: 50%;
  padding: 10px 12px;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  color: #f1ecf3;
  line-height: normal;
  background: transparent;
  border: none;
  position: relative;
  border-radius: 6px 0 0 6px;
  transition: all 0.3s ease-in-out;
}

.PopupSecHeadTabSec button span {
  position: relative;
  z-index: 1;
}

.PopupSecHeadTabSec button:first-child {
  border-radius: 0 6px 6px 0;
}

.PopupSecHeadTabSec button.Active {
  background: #fff;
  color: #373737;
}

.red {
  color: #f40;
}

.CommunicationPopupSec .PopupSecHead p {
  font-size: 16px;
  color: #fff;
  font-weight: 500;
  margin: 0 0 2px 0;
  text-transform: capitalize;
  /* position: relative; */
  display: flex;
  align-items: center;
}

.CommunicationPopupSec .PopupSecHead p a {
  color: #fff;
}

/* .CommunicationPopupSec .PopupSecHead p span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ff8271;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  position: absolute;
  top: -8px;
  right: -26px;
} */

.CommunicationPopupSec .PopupSecHead svg {
  width: 28px;
  height: 28px;
  stroke-width: 1px;
  color: #fff;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.ChatBoxOpenBU {
  border: none;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 4px, rgba(0, 0, 0, 0.2) 0px 2px 12px;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #502688;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.3s ease-in-out;
}

.ChatBoxOpenBU:hover {
  transform: scale(1.1);
}

.ChatBoxOpenBU .BUOpen {
  transition: transform 0.16s linear 0s, opacity 0.06s linear 0s;
  opacity: 1;
  transform: rotate(0deg) scale(1);
  width: 60px;
  height: 60px;
  position: absolute;
}

.CommunicationPopupSec.Open .ChatBoxOpenBU .BUOpen {
  transition: transform 0.16s linear 0s, opacity 0.06s linear 0s;
  opacity: 0;
  transform: rotate(25deg) scale(0.5);
}

.ChatBoxOpenBU .BUClose {
  transition: transform 0.16s linear 0s, opacity 0.06s linear 0s;
  opacity: 0;
  transform: rotate(-25deg) scale(0.5);
  width: 60px;
  height: 60px;
  position: absolute;
}

.CommunicationPopupSec.Open .ChatBoxOpenBU .BUClose {
  transition: transform 0.16s linear 0s, opacity 0.06s linear 0s;
  opacity: 1;
  transform: rotate(0deg) scale(1);
}

.ChatBoxOpenBU svg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.PopupSecBody {
  width: 100%;
  background-color: #fff;
  height: auto;
  max-height: 550px;
  overflow: hidden;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.CheckApplyBU {
  margin: 15px 0 0 0;
}

.CheckApplyBU button {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #ff8271;
  border: 1px solid #ff8271;
  border-radius: 0.5rem;
  width: 120px;
  margin: 15px 0 0 0;
  transition: all 0.3s ease-in-out;
}

.CheckApplyBU button:hover {
  background: #e16e5f;
}

.AddTourErrorMessage {
  color: #f44336;
  font-size: 13px;
  margin-top: 0px;
  margin-bottom: 6px;
}

.AddCommDiv {
  width: 100%;
  padding: 15px 0 30px 0;
}

.DisFlex {
  width: 100%;
  display: flex;
  align-items: center;
}

.AllListBU {
  min-width: 35px;
  height: 35px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  border-radius: 4px;
}

.PopupSecBody .ChatSec {
  width: 100%;
  position: relative;
  transform: translateY(0px);
  transition: all 0.3s ease-in-out;
}

.PopupSecBody.OpenList .ChatSec {
  position: absolute;
  visibility: hidden;
  transform: translateY(20px);
}

.PopupSecBody .ChatList {
  width: 100%;
  position: absolute;
  transform: translateY(20px);
  visibility: hidden;
  height: 442px;
  overflow: auto;
  padding: 10px 20px;
  background-color: #fff;
  transition: all 0.3s ease-in-out;
}

.PopupSecBody.OpenList .ChatList {
  position: relative;
  visibility: visible;
  transform: translateY(0px);
}

.ProspectList {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 0;
  cursor: pointer;
}

.ProspectAvatar {
  width: 44px;
  height: 44px;
  margin-right: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #502688;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fbf7fc;
  border-radius: 50%;
  position: relative;
}

.ProspectAvatar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #502688;
  font-size: 11px;
  font-weight: 500;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: normal;
  position: absolute;
  top: -5px;
  right: -5px;
}

.ProspectName {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  margin: 0;
  color: #343434;
  width: 280px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.accordionOn {
  height: 39px;
  overflow: hidden;
  padding: 0px 10px 0 20px;
  margin: 0px 0;
}

.ListAccoudionTitle {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  color: #502688;
  margin: 0;
  height: 39px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.ListAccoudionTitle svg {
  width: 22px;
  height: auto;
  margin-right: 8px;
}

.accordionOn.Open .ListAccoudionTitle svg {
  transform: rotate(180deg);
}

.accordionOn.Open {
  height: auto;
}

.SearchSecMain {
  width: 100%;
  padding: 20px 20px 10px 20px;
}

.SearchSecUnder {
  width: 100%;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 3px 12px;
}

.SearchSecUnder svg {
  width: 22px;
  height: auto;
  color: #666666;
}

.ProsTenantList {
  color: #343434;
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 2px 0px;
  margin: 0 0 0 0px;
  width: 100%;
  text-transform: capitalize;
}

.ProsTenantListDe {
  color: #666;
  font-size: 12px;
  font-weight: 400;
  line-height: 21px;
  display: flex;
  align-items: center;
  padding: 0px 0px;
  margin: 0 0 0 0px;
  width: 100%;
}

.ProsTenantList.activeTenant {
  color: #502688;
}

.ProsTenantList span {
  margin-right: 10px;
}

.ProsTenantList span:last-child {
  margin-right: 0px;
}

.ProsTenantListDe span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.ProsTenantListDe span:last-child {
  margin-right: 0px;
  padding-right: 0px;
}

.ProsTenantListDe span::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #666666;
  top: 50%;
  right: 0;
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.ProsTenantListDe span:last-child::after {
  display: none;
}

.UnreadList {
  width: 100%;
}

.UnreadListUnder {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.UnreadProfileImg {
  width: 44px;
  min-width: 44px;
  height: 44px;
  min-height: 44px;
  background-color: #f1ecf3;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #502688;
  margin: 0 15px 0px 0;
}

.UnreadInfoSec {
  width: 100%;
}

.UnreadInfoName {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #373737;
  margin: 0 0 8px 0;
}

.StatusBadgeChatHead {
  background-color: #fff;
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 11px;
  padding: 4px 10px !important;
  border-radius: 15px;
  margin: 0 0 0 10px;
  height: 21px;
  line-height: normal;
}

.UnreadInfoName span,
.StatusBadge {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688 !important;
  font-weight: 500 !important;
  font-size: 11px;
  padding: 4px 8px !important;
  border-radius: 15px;
  margin: 0 10px 0 0;
  height: 21px;
  line-height: normal;
}

.UnreadInfoName span.Verified {
  background-color: #e8fff2;
  color: #5ac55e !important;
}

.StatusBadge.NotApplied {
  background-color: #dfe2e6;
  color: #595959 !important;
}

.StatusBadge.Applied {
  background-color: rgba(161, 105, 198, 0.3);
  color: #502688 !important;
}

.StatusBadge.Screened {
  background-color: #fff3e8;
  color: #e99a37 !important;
}

.StatusBadge.Approved {
  background-color: #e8fff2;
  color: #5ac55e !important;
}

.StatusBadge.Declined {
  background-color: #ffe8e8;
  color: #e23725 !important;
}

.UnreadInfoProperty {
  color: #666666;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  display: flex;
  align-items: center;
  padding: 0;
  margin: 0;
}

.UnreadInfoProperty span {
  position: relative;
  padding-left: 10px;
  margin-left: 10px;
}

.UnreadInfoProperty span:first-child {
  padding-left: 0px;
  margin-left: 0px;
}

.UnreadInfoProperty span::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #666;
  top: 50%;
  left: 0;
  transform: translate(0, -50%);
}

.UnreadInfoProperty span:first-child:after {
  display: none;
}

.UnreadProfileCounter {
  width: 20px;
  height: 20px;
  background-color: #ff8271;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: #fff;
  margin: 0;
  border-radius: 50%;
}

.PopupContentMessageSec {
  width: 100%;
  position: relative;
}

.ChatViewSec {
  overflow: auto !important;
  height: 350px;
}

.MessageBlog {
  height: auto !important;
  padding: 16px 16px 10px 16px;
  overflow: auto;
}

.MessageBlog.Receiver {
  display: flex;
  justify-content: flex-end;
}

.MessageBlogInner {
  display: inline-flex;
  max-width: 550px;
}

.MessageBlogCon {
  display: inline-block;
  margin-right: auto;
  background: none;
  color: #343434;
  border-radius: 3px;
  padding: 0;
  margin-bottom: 0;
  text-align: left;
}

.MessageName {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 6px;
  cursor: pointer;
}

.MessageTime {
  font-weight: 500;
  font-size: 11px;
  line-height: 12px;
  letter-spacing: 0.01em;
  color: #7c7c7c;
  margin: 0 0 0 6px;
  cursor: pointer;
}

.MessageBlog.Receiver .MessageName {
  cursor: default;
}

.MessageDesMain {
  color: #343434;
  padding: 6px 12px;
  background: #fbf7fc;
  border-radius: 6px;
  width: 280px;
}

.MessageBlog.Receiver .MessageDesMain {
  color: #ffffff;
  background: #502688;
}

.MessageDes {
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  letter-spacing: 0.01em;
  margin: 0;
  word-break: break-word;
}

.MessageDes.Unread {
  font-style: italic;
}

.ChatBoxnoDataSec {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 20px;
}

.ChatBoxnoDataSec img {
  width: 120px;
  height: auto;
  opacity: 1;
}

.ChatBoxnoDataSec p {
  color: #343434;
  font-size: 32px;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.48px;
  width: 70%;
  text-align: center;
  margin: 20px 0 0 0;
}

.NoDataSecOnlyText {
  margin: 0 0 0 0px;
  /* background: #f6f6f6; */
  text-align: center;
  padding: 15px;
  border-radius: 3px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.NoDataSecOnlyText img {
  width: 80px;
  height: auto;
}

.NoDataSecOnlyText p {
  font-size: 14px;
  width: 100%;
  margin: 8px 0 0 0;
}

.ChatBoxOpenBUCounter {
  width: 22px;
  height: 22px;
  background-color: #ff8271;
  color: #fff;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: -5px;
  right: -2px;
  z-index: 1;
}

.UnreadCounter {
  margin-left: 5px;
}

.UnreadCounter:after {
  content: "";
  position: absolute;
  width: 6px;
  height: 6px;
  background-color: #ff8271;
  border-radius: 50%;
  top: -1px;
  right: -8px;
}

.ChatBoxContentLoader {
  width: 100%;
  height: 300px;
  position: absolute;
  z-index: 1;
  background: #fff;
}

.SearchTPDeRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.SearchTPDeRow span.SearchTPDeName {
  width: 100%;
}

.SearchTPDeRow span.SearchTPDeDe {
  width: 100%;
  font-size: 12px;
  font-weight: 400 !important;
  color: #666666 !important;
  padding: 0;
  min-width: 80px;
  display: flex;
  align-items: center;
}

.SearchTPDeRow span.SearchTPDeDe span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.SearchTPDeRow span.SearchTPDeDe span:last-child {
  position: relative;
  padding-right: 0px;
  margin-right: 0px;
}

.SearchTPDeRow span.SearchTPDeDe span::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #666666;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.SearchTPDeRow span.SearchTPDeDe span:last-child::after {
  display: none;
}

.ChatDeHeadRow {
  display: flex;
  flex-wrap: wrap;
}

.ChatDeHeadName {
  width: 100%;
}

.CommunicationPopupSec .PopupSecHead p.ChatDeHeadDetails {
  font-size: 13px;
  color: #d3cae2;
  font-weight: 400;
  margin: 0;
  text-transform: capitalize;
  position: relative;
  width: 100%;
}

.ChatDeHeadDetails span {
  position: relative;
  padding-right: 10px;
  margin-right: 10px;
}

.ChatDeHeadDetails span:last-child {
  position: relative;
  padding-right: 0px;
  margin-right: 0px;
}

.ChatDeHeadDetails span::after {
  content: "";
  position: absolute;
  width: 1px;
  height: 10px;
  background-color: #cbbce2;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
}

.ChatDeHeadDetails span:last-child::after {
  display: none;
}

.VarifiedBadgeTenant {
  width: 14px;
}

.VarifiedBadgeTenantChatHead {
  width: 16px;
  margin-left: 10px;
  filter: brightness(0) invert(1);
}

.CommuListSec {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0;
  padding: 6px 5px 6px 30px;
  cursor: pointer;
  justify-content: space-between;
}

.CommuListDeSec {
  width: calc(100% - 110px);
}

.CommuListStatusSec {
  width: 110px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.UnreadMessageTime {
  font-size: 12px;
  color: #666;
  margin: 0 0 0px 0;
}

.UnreadMessage {
  font-size: 13px;
  margin: 3px 0 0 0;
  line-height: 19px;
  font-weight: 400;
  color: #666;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.UnreadMessage span {
  margin-right: 5px;
  /* color: #343434;
  font-weight: 500; */
}

.UnreadMessage.Unread {
  font-style: italic;
}

.UnreadMsgSec {
  width: calc(100% - 40px);
}

.UnreadMsgCountSec {
  width: 40px;
  display: flex;
  justify-content: flex-end;
  margin: 6px 0 0px 0;
}

.MarkReadBU {
  font-size: 13px;
  background: transparent;
  width: 109px;
  color: #fff;
  font-weight: 300;
  margin: 0 0 0 auto;
  border-bottom: 1px solid;
  padding: 0 1px;
}

.ActionLeaseBUSec {
  position: absolute !important;
  top: 10px;
  right: 12px;
}

.ActionsLeaseBU {
  width: 40px;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  background-color: transparent !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 8px !important;
}

.ActionsLeaseBU:hover,
.btn-check:active + .btn-primary.ActionsLeaseBU,
.btn-check:checked + .btn-primary.ActionsLeaseBU,
.ActionsLeaseBU.btn-primary.active,
.ActionsLeaseBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsLeaseBU {
  background-color: transparent !important;
  border: none;
}

.ActionsLeaseBU svg {
  width: 22px !important;
  height: 22px !important;
  stroke-width: 2px !important;
}

.CommounMsg {
  font-size: 13px;
  margin: 3px 0 0 0;
  line-height: 19px;
  font-weight: 400;
  color: #666;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.MobileCloseBU {
  margin-left: auto;
  display: none;
}

.MobileCloseBU svg {
  width: 26px !important;
  height: 26px !important;
  stroke-width: 1.5px !important;
  color: #fff !important;
}

.SubHeadSec {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

@media screen and (min-width: 360px) {
  /* .SMProCusRow {
    flex-wrap: wrap;
  } */

  .SMProCusSmallDiv {
    min-width: 100%;
    max-width: 100%;
  }

  .FilesUploadGrid {
    width: 100%;
  }

  .NotesCardInside {
    flex-wrap: wrap;
  }

  .CardInsideCon {
    padding-left: 0;
    padding-top: 25px;
  }

  .ApplicationDetab ul li {
    width: 100%;
  }

  .SMProCusBigDiv {
    width: 100%;
  }

  .TitleWithTag {
    justify-content: space-between;
  }

  .InsideConName {
    width: 100%;
    padding-right: 0;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
    padding: 0 12px;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  span.ConNameTrancate {
    max-width: 100%;
  }

  .TextEclipsName {
    max-width: 230px;
  }

  .TextEclips {
    max-width: 170px;
  }

  .mainCard {
    margin: 0 1rem;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .EditApplicantMain {
    width: 100%;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 100%;
  }

  .FormIMGSec {
    width: 100%;
  }

  .FileIMGDiv {
    padding: 12px 0;
  }
}

@media screen and (min-width: 480px) {
}

@media screen and (min-width: 576px) {
  .FilesUploadGrid {
    width: 50%;
  }

  .ApplicationDetab ul li {
    width: 50%;
  }

  .InsideConName {
    width: auto;
    padding-right: 20px;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  span.ConNameTrancate {
    max-width: 300px;
  }

  .TextEclipsName {
    max-width: 280px;
  }

  .TextEclips {
    max-width: 200px;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
  }

  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
}

@media screen and (min-width: 768px) {
  .NotesCardInside {
    flex-wrap: nowrap;
  }

  .CardInsideCon {
    padding-left: 25px;
    padding-top: 0;
  }

  .ApplicationDetab ul li {
    width: 33.33%;
  }

  .TitleWithTag {
    justify-content: unset;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 50%;
    /* justify-content: unset; */
  }

  span.ConNameTrancate {
    max-width: 400px;
  }

  .TextEclipsName {
    max-width: 400px;
  }

  .TextEclips {
    max-width: 250px;
  }
}

@media screen and (min-width: 992px) {
  .FilesUploadGrid {
    width: 315px;
  }

  .MainReqConDivBig {
    width: 50%;
  }

  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConAction {
    width: 50%;
    justify-content: flex-start;
    padding: 0 12px;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 180px;
  }

  .TextEclips {
    max-width: 250px;
  }

  .EditApplicantMain {
    width: 800px;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .FilesUploadGrid {
    width: 320px;
  }
}

@media screen and (min-width: 1100px) {
  /* .SMProCusRow {
    flex-wrap: nowrap;
  } */

  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  .ApplicationDetab ul li {
    width: 50%;
  }

  /* .SMProCusBigDiv {
    width: 68%;
  } */

  span.ConNameTrancate {
    max-width: 500px;
  }

  .MainReqConDivBig {
    width: 50%;
  }

  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConAction {
    width: 50%;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 180px;
  }

  .TextEclips {
    max-width: 200px;
  }
}

@media screen and (min-width: 1200px) {
  /* .SMProCusBigDiv {
    width: 70%;
  } */
}

@media screen and (min-width: 1280px) {
  /* .SMProCusBigDiv {
    width: 71%;
  } */

  .MainReqConDivSmall {
    width: 42%;
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConAction {
    width: 23%;
    justify-content: flex-end;
    padding: 0 0px;
  }

  span.ConNameTrancate {
    max-width: 600px;
  }

  .TextEclipsName {
    max-width: 220px;
  }

  span.TextEclipsName {
    max-width: 180px;
  }

  .TextEclips {
    max-width: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  /* .SMProCusBigDiv {
    width: 73%;
  } */
}

@media screen and (min-width: 1400px) {
  .SMProCusSmallDiv {
    min-width: 300px;
    max-width: 300px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  .ApplicationDetab ul li {
    width: 33.33%;
  }

  /* .SMProCusBigDiv {
    width: 77%;
  } */
}

@media screen and (min-width: 1500px) {
  .SMProCusSmallDiv {
    min-width: 320px;
    max-width: 320px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  .MainReqConDivSmall {
    width: 41%;
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConAction {
    width: 24%;
    padding-right: 15px;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .TextEclipsName {
    max-width: 110px;
  }

  span.TextEclipsName {
    max-width: 100px;
  }

  .TextEclips {
    max-width: 140px;
  }
}

@media screen and (min-width: 1600px) {
  .SMProCusSmallDiv {
    min-width: 350px;
    max-width: 350px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConDivSmall {
    width: 43%;
  }

  .MainReqConAction {
    width: 22%;
  }

  .TextEclipsName {
    max-width: 140px;
  }

  span.TextEclipsName {
    max-width: 120px;
  }

  .TextEclips {
    max-width: 180px;
  }

  .ReqAddFormSec {
    max-width: 550px;
  }

  .FormIMGSec {
    width: CalC(100% - 570px);
  }

  .ImgFile {
    width: 33.33%;
    height: 150px;
  }

  .FileIMGDiv {
    padding: 12px;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }

  .ImgFile {
    width: 25%;
    height: 130px;
  }

  /* .SMProCusBigDiv {
    width: 79%;
  } */
}

@media screen and (min-width: 1800px) {
  /* .SMProCusBigDiv {
    width: 80%;
  } */

  .MainReqConDivSmall {
    width: 44%;
  }

  .MainReqConDivBig {
    width: 38%;
  }

  .MainReqConAction {
    width: 18%;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 150px;
  }

  .TextEclips {
    max-width: 250px;
  }

  .ImgFile {
    height: 140px;
  }

  .ReqAddFormSec {
    max-width: 600px;
  }

  .FormIMGSec {
    width: CalC(100% - 650px);
  }
}

@media screen and (min-width: 1900px) {
  .ImgFile {
    height: 170px;
  }
}

@media screen and (max-width: 959px) {
  .MobileCloseBU {
    display: unset;
  }
  .ChatBoxOpenBU {
    display: none;
  }
  div.CommunicationPopupSec {
    bottom: 0px;
    right: 0px;
    top: 0;
  }
  .CommunicationPopupSec .ChatPopBox {
    border-radius: 0;
    bottom: -20px;
    animation: slideupT 0.4s 1;
  }
  @keyframes slideupT {
    0% {
      bottom: -20px;
    }

    100% {
      bottom: 0px;
    }
  }
  .CommunicationPopupSec.Open .ChatPopBox {
    bottom: 0px;
  }
  .PopupSecBody {
    height: calc(100vh - 80px);
    max-height: 100%;
  }
  .PopupContent {
    height: calc(100vh - 190px);
  }
  .ChatViewSec {
    height: calc(100vh - 170px);
    max-height: 100% !important;
  }
}

@media screen and (max-width: 767px) {
  .CommunicationPopupSec {
    bottom: 20px;
  }
}

@media screen and (max-width: 480px) {
  .accordionOn {
    padding: 0px 10px 0 10px;
  }
  .SearchSecMain {
    padding: 20px 10px 10px 10px;
  }
  .CommunicationPopupSec .PopupSecHead {
    padding: 12px 10px 16px 10px;
  }
}
