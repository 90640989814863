.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 20px;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  margin-bottom: 100px !important;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: flex-end;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #56328c;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 !important;
  line-height: unset !important;
  /* height: 26px !important; */
}

.ReferStatusBUNew {
  padding-right: 10px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #ffbb33 !important;
  border-color: #ffbb33 !important;
  color: #000 !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #17dd1b !important;
  border-color: #17dd1b !important;
  color: #fff !important;
}

.ReferStatusBU.Open,
.ReferStatusBU.Open:active,
.ReferStatusBU.Open:checked {
  background: #5026884d !important;
  color: #502688 !important;
  border-color: #5026884d !important;
}

.ReferStatusBU.InProgress,
.ReferStatusBU.InProgress:active,
.ReferStatusBU.InProgress:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.Resolved,
.ReferStatusBU.Resolved:active,
.ReferStatusBU.Resolved:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Cancelled,
.ReferStatusBU.Cancelled:active,
.ReferStatusBU.Cancelled:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.TitleWithTag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: table;
  width: 100%;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 44px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  /* position: relative;
  z-index: 2; */
}

/* .ApplicantFilterSec .formControl {
  margin-top: 10px;
} */

.NotesRow {
  display: flex;
  flex-wrap: wrap;
}

.NotesRow a {
  width: 100%;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 20px 8px 0;
  /* min-width: 350px; */
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -4px;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  width: 25%;
  padding: 8px 15px;
}

.InsideConUnit {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  /*width: 25%;*/
  padding: 8px 15px;
  text-align: center;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 15px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg,
.FilterBu svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.FilterBu {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
}

.FilterBu:hover {
  background: #56328c;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.BodyCon {
  display: flex;
}

.BodyFilterArea {
  min-width: 280px;
  max-width: 280px;
  background: #fff;
  position: fixed;
  right: 0;
  height: 100vh;
  padding: 90px 30px 120px 30px;
  overflow: auto;
  top: 60px;
  box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
  transition: all ease-in-out 0.5s;
  z-index: 10;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
}

.FilterList {
  margin-bottom: 20px;
  font-size: 0.875rem;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 0px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.ApplicantDiv {
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 25%;
}

/*.ApplicantDiv::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
    top: 25px;
  left: 0;
}*/

.ApplicantDivTitle {
  margin: 0;
  font-size: 14px;
  color: #ff8271;
  font-weight: 400;
  width: auto;
  display: inline-block;
  /* background: #fff; */
  padding: 8px 0;
  position: relative;
  /* z-index: 1; */
}

.ApplicantDiv .InsideConUnit:first-child {
  text-align: left;
}

.ApplicantDiv .InsideConUnit:last-child {
  text-align: right;
}

.ApplicantName {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApplicantNameDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
}

.ApplicantDivHalf {
  width: 30%;
}

.ApplicantDivHalf:last-child {
  width: 65%;
}

.ApplicantAction {
  display: flex;
  align-items: center;
  width: 20%;
}

.mainCardDiv {
  width: 100%;
  padding-right: 280px;
  padding-bottom: 50px;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
}

.FilterTitle svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.FilterList {
  margin-bottom: 10px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 70px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.DatePickerCustom {
  width: 100%;
  position: relative;
  /* z-index: 1; */
}

.DatePickerCustom svg {
  position: absolute;
  top: 12px;
  color: #000;
  width: 18px;
  height: 18px;
  right: 12px;
  z-index: -1;
  opacity: 0.8;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 8px 15px 8px 0;
}

span.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 0px 0px 0px 0;
}

.MainReqConUR .MainReqConDe:last-child {
  padding-right: 0px;
}

.MainReqConDivSmall {
  padding: 0 15px;
  width: 37%;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 37%;
}

.MainReqTitle {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 0;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
}

.MainReqConDropBU {
  padding: 0 10px;
}

.InsideConCheck {
  /*width: 25%;*/
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.TagDiv {
  font-size: 13px;
  background: #999;
  color: #fff;
  padding: 4px 8px;
  border-radius: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.14px;
}

.TagDiv.Red {
  background: #ffe8e8;
  color: #e23725;
}

.NoDataMain {
  width: 100%;
  margin: 50px 0;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 45px;
  margin: auto;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 30px 0 !important;
  font-weight: 700;
  color: #e5e5e5;
  line-height: unset;
  text-transform: capitalize;
}

.TextEclips {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 12px;
  border-radius: 5px;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 230px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

.ReffStatus {
  margin: 0 10px 0 0;
  font-size: 14px;
  white-space: nowrap;
  /* display: table-cell; */
  display: inline-block;
  vertical-align: middle;
}

.ReffStatus svg {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.ReffStatus.Low svg {
  transform: rotate(180deg);
}

.ReffStatus.High {
  color: #f44336;
}

.ReffStatus.Medium {
  color: #ff9800;
}

.ReffStatus.Low {
  color: #4caf50;
}

.ReffStatus.Archived {
  background: #87858b;
  color: #fff;
  padding: 2px 8px;
  border-radius: 3px;
  font-size: 12px;
}

.ReqAddCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px #f5f5f5;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  max-height: 100%;
  /* overflow: hidden; */
}

/* .ReqAddCard.Open {
  max-height: 100%;
  margin: 10px 0;
  padding: 20px;
} */

.ReqAddCardTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.CustomSelect > div {
  border-radius: 8px;
  border-color: #e1e1e1;
  min-height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 5px;
}

.ReqAddRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ReqAddFormSec {
  width: 100%;
  max-width: 600px;
}

.FormIMGSec {
  width: CalC(100% - 650px);
}

.FormSec {
  width: 100%;
  padding: 8px 0;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.FormFieldArea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  height: 160px;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 16px;
}

.FormSecRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -8px;
}

.FormHalfSec {
  width: 50%;
  padding: 0 8px;
}

.FormField {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 16px;
}

.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.TimePick {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 0.45rem 16px;
  width: 43%;
  height: 44px;
  position: relative;
  margin: 8px 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 13px;
  right: 7px;
  color: #585858;
  /* z-index: 1; */
}

.TimePick > div {
  position: relative;
  /* z-index: 2; */
  padding-right: 10px;
}

.TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.FileIMGDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 12px;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 0 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 0;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #a069c6;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #a069c6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
}

.UploadLabel div.UpIcon {
  display: block;
  width: 100%;
}

.UpIcon img {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadLabel span p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.ImgFile {
  min-width: 180px;
  width: 180px;
  height: 180px;
  position: relative;
  margin: 0px;
  padding: 10px;
}

.ImgFileUnder {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFile a {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  width: 23px;
  height: 23px;
  text-align: center;
}

.ImgFile a svg {
  width: 15px;
  height: 15px;
  margin-top: -5px;
}

.ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.ButtonDiv {
  padding: 20px 0px 10px 0;
}

.ButtonVoiletSolid {
  padding: 15px 30px;
  background-color: #502688;
  margin: 0px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #884ba7;
  border: 1px solid #884ba7;
}

.ButtonVoiletLine {
  padding: 15px 30px;
  background-color: #fff;
  margin: 0px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

button.TableAddBUSecondary {
  align-items: center;
  padding: 9px 14px;
  background: #fff !important;
  display: flex;
  min-height: 0;
  border: 1px solid #e1e1e1 !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #8482a1 !important;
  margin: 0 !important;
  margin: 0 10px !important;
  border-radius: 8px;
}

button.TableAddBUSecondary:hover,
button.TableAddBUSecondary:active {
  background: #fff !important;
  color: #8482a1 !important;
  border: 1px solid #ced4da !important;
}

button.TableAddBUSecondary:after {
  display: none;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  width: 100%;
  margin-top: 4px;
}

.SearchFilterSec {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  padding-bottom: 6px;
  justify-content: space-between;
}

.SearchCon {
  width: 50%;
  padding: 0 10px;
}

.SearchConSec {
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
}

.SearchConSec input {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  width: 100%;
  border: none;
}

.SearchConSec svg {
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.FilterSec {
  width: 50%;
  display: flex;
}

.FilterCard {
  width: 33%;
  padding: 0 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCardDP {
  width: 100%;
  padding: 10px 0;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterBUCard {
  width: 30%;
  padding: 0 10px;
  max-width: 150px;
}

.FilterCard.Select > div > div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.AcrhivedLabel {
  font-size: 13px;
  background: #50505024;
  color: #505050;
  padding: 4px 8px;
  border-radius: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.14px;
  margin: 0 0 0 8px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bfbfbf;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #bfbfbf;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #bfbfbf;
}

.RemainingText {
  font-size: 13px;
  margin: 5px 0;
}

.TitleRow {
  display: flex;
  flex-wrap: wrap;
}

.TitleNo {
  color: #b7b7b7;
  font-size: 14px;
  font-weight: 400;
  line-height: 120%;
  letter-spacing: 0.14px;
  margin: 0;
  width: 100%;
}

.Width100 {
  width: 100%;
}

.UpIMGSec {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}

@media screen and (min-width: 360px) {
  .ApplicantDiv {
    width: 25%;
  }

  .ApplicantAction {
    width: 20%;
  }

  .mainCardDiv {
    padding-right: 0px;
  }

  .FilterOnOffBU {
    display: block;
  }

  .BodyFilterArea {
    right: -280px;
  }
}

@media screen and (min-width: 360px) {
  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
    padding: 0 12px;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  .TitleWithTag {
    justify-content: space-between;
  }

  .InsideConName {
    width: 100%;
    padding-right: 0;
  }

  .BodyFilterArea.Open {
    right: 0px;
  }

  .TextEclips {
    max-width: 170px;
  }

  .TextEclipsName {
    max-width: 230px;
  }

  span.ConNameTrancate {
    max-width: 100%;
  }

  .mainCard {
    margin: 0 1rem;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }
}

@media screen and (min-width: 480px) {
  .FilterPill span {
    max-width: 100%;
    display: unset;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 576px) {
  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
  }

  .InsideConName {
    /* width: auto; */
    padding-right: 20px;
  }

  .TextEclips {
    max-width: 200px;
  }

  .TextEclipsName {
    max-width: 280px;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  span.ConNameTrancate {
    max-width: 300px;
  }

  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
}

@media screen and (min-width: 768px) {
  .ApplicantDiv {
    width: 35%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 24%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 50%;
    /* justify-content: unset; */
  }

  .TitleWithTag {
    justify-content: unset;
  }

  .TextEclips {
    max-width: 250px;
  }

  .TextEclipsName {
    max-width: 400px;
  }

  span.ConNameTrancate {
    max-width: 400px;
  }
}

@media screen and (min-width: 992px) {
  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConDivBig {
    width: 50%;
  }

  .MainReqConAction {
    width: 50%;
    justify-content: flex-start;
    padding: 0 12px;
  }

  .TextEclips {
    max-width: 120px;
  }

  .TextEclipsName {
    max-width: 140px;
  }
}

@media screen and (min-width: 1100px) {
  .MainReqConDivSmall {
    width: 50%;
  }

  .MainReqConDivBig {
    width: 50%;
  }

  .MainReqConAction {
    width: 50%;
  }

  .TextEclips {
    max-width: 170px;
  }

  .TextEclipsName {
    max-width: 200px;
  }

  span.ConNameTrancate {
    max-width: 500px;
  }
}

@media screen and (min-width: 1200px) {
  .ApplicantDiv {
    width: 50%;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .FilterOnOffBU {
    display: none;
  }

  .SearchCon {
    width: 25%;
  }

  .FilterSec {
    width: 75%;
  }
}

@media screen and (min-width: 1280px) {
  .MainReqConDivSmall {
    width: 42%;
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConAction {
    width: 23%;
    justify-content: flex-end;
    padding: 0 0px;
  }

  .TextEclips {
    max-width: 160px;
  }

  .TextEclipsName {
    max-width: 130px;
  }

  span.ConNameTrancate {
    max-width: 600px;
  }
}

@media screen and (min-width: 1300px) {
  .ApplicantDiv {
    width: 35%;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }
}

@media screen and (min-width: 1500px) {
  .ApplicantDiv {
    width: 40%;
  }

  .ApplicantDivHalf {
    width: 30%;
  }

  .ApplicantDivHalf:last-child {
    width: 65%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 42%;
  }

  .MainReqConDivBig {
    width: 36%;
  }

  .MainReqConAction {
    width: 21%;
    padding-right: 15px;
  }

  .BodyFilterArea {
    right: 0px;
  }

  /* .mainCardDiv {
    padding-right: 280px;
  } */

  .FilterBu {
    display: none;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .MainReqConDropBU {
    padding: 0 0px;
  }

  .FilterTitle svg {
    display: none;
  }

  .TextEclips {
    max-width: 140px;
  }

  .TextEclipsName {
    max-width: 130px;
  }

  .SearchCon {
    width: 30%;
  }

  .FilterSec {
    width: 70%;
  }
}

@media screen and (min-width: 1600px) {
  .ApplicantDiv {
    width: 35%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 43%;
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConAction {
    width: 22%;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .MainReqConDropBU {
    padding: 0 10px;
  }

  .TextEclips {
    max-width: 200px;
  }

  .TextEclipsName {
    max-width: 140px;
  }
}

@media screen and (min-width: 1800px) {
  .ApplicantDiv {
    width: 30%;
  }

  .ApplicantAction {
    width: 15%;
    flex-wrap: wrap;
    justify-content: flex-end;
  }

  .MainReqConDivSmall {
    width: 42%;
  }

  .MainReqConDivBig {
    width: 38%;
  }

  .MainReqConAction {
    width: 20%;
  }

  .TextEclips {
    max-width: 250px;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 150px;
  }

  .SearchCon {
    width: 40%;
  }

  .FilterSec {
    width: 60%;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media (min-height: 600px) {
  .BodyFilterArea {
    height: 90vh;
  }
}

@media (min-height: 650px) {
  .BodyFilterArea {
    height: 90.9vh;
  }
}

@media (min-height: 700px) {
  .BodyFilterArea {
    height: 91.6vh;
  }
}

@media (min-height: 750px) {
  .BodyFilterArea {
    height: 92vh;
  }
}

@media (min-height: 800px) {
  .BodyFilterArea {
    height: 92.6vh;
  }
}

@media (min-height: 850px) {
  .BodyFilterArea {
    height: 93vh;
  }
}

@media (min-height: 900px) {
  .BodyFilterArea {
    height: 94vh;
  }
}

@media (min-height: 1050px) {
  .BodyFilterArea {
    height: 95vh;
  }
}
