.Title {
  text-transform: uppercase;
}

.hide {
  display: none;
}

div#root {
  background: #f00;
}

.Header {
  width: 100%;
  display: flex;
  z-index: 1100;
  box-sizing: border-box;
  flex-shrink: 0;
  flex-direction: column;
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  background: #FFF;
  padding: 10px 0;
  box-shadow: 0 3px 10px rgb(62 85 120 / 7%);
  min-height: 64px;
}

.HeaderWrap {
  width: 100%;
  height: auto;
  padding: 15px 0;
  display: flex;
  justify-content: center;
  background: transparent;
}

.Logo {
  max-width: 220px;
  max-height: 60px;
}

.MainBody {
  max-width: 1140px;
  width: 100%;
  background: #fff;
  border-radius: 0;
  box-shadow: none;
  margin: -100px auto 0 auto;
  padding: 5rem 1.5rem 16rem 1.5rem;
  position: relative;
}

.PaymentThanksMain {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.PaymentThankIcon {
  width: 64px;
  height: 64px;
}

.PaymentThanksUnder {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.PaymentStatus {
  font-family: 'Inter', sans-serif;
  font-size: 20px;
  font-weight: 700;
  line-height: 24px;
  color: #333333;
  margin: 20px 0 20px 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 100%;
}

.PaymentAmmount {
  font-family: 'Inter', sans-serif;
  font-size: 48px;
  font-weight: 700;
  line-height: 59px;
  color: #333;
  text-align: center;
  margin: 0 0 10px 0;
  width: 100%;
}

.PaymentThanksCon {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #333333;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  text-align: center;
  width: 82%;
}

.PaymentThanksUnder svg {
  width: 100px;
  height: auto;
  margin-bottom: 30px;
}