.MuiPickersToolbar-toolbar,
.MuiPickersClock-pin,
.MuiPickersClockPointer-pointer,
.MuiPickersClockPointer-noPoint {
    background-color: #2A5BEE;
}

.MuiPickersDay-daySelected {
    background-color: #2A5BEE;
}

.MuiPickersToolbar-toolbar.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding {
    background-color: #2A5BEE;
    padding-left: 20px;
}

.MuiPickersToolbar-toolbar.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding h2 {
    font-size: 3.75rem;
    font-weight: 300;
}

.MuiPickersToolbar-toolbar.MuiPickersTimePickerToolbar-toolbarAmpmLeftPadding button:after {
    display: none;
}

.MuiPickersToolbar-toolbar button:first-child {
    position: relative;
}

.MuiPickersToolbar-toolbar button:first-child::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: transparent;
    right: -17px;
    top: 6px;
    border: 1px solid #cbcbcb;
    border-top: 0;
    border-right: 0;
    transform: rotate(-45deg);
}

.MuiPickersClockPointer-thumb {
    border: 14px solid #2A5BEE;
}

.MuiButton-textPrimary {
    color: #2A5BEE;
}

.e-grid .e-rowcell,
.e-grid .e-headercell,
.e-grid .e-detailheadercell {
    padding: 10px 10px !important;
}

.MuiPickersBasePicker-container {
    flex-direction: column !important;
}

.MuiPickersDatePickerRoot-toolbar {
    max-width: 100% !important;
}