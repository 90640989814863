.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 20px !important;
  /* z-index: 2; */
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.RentBillCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 15px 0;
}

.DateDiv {
  padding: 9px 0;
}

.DateInfo {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  width: 100%;
}

.DateInfo span {
  color: #343434;
  padding-right: 6px;
}

.DateInfo2 {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}

.StatusIcon {
  width: 16px;
  opacity: 0.8;
  margin-right: 12px;
  height: auto;
  margin-top: 2px;
}

/* .DateInfo2 span {
  color: #FFBB33;
} */

.BalanceFlexDiv {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 9px 15px 9px 0;
}

.BalanceFlexDiv svg {
  width: 18px;
  height: 18px;
  color: #666666;
}

.HideContent {
  position: relative;
  padding-top: 15px;
}

.HideInfo {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  padding: 8px 0;
  white-space: normal;
}

.HideInfo span {
  font-weight: 500;
  color: #333;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 12px 18px;
  background: #ff8271;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  font-size: 14px;
  margin: 0 6px;
  text-align: center;
  display: flex;
  align-items: center;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e16e5f;
  color: #fff;
}

.AddNoteBu svg,
.FilterBu svg {
  width: 20px;
  height: 20px;
}

.FilterBu {
  align-items: center;
  padding: 12px 18px;
  background: #502688;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 6px;
  transition: all 0.3s;
  outline: none;
  border: none;
  font-size: 14px;
  margin: 0 6px;
  text-align: center;
  display: flex;
  align-items: center;
}

.FilterBu:hover {
  background: #230058;
  color: #fff;
}

.ButtonArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 0px;
  padding: 0px 0px 15px 0px;
}

.ButtonAreaUnder {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.MarLeft {
  margin-left: auto;
  cursor: pointer;
}

.Failed {
  padding: 6px 13px 6px 13px !important;
  background: #eb575766 !important;
  border: 0 solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #a63434 !important;
  margin: 0 0 0 10px;
}

.Completed {
  padding: 6px 13px 6px 13px !important;
  background: #00808066 !important;
  border: 0 solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #006060 !important;
  margin: 0 0 0 10px;
}

.Pending {
  padding: 6px 13px 6px 13px !important;
  background: #f8d52466 !important;
  border: 0 solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #71621b !important;
  margin: 0 0 0 10px;
}

.ButtonDiv {
  width: 100%;
  padding-top: 25px;
  display: flex;
  align-items: center;
}

.ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-right: 12px !important;
  text-transform: capitalize !important;
  transition: all ease-in-out 0.3s;
}

.ButtonVoiletSolid:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.ButtonDelete {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-right: 12px !important;
  text-transform: capitalize !important;
  transition: all ease-in-out 0.3s;
}

.ButtonDelete:hover {
  background: #f2f0f2 !important;
  color: #343434 !important;
  border: 1px solid #e1e1e1 !important;
}

.ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #502688 !important;
  background: #fff !important;
  border: 1px solid #502688 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  transition: all ease-in-out 0.3s;
}

.ButtonVoiletLine:hover {
  background: #502688 !important;
  color: #fff !important;
  border: 1px solid #502688 !important;
}

.BackArrow {
  color: #505055 !important;
  background: #fff;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-right: 16px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #333 !important;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 20px;
  height: 20px;
}

.DueOnBadge {
  margin: 0;
  font-size: 14px;
  color: #000 !important;
  font-weight: 500;
  margin-bottom: 3px;
}

.BadgeDate {
  margin: 0;
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
}

.CreditedBadge {
  color: #2fa42b !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.PendingBadge {
  color: #ffbc00 !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.FailedBadge {
  color: #f44336 !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.FilterSearchSec {
  width: calc(100% - 700px);
  padding-right: 0;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border: 1px solid #e1e1e1;
  margin-bottom: 0;
  margin-top: 0;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 11px 12px;
  margin-right: 15px;
  background-color: #fff;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: relative;
  left: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 20px;
  margin-right: 10px;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBUIcon {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #6d6d6d !important;
  background-color: transparent !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 15px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
  padding: 11px 20px 11px 15px !important;
}

.ActionsBULine {
  width: 200px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBU:hover,
.btn-check:active+.btn-primary.ActionsBU,
.btn-check:checked+.btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBUIcon:hover,
.btn-check:active+.btn-primary.ActionsBUIcon,
.btn-check:checked+.btn-primary.ActionsBUIcon,
.ActionsBUIcon.btn-primary.active,
.ActionsBUIcon.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBUIcon {
  background-color: transparent;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active+.btn-primary.ActionsBU.Red,
.btn-check:checked+.btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBUIcon svg {
  min-width: 16px;
  min-height: 16px;
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  margin-left: 0;
}

.ActionsBU.Red svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 0;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none !important;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  padding: 10px 10px !important;
  min-width: 80px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus,
.ActionsBUListItem:active {
  color: #343434 !important;
  background-color: #f8f8f8 !important;
}

.ActionsBUListItem:last-child {
  border: none;
}

.ActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.ActionsBUListItem img {
  width: 17px;
  height: auto;
  margin-right: 12px;
}

.ActionsBUListItem.Delete svg {
  color: #f44336;
}

.CheckFilter {
  display: flex;
  flex-wrap: wrap;
}

.CheckFilter label {
  width: 100%;
  margin: 0;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.AddSection {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px #f5f5f5;
  border-radius: 8px;
  margin: 25px 0 0 0;
  padding: 0px 0px;
  max-height: 100%;
}

.AddLayoutForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SMSmallSideBody {
  width: 100%;
}

.AddUserMain {
  width: 100%;
  padding: 0px;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  margin: 0 -12px;
}

.FormGroup {
  width: 50% !important;
  padding: 14px 12px !important;
  font-size: 0.875rem;
  position: relative !important;
}

.FormGroupFull {
  width: 100% !important;
  padding: 14px 12px !important;
  font-size: 0.875rem;
  position: relative !important;
}

.DetailsDiv {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 5px 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.DetailsDiv::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #ffbb33;
  left: 0;
  top: 0;
}

.DetailsDivHalf {
  width: 50%;
  padding: 5px 15px;
}

.DetailsTitle {
  font-size: 14px;
  color: #666;
  line-height: 21px;
  margin: 0;
  font-weight: 600;
}

.DetailsDes {
  font-size: 14px;
  color: #666;
  line-height: 21px;
  margin: 0;
}

.DetailsDes span {
  font-weight: 600;
}

.Example {
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 5px 0;
}

.BreadcrumbHeadSec {
  width: 100%;
  padding: 0 0px 25px 0px;
}

.PageTitleSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0 0;
  position: relative;
}

.PageTitleSec>div {
  display: flex;
  align-items: center;
}

.SMProCusRow {
  margin: 35px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* float: left; */
  width: -webkit-fill-available;
  width: -moz-available;
}

.RentRollDetailsSec {
  width: 100%;
}

.NotesCard {
  width: 100%;
  /* border-radius: 6px; */
  padding: 15px 25px;
  /* margin: 10px 0; */
  position: relative;
  /* box-shadow: 0 0 20px 0 #f5f5f5; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.NotesCard a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .NotesCard:hover {
  box-shadow: 0 0 20px 0 #f2f2f2;
} */

.NotesCard:hover {
  cursor: pointer;
}

.AlertCardFirstSec {
  width: 100%;
  display: flex;
  align-items: center;
}

.AccoTitleHead {
  /* width: calc(100% - 126px); */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.AlertTitle {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 150%;
  margin: 0;
  width: 60%;
}

.AlertText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  width: 30%;
}

.AlertText span {
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  position: relative;
  display: inline-block;
  margin-right: 10px !important;
}

.AlertText span:last-child {
  margin: 0;
  padding: 0;
}

.AlertText span:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.AlertText span:last-child:after {
  display: none;
}

.AccoMainSec {
  border-top: 1px solid #eeeeee;
  margin: -6px 0 0 0;
  padding: 12px 0 0 15px;
}

.AccoMainSec ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.AccoMainSec ul li {
  list-style: none;
  padding: 6px 12px;
  width: 33.33%;
}

.AccoMainSec ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.AccoMainSec ul li p span {
  color: #343434;
  font-weight: 500;
}

.CussAccoRow {
  width: 100%;
  padding: 8px 20px 8px 25px;
}

.CardStatusSec {
  width: 30%;
  max-width: 210px;
  min-width: 210px;
}

.CardDesSec {
  width: 25%;
}

.CardAmountSec {
  justify-content: space-between;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.CardAmountSec>div {
  width: 33.33%;
}

.AccoMainSec ul li.DetailsBUSec {
  width: 100%;
  padding: 10px 12px 15px 12px;
}

.RentRollListSec {
  width: 100%;
}

.ActionsBU span.Small {
  display: none;
}

.AccoMainSec ul li.WidthFull {
  width: 100%;
}

.NoDataMain {
  width: calc(100% - 0px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  /* text-transform: capitalize; */
  width: 100%;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

@media screen and (min-width: 360px) {
  .mainCard {
    margin: 0 0rem;
  }

  /* .AddNoteBu,
  .FilterBu {
    width: 100%;
    padding: 12px 18px;
    margin: 6px 6px;
  } */

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .DetailsDivHalf {
    width: 100%;
  }

  .BreadcrumbHeadSec {
    padding: 0 15px 25px 15px;
  }

  .NotesCard {
    padding: 20px 20px;
  }

  .AlertTitle {
    width: 100%;
    font-size: 14px;
  }

  .AlertText {
    width: 100%;
  }

  .ButtonArea {
    padding: 0px 15px 15px 15px;
  }

  .RentRollListSec {
    padding: 0 15px;
  }
}

@media screen and (min-width: 480px) {
  /* .AddNoteBu,
  .FilterBu {
    width: auto;
    padding: 7px 18px;
    margin: 0px 6px;
  } */
}

@media screen and (min-width: 576px) {
  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }

  .DetailsDivHalf {
    width: 50%;
  }

  .BreadcrumbHeadSec {
    padding: 0 0px 25px 0px;
  }

  .AlertTitle {
    font-size: 16px;
  }

  .ButtonArea {
    padding: 0px 0px 15px 0px;
  }

  .RentRollListSec {
    padding: 0 0px;
  }

  .FilterSearchSec {
    width: calc(100% - 150px);
  }
}

@media screen and (min-width: 768px) {
  .AlertTitle {
    width: 70%;
  }

  .AlertText {
    width: 25%;
  }
}

@media screen and (min-width: 992px) {
  .AlertTitle {
    width: 60%;
  }

  .AlertText {
    width: 30%;
  }

  .CardDesSec {
    display: none;
  }

  .CardAmountSec {
    width: 70%;
  }
}

@media screen and (min-width: 1200px) {
  .CardDesSec {
    display: block;
  }

  .CardAmountSec {
    width: 50%;
  }

  .FilterSearchSec {
    width: calc(100% - 300px);
  }

  .AccoMainSec ul li {
    width: 33.33%;
  }

  .CardDesList {
    display: none;
  }
}

@media screen and (min-width: 1600px) {
  .NotesCard {
    padding: 25px 25px;
  }

  .FilterSearchSec {
    width: calc(100% - 700px);
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media (max-width: 991px) {
  .CardDesSec {
    display: none;
  }

  .CardAmountSec>div {
    width: 100%;
    padding: 1.5px 0;
  }

  .CardAmountSec>div:nth-child(2) {
    display: none;
  }

  .AccoMainSec ul li {
    width: 50%;
  }
}

@media (max-width: 767px) {
  .BackAddBU {
    display: flex !important;
  }

  .BreadcrumbHeadSec nav {
    display: none;
  }

  .PageTitleSec {
    padding: 25px 0 0 0;
  }

  .SMProCusRow {
    margin: 0px 0px 0px 0px;
  }

  .AccoMainSec ul li {
    width: 100%;
  }
  .ActionsBULine {
    width: auto;
    height: 44px;
    color: #fff !important;
    background-color: #502688 !important;
    padding: 11px 15px 11px 15px !important;
    border: 1px solid #502688 !important;
  }

  .ActionsBULine svg {
    min-width: 16px;
    min-height: 16px;
    width: 18px;
    height: 18px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .AddBuText {
    display: none;
  }

  .ActionsBU.Red {
    padding: 11px 14px 11px 14px !important;
  }

  .ActionsBU svg {
    min-width: 16px;
    min-height: 16px;
    width: 22px;
    height: 22px;
    margin: 0px !important;
  }
}

@media (max-width: 575px) {
  .FilterSearchSec {
    width: calc(100% - 58px);
  }

  .ActionsBULine {
    padding: 11px 8px 11px 12px !important;
  }
}

@media (max-width: 479px) {
  .CardAmountSec {
    width: 100%;
    padding: 2px 0 0 28px;
  }

  .AccoMainSec ul li {
    padding: 6px 0px;
  }

  .AccoMainSec {
    padding: 12px 0 0 0px;
  }

  .AccoMainSec ul li.DetailsBUSec {
    padding: 10px 0px 15px 0px;
  }

  .CussAccoRow {
    padding: 8px 14px 8px 14px;
  }
}