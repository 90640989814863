.appLogo {
  padding-left: 30px;
  width: 220px;
}

.TopHeader {
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07) !important;
  z-index: 999 !important;
  background-color: #fff !important;
}

.toolbarPadding {
  padding: 11px 20px 11px 0;  
}

.customMarginRight {
  margin-right: 10px;
}

.gvRpcf .MuiBadge-badge,
.MuiBadge-badge {
  background: #ff8271 !important;
  color: #fff;
}

.ejBpGU,
.mwSjl {
  background: #ff8271 !important;
}

.customHeaderIcon svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
  color: #a5a5a5;
}

.MobileHeader {
  box-shadow: 0 -3px 10px rgba(62, 85, 120, 0.07) !important;
  z-index: 99 !important;
  background-color: #fff !important;
  flex-direction: unset !important;
}

.MobileMenuLink {
  width: 20%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  padding: 15px 5px 12px 5px;
}

.MobileMenuLink svg {
  width: 22px;
  height: auto;
  color: #757575;
  margin-bottom: 5px;
  stroke-width: 1.5px;
}

.MobileMenuLink span {
  width: 100%;
  text-align: center;
  font-size: 11px;
  color: #757575;
  font-weight: 400;
}

@media (min-width: 360px) {
  .appLogo {
    padding-left: 5px;
    width: 160px;
  }
}

@media (min-width: 480px) {
  .appLogo {
    padding-left: 5px;
    width: 190px;
  }
}

/************************** Notification CSS *******************************/

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px;
  height: 81%;
  overflow: auto;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 0 0 15px 0;
  background: #fff;
  /*overflow: hidden;*/
}

.NotesCard.Unread {
  background: #e5e8eb;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
  position: relative;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 600px;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDivBig img {
  width: 35px;
  height: 35px;
  margin-right: 15px;
}

.InsideConName {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 6px 0px 8px 0;
  width: 100%;
}

.MainReqConDe {
  font-size: 13px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0px 0px 5px 0;
  text-align: left;
  color: #666;
  width: 100%;
}

.MainReqConDeName {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 0px 0px 5px 0;
  text-align: left;
  color: #000;
  width: 100%;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  width: 50px;
  padding: 0 15px;
  justify-content: flex-end;
}

.NotiCon {
  width: CalC(100% - 100px);
}

.DateTS {
  font-size: 12px;
  color: #666;
  margin: 0;
}

.MainReqConAction svg {
  width: 18px;
  height: 18px;
  margin-bottom: -8px;
  color: #666;
}

.NotiMainSlides {
  width: 750px;
  background: #fff;
  padding: 15px;
  position: fixed;
  height: 100%;
  z-index: 99999;
  right: 0;
}

.NotiMain {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 99999;
  left: 0;
  display: none;
}

.NotiMain:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.NotiHeader p {
  margin: 0;
  color: #505050;
  font-size: 18px;
  font-weight: 700;
}

.NotiHeader {
  display: flex;
  margin-bottom: 25px;
}

.NotiHeader svg {
  width: 22px;
  height: 22px;
  margin-right: 15px;
  color: #666;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #56328c;
  color: #fff;
}

.AddNoteBu svg,
.FilterBu svg {
  width: 20px;
  height: 20px;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
  border-radius: 5px;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

.TableAddBUSecondary button,
button.TableAddBUSecondary {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
  margin: 0 0 0 15px !important;
}

.TableAddBUSecondary button:hover,
button.TableAddBUSecondary:hover {
  background: #56328c;
}

button.TableAddBUSecondary:focus,
button.TableAddBUSecondary:active {
  color: #fff !important;
  background-color: #56328c !important;
  border-color: transparent !important;
  box-shadow: none !important;
}

button.TableAddBUSecondary:after {
  display: none;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ApplicantFilterSec .formControl {
  margin-top: 10px;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  border: none;
  background: transparent;
}

.ViewAllBU,
.ViewAllBU:hover {
  color: #ff8271;
  font-size: 16px;
  font-weight: 700;
  margin-left: auto;
  margin-top: 0px;
  position: relative;
}

.ViewAllBU:after {
  content: "";
  position: absolute;
  width: 0px;
  height: 3px;
  background: #ff8271;
  bottom: 4px;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all 0.3s ease-in-out;
}

.ViewAllBU:hover:after {
  width: 100%;
}

.CustomSelect {
  width: 100%;
  font-size: 0.875rem;
  margin-top: 10px;
}

.NoDataMain {
  width: 100%;
  margin: 50px 0;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 45px;
  margin: auto;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 30px 0 !important;
  font-weight: 700;
  color: #e5e5e5;
  line-height: unset;
  text-transform: capitalize;
}

.RaedBU,
.RaedBU:hover {
  font-size: 14px;
  margin-top: 15px;
  background: #230058;
  width: 100%;
  color: #fff;
  padding: 8px;
  text-align: center;
  border-radius: 8px;
}

.RaedBUSub,
.RaedBUSub:hover {
  font-size: 12px;
  margin-top: 0px;
  background: #ededed;
  width: 110px;
  color: #adadad;
  padding: 8px 15px;
  text-align: center;
  border-radius: 5px;
  margin-left: 10px;
}

.RaedBUSub.Active,
.RaedBUSub.Active:hover {
  font-size: 12px;
  margin-top: 0px;
  background: #230058;
  width: 110px;
  color: #fff;
  padding: 8px 15px;
  text-align: center;
  border-radius: 5px;
  margin-left: 10px;
}

.CheckSubArea {
  padding-top: 15px;
  padding-left: 26px;
}

.NotiCheck {
  display: block;
  margin: -2px 10px 0 -11px;
}

@media (min-width: 360px) {
  .NotiMainSlides {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 250px;
  }
}

@media (min-width: 480px) {
  .MainReqConDivBig {
    width: 350px;
  }
}

@media (min-width: 576px) {
  .MainReqConDivBig {
    width: 450px;
  }
}

@media (min-width: 768px) {
  .NotiMainSlides {
    width: 750px;
  }

  .MainReqConDivBig {
    width: 600px;
  }
}

@media (min-height: 600px) {
  .NotesRow {
    height: 66%;
  }
}

@media (min-height: 650px) {
  .NotesRow {
    height: 67%;
  }
}

@media (min-height: 700px) {
  .NotesRow {
    height: 69%;
  }
}

@media (min-height: 750px) {
  .NotesRow {
    height: 71%;
  }
}

@media (min-height: 800px) {
  .NotesRow {
    height: 73%;
  }
}

@media (min-height: 850px) {
  .NotesRow {
    height: 74%;
  }
}

@media (min-height: 900px) {
  .NotesRow {
    height: 76%;
  }
}

@media (min-height: 950px) {
  .NotesRow {
    height: 77%;
  }
}

/************************** Notification CSS *******************************/

@media (min-width: 960px) {
  .MobileHeader {
    display: none !important;
  }
}
