.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 20px;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  margin-bottom: 100px !important;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.SMProCusRow {
  margin: 0 -15px;
  display: flex;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0 15px;
  display: table;
  flex-wrap: wrap;
}

.SMProCusBigDiv {
  width: 100%;
  padding: 0 15px;
}

.card {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
}

.card .cardbody {
  padding: 1.5rem;
}

.SMProCardHead {
  text-align: center;
  padding: 25px 15px;
  position: relative;
}

.SMProCardHead .avatar {
  margin-bottom: 15px;
}

.SMProCardHead h5 {
  font-size: 17px;
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.SMProPicMain {
  position: relative;
  width: 89px;
  margin: 0px auto;
}

.SMProPicMain a {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
}

.SMProPicMain a svg {
  width: 17px;
  stroke-width: 2;
}

.avatar {
  display: inline-block;
  margin-bottom: 0;
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  border: 2px solid white;
  /*margin: 0 0 1rem;*/
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.SMProCardHead .avatar {
  margin-bottom: 15px;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar > a > img,
.header form .input-group .input-group-append .avatar > button.btn > img,
.chat-block
  .chat-sidebar
  .chat-sidebar-content
  .list-group
  .avatar
  > .list-group-item.active
  > img,
.avatar > img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
  /*font-size: 19px;*/
}

.roundedcircle {
  border-radius: 50% !important;
}

.SMProCardDe {
  text-align: center;
}

.SMProCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 0 20px 15px 20px;
}

.SMProCardDe .MuiButton-outlinedPrimary {
  margin: 20px 10px;
}

.SMProCardDe .MuiButton-outlinedPrimary svg {
  margin-right: 8px;
}

ul:not(.list-unstyled) {
  margin: 0;
  padding: 0;
}

ul:not(.list-unstyled) li {
  list-style-type: none;
}

.SMProDETag {
  color: #b2b5cb;
  margin-right: 15px;
}

.SMProDE {
  margin-left: AUTO;
  text-align: right;
  color: #9f9f9f;
}

.SMBigSideHead {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  height: 36px;
}

.SMBigSideHead h5 {
  margin-bottom: 0;
  padding-top: 6px;
  font-size: 17px;
  font-weight: 500;
  line-height: 1.2;
  margin-top: 0;
}

.SMBigSideBody {
  position: relative;
}

.EditBU {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
}

.EditBU svg {
  width: 17px;
  stroke-width: 2;
}

.AddUserMain {
  width: 600px;
  padding: 15px;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin: 0 -15px; */
}

.FormGroup {
  width: 100%;
  padding: 0 0px 15px 0px;
  font-size: 0.875rem;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ButtonDiv {
  padding: 0 0px;
}

.formControlTextArea {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 100px;
  padding: 0.375rem 0.75rem;
  resize: none;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ButtonVoiletSolid {
  padding: 12px 30px;
  background-color: #502688;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolid:hover {
  background-color: #230058;
  border: 1px solid #230058;
}

.ButtonVoiletLine {
  padding: 12px 30px;
  background-color: #fff;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 0.5rem;
  color: #502688;
  border: 1px solid #502688;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletLine:hover {
  background-color: #502688;
  color: #fff;
}

.SmallDiv {
  width: 35%;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.BigDiv {
  width: 55%;
}

.FormRowCus {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 -8px;
}

.FormGroupCusS {
  padding: 0 8px 15px 8px;
  width: 40%;
  font-size: 0.875rem;
}

.FormGroupCusB {
  padding: 0 8px 15px 8px;
  width: 60%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: baseline;
}

.FormGroupCus {
  width: 100%;
  padding: 0 0px 0px 0px;
}

.TimePick {
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
  width: 43%;
  height: 38px;
  position: relative;
}

.TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 10px;
  right: 7px;
  color: #585858;
  /* z-index: 1; */
}

.TimePick > div {
  position: relative;
  /* z-index: 2; */
  padding-right: 18px;
}

.FileUpDuBUDiv {
  width: 200px;
  height: 140px;
  margin: 15px;
}

.FileUpDuBUDiv input {
  display: none;
}

.UploadLabel {
  padding: 5px 15px 5px 20px;
  background-color: transparent;
  font-size: 14px;
  width: 100%;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  -webkit-align-items: center;
  align-items: center;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #ff8271;
  text-align: center;
  vertical-align: middle;
  border: 1px dashed #ff82717a;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
}

.UploadLabel div.UpIcon {
  display: block;
}

.UploadText {
  margin: 0 0 5px 0;
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  font-size: 10px;
}

.FileIMGDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px 15px -15px;
}

.ImgFile {
  width: 200px;
  height: 140px;
  position: relative;
  margin: 15px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFile a {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  width: 23px;
  height: 23px;
  text-align: center;
}

.ImgFile a svg {
  width: 15px;
  height: 15px;
  margin-top: -5px;
}

.ImgFile button {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: -5px;
  right: -5px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0px;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  width: 100%;
  margin-top: 4px;
}

.FormGroup p {
  font-size: 12px;
  margin-bottom: 0px;
}

.FormGroupCusB p {
  font-size: 12px;
  margin-bottom: 0px;
}

@media screen and (min-width: 360px) {
  .SMProCusRow {
    flex-wrap: wrap;
  }

  .SMProCusSmallDiv {
    width: 100%;
  }

  .ButtonDiv {
    padding: 0 0px;
    width: 100%;
  }

  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: 100%;
    display: inline-block;
  }

  .AddUserMain {
    width: 100%;
  }

  .SmallDiv {
    width: 100%;
  }

  .BigDiv {
    width: 100%;
  }

  .FormGroupCusS {
    width: 100%;
  }

  .FormGroupCusB {
    width: 100%;
  }

  .mainCard {
    margin: 0 1rem;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }
}

@media screen and (min-width: 480px) {
  .ButtonDiv {
    padding: 0 0px;
  }

  .ButtonVoiletSolid,
  .ButtonVoiletLine {
    width: auto;
  }
}

@media screen and (min-width: 576px) {
  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
}

@media screen and (min-width: 768px) {
  .SmallDiv {
    width: 35%;
  }

  .BigDiv {
    width: 60%;
  }

  .FormGroupCusS {
    width: 35%;
  }

  .FormGroupCusB {
    width: 65%;
  }
}

@media screen and (min-width: 992px) {
  .SmallDiv {
    width: 30%;
  }

  .BigDiv {
    width: 65%;
  }
}

@media screen and (min-width: 1100px) {
  .SMProCusRow {
    flex-wrap: nowrap;
  }

  .SMProCusSmallDiv {
    width: 380px;
  }

  .SmallDiv {
    width: 35%;
  }

  .BigDiv {
    width: 60%;
  }

  .FormGroupCusS {
    width: 40%;
  }

  .FormGroupCusB {
    width: 60%;
  }
}

@media screen and (min-width: 1200px) {
}

@media screen and (min-width: 1280px) {
  .AddUserMain {
    width: 850px;
  }

  .BigDiv {
    width: 55%;
  }
}

@media screen and (min-width: 1300px) {
  .SMProCusSmallDiv {
    width: 330px;
  }
}

@media screen and (min-width: 1400px) {
  .SMProCusSmallDiv {
    width: 400px;
  }
}

@media screen and (min-width: 1500px) {
  .SMProCusSmallDiv {
    width: 420px;
  }
}

@media screen and (min-width: 1600px) {
  .SMProCusSmallDiv {
    width: 450px;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}
