.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 60px !important;
  /* z-index: 2; */
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.AddCardMain {
  width: 600px;
  padding: 20px;
}

.DetailsCardMainInside {
  width: 100%;
  /*border: 1px solid #dee2e6;*/
  border-radius: 12px;
  /*padding: 20px 25px;*/
}

.FullFormGroup {
  width: 300px;
  padding-bottom: 15px;
}

.ModalFormGroup {
  width: 100%;
  padding-bottom: 0px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.MultiFormGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FormGroupBig {
  width: 190px;
  padding-bottom: 15px;
}

.FormGroupSmall {
  width: 110px;
  padding-bottom: 15px;
}

.AddUnitHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.AddUnitHead h5 {
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: #343434;
  display: flex;
  align-items: center;
}

.AddUnitBu {
  align-items: center;
  padding: 9px 14px;
  background: rgb(255, 130, 113);
  display: flex;
  min-height: 0px;
  color: rgb(255, 255, 255);
  border-radius: 5px;
  transition: all 0.3s ease-in-out 0s;
  outline: none;
  border: none;
  height: 38px;
  margin-left: 15px;
}

.AddUnitBu:hover {
  background: rgb(233, 117, 101);
}

.AddUnitBu svg {
  width: 20px;
  height: 20px;
}

.UnitsTableMain {
  border: 1px solid #dee2e6;
  border-radius: 12px;
  margin: 15px 0;
  overflow: hidden;
}

.UnitsTable {
  overflow: auto;
  height: 243px;
}

.UnitsTable table {
  width: 100%;
}

.UnitsTable table tr {
  display: flex;
  flex-wrap: wrap;
}

.UnitsTable table tr:nth-child(odd) td {
  background: #fbf7fc;
}

.UnitsTable table td {
  display: flex;
  justify-content: space-between;
  padding: 18px 25px;
  width: 50%;
  border-bottom: 1px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.UnitsTable table td:last-child {
  border-right: 0px solid #dee2e6;
}

.UnitsTable table tr:last-child td {
  border-bottom: 0px solid #dee2e6;
  border-right: 1px solid #dee2e6;
}

.UnitsTable table tr:last-child td:last-child {
  border-bottom: 0px solid #dee2e6;
  border-right: 0px solid #dee2e6;
}

.UnitsTable table td p {
  font-size: 14px;
  font-weight: 600;
  color: #666666;
  margin: 0;
  display: flex;
  align-items: center;
}

.UnitsTable table td a {
  color: #333;
}

.UnitsTable table td a svg {
  width: 20px;
  height: 20px;
}

.ButtonDiv {
  padding: 0 20px;
}

.ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #fff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: .5rem !important;
  width: 48% !important;
  margin: 0 !important;
  border-bottom-right-radius: .5rem !important;
  transition: all .3s ease-in-out !important;
}

.ButtonVoiletSolid:hover {
  background: #e16e5f;
}

.ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: .5rem !important;
  width: 48% !important;
  margin: 0 !important;
  border-bottom-left-radius: .5rem !important;
  transition: all .3s ease-in-out !important;
}

.ButtonVoiletLine:hover {
  background: #f1ecf3;
}

.ModalBUCan {
  color: #ff8271;
}

.ModalBUCan:hover {
  background-color: rgb(255 130 113 / 4%);
}

.ModalBUSub {
  color: #230058;
}

.ModalBUSub:hover {
  background-color: rgb(35 0 88 / 4%);
}

.DetailsDTextFull {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

.DetailsDTextFull span {
  color: #b2b5cb;
  min-width: 70px;
  display: inline-block;
  font-weight: 500;
}

.DetailsDTextGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

/* .DeTable {
  padding: 15px;
} */

.SMProCusRow {
  margin: 35px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  /* float: left; */
  width: -webkit-fill-available;
  width: -moz-available;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0;
}

.card {
  width: 100%;
  height: 100%;
  /* border-radius: 0.5rem; */
  /* margin-bottom: 1.875rem; */
  position: relative;
  background: white;
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
  /* overflow: hidden; */
  z-index: 3;
}

.LoaderCard {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.card .cardbody {
  padding: 1.5rem;
}

.SMProCardHead {
  text-align: left;
  padding: 25px 15px 15px 15px;
  position: relative;
}

.SMProCardHead .avatar {
  margin-bottom: 25px;
  position: absolute;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  background-color: #fbf7fc;
  transition: all 0.2s ease-in-out;
}

.SMProCardHead .SMProPicMainDubble:hover .avatar {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.SMProCardHead .avatar.active {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  z-index: 3 !important;
}

.SMProCardHead .avatar img {
  position: relative;
  z-index: 2;
  /* border: 2px solid white; */
}

/* .SMProCardHead .avatar::after {
  content: "";
  position: absolute;
  width: 106px;
  height: 106px;
  background: #10071d61;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.SMProCardHead .avatar:hover::after {
  transform: translate(-50%, -50%) scale(1);
} */

.SMProCardHead h5 {
  margin: 0;
  line-height: 28px;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  /* position: absolute; */
  cursor: pointer;
  padding: 6px 20px;
  text-align: center;
  left: 0;
  /* display: none; */
}

.SMProCardHead img.Varified {
  width: 16px;
  margin-left: 3px;
}

.SMProCardHead h5 svg {
  width: 21px;
  height: 21px;
  margin-left: 7px;
  color: #5e5e5e;
}

.SMProCardHeadTab {
  text-align: center;
  padding: 25px 15px;
  position: relative;
  width: 170px;
}

.SMProCardHeadTab .avatar {
  margin-bottom: 15px;
}

.SMProPicMain {
  position: relative;
  width: 130px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  height: 105px;
}

.SMProPicMain a,
.SMProPicMain span.UpPicBU {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.SMProPicMain a svg,
.SMProPicMain span.UpPicBU svg {
  width: 17px;
  stroke-width: 2;
}

.avatar {
  display: inline-block;
  margin-bottom: 0;
  min-height: 58px;
  min-width: 58px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
  background-color: #fbf7fc;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 2px solid white; */
  /*margin: 0 0 1rem;*/
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar>a>img,
.header form .input-group .input-group-append .avatar>button.btn>img,
.chat-block .chat-sidebar .chat-sidebar-content .list-group .avatar>.list-group-item.active>img,
.avatar>img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: center;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
  /*font-size: 19px;*/
}

.SMProCardDe {
  text-align: center;
}

.SMProCardDe ul {
  margin: 0;
  padding: 0 0 0px 0 !important;
}

.SMProCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 8px 4px 8px 4px;
  font-weight: 400;
  color: #94c5df;
  line-height: 21px;
}

.SMProCardDe .MuiButton-outlinedPrimary {
  margin: 20px 10px;
}

.SMProCardDe .MuiButton-outlinedPrimary svg {
  margin-right: 8px;
}

ul:not(.list-unstyled) {
  margin: 0;
  padding: 0;
}

ul:not(.list-unstyled) li {
  list-style-type: none;
}

.SMProDETag {
  /* color: #b2b5cb; */
  margin-right: 12px;
  display: flex;
  align-items: center;
  min-width: 100px;
  white-space: nowrap;
}

.SMProDE {
  /* margin-left: AUTO; */
  text-align: left;
  color: #fff;
  width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.SMProDE>div {
  text-align: left;
}

.SMProCusBigDiv {
  width: 100%;
  padding: 0;
}

.EditBU {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
}

.EditBU svg {
  width: 17px;
  stroke-width: 2;
}

.CustomHeadingSelect {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  width: 60%;
  margin: 0px auto 0 auto;
}

.CustomHeadingSelect>div {
  /* border: none;
    border-bottom: 1px solid #cccccc;
    border-radius: 0; */
  border: none;
}

/* .CustomHeadingSelect>div>div:nth-child(1)>div:nth-child(1) {
  width: 100%;
  font-size: 17px;
  font-weight: 700;
  color: #505050;
} */

.CustomHeadingSelect>div>div:nth-child(1)>div:nth-child(1).css-1uccc91-singleValue {
  width: 100%;
  max-width: calc(100% - 20px);
}

.CustomHeadingSelect>div>div:nth-child(2) {
  display: block;
}

.ChartFullDiv canvas,
.ChartFullDiv {
  width: 100%;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0 0 0 20px;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
}

.ActionsBULine {
  width: 200px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none;
}

.ActionsBU:hover,
.btn-check:active+.btn-primary.ActionsBU,
.btn-check:checked+.btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active+.btn-primary.ActionsBU.Red,
.btn-check:checked+.btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500;
  color: #343434;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 10px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus {
  color: #343434;
  background: #f8f8f8;
}

.ActionsBUListItem:last-child {
  border: none;
}

.ActionSec {
  display: flex;
  justify-content: flex-end;
}

.EditApplicantMain {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
}

.ProfilePicArea {
  width: 400px;
  background: #fe8371;
  display: flex;
  align-items: center;
  position: relative;
}

.ProfilePicSec {
  width: 100%;
  padding: 50px 25px;
  position: relative;
}

.ProfilePicArea .ImageArea {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}

.ProfilePicArea .ImageArea img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.UploadIcon {
  width: 30px;
  height: 30px;
  position: absolute;
  background: #fff;
  padding: 0;
  min-width: 30px;
  border-radius: 50%;
  right: 7px;
  bottom: 17px;
  z-index: 1;
  box-shadow: 1px 1px 7px 0 #3333334a;
}

.UploadIcon:hover {
  background: #e2e2e2;
}

.UploadIcon svg {
  width: 15px;
  height: 15px;
}

.ApplicantName {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 42px;
  margin-top: 30px;
}

.AppliFormArea {
  width: 400px;
  padding: 60px 50px 40px 50px;
  position: relative;
}

.AppliFormArea {
  width: 400px;
  padding: 60px 30px 40px 30px;
  position: relative;
}

.FormGroup {
  width: 100%;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  padding: 0 20px 0px 20px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.FormGroupHalfB {
  width: 55%;
  padding-bottom: 10px;
  font-size: 0.875rem;
  position: relative;
}

.FormGroupHalfS {
  width: 40%;
  padding-bottom: 10px;
  font-size: 0.875rem;
  position: relative;
}

.ButtonArea {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
}

.ClosePopsBU,
.ClosePopsBU:hover {
  background: no-repeat;
  padding: 0;
  border: none;
  position: absolute;
  top: 18px;
  right: 20px;
  color: #666;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #666;
  margin: 0;
}

.TabSecMain {
  padding: 20px 0;
}

.TabTitleSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid #230058;
}

.TabTitleSec .Title {
  font-size: 15px;
  color: #666;
  font-weight: 600;
  margin: 0;
}

.TabTitleSec .SubTitle {
  font-size: 13px;
  color: #666;
  font-weight: 400;
  margin: 0;
}

.ButtonVoiletSolidSM {
  padding: 12px 26px;
  background-color: #502688;
  margin: 20px 0 0 0;
  font-size: 13px;
  width: auto;
  /* display: -webkit-inline-flex;
    display: inline-flex; */
  font-weight: 600;
  /* -webkit-align-items: center;
    align-items: center; */
  line-height: 14px;
  border-radius: 5px;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #502688;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolidSM:hover {
  background-color: #884ba7;
  border: 1px solid #884ba7;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 0px;
  margin-bottom: 0;
}

.FieldM {
  color: #333;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.QuickbookHeadSec {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0px;
}

.imageValidationError {
  color: #f44336;
  font-size: 12px;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  transform: translate(-50%, 0px);
  width: 280px;
}

.BackArrow {
  color: #505055 !important;
  background: #fff;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-right: 16px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

/* .BackArrow:after {
  content: '';
  position: absolute;
  width: Calc(100% - 25px);
  height: 1px;
  background: #333;
  bottom: 0px;
  right: 0;
  opacity: 0;
  transition: all .2s ease-in-out;
} */

.BackArrow:hover {
  color: #333 !important;
}

/* .BackArrow:hover:after {
  opacity: 1;
} */

.BackArrow svg {
  width: 20px;
  height: 20px;
}

.ProfileEditBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #502688;
  border-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.ProfileEditBU:hover {
  background: #230058;
}

/* .cardUnderS {
  width: 100%;
  padding: 30px 20px 30px 20px;
  border-top: 1px solid #dee2e6;
  margin-top: 10px;
  position: relative;
  transition: all .3s ease-in-out;
  cursor: pointer;
} */

.cardUnderS {
  width: 100%;
  padding: 0px 4px 0px 4px;
  /* border-top: 1px solid #4b525c; */
  border-top: 0px solid #dee2e6;
  margin-top: 0px;
}

/* .cardUnderS.Active {
  padding: 20px 20px 20px 20px;
  height: 68px;
  overflow: hidden;
  transition: all .3s ease-in-out;
} */

.ControllTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #505050;
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ControllTitle.WithBorder {
  /* border-bottom: 1px solid #DFE2E6; */
  padding-bottom: 10px;
  margin-bottom: 10px;
}

.WidgetIndi {
  width: 100%;
  padding: 20px 0 20px 0;
  border-bottom: 1px solid #4d7b93;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
}

.WidgetIndi.Doorlock {
  padding: 20px 0;
  align-items: center;
  justify-content: flex-start;
}

.WidgetIndiBettery {
  width: 100%;
  padding: 20px 0;
  /* border-bottom: 1px solid #545c67; */
  border-bottom: 1px solid #dee2e6;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
}

.WidgetIndi:last-of-type,
.WidgetIndiBettery:last-of-type {
  padding: 12px 0 0 0;
  border-bottom: 0px solid #dfe2e6;
}

.HeadIcon {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edf9ff;
  border-radius: 4px;
  margin-right: 16px;
}

.HeadIcon img {
  width: 20px;
  height: auto;
}

.BatteryT {
  font-weight: 600;
  font-size: 14px;
  line-height: 150%;
  margin-bottom: 2px;
  letter-spacing: 0.01em;
  color: #f2f2f2;
}

.BatteryTT {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  margin: 0;
  letter-spacing: 0.01em;
  color: #94c5df;
}

.WidgetT {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 10px;
  letter-spacing: .01em;
  color: #94c5df;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.OnOffSwitchSmartDE {
  margin-top: -5px;
}

.WidgetT button {
  background: #ff8271;
  color: #fff;
  font-size: 14px;
  padding: 5px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.WidgetT button svg {
  width: 22px;
  height: auto;
}

.WidgetTB {
  font-weight: 600;
  font-size: 32px;
  line-height: 28px;
  margin: 10px 0 15px 0;
  letter-spacing: 0.01em;
  color: #fff;
  width: 100%;
}

.Disable .WidgetTB {
  color: #bcbcbc;
}

.PinArea {
  width: 100%;
  display: flex;
}

.PinArea>div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.PinArea>div input {
  width: 100% !important;
  border: 1px solid #e1e1e1;
  height: 40px;
  border-radius: 4px;
}

.PinArea>div input:disabled {
  background-color: #e1e1e1;
  color: #343434;
}

.PinArea>div span {
  opacity: 0;
}

.ResetPinBU {
  background: #502688;
  color: #272208;
  box-shadow: none;
  min-width: 40px;
  height: 40px;
  border-radius: 4px;
  border-width: 0px;
  border-color: #ddd;
  border-style: solid;
  position: relative;
  margin-left: 8px;
}

.ResetPinBUIcon {
  width: 24px;
  height: auto;
}

.Table {
  padding-top: 8px;
}

.RangeLabel {
  position: absolute;
  top: 0;
  width: 85%;
  height: 100%;
}

.RangeLabelText {
  font-size: 14px;
  line-height: 24px;
  font-family: "Poppins", sans-serif;
  color: #f2f2f2;
  font-weight: 400;
  position: absolute;
  right: 0;
  margin: 0;
  display: flex;
  align-items: flex-start;
}

.RangeLabelText span {
  font-size: 11px;
  line-height: 22px;
  margin-left: 1px;
}

.RangeLabelText.Text100,
.RangeLabelText.Text95F {
  top: 2%;
}

.RangeLabelText.Text75 {
  top: 24.5%;
}

.RangeLabelText.Text50 {
  top: 48%;
}

.RangeLabelText.Text25,
.RangeLabelText.Text54F {
  top: 70%;
}

.RangeLabelText.Text0,
.RangeLabelText.Text41F {
  top: 93%;
}

.RangeLabelText.Text81F {
  top: 27.5%;
}

.RangeLabelText.Text68F {
  top: 48%;
}

.RangeIMG {
  position: absolute;
  top: 20px;
  width: 16px;
  height: CalC(100% - 40px);
  left: 45px;
}

.RangeIMG .IMG {
  width: 123px;
  position: absolute;
  right: 0px;
}

.RangeIMG .IMG.IMGRange0 {
  bottom: -1px;
  display: none;
}

.RangeIMG .IMG.IMGRange25 {
  bottom: 59px;
}

.RangeIMG .IMG.IMGRange50 {
  bottom: 181px;
  display: none;
}

.RangeIMG .IMG.IMGRange75 {
  top: 61px;
  display: none;
}

.RangeIMG .IMG.IMGRange100 {
  top: -1px;
  display: none;
}

.ColorSec {
  width: 80px;
  padding: 8px 0;
}

.ColorSec .Title {
  font-weight: 400;
  font-size: 13px;
  line-height: 24px;
  margin: 0;
  color: #bcbcbc;
}

.ColorBU {
  width: 32px;
  height: 32px;
  background-color: #f40;
  border-radius: 50%;
  border: none;
  margin: 12px 10px 12px 0;
  position: relative;
}

.ColorBU.Active::after {
  content: "";
  width: 33px;
  height: 33px;
  border: 4px solid #fff;
  position: absolute;
  border-radius: 50%;
  top: -1px;
  left: -1px;
}

.AddColorBU {
  width: 32px;
  height: 32px;
  background-color: #502688;
  border-radius: 50%;
  border: none;
  margin: 12px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AddColorBU svg {
  color: #fff;
  width: 20px;
  height: 20px;
}

.WaterMeterSec {
  width: 100%;
  position: relative;
  transform: rotate(-90deg);
  height: auto;
  /* right: -160px; */
}

.WaterCursor {
  width: 130px;
  height: 20px;
  background-color: transparent;
  position: absolute;
  right: 50%;
  top: 47%;
  transform: translate(0%, -50%) rotate(0deg);
  transform-origin: center right;
  transition: all 0.5s ease-in-out;
}

.WaterMeterSec:hover .WaterCursor {
  transform: rotate(180deg);
}

.WaterCursor img {
  transform: rotate(-90deg);
}

.ControllerRow {
  display: flex;
  width: 300px;
  position: relative;
}

.WaterMCon {
  width: 150px;
  height: 300px;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  padding: 20px 0;
}

.WaterMWidget {
  position: absolute;
  left: 160px;
}

.Full {
  width: 100%;
}

.OnOffSwitchWM {
  width: 100%;
  margin: 30px 0 10px 0;
}

.UtilitiesDetails {
  width: 100%;
  background: #ffffff;
  /* box-shadow: 0px 0px 10px rgba(27, 27, 78, 0.1);
  border-radius: 8px;*/
  padding: 20px 0px;
}

.UtilitiesDetailsTitleSec {
  display: flex;
  align-items: center;
  width: 100%;
}

.UtilitiesDetailsTitleSec .ImgSec {
  min-width: 40px;
  min-height: 40px;
  width: 40px;
  height: 40px;
  background: #502688;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 16px;
}

.UtilitiesDetailsTitleSec .ImgSec img {
  width: auto;
  height: 20px;
}

.UtilitiesDetailsRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 28px;
}

.UtilitiesDetailsUnit {
  width: 50%;
  position: relative;
}

.UtilitiesDetailsUnit:after {
  content: "";
  width: 1px;
  height: 32px;
  border-radius: 0.5px;
  background: #8e8e93;
  opacity: 0.3;
  position: absolute;
  top: 50%;
  right: -1px;
  transform: translate(0px, -50%);
}

.UtilitiesDetailsUnit:last-child:after {
  display: none;
}

.UtilitiesDetailsUnit .DetailsUnit {
  font-weight: 700;
  font-size: 32px;
  line-height: 39px;
  color: #333333;
  margin: 0;
  padding: 0 0 4px 0;
  text-align: center;
}

.UtilitiesDetailsUnit .DetailsUnitText {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;
  color: #bcbcbc;
  margin: 0;
  padding: 0;
  text-align: center;
}

.DetailsTitleSec {
  width: 100%;
}

.DetailsTitleSec .Title {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.2px;
  color: #333333;
  margin: 0;
  padding-bottom: 5px;
}

.DetailsTitleSec .SubTitle {
  font-weight: 600;
  font-size: 13px;
  line-height: 15px;
  color: #bcbcbc;
  margin: 0;
}

.GraphSec {
  width: 100%;
  margin: 28px 0 18px 0;
}

.GraphTabSec {
  width: 100%;
  background: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  position: relative;
  padding: 0;
  overflow: hidden;
  margin: 0 0 10px 0;
  border-bottom: 1px solid #f2f2f2;
}

.GraphTab {
  width: 33.33%;
  color: #333;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  justify-content: center;
  padding: 12px;
  cursor: pointer;
}

.ActiveGraphTab {
  width: 33.33%;
  height: 2px;
  background: #ff8271;
  position: absolute;
  left: 0;
  bottom: 0;
  transition: all 0.5s ease-in-out;
}

.GraphTab.One.Active~.ActiveGraphTab {
  left: 0;
}

.GraphTab.Two.Active~.ActiveGraphTab {
  left: 33.33%;
}

.GraphTab.Three.Active~.ActiveGraphTab {
  left: 66.66%;
}

.GraphTab.One:hover~.ActiveGraphTab {
  left: 0;
}

.GraphTab.Two:hover~.ActiveGraphTab {
  left: 33.33%;
}

.GraphTab.Three:hover~.ActiveGraphTab {
  left: 66.66%;
}

.Charts {
  width: 100%;
  position: relative;
}

.HorizontalStats {
  width: CalC(100% - 20px);
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: -25px;
  padding: 0px 0;
  right: 0;
  z-index: 1;
}

.HorizontalStats .Label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #bcbcbc;
  margin: 0;
}

.HorizontalLine {
  width: CalC(100% - 15px);
  height: CalC(100% - -12px);
  position: absolute;
  top: -2px;
  right: 0;
  z-index: -1;
}

.HorizontalLine div {
  width: 100%;
  background-image: linear-gradient(to right,
      #e5e2e2 0%,
      #e5e2e2 50%,
      transparent 50%);
  background-size: 16px 1px;
  background-repeat: repeat-x;
  height: 1px;
  position: relative;
}

.HorizontalLine div:nth-child(1) {
  top: 2.5%;
}

.HorizontalLine div:nth-child(2) {
  top: 32.5%;
}

.HorizontalLine div:nth-child(3) {
  top: 62%;
}

.VerticalStats {
  position: absolute;
  top: -2px;
  align-items: baseline;
  display: flex;
  flex-wrap: wrap;
  height: CalC(100% - -13px);
  align-content: space-between;
  background: #fff;
  left: -3px;
  padding-bottom: 8px;
}

.VerticalStats .Label {
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #bcbcbc;
  margin: 0;
  width: 60%;
  padding-left: 0px;
  text-align: center;
}

.ProfileEditFormSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0;
}

.EditFormFullSec {
  width: 100%;
  padding: 10px 0;
}

.EditFormHalfSec {
  width: 48%;
  padding: 10px 0;
}

.FormGroupPro {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 20px 15px 20px;
  font-weight: 400;
  color: #919191;
}

.FormGroupProLabel {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 100px;
  white-space: nowrap;
}

.CustomSelect {
  width: 100%;
}

.CustomSelect>div {
  border-color: #e1e1e1 !important;
  border-radius: 6px !important;
  min-height: 40px !important;
  font-weight: 400;
  font-size: 14px;
  text-align: left;
}

.FormControlPro {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 21px;
  height: 40px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ProButtonArea {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
}

.ButtonVoiletLinePro {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #502688;
  border: 1.5px solid #502688;
  padding: 10px 16px;
  background: #fff;
  border-radius: 0px;
  width: 50%;
  margin: 0;
  border-bottom-left-radius: 0.5rem;
  transition: all 0.3s ease-in-out;
}

.ButtonVoiletLinePro:hover {
  color: #fff;
  border: 1.5px solid #230058;
  background: #230058;
}

.ButtonVoiletSolidPro {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #502688;
  border-radius: 0;
  width: 50%;
  margin: 0;
  border: none;
  border-bottom-right-radius: 0.5rem;
}

.ButtonVoiletSolidPro:hover {
  background: #230058;
}

.ProEditSec {
  position: absolute !important;
  right: 5px;
  top: 5px;
}

.EditActionsBU {
  width: 40px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  background-color: rgb(255 255 255 / 20%) !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.EditActionsBU:hover {
  background-color: rgb(255 255 255 / 100%) !important;
  color: #343434 !important;
}

.EditActionsBU.Table {
  background-color: transparent !important;
  color: #502688 !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 0;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif !important;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem img {
  width: 17px;
  height: auto;
  margin-right: 12px;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.Offline {
  position: relative;
  overflow: hidden;
}

.OfflineSection {
  width: 100%;
  height: calc(100% - 64px);
  min-height: 195px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  z-index: 10;
  top: 64px;
  left: 0;
}

.OfflineSection svg {
  max-width: 60px;
  max-width: 60px;
  object-fit: contain;
  object-position: center;
}

.OfflineSection p {
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  font-weight: 400;
  margin: 30px 0 0 0;
  width: 100%;
  text-align: center;
}

.MarTop30 {
  margin-top: 30px !important;
}

.DoorLockedIcon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  animation: pulse-black 2.2s infinite;
}

@keyframes pulse-black {
  0% {
    box-shadow: 0 0 0 0 rgba(193, 249, 195, 0.7);
  }

  70% {
    box-shadow: 0 0 0 20px rgba(193, 249, 195, 0);
  }

  100% {
    box-shadow: 0 0 0 0 rgba(193, 249, 195, 0);
  }
}

.DoorLockedIcon.Green {
  background: #e3ffe4;
}

.Disable .RangeMark,
.RangeMarkD {
  display: none;
}

.Disable .RangeMarkD {
  display: block;
  width: 100%;
  height: 100%;
}

.uploadButton {
  display: none;
}

.BreadcrumbHeadSec {
  width: 100%;
  padding: 0 0px 25px 0px;
}

.PageTitleSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0 0;
  position: relative;
}

.PageTitleSec>div {
  display: flex;
  align-items: center;
}

.CardSmall {
  width: 100%;
  height: 100%;
  background: #4d7b93;
  /* border-right: 1px solid #dee2e6; */
}

.AlertsRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  align-content: space-between;
}

.AlertsCard {
  width: 33.33%;
  padding: 12px 12px;
}

.AlertsCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
}

.AlertsCardHead {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.AlertsCardImg {
  min-width: 60px;
  width: 60px;
  min-height: 60px;
  height: 60px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.AlertsCardImg.Offline {
  background-color: #ffe8e8;
}

.AlertsCardImg.Online {
  background-color: #E3FFE4;
}

.AlertsCardImg.Lowbatter {
  background-color: #FFF2E3;
}

.AlertsCardImg.Waterleak {
  background-color: #E6E8F4;
}

.AlertsCardImg img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  object-position: center;
}

.AlertsCardConSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-content: center;
}

.AlertsCardTitle {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  width: 100%;
  margin: 0 0 8px 0;
  color: #505050;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Inter", sans-serif !important;
}

.AlertsCardTitleSm {
  font-size: 16px;
  font-weight: 600;
  line-height: 140%;
  width: 100%;
  margin: 0 0 0 0;
  color: #505050;
  font-family: "Inter", sans-serif !important;
}

.AlertsCardBody {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.AlertsCardBody ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.AlertsCardBody ul li {
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
}

.AlertsCardBody ul li .Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
  min-width: 110px;
}

.AlertsCardBody ul li .Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  color: #343434;
}

.AlertsCardFoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.CardDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
}

.RangeMark {
  width: 100%;
  height: 100%;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.ProfileCardEditSec {
  width: 100%;
  padding: 25px 0 0px 0;
}

.ProfileCardEditSec form {
  width: 100%;
}

.ProfileCardEditSec .FormGroupFull {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 0px 0px 0px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileCardEditSec .FormGroupFull.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ProfileCardEditSec .TimePick {
  width: 48%;
  position: relative;
  margin: 0px 0;
}

.ProfileCardEditSec .TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 7px;
  color: #585858;
  transform: translate(0px, -50%);
}

.ProfileCardEditSec .TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.ProfileCardEditSec .ButtonArea {
  display: flex;
  justify-content: flex-start;
  padding: 0px 0px 10px 0px;
}

.ProfileCardEditSec .ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 0.5rem !important;
  margin: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletSolid:hover {
  background: #e16e5f !important;
}

.ProfileCardEditSec .ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0.5rem !important;
  margin: 0 15px 0 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletLine:hover {
  background: #f1ecf3 !important;
}

.ProfileIMGSec {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProfileIMGCard {
  position: relative;
}

.ProfileIMG {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #f0f0f0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ProfileIMG img {
  width: 50px;
  height: 50px;
  object-fit: contain;
  object-position: center;
}

.ProfileIMGUploadBUMobile {
  width: 32px;
  height: 32px;
  background-color: #ffffff !important;
  color: #6c4c9e !important;
  position: absolute !important;
  bottom: 0;
  right: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ProfileEditFormSec .FormGroup {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 20px 0px 20px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileEditFormSec.ScoreCard .FormGroup div>div::before,
.ProfileEditFormSec.ScoreCard .FormGroup div>div::after {
  display: none;
}

.ProfileEditFormSec .FormGroup>div {
  width: 100%;
}

.ProfileEditFormSec .FilterLabel {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 80px;
  white-space: nowrap;
}

.ProfileEditFormSec.ScoreCard .FilterLabel {
  min-width: 150px;
}

.CustomCheck {
  position: absolute;
  top: -12px;
  left: 0;
  z-index: 1;
  background: #ffffff;
  border-bottom-right-radius: 5px;
}

.CustomCheck span {
  padding: 2px 4px 4px 2px;
}

.AlertMainCheckSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 12px 5px 12px;
}

.AlertDeleteBU {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #ff8271;
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
}

.AlertDeleteBU:focus {
  outline: none;
  box-shadow: none;
}

.AlertDeleteBU svg {
  width: 20px;
  margin-right: 6px;
}

.NoDataMain {
  width: calc(100% - 24px);
  height: 410px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataMainAdd {
  width: 100%;
  margin: 50px 0;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataAddIMG {
  width: 110px;
  height: 110px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 30px;
  margin: auto;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataAddText {
  text-align: center;
  font-size: 30px;
  padding: 20px 0 0 0 !important;
  font-weight: 700;
  color: #e5e5e5;
  line-height: unset;
  text-transform: capitalize;
}

.NoPinSec {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 20px 0 0;
}

.NoPinSec img {
  width: 80px;
  height: 60px;
  opacity: 1;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 15px;
  object-fit: contain;
  object-position: center;
}

.NoPinSec p {
  width: 100%;
  font-size: 20px;
  line-height: 24px;
  font-family: "Poppins", sans-serif !important;
  color: #d5d3d9;
  font-weight: 600;
  margin: 0 0 20px;
}

.NoPinSec button {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #fff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: .5rem !important;
  width: auto;
  margin: 0 !important;
  border-bottom-right-radius: .5rem !important;
  transition: all .3s ease-in-out !important;
}

.NoPinSec button:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.PinTypeSec {
  width: 100%;
  padding: 5px 0 15px 0 !important;
}

.PinArea {
  width: 100%;
  display: flex;
  padding: 10px 0 10px 0;
}

.PinArea>div {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.PinArea label {
  padding-bottom: 6px;
  margin-top: -5px;
  font-weight: 400;
  font-size: 13px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #f2f2f2;
}

.PinArea>div span {
  opacity: 0;
}

.ButtonAreaPinCode {
  display: flex;
  justify-content: space-between;
  padding: 25px 0 5px;
}

.PinCodeListArea {
  width: 100%;
}

.PinCodeList {
  width: 100%;
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.PinCodeList:last-child {
  border: none;
}

.PinCodeListTitleSec {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.PinCodeListTitle {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 5px;
  letter-spacing: .01em;
  color: #f2f2f2;
  width: 100%;
}

.PinCodeListTitleSec div {
  display: flex;
}

.PinCodeListTitleSec div button {
  background-color: initial;
  color: #a0cce3;
  display: flex;
  width: 20px;
  height: 20px;
  padding: 1px;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
}

.PinDatePicker {
  width: 100%;
  padding: 15px 0 !important;
}

.PinCodeListDate {
  font-weight: 400;
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 0;
  letter-spacing: .01em;
  color: #aaa;
  width: 100%;
}

.PincodeListPinArea {
  display: flex;
  align-items: center;
}

.PincodeListPinArea .PincodeViewBU {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  padding: 11px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #502688;
  color: #fff;
  margin-left: 7px;
}

.FormAccoHead {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.FormAccoHeadTitle {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  letter-spacing: 0.01em;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding: 0 12px;
}

.NotesRow a {
  width: 100%;
}

.NotesCard {
  width: 100%;
  /* border-radius: 6px; */
  padding: 15px 25px;
  /* margin: 10px 0; */
  position: relative;
  /* box-shadow: 0 0 20px 0 #f5f5f5; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.NotesCard a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .NotesCard:hover {
  box-shadow: 0 0 20px 0 #f2f2f2;
} */

.NotesCard:hover {
  cursor: pointer;
}

.AlertCardFirstSec {
  width: 100%;
  display: flex;
  align-items: center;
}

.DeviceAlertIcon {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  margin-left: 10px;
}

.DeviceAlertIcon.Blue {
  background: #e8f0ff;
}

.DeviceAlertIcon.Orange {
  background: #fff3e8;
}

.DeviceAlertIcon.Red {
  background: #ffe8e8;
}

.DeviceAlertIcon.Green {
  background: #e3ffe4;
}

.AccoTitleHead {
  width: calc(100% - 126px);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.AlertTitle {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
  line-height: 150%;
  margin: 0;
  width: 60%;
}

.AlertText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  width: 30%;
}

.AlertText span {
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  position: relative;
  display: inline-block;
}

.AlertText span:last-child {
  margin: 0;
  padding: 0;
}

.AlertText span:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.AlertText span:last-child:after {
  display: none;
}

.AccoMainSec {
  border-top: 1px solid #eeeeee;
  margin: -6px 0 0 48px;
  padding: 12px 0 0 0;
}

.AccoMainSec ul {
  width: 100%;
}

.AccoMainSec ul li {
  list-style: none;
  padding: 5px 0;
}

.AccoMainSec ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.AccoMainSec ul li p span {
  color: #343434;
  font-weight: 500;
}

@media screen and (min-width: 360px) {
  .AccoMainSec {
    margin: -6px 0 0 0px;
  }

  .AccoMainSec ul li p span {
    min-width: 120px;
  }

  .AlertText {
    width: 100%;
    font-size: 12px;
  }

  .AlertTitle {
    width: 100%;
    font-size: 14px;
  }

  .AccoTitleHead {
    width: calc(100% - 95px);
  }

  .DeviceAlertIcon {
    margin-right: 15px;
    margin-left: 0px;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  /* .CardSmall {
    border-bottom: 1px solid #dee2e6;
    border-right: 0px solid #dee2e6;
  } */
  .BreadcrumbHeadSec {
    padding: 0 15px 25px 15px;
  }

  .AddCardMain {
    width: 100%;
  }

  .FormGroupBig {
    width: 100%;
  }

  .FormGroupSmall {
    width: 100%;
  }

  .FullFormGroup {
    width: 100%;
  }

  .AddCardMain {
    padding: 0px;
  }

  .ButtonDiv {
    padding: 0 0px;
  }

  .DetailsDTextGroup .DetailsDTextFull {
    width: 100%;
  }

  /* .SMProCusRow {
    flex-wrap: wrap;
  } */

  .SMProCusSmallDiv {
    min-width: 100%;
    max-width: 100%;
  }

  .SMProCusBigDiv {
    width: 100%;
  }

  .mainCard {
    margin: 0 0rem;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .EditApplicantMain {
    width: 100%;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 100%;
  }

  .FormGroupHalfB {
    width: 100%;
  }

  .FormGroupHalfS {
    width: 100%;
  }

  .AlertsCard {
    width: 100%;
  }

  .AlertsCardHead {
    flex-wrap: wrap;
    justify-content: center;
  }

  .AlertsCardImg {
    border-radius: 50%;
    margin-right: 0;
    margin-bottom: 16px;
  }

  .AlertsCardTitle,
  .AlertsCardTitleSm {
    text-align: center;
  }
}

@media screen and (min-width: 480px) {
  .AddCardMain {
    padding: 20px;
  }

  .ButtonDiv {
    padding: 0 20px;
  }

  .FormGroupHalfB {
    width: 55%;
  }

  .FormGroupHalfS {
    width: 40%;
  }
}

@media screen and (min-width: 576px) {
  .AccoMainSec {
    margin: -6px 0 0 48px;
  }

  .AccoMainSec ul li p span {
    min-width: 130px;
  }

  .AlertTitle {
    font-size: 16px;
  }

  .AccoTitleHead {
    width: calc(100% - 95px);
  }

  .DeviceAlertIcon {
    margin-right: 25px;
    margin-left: 10px;
  }

  .BreadcrumbHeadSec {
    padding: 0 0px 25px 0px;
  }

  .FormGroupBig {
    width: 36%;
  }

  .FormGroupSmall {
    width: 20%;
  }

  .FullFormGroup {
    width: 300px;
  }

  .DetailsDTextGroup .DetailsDTextFull {
    width: auto;
  }

  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1.19rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }
}

@media screen and (min-width: 768px) {
  .AlertText {
    width: 25%;
    font-size: 14px;
  }

  .AlertTitle {
    width: 70%;
  }

  .AddCardMain {
    width: 600px;
  }

  .FormGroupBig {
    width: 180px;
  }

  .FormGroupSmall {
    width: 110px;
  }

  .AlertsCard {
    width: 50%;
  }
}

@media screen and (min-width: 992px) {
  .AlertText {
    width: 30%;
  }

  .AlertTitle {
    width: 60%;
  }

  .EditApplicantMain {
    width: 800px;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 400px;
  }

  .AlertsCardHead {
    flex-wrap: unset;
    justify-content: unset;
  }

  .AlertsCardImg {
    border-radius: 8px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  .AlertsCardTitle,
  .AlertsCardTitleSm {
    text-align: left;
  }
}

@media screen and (min-width: 1024px) {}

@media screen and (min-width: 1100px) {
  /* .CardSmall {
    border-bottom: 0px solid #dee2e6;
    border-right: 1px solid #dee2e6;
  } */
  /* .SMProCusRow {
    flex-wrap: nowrap;
  } */

  .SMProCusSmallDiv {
    min-width: 350px;
    max-width: 350px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  /* .SMProCusBigDiv {
    width: 100%;
  } */
  .AlertsCard {
    width: 100%;
  }
}

@media screen and (min-width: 1200px) {

  /* .SMProCusBigDiv {
    width: 100%;
  } */
  .AlertsCard {
    width: 50%;
  }
}

@media screen and (min-width: 1300px) {
  /* .SMProCusBigDiv {
    width: 100%;
  } */
}

@media screen and (min-width: 1400px) {
  /* .SMProCusBigDiv {
    width: 100%;
  } */
}

@media screen and (min-width: 1500px) {}

@media screen and (min-width: 1600px) {
  .AlertsCard {
    width: 33.33%;
  }

  .NotesCard {
    padding: 15px 18px;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media screen and (min-width: 1800px) {
  /* .SMProCusBigDiv {
    width: 100%;
  } */
}

@media screen and (max-width: 767px) {
  .BreadcrumbHeadSec nav {
    display: none;
  }

  .PageTitleSec {
    padding: 25px 0 0 0;
  }

  .SMProCusRow {
    margin: 0px 0px 0px 0px;
  }

  .BackAddBU {
    display: flex !important;
  }
}

@media screen and (max-width: 480px) {
  .EditFormHalfSec {
    width: 100%;
  }

  .ProfileCardEditSec .ButtonVoiletLine,
  .ProfileCardEditSec .ButtonVoiletSolid {
    width: 50%;
  }
}