.Header {
  width: 100%;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 60;
}

.Container {
  width: 1360px;
  margin: 0 auto;
}

.HeadRow {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.Logo {
  max-width: 240px;
  max-height: 50px;
}

.Logo img {
  width: 100%;
  height: 50px;
  object-fit: contain;
  object-position: center;
}

.RightSec {
  display: flex;
  align-items: center;
}

.MenuArea {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.MenuArea ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.MenuArea ul li {
  list-style: none;
}

.MenuArea ul li a {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #000;
  display: block;
  padding: 7px 20px;
}

.ContactBUHead {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #ff8271;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 6px 15px 6px 15px;
  transition: all .3s ease-in-out;
  margin-left: 15px;
  position: relative;
}

.ContactBUHeadDRBU {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #ff8271;
  min-width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 6px 15px 6px 15px;
  transition: all .3s ease-in-out;
  margin-left: 15px;
  position: relative;
}

.ContactLinkHead {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #ff8271;
  background: #fff;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 6px 15px 6px 15px;
  transition: all .3s ease-in-out;
  margin-left: 10px;
  position: relative;
}

.ContactBUHead svg,
.ContactBUHeadDRBU svg {
  width: 22px;
  margin-right: 15px;
  /* margin-left: 0px;
  position: absolute;
  right: 30px;
  transition: all .3s ease-in-out; */
}

.ContactBUHeadDRBU svg:last-child {
  margin-right: 0;
  margin-left: 15px;
}

.ContactBUHead:hover,
.ContactBUHeadDRBU:hover {
  color: #fff;
  background: #392978;
}

.ContactLinkHead:hover {
  color: #392978;
  background: #fff;
}

/* .ContactBUHead:hover svg {
  right: 20px;
} */

.MenuHamBU {
  width: 30px;
  height: 30px;
  color: #000;
}

.MenuHamBU svg {
  width: 30px;
  height: 30px;
}

.DropDownList ul {
  display: block;
  flex-wrap: wrap !important;
  padding: 8px 0 !important;
  background: #fff;
  box-shadow: 0 0 20px #33333329;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.DropDownList ul li {
  width: 220px !important;
}

@media (max-width: 991px) {
  .ContactLinkHead {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 15px;
    border: 2px solid #ff8271;
  }

  .ContactLinkHead:hover {
    background: #ff8271;
    color: #fff;
  }
}

@media (min-width: 360px) {
  .Container {
    width: Calc(100% - 30px);
  }

  .MenuArea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    width: 300px;
    right: -350px;
    top: 80px;
    background: #fff;
    height: Calc(100% - 80px);
    align-content: flex-start;
    box-shadow: -3px 10px 20px 0 #3333332e;
    padding: 0 30px;
    transition: all .3s ease-in-out;
  }

  .MenuArea.Open {
    right: 0px;
  }

  .MenuArea ul {
    flex-wrap: wrap;
    padding: 20px 0;
  }

  .MenuArea ul li {
    width: 100%;
  }

  .MenuArea ul li a {
    padding: 7px 0px;
  }

  .ContactBUHead {
    width: 100%;
    margin-left: 0px;
  }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {}

@media (min-width: 768px) {}

@media (min-width: 992px) {
  .MenuHamBU {
    display: none;
  }

  .MenuArea {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    position: unset;
    width: auto;
    right: unset;
    top: unset;
    background: #fff;
    height: auto;
    align-content: unset;
    box-shadow: none;
    padding: 0;
  }

  .MenuArea ul {
    flex-wrap: nowrap;
    padding: 0;
  }

  .MenuArea ul li {
    width: auto;
  }

  .MenuArea ul li a {
    padding: 7px 15px;
  }

  .ContactBUHead {
    width: 110px;
    margin-left: 8px;
  }
}

@media (min-width: 1100px) {
  .MenuArea ul li a {
    padding: 7px 20px;
  }
}

@media (min-width: 1200px) {
  .Container {
    width: 1140px;
  }
}

@media (min-width: 1300px) {}

@media (min-width: 1400px) {
  /* .Container {
    width: 1360px;
  } */
}

@media (min-width: 1500px) {}

@media (min-width: 1600px) {}

@media (min-width: 1700px) {}

@media (min-width: 1800px) {}