.PrivacyArea {
    padding: 60px 0;
}

.Container {
    width: 1360px;
    margin: 0 auto;
}

.PolicyTitleSec {
    width: 100%;
    max-width: 950px;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.PolicyTitle {
    font-size: 72px;
    font-weight: 800;
    line-height: 80px;
    color: #13052a;
    letter-spacing: -3px;
    margin-bottom: 50px;
    text-align: center;
}

.PolicyConSec {
    width: 100%;
}

.PolicyConTitle {
    font-size: 22px;
    line-height: 35px;
    margin-bottom: 10px;
    font-weight: 700;
    color: #000;
}

.PolicyConDe {
    width: 100%;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 15px;
    color: #7f7f7f;
    font-weight: 500;
}

/* .PolicyConDe ul {
    margin-top: 10px;
} */

.PolicyConDe ul li {
    padding-bottom: 6px;
}

.PolicyText,
.PolicyConDe p {
    font-size: 18px;
    line-height: 30px;
    margin: 20px 0 6px 0;
    font-weight: 500;
}

.PolicyText span,
.PolicyConDe p span,
.PolicyConDe ul li span {
    font-weight: 700;
    color: #000;
}

@media (min-width: 360px) {
    .Container {
        width: Calc(100% - 30px);
    }

    .PolicyTitle {
        font-size: 54px;
        line-height: 72px;
    }
}

@media (min-width: 768px) {
    .PolicyTitle {
        font-size: 58px;
        line-height: 74px;
    }
}

@media (min-width: 992px) {
    .PolicyTitle {
        font-size: 66px;
        line-height: 74px;
    }
}

@media (min-width: 1200px) {
    .Container {
        width: 1140px;
    }

    .PolicyTitle {
        font-size: 72px;
        line-height: 80px;
    }
}