.copyrightTextCompany {
  color: #230058;
  font-weight: 600;
  text-decoration: none;
}
.copyrightText {
  color: #9e9e9e;
  line-height: 33px;
  font-size: 13px;
}
.footerWrap .MuiGrid-container {
  flex-wrap: nowrap !important;
}
.footerWrap .MuiListItem-root {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.jfnHfM,
.jUXIJT {
  margin-top: 1.875rem;
}

.FooterMain {
  position: fixed !important;
  width: CalC(100% - 0px);
  bottom: 0;
  background: #fbf7fc !important;
  z-index: 10;
}

.Open .FooterMain {
  z-index: 9;
}

@media (min-width: 360px) {
  .footerWrap .MuiGrid-container {
    flex-wrap: wrap !important;
  }
  .footerWrap .MuiGrid-container ul {
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .footerWrap .MuiGrid-container ul li {
    width: 100%;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .footerWrap .MuiGrid-container {
    flex-wrap: nowrap !important;
  }
  .footerWrap .MuiGrid-container ul {
    width: auto;
    display: unset;
    justify-content: unset;
  }
  .footerWrap .MuiGrid-container ul li {
    width: auto;
    text-align: unset;
  }
}

/* @media (min-width: 576px) {} */

@media (min-width: 960px) {
  .FooterMain {
    width: CalC(100% - 85px);
  }
}

@media (min-width: 1500px) {
  .FooterMain {
    width: CalC(100% - 85px);
  }
  .FilterFoot .FooterMain,
  .FilterFoot.Open .FooterMain {
    width: CalC(100% - 385px);
  }
}
