::placeholder {
  color: #757779;
  opacity: 1;
}

:-ms-input-placeholder {
  color: #757779;
}

::-ms-input-placeholder {
  color: #757779;
}

.pageTitleWrap {
  width: 100%;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  box-shadow: 0 3px 10px rgba(62, 85, 120, 0.07);
  padding: 1rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  top: 66px;
  left: auto;
  right: 0;
  position: sticky;
  z-index: 999;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem+.breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem+.breadcrumbItem::before {
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0;
  border-radius: 0.5rem;
  margin: 0 1.8rem;
  position: relative;
  background: transparent;
  border: none;
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 0%);
  padding-bottom: 60px !important;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  min-width: 105px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  height: 38px;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #1565c0;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #1258a8;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.SMProCusRow {
  margin: 35px 0px 0px 0px;
  display: flex;
  flex-wrap: wrap;
  width: -webkit-fill-available;
  width: -moz-available;
}

.SMProCusSmallDiv {
  width: 450px;
  padding: 0;
}

.SMProCusBigDiv {
  width: 100%;
  padding: 0;
}

.card {
  width: 100%;
  height: 100%;
  border-radius: 0;
  margin-bottom: 0;
  background: white;
  border: none;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  min-width: 0;
  padding-bottom: 0;
}

.CardSmall {
  width: 100%;
  height: 100%;
  background: #4d7b93;
}

.card .cardbody {
  padding: 1.5rem;
}

.SMProCardHead {
  text-align: left;
  padding: 25px 15px 15px 15px;
  position: relative;
}

.SMProCardHead .avatar {
  margin-bottom: 25px;
  position: absolute;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
  opacity: 0.3;
  transition: all 0.2s ease-in-out;
}

.SMProCardHead .SMProPicMainDubble:hover .avatar {
  cursor: pointer;
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
}

.SMProCardHead .avatar.active {
  -webkit-filter: grayscale(0%);
  filter: grayscale(0%);
  opacity: 1;
  z-index: 3 !important;
}

.SMProCardHead .avatar img {
  position: relative;
  z-index: 2;
  border: 2px solid white;
  background-color: #fff;
}

.SMProCardHead .avatar p {
  width: 90px;
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fbf7fc;
  margin: 0;
  border-radius: 50%;
  position: relative;
  z-index: 2;
  font-size: 26px;
  font-weight: 600;
  line-height: normal;
  color: #502688;
  letter-spacing: 1px;
  border: 2px solid #fff;
}

.SMProCardHead .avatar::after {
  content: "";
  position: absolute;
  width: 106px;
  height: 106px;
  background: #10071d61;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%) scale(0.65);
  transition: all 0.3s ease-in-out;
  z-index: 1;
}

.SMProCardHead .avatar:hover::after {
  transform: translate(-50%, -50%) scale(1);
}

.SMProCardHead h5 {
  margin: 0;
  line-height: 2.22;
  width: 100%;
  font-size: 20px;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
  padding: 0 20px;
  text-align: center;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.SMProCardHead img.Varified {
  width: 16px;
  margin-left: 10px;
}

.SMProCardHead h5 svg {
  width: 21px;
  height: 21px;
  margin-left: 7px;
  color: #5e5e5e;
}

.SMProPicMain {
  position: relative;
  width: 90px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  height: 105px;
}

.SMProPicMainDubble {
  position: relative;
  width: 130px;
  margin: 20px auto 0;
  display: flex;
  justify-content: center;
  height: 105px;
  transition: all 0.3s ease-in-out;
}

.SMProPicMainDubble.Three {
  width: 150px;
}

.SMProPicMainDubble:hover {
  width: 195px;
}

.SMProPicMainDubble.Three:hover {
  width: 290px;
}

.SMProPicMainDubble .avatar:first-child {
  left: 0;
}

.SMProPicMainDubble .avatar:last-child {
  right: 0;
}

.SMProPicMainDubble .avatar:nth-child(1) {
  z-index: 3;
}

.SMProPicMainDubble .avatar:nth-child(2) {
  z-index: 2;
  opacity: .7;
}

.SMProPicMainDubble .avatar:nth-child(3) {
  z-index: 1;
}

.SMProPicMain a,
.SMProPicMain span.UpPicBU {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
  cursor: pointer;
}

.SMProPicMain a svg,
.SMProPicMain span.UpPicBU svg {
  width: 17px;
  stroke-width: 2;
}

.avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0;
  min-height: 58px;
  min-width: 58px;
  height: 90px;
  width: 90px;
  border-radius: 50%;
}

.avatar.avatarxl {
  height: 5.8rem;
  width: 5.8rem;
}

.roundedcircle {
  border-radius: 50% !important;
}

.avatar>a>img,
.header form .input-group .input-group-append .avatar>button.btn>img,
.chat-block .chat-sidebar .chat-sidebar-content .list-group .avatar>.list-group-item.active>img,
.avatar>img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.avatar.avatarlg {
  height: 4.5rem;
  width: 4.5rem;
}

.avatar.avatarlg .avatartitle {
  font-size: 29px;
}

.avatar .avatartitle {
  background: #d7d7d7;
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  text-transform: uppercase;
}

.roundedcircle {
  border-radius: 50% !important;
}

.SMProCardDe {
  text-align: center;
}

.SMProCardDe ul {
  margin: 0;
  padding:0 0 0px 0 !important;
}

.SMProCardDe ul li {
  display: flex;
  font-size: 14px;
  padding: 7px 4px 7px 4px;
  font-weight: 400;
  color: #94c5df;
  line-height: 28px;
}

.SMProCardDe .MuiButton-outlinedPrimary {
  margin: 20px 10px;
}

.SMProCardDe .MuiButton-outlinedPrimary svg {
  margin-right: 8px;
}

ul:not(.list-unstyled) {
  margin: 0;
  padding: 0;
}

ul:not(.list-unstyled) li {
  list-style-type: none;
}

.SMProDETag {
  margin-right: 24px;
  display: flex;
  align-items: center;
  min-width: 75px;
  white-space: nowrap;
}

.SMProDE {
  /* margin-left: AUTO; */
  text-align: left;
  color: #f2f2f2;
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  white-space: nowrap;
  transition: all .3s ease-in-out;
}

.SMProDE svg {
  width: 16px;
  height: 16px;
  margin-right: 8px;
  margin-top: -4px;
}

a.SMProDE:hover {
  color: #f48272;
}

a.SMProDE::after {
  content: '';
  position: absolute;
  width: 0%;
  height: 1px;
  background: #4d7b93;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  transition: all .3s ease-in-out;
}

a.SMProDE:hover::after {
  width: 100%;
  background: #f48272;
}

.SMProDE>div {
  text-align: left;
}

.SMBigSideHead {
  display: flex;
  margin-bottom: 20px;
  height: 33px;
  align-items: center;
}

.SMBigSideHead h5 {
  margin-bottom: 0;
  padding-top: 0px;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  margin-top: 0;
  margin-right: 25px;
  color: #343434;
}

.SMBigSideBody {
  position: relative;
}

.EditBU {
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  min-width: 40px;
  height: 40px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
}

.EditBU svg {
  width: 17px;
  stroke-width: 2;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: 38px;
  padding: 0.375rem 0.4rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  transition: all 0.3s !important;
  margin: 0 !important;
  line-height: unset !important;
}

.ReferStatusBUNew {
  padding-right: 10px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background-color: #eb575766 !important;
  border-color: #eb575766 !important;
  color: #a63434 !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #f8d52466 !important;
  border-color: #f8d52466 !important;
  color: #71621b !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #17dd1b !important;
  border-color: #17dd1b !important;
  color: #fff !important;
}

.ReferStatusBU.Open,
.ReferStatusBU.Open:active,
.ReferStatusBU.Open:checked {
  background-color: #5026884d !important;
  border-color: #5026884d !important;
  color: #502688 !important;
}

.ReferStatusBU.InProgress,
.ReferStatusBU.InProgress:active,
.ReferStatusBU.InProgress:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.Resolved,
.ReferStatusBU.Resolved:active,
.ReferStatusBU.Resolved:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Cancelled,
.ReferStatusBU.Cancelled:active,
.ReferStatusBU.Cancelled:checked {
  background-color: #ffe8e8 !important;
  border-color: #ffe8e8 !important;
  color: #e23725 !important;
}

.ApplicationTabText1 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #343434;
  margin: 0;
}

.ApplicationTabText2 {
  font-size: 14px;
  line-height: 21px;
  font-weight: 400;
  color: #666666;
  margin: 0;
}

.ApplicationDetab ul {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.ApplicationDetab ul li {
  width: 33.33%;
  padding: 15px;
}

.FilesUploadRow {
  display: flex;
  flex-wrap: wrap;
  margin: 20px -15px;
}

.FilesUploadGrid {
  max-width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  display: grid;
}

.FilesUploadCard {
  box-shadow: 0 0 7px 2px rgb(62 85 120 / 10%);
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  border: 1px solid #ebebeb !important;
}

.CardIconSec {
  background-color: #f5f5f5;
  padding: 2rem;
  text-align: center;
  font-size: 2rem;
  border-bottom: 1px solid #ebebeb;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  line-height: normal;
}

.CardIconSec img {
  width: 40px;
  height: 40px;
  margin-top: 7px;
}

.CardConSec {
  padding: 0.5rem;
}

.CardConSec p {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 0;
}

.FilesUpCardDD,
.FilesUpCardDD:active,
.FilesUpCardDD:checked,
.FilesUpCardDD:hover,
.FilesUpCardDD:focus {
  position: absolute;
  right: 0;
  top: 0;
  min-width: 20px;
  margin: 0;
  padding: 6px 8px;
  background-color: transparent !important;
  color: #000 !important;
  border: none;
  border-color: transparent !important;
  box-shadow: none !important;
}

.FilesUpCardDD.NoPadd,
.FilesUpCardDD.NoPadd:active,
.FilesUpCardDD.NoPadd:checked,
.FilesUpCardDD.NoPadd:hover,
.FilesUpCardDD.NoPadd:focus {
  padding: 0;
}

.DropDownMain {
  position: absolute;
  right: 0;
  top: 0;
}

.FilesUpCardDD:after {
  display: none;
}

.FilesUpCardDD svg {
  width: 18px;
}

.FileUpDuContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  margin-top: 10px;
  -webkit-align-items: center;
  align-items: center;
}

.FileUpDuContainer h5 {
  font-size: 17px;
  color: #505050;
  text-align: left;
  margin: 0;
  font-weight: 500;
}

.UploadLabel {
  padding: 5px 15px 5px 20px;
  background-color: transparent;
  font-size: 13px;
  width: 100%;
  display: inline-flex;
  font-weight: 500;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  line-height: 16px;
  border-radius: 4px;
  color: #502688;
  text-align: center;
  vertical-align: middle;
  /* border: 1px dashed #502688; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 100%;
  text-transform: capitalize;
  cursor: pointer;
  position: relative;
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23502688FF' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
}

.UploadLabel svg {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.UploadLabel div {
  display: none;
}

.UploadLabel span {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.UploadLabel div.UpIcon {
  width: 50px;
  background: #502688;
  height: 50px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.UpIcon img {
  width: 100%;
  height: auto;
}

.UploadLabel span p {
  text-align: center;
  width: 100%;
  font-weight: 600;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.UploadLabel span p.UploadText {
  margin: 0 0 5px 0;
  /* position: absolute; */
  bottom: 0;
  width: 100%;
  left: 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #666666;
}

.NotesRow {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.AddCommDiv {
  width: 100%;
  padding: 15px 0 30px 0;
}

.NotesRow a {
  width: 100%;
}

.NotesCard {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.06);
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 4px 8px 0 rgba(51, 51, 51, 0.15);
}

.NotesCardInside {
  display: flex;
  padding-top: 5px;
  position: relative;
}

.NotesCardInside img {
  width: 45px;
  height: 45px;
  min-width: 45px;
  min-height: 45px;
  border-radius: 50%;
}

.CardInsideCon {
  width: 100%;
  padding-left: 25px;
}

.InsideConName {
  font-size: 15px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 3px;
  padding: 0px 20px 0px 0;
}

.InsideConName span {
  color: #b7b7b7;
  font-weight: 500;
}

span.ConNameTrancate {
  color: #32007e;
  padding-right: 8px;
  font-weight: 600;
  max-width: 80%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -4px;
}

.InsideConDate {
  font-size: 11px;
  line-height: normal;
  font-weight: 500;
  color: #a7abc3;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #505050;
  margin-bottom: 0;
  overflow-wrap: break-word !important;
  word-wrap: break-word !important;
  word-break: break-word !important;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 12px 0 12px 0;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border: 1px solid #e1e1e1;
  margin-bottom: 0;
  margin-top: 0;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 11px 12px;
  margin-right: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: relative;
  left: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 20px;
  margin-right: 10px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 18px;
  background: #ff8271;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 44px;
  margin-left: 15px;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 22px;
  height: 22px;
}

.AppliSwitchBUMain {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.AppliSwitchBUMain button {
  color: #ff8271;
}

.AppliSwitchBUMain button svg {
  width: 20px;
  height: 20px;
  margin-left: 8px;
}

.AppliTwo .AppliSwitchBUMain button svg {
  margin-left: 0px;
  margin-right: 8px;
}

.AppliSwitchRow {
  width: 100%;
  display: flex;
}

.AppliOne {
  width: 100%;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  left: 0%;
  position: relative;
}

.AppliTwo {
  width: 100%;
  transition: all 0.5s ease-in-out;
  opacity: 1;
  left: -110%;
  position: absolute;
  z-index: -2;
}

.NextAppli .AppliSwitchRow .AppliOne {
  width: 100%;
  left: -110%;
  position: absolute;
  z-index: -2;
}

.NextAppli .AppliSwitchRow .AppliTwo {
  width: 100%;
  left: 0%;
  position: relative;
  z-index: 0;
}

.PropeCardInFR {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.MainReqConDivSmall {
  padding: 0 15px;
  width: 37%;
}

.MainReqConDivBig {
  padding: 0 15px;
  width: 37%;
}

.MainReqTitle {
  font-size: 18px;
  line-height: normal;
  font-weight: 600;
  color: #32007e;
  margin-bottom: 0;
  padding: 8px 0;
}

.MainReqConUR {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.MainReqConDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  margin-bottom: 0;
  padding: 8px 15px 8px 0;
  text-align: left;
  color: #666;
  display: flex;
}

.MainReqConDe span {
  color: #999;
  padding-right: 5px;
}

.MainReqConUR .MainReqConDe:last-child {
  padding-right: 0px;
}

.MainReqConAction {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 25%;
  justify-content: flex-end;
}

.MainReqConDropBU {
  padding: 0 10px;
}

.InsideConCheck {
  padding: 0 10px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.InsideConCheck p {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #999;
  margin-bottom: 0;
  padding: 8px;
}

.InsideConCheck img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.TitleWithTag {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding-bottom: 6px;
}

.TagDiv {
  font-size: 13px;
  background: #999;
  color: #fff;
  padding: 4px 8px;
  border-radius: 24px;
  font-weight: 500;
  line-height: 120%;
  letter-spacing: 0.14px;
}

.TagDiv.Red {
  background: #ffe8e8;
  color: #e23725;
}

.TagDiv.Yellow {
  background: #ffbb33;
}

.TagDiv.Common {
  background: #eee4ff;
  color: #230058;
}

.TagDiv.Error {
  background: #f44336;
}

.TagDiv.Success {
  background: #17dd1b;
}

.SMProCardTab {
  display: flex;
  flex-wrap: wrap;
  width: 550px;
}

.SMProCardHeadTab {
  text-align: center;
  padding: 25px 15px;
  position: relative;
  width: 170px;
}

.SMProCardHeadTab .avatar {
  margin-bottom: 15px;
}

.SMProPicMainTab {
  position: relative;
  width: 90px;
}

.SMProPicMainTab a {
  position: absolute;
  bottom: 15px;
  right: -5px;
  background-color: rgba(19, 51, 86, 0.5);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #fff;
  text-align: center;
  padding-top: 5px;
}

.SMProPicMainTab a svg {
  width: 17px;
  stroke-width: 2;
}

.SMProCardDeTab {
  text-align: center;
  padding: 25px 15px;
  width: 380px;
}

.SMProCardDeTab ul li {
  display: flex;
  font-size: 14px;
  padding: 0 0px 15px 0px;
  position: relative;
}

.SMProCardDeTab ul li h5 {
  font-size: 17px;
  margin: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.SMProDETagTab {
  color: #b2b5cb;
  margin-right: 15px;
  display: flex;
  align-items: center;
  width: 100px;
}

.SMProDETab {
  margin-left: 0;
  text-align: left;
  color: #9f9f9f;
}

.EditBUTab {
  position: absolute;
  top: -6px;
  right: 8px;
  background-color: rgba(19, 51, 86, 0);
  border-radius: 50%;
  width: 35px;
  height: 35px;
  color: #8999aa;
  text-align: center;
  padding-top: 5px;
}

.EditBUTab svg {
  width: 17px;
  stroke-width: 2;
}

.LoaderCard {
  width: 100%;
  border-radius: 0.5rem;
  margin-bottom: 1.875rem;
  position: relative;
  background: white;
  border: none;
  box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1);
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  padding: 1.5rem;
  margin: 1.5rem;
}

.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 8px 15px 8px 0;
}

span.TextEclipsName {
  max-width: 120px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
  color: #666;
  padding: 0px 0px 0px 0;
}

.TextEclips {
  max-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
}

.ReffStatus {
  margin: 0 10px 0 0;
  font-size: 14px;
  white-space: nowrap;
  display: inline-block;
  vertical-align: middle;
}

.ReffStatus svg {
  width: 18px;
  height: 18px;
  margin-bottom: 2px;
  margin-right: 5px;
}

.ReffStatus.Low svg {
  transform: rotate(180deg);
}

.ReffStatus.High {
  color: #f44336;
}

.ReffStatus.Medium {
  color: #ff9800;
}

.ReffStatus.Low {
  color: #4caf50;
}

.ReffStatus.Archived {
  padding: 5px 12px 5px 12px !important;
  background: #87858b !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  color: #fff;
}

.uploadButton {
  display: none;
}

.UploadLabel div {
  display: none;
}

.NoDataMain {
  width: 100%;
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  position: relative;
}

.NoDataMain div {
  width: 300px;
  display: block;
  text-align: center;
}

.NoDataIMG {
  width: 130px;
  height: auto;
  margin-bottom: 40px;
  opacity: 0.35;
}

.NoDataText {
  font-weight: 700;
  font-size: 32px;
  line-height: normal;
  color: rgba(0, 0, 0, 0.5);
  margin: 0 0 25px;
}

.AddLeaseBU {
  padding: 9px 25px 9px 21px;
  background-color: #ff8271;
  margin: 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddLeaseBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.AddLeaseBU svg {
  width: 22px;
  margin-right: 7px;
}

.Permission h5 {
  font-size: 16px;
  color: #505050;
  text-align: left;
  margin: 10px 0;
  font-weight: 600;
  width: 100%;
  font-family: "Inter", sans-serif !important;
}

.PermissionSwitch {
  font-size: 14px;
  font-weight: 600;
  color: #7e7e7e;
  margin: 5px 0;
}

.DateInfo {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  width: 100%;
}

.DateInfo span {
  color: #343434;
  padding-right: 6px;
}

.DateInfo2 span {
  color: #ffbb33;
}

.formGroup {
  margin-bottom: 12px;
}

.ReferCommentArea {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  height: 220px;
  padding: 10px 15px;
  resize: none;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  color: #343434;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.ColorDark {
  color: #000 !important;
}

.CustomHeadingSelect {
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.2;
  width: 80%;
  margin: 0px auto 0 auto;
}

.CustomHeadingSelect>div {
  border: none;
}

.CustomHeadingSelect>div>div:nth-child(1)>div:nth-child(1).css-1uccc91-singleValue {
  width: 100%;
  max-width: calc(100% - 20px);
}

.CustomHeadingSelect>div>div:nth-child(2) {
  display: block;
}

.QuickbookHeadSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.BackArrow {
  color: #505055 !important;
  background: #fff;
  border-radius: 3px;
  width: 35px;
  height: 35px;
  margin-right: 16px;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;
}

.BackArrow:hover {
  color: #333 !important;
}

.BackArrow svg {
  width: 20px;
  height: 20px;
}

.ActionsBU {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBUIcon {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #6d6d6d !important;
  background-color: transparent !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 15px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
  padding: 11px 20px 11px 15px !important;
}

.ActionsBULine {
  width: 200px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0 !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBU:hover,
.btn-check:active+.btn-primary.ActionsBU,
.btn-check:checked+.btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBUIcon:hover,
.btn-check:active+.btn-primary.ActionsBUIcon,
.btn-check:checked+.btn-primary.ActionsBUIcon,
.ActionsBUIcon.btn-primary.active,
.ActionsBUIcon.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBUIcon {
  background-color: transparent;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active+.btn-primary.ActionsBU.Red,
.btn-check:checked+.btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show>.btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBUIcon svg {
  min-width: 16px;
  min-height: 16px;
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  margin-left: 0;
}

.ActionsBU.Red svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 0;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none !important;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  padding: 10px 10px !important;
  min-width: 158px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus,
.ActionsBUListItem:active {
  color: #343434 !important;
  background-color: #f8f8f8 !important;
}

.ActionsBUListItem:last-child {
  border: none;
}

.ActionSec {
  display: flex;
  justify-content: flex-end;
}

.EditApplicantMain {
  width: 800px;
  display: flex;
  flex-wrap: wrap;
}

.ProfilePicArea {
  width: 400px;
  background: #fe8371;
  display: flex;
  align-items: center;
  position: relative;
}

.ProfilePicSec {
  width: 100%;
  padding: 50px 25px;
  position: relative;
}

.ProfilePicArea .ImageArea {
  width: 150px;
  height: 150px;
  margin: 0 auto;
  position: relative;
}

.ProfilePicArea .ImageArea img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  object-position: center;
}

.UploadIcon {
  width: 30px;
  height: 30px;
  position: absolute !important;
  background-color: #ffffff !important;
  color: #343434 !important;
  padding: 0 !important;
  min-width: 30px !important;
  border-radius: 50% !important;
  right: -6px;
  bottom: -2px;
  z-index: 2;
  box-shadow: 1px 1px 7px 0 #3333334a;
  display: none !important;
}

.SMProCardHead .avatar.active .UploadIcon {
  display: flex !important;
}

.UploadIcon:hover {
  background: #e2e2e2;
}

.UploadIcon svg {
  width: 15px;
  height: 15px;
}

.ApplicantName {
  font-size: 30px;
  font-weight: 700;
  color: #fff;
  text-align: center;
  line-height: 42px;
  margin-top: 30px;
}

.AppliFormArea {
  width: 400px;
  padding: 60px 50px 40px 50px;
  position: relative;
}

.FormGroup {
  width: 100%;
  padding-bottom: 10px;
  font-size: 0.875rem;
  position: relative;
}

.ButtonArea {
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 20px 20px;
}

.ClosePopsBU,
.ClosePopsBU:hover {
  background: no-repeat;
  padding: 0;
  border: none;
  position: absolute;
  top: 18px;
  right: 20px;
  color: #666;
}

.TabSecMain {
  padding: 10px 0 20px 0;
}

.TabTitleSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  padding-bottom: 6px;
  border-bottom: 2px solid #230058;
}

.TabTitleSec .Title {
  font-size: 15px;
  color: #666;
  font-weight: 600;
  margin: 0;
}

.TabTitleSec .SubTitle {
  font-size: 13px;
  color: #666;
  font-weight: 400;
  margin: 0;
}

.ButtonVoiletSolidSM {
  padding: 14px 20px;
  background-color: #f48272;
  margin: 20px 0 0 0;
  font-size: 13px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 8px;
  color: #fff;
  text-align: center;
  border: 1px solid #f48272;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.ButtonVoiletSolidSM:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.imageValidationError {
  color: #f44336;
  font-size: 12px;
  text-align: center;
  display: block;
  position: absolute;
  bottom: 10px;
  left: 50%;
  background: #ffffff;
  padding: 5px 15px;
  border-radius: 4px;
  transform: translate(-50%, 0px);
  width: 280px;
}

.ProfileEditBU {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  padding: 10px 16px;
  background: #502688;
  border-radius: 0;
  border-bottom-right-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
}

.ProfileEditBU:hover {
  background: #230058;
}

.cardUnderS {
  width: 100%;
  padding: 22px 4px 15px 4px;
  border-top: 0px solid #4b525c;
  margin-top: 0px;
}

.cardUnderSHead {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.cardUnderSHead .HeadIcon {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #edf9ff;
  border-radius: 4px;
  margin-right: 16px;
}

.cardUnderSHead .HeadIcon img {
  width: 19px;
  height: auto;
}

.cardUnderSHead .HeadConTitle {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  letter-spacing: 0.01em;
  color: #ffffff;
  margin-bottom: 2px;
}

.cardUnderSHead .HeadConText {
  font-weight: 500;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin-bottom: 0;
  color: #94c5df;
}

.cardUnderSBody {
  /* max-height: 300px;
  overflow: auto;
  margin-right: -20px; */
  width: 100%;
}

.cardUnderSBody::-webkit-scrollbar {
  width: 6px;
}

.cardUnderSBody::-webkit-scrollbar-track {
  background: #f1ecf3;
}

.cardUnderSBody::-webkit-scrollbar-thumb {
  background: #97a0ae;
}

.cardUnderSBody::-webkit-scrollbar-thumb:hover {
  background: #8c95a3;
}

.cardUnderSBody ul {
  margin: 0;
  padding: 0;
}

.cardUnderSBody ul li {
  list-style: none;
  padding-bottom: 35px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #343434;
  display: flex;
  position: relative;
}

.cardUnderSBody ul li p {
  margin: 0;
  color: #f2f2f2;
  line-height: 26px;
}

.cardUnderSBody ul li p.Date {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 0.01em;
  margin-bottom: 0;
  color: #94c5df;
}

.cardUnderSBody ul li:last-child {
  padding-bottom: 0;
}

.cardUnderSBody ul li:after {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  top: 0;
  left: 12px;
  border: 1px dashed #e5e5e5;
}

.cardUnderSBody ul li.Completed:after {
  border: 1px dashed #4caf50;
}

.cardUnderSBody ul li a,
.cardUnderSBody ul li button {
  color: #e5e5e5 !important;
  line-height: 26px;
  background-color: transparent;
  padding: 0;
}

.cardUnderSBody ul li a svg,
.cardUnderSBody ul li button svg {
  width: 18px;
  margin-left: 8px;
  color: #e5e5e5;
}

.cardUnderSBody ul li span {
  width: 26px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e5e5e5;
  border-radius: 50%;
  color: #fff;
  margin-right: 18px;
  position: relative;
  z-index: 1;
  border: 2px solid;
  font-size: 14px;
  line-height: 14px;
}

.cardUnderSBody ul li.Completed span {
  background: #4caf50;
}

.cardUnderSBody ul li.Failed span {
  background: #f40;
}

.cardUnderSBody ul li.Ongoing span {
  background: #ffbc00;
}

.cardUnderSBody ul li span svg {
  width: 14px;
}

.SummeryList {
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #666666;
  margin-bottom: 16px;
}

.SummeryList:last-of-type {
  margin-bottom: 0;
}

.SummeryList.Green {
  color: #6eff74;
}

.SummeryList.Red {
  color: #ff6a38;
}

.SummeryList span {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #94c5df;
  width: 150px;
  display: inline-block;
  margin-right: 10px;
  text-shadow: unset;
}

.FormAccoHead {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormAccoHeadTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  text-transform: capitalize;
  color: #505050;
  margin: 0;
  font-family: "Inter", sans-serif !important;
}

.AccoCheck {
  width: 24px;
  height: 24px;
  border: 1px solid #dfe1e9;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccoCheck.Active {
  border: none;
  background: #2fa42b;
}

.AccoCheck svg {
  width: 16px;
  height: 20px;
  display: none;
  color: #fff;
  stroke-width: 3px;
}

.AccoCheck.Active svg {
  display: block;
}

.FooterWrap {
  width: 100%;
  padding: 0 168px;
}

.footerMain {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  padding: 95px 0 75px;
  margin-top: 90px;
}

.footerMainLeft {
  width: 20%;
}

.footerMainLeft img {
  max-width: 240px;
  max-height: 100px;
  display: block;
  object-fit: contain;
  width: 100%;
  height: 100%;
  object-position: left;
}

.footerMainRight {
  width: 75%;
  padding-top: 0px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.footerMainRight h3 {
  color: #2c2c2c;
  font-size: 15px;
  font-weight: 600;
  padding-bottom: 25px;
  line-height: 18px;
  margin: 0;
  letter-spacing: 0.01em;
}

.footerMainRight ul {
  margin: 0;
  padding: 0;
}

.footerMainRight ul li {
  list-style: none;
  color: #2c2c2c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 3px;
  text-transform: none;
}

.footerMainRight ul li a {
  color: #2c2c2c;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 3px;
  text-transform: none;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  display: inline-block;
}

.footerMainRight ul li a:hover {
  color: #2a5bee;
}

.CussAccoRow {
  width: 100%;
  padding: 8px 20px 8px 25px;
}

.AccoFormSec {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FormGroupHalf {
  width: 48% !important;
  padding: 15px 0 !important;
  font-size: 14px;
  position: relative !important;
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
}

.FormGroupFull {
  width: 100% !important;
  padding: 14px 0px !important;
  font-size: 0.875rem;
  position: relative !important;
}

.ToggleBUHalf {
  width: 48% !important;
  padding: 15px 0 !important;
  font-size: 14px;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-start;
}

.FormLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 8px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.FormLabel span {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #bec1ca;
}

.FormLabelSmall {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 12px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 38px;
  height: 56px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8b8b8b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe1e9;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.Dollar .formControl {
  padding: 0.375rem 0.75rem 0.375rem 4.3rem;
}

.formControlTextarea {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 38px;
  height: 56px;
  padding: 12px 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #8b8b8b;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #dfe1e9;
  border-radius: 0px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 150px;
  resize: none;
}

.ErrorM {
  color: #f44336;
  font-size: 13px;
}

.AppliFormToggleBU {
  width: 50%;
  height: 56px;
  background: #fff;
  border: 1px solid #dfe1e9;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  cursor: auto !important;
}

.AppliFormToggleBU.Active {
  width: 50%;
  height: 56px;
  background: #f2f2f2;
  border: 1.5px solid #b9b8b8;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #b9b8b8;
}

.CalendarIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 44px;
  height: 44px;
  background: #f2f2f2;
  color: #bab9b9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CalendarIcon svg {
  width: 20px;
  height: 20px;
}

.ResendLink {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 78px;
  height: 44px;
  background: #eff3ff;
  color: #2a5bee;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.applicantNextBU.Disabled,
.applicantNextBU.Disabled:hover {
  width: 180px;
  height: 56px;
  background: #d4e3fe;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  cursor: auto;
}

.applicantNextBU {
  width: 180px;
  height: 56px;
  background: #2a5bee;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
}

.applicantNextBU:hover {
  background: #1e47c5;
}

.ShowHideIcon {
  position: absolute;
  top: 6px;
  right: 6px;
  width: 44px;
  height: 44px;
  background: #fff;
  color: #bec1ca;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.ShowHideIcon svg {
  width: 20px;
  height: 20px;
}

.AccoFormBUsec {
  margin-top: 20px;
  width: 100%;
}

.FormFieldMessage {
  font-weight: 500;
  font-size: 13px;
  line-height: 150%;
  color: #2c2c2c;
  margin-top: 8px;
  margin-bottom: 0;
}

.AccoDollarSign {
  position: absolute;
  top: 0;
  left: 0;
  width: 56px;
  height: 56px;
  background: #f0f0f0;
  color: #2c2c2c;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  margin: 0;
}

.AccoDollarSign svg {
  width: 20px;
  height: 20px;
}

.Width100 {
  width: 100%;
}

.AccoFormSec .Width100 {
  width: 100%;
  position: relative;
  padding-bottom: 0px;
}

.DivFull {
  width: 100%;
}

.FileUpTitle {
  font-weight: 500;
  font-size: 15px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 6px 0;
}

.TermText {
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0 0 0 0;
  padding-bottom: 20px;
}

.TermTextTitle {
  font-size: 18px;
  font-weight: 500;
  margin: 10px 0 0 0;
  color: #000;
  padding-bottom: 20px;
}

.TermText li {
  padding: 5px 0;
}

.DivFullTerm {
  border: 3px solid #1a1a1a;
  height: 600px;
  overflow: auto;
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 4px;
}

.TermCheckBox {
  margin-bottom: 40px;
  width: 100%;
}

.FileUpList {
  margin: 0;
  padding: 0;
}

.FileUpList li {
  list-style: none;
  position: relative;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  padding: 6px 0 6px 25px;
}

.FileUpList li::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  background: #2a5bee;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
}

.SignButton {
  width: 182px;
  height: 56px;
  background: #2a5bee;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 0px;
  margin-right: 15px;
}

.SignedButton {
  width: 241px;
  height: 56px;
  background: #2fa42b;
  border: none;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  margin-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
}

.SignedButton .Icon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background: #fff;
  border-radius: 50%;
  color: #2fa42b;
}

.SignedButton .Icon svg {
  width: 16px;
  height: auto;
  margin-top: 2px;
  stroke-width: 3px;
}

.RentallAppliSubmitBU {
  width: 100%;
  height: 64px;
  background: #d4e3fe;
  border: none;
  margin-top: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  color: #ffffff;
  padding: 20px;
  cursor: auto !important;
}

.RentallAppliSubmitBU.Active {
  background: #2a5bee;
  cursor: pointer !important;
}

.CusRadioRow {
  width: 100%;
  display: flex;
  position: relative;
  padding-bottom: 0px;
}

.CusRadio {
  width: 50%;
  cursor: auto;
}

.checkmark {
  width: 100%;
  height: 56px;
  background: #fff;
  border: 1px solid #dfe1e9;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #8c8c8c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CusRadio input:checked~.checkmark {
  background: #e2e2e2;
  border: 1.5px solid #b9b8b8;
  color: #b9b8b8;
}

.CusRadio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.SignarureBody {
  width: 100%;
}

.SignedBGBUGroup {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

form {
  width: 100%;
}

.FilterSearchSec {
  width: 600px;
  padding-right: 15px;
  display: flex;
}

.CheckFilter {
  display: flex;
  flex-wrap: wrap;
}

.CheckFilter label {
  width: 100%;
  margin: 0;
}

.ReqNo {
  font-weight: 400;
  font-size: 13px;
  line-height: 120%;
  letter-spacing: 0.01em;
  color: #b7b7b7;
  margin: 0 0 6px 0;
  width: 100%;
  display: flex;
}

.ReqNo img {
  width: 17px;
  margin-right: 8px;
}

.ReqAddCard {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 20px #f5f5f5;
  border-radius: 8px;
  margin: 10px 0;
  padding: 20px;
  max-height: 100%;
}

.ReqAddCard.Open {
  max-height: 100%;
  margin: 10px 0;
  padding: 20px;
}

.ReqAddCardTitle {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #343434;
  margin-bottom: 8px;
}

.CustomSelect>div {
  border-radius: 8px;
  border-color: #e1e1e1;
  min-height: 44px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding-left: 5px;
}

.ReqAddRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ReqAddFormSec {
  display: flex;
  flex-wrap: wrap;
  padding-top: 15px;
}

.FormIMGSec {
  width: 100%;
  padding: 12px 0 25px 0;
}

.FormSec {
  width: 100%;
  padding: 0px 0;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 600;
  color: #333333;
  margin: 0;
}

.FormFieldArea {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  height: 160px;
  resize: none;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 16px;
}

.FormSecRow {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.FormHalfSec {
  width: 48%;
  padding: 0px;
}

.ButtonAction {
  width: 100%;
  padding-top: 0px;
  display: flex;
  align-items: center;
}

.ButtonActionPay {
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: center;
}

.FormHalfSecTwo {
  width: 48%;
  padding: 14px 0px;
}

.FormField {
  width: 100%;
  background: #ffffff;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  padding: 11px 16px;
}

.FormSec.WithSign .FormField {
  padding: 11px 16px 11px 40px;
}

.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 14px 0;
}

.TimePick {
  width: 43%;
  position: relative;
  margin: 0px 0;
}

.TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 7px;
  color: #585858;
  transform: translate(0px, -50%);
}

.TimePick > div {
  position: relative;
  width: 100%;
  /* z-index: 2; */
  /* padding-right: 10px; */
}

.TimePick2 {
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  padding: 0.45rem 16px;
  width: 100%;
  height: 44px;
  position: relative;
  margin: 0;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
}

.TimePick2 svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 13px;
  right: 7px;
  color: #585858;
}

.TimePick2>div {
  position: relative;
  padding-right: 10px;
}

.TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.FileIMGDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0px;
}

.FileUpDuBUDiv {
  width: 100%;
  height: 280px;
  margin: 10px 0 20px 0;
}

.FileUpDuBUDiv input {
  display: none;
}

.ButtonDiv {
  width: 100%;
  padding-top: 25px;
  display: flex;
  align-items: center;
}

.ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-right: 12px !important;
  text-transform: capitalize !important;
  transition: all ease-in-out 0.3s;
}

.ButtonVoiletSolid:hover {
  background: #e16e5f !important;
  border: 1px solid #e16e5f !important;
}

.ButtonDelete {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin-right: 12px !important;
  text-transform: capitalize !important;
  transition: all ease-in-out 0.3s;
}

.ButtonDelete:hover {
  background: #f2f0f2 !important;
  color: #343434 !important;
  border: 1px solid #e1e1e1 !important;
}

.ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #502688 !important;
  background: #fff !important;
  border: 1px solid #502688 !important;
  border-radius: 8px !important;
  padding: 10px 16px !important;
  margin: 0 !important;
  text-transform: capitalize !important;
  transition: all ease-in-out 0.3s;
}

.ButtonVoiletLine:hover {
  background: #502688 !important;
  color: #fff !important;
  border: 1px solid #502688 !important;
}

.ErrorMIcon {
  color: #f44336;
  font-size: 12px;
  width: auto;
  position: absolute;
  right: 28px;
  top: 12px;
  background: #fff;
  padding-left: 10px;
}

.ErrorMIcon svg {
  width: 20px;
}

.HeadTable {
  width: 100%;
  padding: 15px 50px 0 18px;
}

.PaymentListHead {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.HeadTable table,
.PaymentListHead table {
  width: 100%;
}

.HeadTable table td {
  width: 20%;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #333333;
}

.PaymentListHead table td {
  width: 20%;
}

.DateInfo {
  margin: 0;
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
}

.DateInfo span {
  color: #343434;
  font-weight: 500;
}

.DateInfo2 {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}

.StatusIcon {
  width: 16px;
  opacity: 0.8;
  margin-right: 12px;
  height: auto;
  margin-top: 2px;
}

.DateInfo2 span {
  color: #ffbb33;
  font-weight: 500;
}

.DateInfo2 .DueOnBadge {
  margin: 0;
  font-size: 14px;
  color: #000;
  font-weight: 500;
  margin-bottom: 3px;
}

.BadgeDate {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
}

.DateInfo2 .CreditedBadge {
  color: #2fa42b;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.DateInfo2 .PendingBadge {
  color: #ffbc00;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.DateInfo2 .FailedBadge {
  color: #f44336;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.CreditedBadge {
  color: #2fa42b !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.PendingBadge {
  color: #ffbc00 !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.FailedBadge {
  color: #f44336 !important;
  font-weight: 500;
  margin: 0;
  margin-bottom: 3px;
}

.ActionBuArea {
  display: flex;
}

.ActionSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ActionBuArea button {
  width: 30px;
  height: 30px;
  background: transparent;
  border: none;
  color: #5b5a5b;
  margin: 0 3px;
}

.ActionBuArea button svg {
  width: 18px;
}

.PaymentBodyTableArea {
  width: 100%;
  padding: 0 0px 0 58px;
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #f3f3f3;
}

.PaymentBodyTableCard {
  width: 20%;
}

.PaymentBodyTableArea table {
  width: 100%;
}

.PaymentBodyTableArea table td {
  width: 20%;
  vertical-align: top;
}

.HideInfo {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  padding: 10px 0;
  white-space: normal;
}

.HideInfo span {
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 5px;
  display: inline-block;
}

.FormSec.WithSign {
  position: relative;
}

.FormSec.WithSign svg {
  position: absolute;
  width: 18px;
  height: 18px;
  color: #4a4a4a;
  top: 22px;
  left: 13px;
}

.ProfileEditFormSec .FormGroup {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 20px 0px 20px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileEditFormSec .FormGroup>div {
  width: 100%;
}

.ProfileEditFormSec .FilterLabel {
  color: #919191;
  font-size: 14px;
  font-weight: 400;
  margin-right: 10px;
  display: flex;
  align-items: center;
  min-width: 80px;
  white-space: nowrap;
}

.ProfileEditFormSec .formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  min-height: 21px;
  height: 38px;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #cccccc;
  border-radius: 5px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ProfileEditFormSec .formControl.Error {
  border: 1px solid #f40;
}

.ProfileEditFormSec .ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 0.5rem !important;
  width: 48% !important;
  margin: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileEditFormSec .ButtonVoiletSolid:hover {
  background: #e16e5f !important;
}

.ProfileEditFormSec .ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0.5rem !important;
  width: 48% !important;
  margin: 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileEditFormSec .ButtonVoiletLine:hover {
  background: #f1ecf3 !important;
}

.AppliAccoFormDateTenantDE>div>div {
  font-size: 14px !important;
  color: #8b8b8b !important;
}

.UpIMGSec {
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 0 0;
  width: 100%;
}

.ImgFile {
  min-width: 100px;
  width: 120px;
  height: 120px;
  position: relative;
  margin: 0;
  padding: 10px;
}

.ImgFile img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 6px;
}

.ImgFileUnder {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
}

.ImgFile button,
.ImgFile button:hover {
  position: absolute;
  background: #f40;
  color: #fff;
  border-radius: 50%;
  top: 5px;
  right: 8px;
  min-width: 23px;
  min-height: 23px;
  text-align: center;
  padding: 0;
  width: 23px;
  height: 23px;
}

.ImgFile button svg {
  width: 15px;
  height: 15px;
  margin-top: 0;
}

.ImgFileUnder {
  width: 100%;
  height: 100%;
  position: relative;
  margin: 0;
}

.PaymentIcon {
  width: 15px;
  margin-right: 8px;
}

.UploadImgSign {
  width: 250px;
  height: 150px;
}

.UploadImgSign img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: left;
}

.FileListRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.UpFileList {
  width: 50%;
  width: CalC(50% - 15px);
  padding: 15px 0;
}

.UpFileListUnder {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #ffffff;
  box-shadow: 0px 0px 20px #ececec;
  border-radius: 8px;
  padding: 16px 8px 16px 16px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  height: 67px;
}

.UpFileListUnder:hover {
  background-color: rgba(42, 91, 238, 0.1);
}

.FileName {
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  color: #2c2c2c;
  margin: 0;
  display: flex;
  align-items: center;
  width: 300px;
  min-width: 300px;
}

.FileName span {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
}

.FileName svg {
  width: 26px;
  height: 26px;
  min-width: 26px;
  min-height: 26px;
  color: #2a5bee;
  margin-right: 12px;
  stroke-width: 1.5;
}

.FileSize {
  font-weight: 400;
  font-size: 14px;
  line-height: 150%;
  color: #949494;
  margin: 0 0 0 0;
  padding: 0 0 0 0;
  position: relative;
  width: 180px;
  min-width: 180px;
  text-align: center;
}

.FileListRename {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #343434 !important;
  padding: 6px 11px !important;
  min-width: 20px !important;
  transition: all 0.2s ease-in-out !important;
}

.FileListDelete {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #eb534a !important;
  padding: 6px 11px !important;
  min-width: 20px !important;
  transition: all 0.2s ease-in-out !important;
}

.FileListCancel {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 22px !important;
  letter-spacing: 0.01em !important;
  color: #343434 !important;
  padding: 6px 11px !important;
  min-width: 20px !important;
  transition: all 0.2s ease-in-out !important;
}

.FileListCancel svg {
  width: 22px !important;
  height: 22px !important;
}

.FileListRename:hover,
.FileListDelete:hover,
.FileListCancel:hover {
  background: transparent !important;
}

.FileListRename,
.FileListDelete {
  opacity: 0;
}

.UpFileListUnder:hover .FileListRename,
.UpFileListUnder:hover .FileListDelete,
.UpFileListUnder.Active .FileListRename,
.UpFileListUnder.Active .FileListDelete {
  opacity: 1;
}

.FileRenameField {
  width: 100%;
  background: #ffffff;
  border-radius: 6px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #2c2c2c;
}

.FileRenameField input {
  height: 24px;
  padding: 0;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #2c2c2c;
  padding: 7px 10px 8px 10px;
}

.FileRenameField fieldset {
  border-color: #e1e1e1;
  border-radius: 8px;
  top: 0px;
  border-width: 1px !important;
}

.FileRenameField fieldset legend {
  line-height: 0 !important;
}

.FileListRight {
  display: flex;
  align-items: center;
}

.FileListRight p.FileSize:last-of-type {
  margin: 0;
  padding: 0;
}

.FileListRight p::after {
  content: "";
  width: 2px;
  height: 10px;
  background: #cdcdcd;
  position: absolute;
  top: 53%;
  right: -2px;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
  border-radius: 0;
}

.FileListRight p:last-of-type:after {
  display: none;
}

.FileListRight a svg,
.FileListRight button svg {
  width: 20px;
  height: 20px;
  stroke-width: 1.5;
}

.DelTenantBU {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 18px;
  background: #eb534a;
  border-radius: 8px;
  border: none;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  color: #ffffff;
  transition: all 0.3s ease-in-out;
  margin: 25px 0 0 0;
}

.DelTenantBU svg {
  width: 20px;
  margin-right: 12px;
}

.DelTenantBU:hover {
  background: #bd3931;
}

.EyeBU {
  background: #fff;
  border: none;
  color: #ff8271;
}

.EyeBU svg {
  width: 18px;
}

.IDImageSec {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 500px;
  min-height: 150px;
}

.IDImage {
  width: auto;
  min-width: 50px;
  max-width: 500px;
  height: auto;
  min-height: 50px;
  max-height: 500px;
  object-fit: contain;
  object-position: center;
  margin: 0;
}

.IDImageCloseBU {
  position: absolute;
  top: 2px;
  right: 5px;
  padding: 5px;
  background: #ffffff;
  border-radius: 50%;
}

.ProEditSec {
  position: absolute !important;
  right: 10px;
  top: 10px;
}

.EditActionsBU {
  width: 40px !important;
  height: 40px;
  display: flex !important;
  justify-content: center;
  align-items: center !important;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #ffffff !important;
  background-color: rgb(255 255 255 / 20%) !important;
  margin: 0 !important;
  border: none !important;
  padding: 0 !important;
  border-radius: 50% !important;
}

.EditActionsBU:hover {
  color: #343434 !important;
  background-color: rgb(255 255 255 / 100%) !important;
}

.EditActionsBU.Table {
  background-color: transparent !important;
  color: #502688 !important;
}

.EditActionsBU svg {
  min-width: 16px;
  min-height: 16px;
  width: 24px;
  height: 24px;
  margin-bottom: 0;
  margin-left: 0;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif !important;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem img {
  width: 17px;
  height: auto;
  margin-right: 12px;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.BreadcrumbHeadSec {
  width: 100%;
  padding: 0 0px 25px 0px;
}

.PageTitleSec {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 0 0 0;
  position: relative;
}

.PageTitleSec>div {
  display: flex;
  align-items: center;
}

.GropSelectTitle {
  font-size: 12px !important;
  color: #bcbcbc !important;
  text-transform: uppercase;
  margin-bottom: -8px;
  margin-top: 5px;
}

.GropSelectList {
  padding: 8px 16px !important;
}

.GropSelectList:last-child {
  margin-bottom: 15px !important;
}

.GropSelectList img {
  width: 22px;
  height: 20px;
  margin-right: 12px;
}

.ReqSmallFilter {
  display: none;
}

.ReqBigFilter {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}

.AddFormTitleSec {
  display: none;
  align-items: center;
  padding-bottom: 25px;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.AddPropertyTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #505050;
  width: 100%;
  margin-bottom: 0px;
}

.PaymentListTitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  letter-spacing: 0.01em;
  text-transform: uppercase;
  color: #000000;
  margin: 0 0 3px 0;
  width: 100%;
}

.PaymentListSec1 {
  width: 20%;
}

.PaymentListSec2 {
  width: 26%;
}

.PaymentListSec3 {
  width: 17%;
}

.PaymentListSec4 {
  width: 20%;
}

.EditProfileSec {
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  padding: 40px;
  background-color: #fff;
  overflow: auto;
}

.ProfileIMGSec {
  width: 100%;
  display: flex;
  justify-content: center;
}

.ProfileIMGCard {
  position: relative;
}

.ProfileIMG {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 3px solid #f0f0f0;
}

.ProfileIMG img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.ProfileIMGUploadBUMobile {
  width: 32px;
  height: 32px;
  background-color: #ffffff !important;
  color: #6c4c9e !important;
  position: absolute !important;
  bottom: 0;
  right: 0;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.ProfileEditFormSec {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 25px 0;
}

.EditFormFullSec {
  width: 100% !important;
  padding: 10px 0 !important;
}

.EditFormHalfSec {
  width: 48% !important;
  padding: 10px 0 !important;
}

.ButtonSec {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
}

.AddBU {
  padding: 15px 25px;
  background-color: #ff8271;
  margin: 0 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.CancelBU {
  padding: 15px 25px;
  background-color: #fff;
  margin: 0 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1;
  text-align: center;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.CancelBU:hover {
  background-color: #f1ecf3;
}

.ProfileCardEditSec {
  width: 100%;
  padding: 25px 0 0px 0;
}

.ProfileCardEditSec form {
  width: 100%;
}

.ProfileCardEditSec .FormGroupFull {
  width: 100%;
  position: relative;
  display: flex;
  font-size: 14px;
  padding: 0 0px 0px 0px;
  font-weight: 400;
  color: #919191;
  margin-bottom: 22px;
}

.ProfileCardEditSec .FormGroupFull.FormTimeZone {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.ProfileCardEditSec .TimePick {
  width: 48%;
  position: relative;
  margin: 0px 0;
}

.ProfileCardEditSec .TimePick svg.ClockIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  top: 50%;
  right: 7px;
  color: #585858;
  transform: translate(0px, -50%);
}

.ProfileCardEditSec .TimeP {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  margin: 0;
  color: #bfbfbf;
}

.ProfileCardEditSec .ButtonArea {
  display: flex;
  justify-content: flex-start;
  padding: 0px 0px 10px 0px;
}

.ProfileCardEditSec .ButtonVoiletSolid {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #ffffff !important;
  padding: 10px 16px !important;
  background: #ff8271 !important;
  border: 1px solid #ff8271 !important;
  border-radius: 0.5rem !important;
  margin: 0 !important;
  border-bottom-right-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletSolid:hover {
  background: #e16e5f !important;
}

.ProfileCardEditSec .ButtonVoiletLine {
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: #343434 !important;
  padding: 10px 16px !important;
  background: #fff !important;
  border: 1px solid #e1e1e1 !important;
  border-radius: 0.5rem !important;
  margin: 0 15px 0 0 !important;
  border-bottom-left-radius: 0.5rem !important;
  transition: all 0.3s ease-in-out !important;
}

.ProfileCardEditSec .ButtonVoiletLine:hover {
  background: #f1ecf3 !important;
}

.RemainingText {
    font-size: 13px;
    margin: 5px 0 0 0;
}

.FormAccoHead {
  width: 100%;
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
}

.FormAccoHeadTitlePro {
  font-weight: 600;
  font-size: 15px;
  line-height: 150%;
  text-transform: uppercase;
  color: #fff;
  margin: 0;
  letter-spacing: 0.01em;
}

.AddSection {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 0px #f5f5f5;
  border-radius: 8px;
  margin: 25px 0 0 0;
  padding: 0px 0px;
  max-height: 100%;
}

.DetailsDiv {
  width: 100%;
  border: 1px solid #e5e8eb;
  border-radius: 6px;
  padding: 5px 5px;
  position: relative;
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.DetailsDiv::before {
  content: "";
  position: absolute;
  width: 4px;
  height: 100%;
  background: #ffbb33;
  left: 0;
  top: 0;
}

.DetailsDivHalf {
  width: 50%;
  padding: 5px 15px;
}

.DetailsTitle {
  font-size: 14px;
  color: #666;
  line-height: 21px;
  margin: 0;
  font-weight: 600;
}

.DetailsDes {
  font-size: 14px;
  color: #666;
  line-height: 21px;
  margin: 0;
}

.DetailsDes span {
  font-weight: 600;
}

.AddLayoutForm {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.SMSmallSideBody {
  width: 100%;
}

.AddUserMain {
  width: 100%;
  padding: 0px;
}

.FormRow {
  display: flex;
  flex-wrap: wrap;
  font-size: 0.875rem;
  margin: 0 -12px;
}

.Example {
  font-size: 12px;
  width: 100%;
  margin: 0;
  padding: 5px 0;
}

.RentRollListSec {
  width: 100%;
}

.NotesCard2 {
  width: 100%;
  /* border-radius: 6px; */
  padding: 15px 25px;
  /* margin: 10px 0; */
  position: relative;
  /* box-shadow: 0 0 20px 0 #f5f5f5; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.NotesCard2 a {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* .NotesCard:hover {
  box-shadow: 0 0 20px 0 #f2f2f2;
} */

.NotesCard2:hover {
  cursor: pointer;
}

.AlertCardFirstSec {
  width: 100%;
  display: flex;
  align-items: center;
}

.AccoTitleHead {
  /* width: calc(100% - 126px); */
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}

.CardStatusSec {
  width: 30%;
  max-width: 210px;
  min-width: 200px;
}

.DateInfoAcc {
  margin: 0;
  font-size: 14px;
  color: #666666;
  font-weight: 400;
  display: flex;
  align-items: flex-start;
}

.DateInfoAcc .DueOnBadge {
  margin: 0;
  font-size: 14px;
  color: #000 !important;
  font-weight: 500;
  margin-bottom: 3px;
}

.DateInfoAcc .BadgeDate {
  margin: 0;
  font-size: 14px;
  color: #666666 !important;
  font-weight: 400;
}

.CardDesSec {
  width: 25%;
}

.CardAmountSec {
  justify-content: space-between;
  width: 50%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.CardAmountSec>div {
  width: 33.33%;
}

.AccoMainSec {
  border-top: 1px solid #eeeeee;
  margin: -6px 0 0 0;
  padding: 12px 0 0 15px;
}

.AccoMainSec ul {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  /* justify-content: space-between; */
}

.AccoMainSec ul li {
  list-style: none;
  padding: 6px 12px;
  width: 33.33%;
}

.AccoMainSec ul li p {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  align-items: flex-start;
  width: 100%;
}

.AccoMainSec ul li p span {
  color: #343434;
  font-weight: 500;
}

.AccoMainSec ul li.DetailsBUSec {
  width: 100%;
  padding: 10px 12px 15px 12px;
}

.AccoMainSec ul li.WidthFull {
  width: 100%;
}

.AlertText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
  display: flex;
  width: 30%;
}

.AlertText span {
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  position: relative;
  display: inline-block;
  margin-right: 10px !important;
}

.AlertText span:last-child {
  margin: 0;
  padding: 0;
}

.AlertText span:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.AlertText span:last-child:after {
  display: none;
}

@media screen and (min-width: 360px) {
  .AlertText {
    width: 100%;
  }
  .NotesCard2 {
    padding: 20px 20px;
  }
  .RentRollListSec {
    padding: 0 0px;
  }
  .DetailsDivHalf {
    width: 100%;
  }
  .FileListRename,
  .FileListDelete {
    padding: 6px 6px !important;
  }

  .UpFileList {
    width: 100%;
  }

  .FileListRight {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(0px, -50%);
  }

  .FileSize {
    padding: 0 0 0 37px;
    width: 190px;
    min-width: 180px;
    text-align: left;
  }

  .FileName {
    width: 190px;
    min-width: 100px;
  }

  .UpFileListUnder {
    flex-wrap: wrap;
    position: relative;
    padding: 10px 8px 10px 16px;
    ;
  }

  .UpFileList {
    width: 100%;
  }

  .BreadcrumbHeadSec {
    padding: 0 15px 25px 15px;
  }

  .SMProCusSmallDiv {
    min-width: 100%;
    max-width: 100%;
  }

  .FilesUploadGrid {
    width: 100%;
  }

  .NotesCardInside {
    flex-wrap: wrap;
  }

  .CardInsideCon {
    padding-left: 0;
    padding-top: 25px;
  }

  .ApplicationDetab ul li {
    width: 100%;
  }

  .SMProCusBigDiv {
    width: 100%;
  }

  .InsideConName {
    padding-right: 0;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
    padding: 0 12px;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  span.ConNameTrancate {
    max-width: 100%;
  }

  .TextEclipsName {
    max-width: 230px;
  }

  .TextEclips {
    max-width: 170px;
  }

  .mainCard {
    margin: 0 0rem;
  }

  .pageTitleWrap {
    padding: 1rem 1rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 19ch;
  }

  .EditApplicantMain {
    width: 100%;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 100%;
  }

  .card .cardbody {
    padding: 1rem;
  }

  .DropdownActionBU {
    position: absolute;
    top: 5px;
    right: 5px;
  }
  .FileUploadSmall .FileUpDuBUDiv {
    width: 200px;
    height: 90px;
    margin: 0;
    padding: 0;
  }
  .FileUploadSmall .UploadLabel {
    padding: 5px 12px 5px 12px;
  }
  .FileUploadSmall .UploadLabel div.UpIcon {
    width: 45px;
    height: 45px;
    margin-bottom: 0;
    margin-right: 12px;
    padding: 12px;
  }
  .FileUploadSmall .UploadLabel span p {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 6px;
    text-align: left;
  }
  .FileUploadSmall .UploadLabel span p.UploadText {
    margin: 0;
    font-size: 11px;
    line-height: 14px;
  }
  .FileUploadSmall .UploadLabel span {
    align-items: center;
    flex-wrap: nowrap;
  }
  .FileUploadSmall .UploadLabel span > div {
    display: block;
  }
  .FileUploadSmall .ImgFile {
    min-width: 100px;
    width: 33.33%;
    height: 150px;
    position: relative;
    margin: 0;
    padding: 10px 15px 10px 0;
  }
  .FileUploadSmall .UpIMGSec {
    margin: 15px 0px 0 0px;
  }
  .IDImageSec {
    width: 300px;
  }
}

@media screen and (min-width: 480px) {
  .FileName {
    width: 300px;
    min-width: 100px;
  }
}

@media screen and (min-width: 576px) {
  .RentRollListSec {
    padding: 0 0px;
  }
  .DetailsDivHalf {
    width: 50%;
  }
  .FileUploadSmall .ImgFile {
    width: 25%;
  }
  .FileListRename,
  .FileListDelete {
    padding: 6px 10px !important;
  }

  .FileListRight {
    position: unset;
    top: unset;
    right: unset;
    transform: unset;
  }

  .FileSize {
    padding: 0 0 0 0px;
    width: 160px;
    min-width: 160px;
    text-align: center;
  }

  .FileName {
    width: 200px;
    min-width: 100px;
  }

  .UpFileListUnder {
    flex-wrap: nowrap;
  }

  .BreadcrumbHeadSec {
    padding: 0 0px 25px 0px;
  }

  .FilesUploadGrid {
    width: 50%;
  }

  .ApplicationDetab ul li {
    width: 50%;
  }

  .InsideConName {
    width: auto;
    padding-right: 5px;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  span.ConNameTrancate {
    max-width: 300px;
  }

  .TextEclipsName {
    max-width: 280px;
  }

  .TextEclips {
    max-width: 200px;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
  }

  .mainCard {
    margin: 0 1.8rem;
  }

  .pageTitleWrap {
    padding: 1.19rem 1.8rem;
  }

  .breadcrumb li.breadcrumbItem.active {
    max-width: 25ch;
  }

  .card .cardbody {
    padding: 1.5rem;
  }
  .IDImageSec {
    width: 500px;
  }
}

@media screen and (min-width: 768px) {
  .AlertText {
    width: 25%;
  }
  .NotesCardInside {
    flex-wrap: nowrap;
  }

  .CardInsideCon {
    padding-left: 25px;
    padding-top: 0;
  }

  .ApplicationDetab ul li {
    width: 33.33%;
  }

  .TitleWithTag {
    justify-content: unset;
  }

  .MainReqConDivSmall {
    width: 60%;
  }

  .MainReqConDivBig {
    width: 60%;
  }

  .MainReqConAction {
    width: 40%;
    justify-content: flex-end;
  }

  span.ConNameTrancate {
    max-width: 400px;
  }

  .TextEclipsName {
    max-width: 400px;
  }

  .TextEclips {
    max-width: 250px;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .PaymentListSec1 {
    width: 100%;
    padding-bottom: 15px;
  }

  .PaymentListSec2 {
    width: 40%;
    padding-left: 27px;
  }

  .PaymentListSec3 {
    width: 15%;
  }

  .PaymentListSec4 {
    width: 15%;
  }

  .PaymentBodyTableCard {
    width: 33.33%;
  }
}

@media screen and (min-width: 992px) {
  .AlertText {
    width: 30%;
  }
  .CardAmountSec {
    width: 70%;
  }
  .CardDesSec {
    display: none;
  }
  .UpFileList {
    width: CalC(50% - 15px);
  }

  .FilesUploadGrid {
    width: 315px;
  }

  .MainReqConDivBig {
    width: 34%;
  }

  .MainReqConDivSmall {
    width: 38%;
  }

  .MainReqConAction {
    width: 28%;
    justify-content: flex-end;
    padding: 0 12px;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 180px;
  }

  .TextEclips {
    max-width: 250px;
  }

  .EditApplicantMain {
    width: 800px;
  }

  .ProfilePicArea,
  .AppliFormArea {
    width: 400px;
  }
}

@media screen and (min-width: 1024px) {
  .FilesUploadGrid {
    width: 320px;
  }
}

@media screen and (min-width: 1100px) {
  .UpFileList {
    width: 100%;
  }

  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 330px);
  }

  .ApplicationDetab ul li {
    width: 50%;
  }

  span.ConNameTrancate {
    max-width: 500px;
  }

  .MainReqConDivBig {
    width: 55%;
  }

  .MainReqConDivSmall {
    width: 55%;
  }

  .MainReqConAction {
    width: 45%;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 180px;
  }

  .TextEclips {
    max-width: 200px;
  }
}

@media screen and (min-width: 1200px) {
  .CardAmountSec {
    width: 65%;
  }
  .AccoMainSec ul li {
    width: 33.33%;
  }
}

@media screen and (min-width: 1280px) {
  .MainReqConDivSmall {
    width: 60%;
  }

  .MainReqConDivBig {
    width: 60%;
  }

  .MainReqConAction {
    width: 35%;
    justify-content: flex-end;
    padding: 0 0px;
  }

  span.ConNameTrancate {
    max-width: 600px;
  }

  .TextEclipsName {
    max-width: 220px;
  }

  span.TextEclipsName {
    max-width: 180px;
  }

  .TextEclips {
    max-width: 250px;
  }
}

@media screen and (min-width: 1300px) {
  .SMProCusSmallDiv {
    min-width: 330px;
    max-width: 330px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 330px);
  }
}

@media screen and (min-width: 1400px) {
  .UpFileList {
    width: CalC(50% - 15px);
  }

  .SMProCusSmallDiv {
    min-width: 300px;
    max-width: 300px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 300px);
  }

  .ApplicationDetab ul li {
    width: 33.33%;
  }

  .MainReqConDivSmall {
    width: 42%;
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConAction {
    width: 23%;
  }
}

@media screen and (min-width: 1500px) {
  .CardDesSec {
    display: block;
  }
  .CardDesList {
    display: none;
  }
  .CardAmountSec {
    width: 50%;
  }
  .SMProCusSmallDiv {
    min-width: 320px;
    max-width: 320px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 320px);
  }

  .MainReqConDivSmall {
    width: 41%;
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConAction {
    width: 24%;
    padding-right: 15px;
  }

  .NotesCard {
    padding: 15px 25px;
  }

  .TextEclipsName {
    max-width: 110px;
  }

  span.TextEclipsName {
    max-width: 100px;
  }

  .TextEclips {
    max-width: 140px;
  }

  .PaymentListSec1 {
    width: 20%;
    padding-bottom: 0;
  }

  .PaymentListSec2 {
    width: 26%;
    padding-left: 0;
  }

  .PaymentListSec3 {
    width: 17%;
  }

  .PaymentListSec4 {
    width: 20%;
  }

  .DropdownActionBU {
    position: unset;
    top: unset;
    right: unset;
  }

  .PaymentBodyTableCard {
    width: 25%;
  }
}

@media screen and (min-width: 1600px) {
  .NotesCard2 {
    padding: 25px 25px;
  }
  .SMProCusSmallDiv {
    min-width: 350px;
    max-width: 350px;
  }

  .SMProCusBigDiv {
    width: Calc(100% - 350px);
  }

  .MainReqConDivBig {
    width: 35%;
  }

  .MainReqConDivSmall {
    width: 41%;
  }

  .MainReqConAction {
    width: 24%;
  }

  .TextEclipsName {
    max-width: 140px;
  }

  span.TextEclipsName {
    max-width: 120px;
  }

  .TextEclips {
    max-width: 180px;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media screen and (min-width: 1800px) {
  .PaymentBodyTableCard {
    width: 20%;
  }

  .MainReqConDivSmall {
    width: 42%;
  }

  .MainReqConDivBig {
    width: 38%;
  }

  .MainReqConAction {
    width: 20%;
  }

  .TextEclipsName {
    max-width: 250px;
  }

  span.TextEclipsName {
    max-width: 150px;
  }

  .TextEclips {
    max-width: 250px;
  }
}

@media screen and (min-width: 1900px) {}

@media screen and (max-width: 991px) {
  .CardDesSec {
    display: none;
  }
  .CardAmountSec>div {
    width: 100%;
    padding: 1.5px 0;
  }
  .CardAmountSec>div:nth-child(2) {
    display: none;
  }
  .AccoMainSec ul li {
    width: 50%;
  }
}

@media screen and (max-width: 767px) {
  .AccoMainSec ul li {
    width: 100%;
  }
  .BreadcrumbHeadSec nav {
    display: none;
  }

  .PageTitleSec {
    padding: 25px 0 0 0;
  }

  .ReqBigFilter {
    display: none;
  }

  .ReqSmallFilter {
    display: block;
  }

  .ActionsBULine {
    width: auto;
    height: 44px;
    color: #fff !important;
    background-color: #502688 !important;
    padding: 11px 15px 11px 15px !important;
    border: 1px solid #502688 !important;
  }

  .ActionsBULine svg {
    min-width: 16px;
    min-height: 16px;
    width: 18px;
    height: 18px;
    margin-bottom: 0px;
    margin-left: 0px;
  }

  .AddBuText {
    display: none;
  }

  .ActionsBU.Red {
    padding: 11px 14px 11px 14px !important;
  }

  .ActionsBU svg {
    min-width: 16px;
    min-height: 16px;
    width: 22px;
    height: 22px;
    margin: 0px !important;
  }

  .ReqAddCard {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    z-index: 999;
    overflow: auto;
    box-shadow: none;
    padding: 40px 40px;
    height: 100%;
  }

  .AddFormTitleSec {
    display: flex;
  }

  .PaymentListSec1 {
    width: 100%;
    padding-bottom: 15px;
  }

  .PaymentListSec2 {
    width: 100%;
    padding-left: 27px;
    padding-bottom: 15px;
  }

  .PaymentListSec3 {
    width: 40%;
    padding-left: 27px;
  }

  .PaymentListSec4 {
    width: 20%;
  }

  .UpFileList {
    width: 100%;
  }

  .SMProCusRow {
    margin: 0px 0px 0px 0px;
  }

  .DetailsFooter {
    display: none;
  }

  .ToggleBUHalf,
  .FormGroupHalf {
    width: 100% !important;
  }

  .BackAddBU {
    display: flex !important;
  }
}

@media screen and (max-width: 600px) {
  .FormHalfSec,
  .FormHalfSecTwo {
    width: 100%;
  }

  .ReqAddCard {
    padding: 30px;
  }

  .PaymentBodyTableCard {
    width: 100%;
  }
}

@media screen and (max-width: 575px) {
  .FileUploadSmall .ImgFile {
    width: 25%;
    height: 140px;
  }
  .FormHalfSec,
  .FormHalfSecTwo {
    width: 100%;
  }
}

@media screen and (max-width: 480px) {
  .AccoMainSec ul li {
    padding: 6px 0px;
  }

  .AccoMainSec {
    padding: 12px 0 0 0px;
  }

  .AccoMainSec ul li.DetailsBUSec {
    padding: 10px 0px 15px 0px;
  }
  .CussAccoRow {
    padding: 8px 14px 8px 14px;
  }
  .CardAmountSec {
    width: 100%;
    padding: 2px 0 0 28px;
  }
  .PaymentListSec3 {
    width: 100%;
    padding-bottom: 15px;
  }

  .PaymentListSec4 {
    width: 100%;
    padding-left: 27px;
  }

  .EditProfileSec {
    padding: 30px;
  }

  .ButtonSec {
    display: flex;
    justify-content: space-between;
  }

  .AddBU {
    margin: 0;
    width: 47%;
  }

  .CancelBU {
    margin: 0;
    width: 47%;
  }

  .EditFormHalfSec {
    width: 100% !important;
  }

  .ProfileCardEditSec .ButtonArea {
    justify-content: space-between;
  }

  .ProfileCardEditSec .ButtonVoiletSolid,
  .ProfileCardEditSec .ButtonVoiletLine {
    width: 48%;
    margin: 0 !important;
  }
  .FileUploadSmall .ImgFile {
    width: 33.33%;
    height: 120px;
  }
}