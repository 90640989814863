@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

* {
    font-family: 'Inter', sans-serif !important;
}

.RentalAppliArea {
    width: 1000px;
    background-color: #fff;
    margin: -380px auto 0 auto;
    padding: 64px;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.RentalAppliHead {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 50px;
}

.RentalAppliHead img {
    max-width: 240px;
    height: 60px;
    object-fit: contain;
    object-position: center;
    margin-bottom: 30px;
}

.RentalAppliHeadTitle {
    font-weight: 600;
    font-size: 22px;
    line-height: 130%;
    text-align: center;
    color: #2C2C2C;
    margin: 0;
    width: 100%;
}

.ApplicantSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.ApplicantSecTitle {
    font-weight: 500;
    font-size: 20px;
    line-height: 130%;
    text-align: center;
    color: #2C2C2C;
    margin-bottom: 32px;
}

.FormNoteSec {
    width: 100%;
    background: #EFF3FF;
    padding: 16px 23px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.FormNoteSec img {
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.FormNoteText {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0;
}

.FormNoteText span {
    color: #2A5BEE;
}

.FormAccoHead {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FormAccoHeadTitle {
    font-weight: 500;
    font-size: 15px;
    line-height: 150%;
    text-transform: uppercase;
    color: #2C2C2C;
    margin: 0;
}

.AccoCheck {
    width: 24px;
    height: 24px;
    border: 1px solid #DFE1E9;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AccoCheck.Active {
    border: none;
    background: #2FA42B;
}

.AccoCheck svg {
    width: 16px;
    height: 20px;
    display: none;
    color: #fff;
    stroke-width: 3px;
}

.AccoCheck.Active svg {
    display: block;
}

.FooterWrap {
    width: 100%;
    padding: 0 168px;
}

.footerMain {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    border-top: 1px solid rgba(0, 0, 0, .2);
    padding: 95px 0 75px;
    margin-top: 90px;
}

.footerMainLeft {
    width: 20%;
}

.footerMainLeft img {
    max-width: 240px;
    max-height: 100px;
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    object-position: left;
}

.footerMainRight {
    width: 75%;
    padding-top: 0px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-justify-content: space-between;
    justify-content: space-between;
}

.footerMainRight h3 {
    color: #2c2c2c;
    font-size: 15px;
    font-weight: 600;
    padding-bottom: 25px;
    line-height: 18px;
    margin: 0;
    letter-spacing: 0.01em;
}

.footerMainRight ul {
    margin: 0;
    padding: 0;
}

.footerMainRight ul li {
    list-style: none;
    color: #2c2c2c;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 3px;
    text-transform: none;
}

.footerMainRight ul li a {
    color: #2c2c2c;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 3px;
    text-transform: none;
    cursor: pointer;
    -webkit-transition: all .3s;
    transition: all .3s;
    display: inline-block;
}

.footerMainRight ul li a:hover {
    color: #2A5BEE;
}

.CussAccoRow {
    display: flex;
    flex-wrap: wrap;
}

.AccoFormSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

}

.FormGroupHalf {
    width: 48%;
    padding: 15px 0;
    font-size: .875rem;
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

.FormGroupFull {
    width: 100%;
    padding: 15px 0;
    font-size: .875rem;
    position: relative;
}

.ToggleBUHalf {
    width: 48%;
    padding: 15px 0;
    font-size: .875rem;
    display: flex;
    flex-wrap: wrap;
    align-content: flex-start;
}

.FormLabel {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0 0 8px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.FormLabel span {
    font-weight: 400;
    font-size: 15px;
    line-height: 150%;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #BEC1CA;
}

.FormLabelSmall {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0 0 12px 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.formControl {
    display: block;
    width: 100%;
    min-width: 80px;
    min-height: 38px;
    height: 56px;
    padding: .375rem .75rem;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2c2c2c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #DFE1E9;
    border-radius: 0px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.Dollar .formControl {
    padding: 0.375rem .75rem .375rem 4.3rem;
}

.formControl:focus,
.formControlTextarea:focus {
    box-shadow: 0px 0px 10px 0px #649bdd38;
}

.formControlTextarea {
    display: block;
    width: 100%;
    min-width: 80px;
    min-height: 38px;
    height: 56px;
    padding: 12px 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2c2c2c;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #DFE1E9;
    border-radius: 0px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 150px;
    resize: none;
}

.ErrorM {
    color: #f44336;
    font-size: 13px;
    margin-top: 0;
    margin-bottom: 0;
    position: absolute;
    bottom: -23px;
}

.AppliFormToggleBU {
    width: 50%;
    height: 56px;
    background: #fff;
    border: 1px solid #DFE1E9;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2c2c2c;
}

.AppliFormToggleBU.Active {
    width: 50%;
    height: 56px;
    background: #EFF3FF;
    border: 1.5px solid #2A5BEE;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2A5BEE;
}

.CalendarIcon {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 44px;
    height: 44px;
    background: #EFF3FF;
    color: #2A5BEE;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CalendarIcon svg {
    width: 20px;
    height: 20px;
}

.applicantNextBU.Disabled,
.applicantNextBU.Disabled:hover {
    width: 180px;
    height: 56px;
    background: #D4E3FE;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    cursor: auto;
}

.applicantNextBU {
    width: 180px;
    height: 56px;
    background: #2A5BEE;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    transition: all .3s ease-in-out;
}

.applicantNextBU:hover {
    background: #1e47c5;
}

.ShowHideIcon {
    position: absolute;
    top: 6px;
    right: 6px;
    width: 44px;
    height: 44px;
    background: #fff;
    color: #BEC1CA;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
}

.ShowHideIcon svg {
    width: 20px;
    height: 20px;
}

.AccoFormBUsec {
    margin-top: 20px;
    width: 100%;
}

.FormFieldMessage {
    font-weight: 500;
    font-size: 13px;
    line-height: 150%;
    color: #2C2C2C;
    margin-top: 8px;
    margin-bottom: 0;
}

.AccoDollarSign {
    position: absolute;
    top: 0;
    left: 0;
    width: 56px;
    height: 56px;
    background: #F0F0F0;
    color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    margin: 0;
}

.AccoDollarSign svg {
    width: 20px;
    height: 20px;
}

.Width100 {
    width: 100%;
}

.AccoFormSec .Width100 {
    width: 100%;
    position: relative;
    padding-bottom: 0px;
}

.DivFull {
    width: 100%;
}

.FileUpTitle {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0 0 8px 0;
}

.FileUpList {
    margin: 0;
    padding: 0;
}

.FileUpList li {
    list-style: none;
    position: relative;
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    padding: 8px 0 8px 25px;
}

.FileUpList li::after {
    content: '';
    position: absolute;
    width: 8px;
    height: 8px;
    background: #2A5BEE;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
}

.FileIMGDiv {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 8px 0 0 0;
    padding: 12px 0;
}

.FileUpDuBUDiv {
    width: 100%;
    height: 334px;
    margin: 0 0 24px 0;
}

.FileUpDuBUDiv input {
    display: none;
}

.UploadLabel {
    padding: 5px 15px 5px 20px;
    background-color: transparent;
    font-size: 14px;
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
    font-weight: 500;
    -webkit-align-items: center;
    align-items: center;
    line-height: 14px;
    border-radius: .5rem;
    color: #a069c6;
    text-align: center;
    vertical-align: middle;
    border: 1px dashed #2A5BEE;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    height: 100%;
    text-transform: capitalize;
    cursor: pointer;
    position: relative;
}

.UploadLabel svg {
    width: 24px;
    height: 24px;
    margin-bottom: 10px;
}

.UploadLabel div {
    display: none;
}

.UploadLabel span {
    width: 100%;
}

.UploadLabel div.UpIcon {
    display: block;
    width: 100%;
}

.UpIcon img {
    width: 72px;
    height: 72px;
    margin-bottom: 10px;
}

.UploadLabel span p {
    text-align: center;
    width: 100%;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #343434;
    margin-bottom: 8px;
}

.UploadText {
    margin: 0 auto 5px auto;
    width: 300px !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 150% !important;
    text-align: center !important;
    color: #9B9EA7 !important;
}

.ImgFile {
    min-width: 200px;
    width: 200px;
    height: 128px;
    position: relative;
    margin: 0px;
}

.ImgFile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 6px;
}

.ImgFile a {
    position: absolute;
    background: #f40;
    color: #fff;
    border-radius: 50%;
    top: -5px;
    right: -5px;
    width: 23px;
    height: 23px;
    text-align: center;
}

.ImgFile a svg {
    width: 15px;
    height: 15px;
    margin-top: -5px;
}

.ImgFile button {
    position: absolute;
    background: #f40;
    color: #fff;
    border-radius: 50%;
    top: -5px;
    right: -5px;
    min-width: 23px;
    min-height: 23px;
    text-align: center;
    padding: 0;
    width: 23px;
    height: 23px;
}

.ImgFile button svg {
    width: 15px;
    height: 15px;
    margin-top: 0px;
}

.UpFileList {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #F0F0F0;
    padding: 0 0 16px 0;
    margin-bottom: 16px;
}

.UpFileList:last-of-type {
    border-bottom: 0px solid #F0F0F0;
    padding: 0 0 0 0;
    margin-bottom: 16px;
}

.FileName {
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2C2C2C;
    margin: 0;
}

.FileSize {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: #9B9EA7;
    margin: 0 12px 0 0;
}

.FileListRight {
    display: flex;
    align-items: center;
}

.FileListRight a {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #2C2C2C;
    background-color: #fff;
    margin-top: -3px;
}

.FileListRight a svg {
    width: 18px;
    height: 18px;
}

.SignButton {
    width: 182px;
    height: 56px;
    background: #2A5BEE;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 16px;
}

.SignedButton {
    width: 241px;
    height: 56px;
    background: #2FA42B;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    margin-top: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.SignedButton .Icon {
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    background: #fff;
    border-radius: 50%;
    color: #2FA42B;
}

.SignedButton .Icon svg {
    width: 16px;
    height: auto;
    margin-top: 2px;
    stroke-width: 3px;
}

.RentallAppliSubmitBU {
    width: 100%;
    height: 64px;
    background: #D4E3FE;
    border: none;
    margin-top: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #FFFFFF;
    padding: 20px;
    cursor: auto !important;
}

.RentallAppliSubmitBU.Active {
    background: #2A5BEE;
    cursor: pointer !important;
}

.CusRadioRow {
    width: 100%;
    display: flex;
    position: relative;
    padding-bottom: 0px
}

.CusRadio {
    width: 50%;
    cursor: pointer;
}

.checkmark {
    width: 100%;
    height: 56px;
    background: #fff;
    border: 1px solid #DFE1E9;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #2c2c2c;
    display: flex;
    justify-content: center;
    align-items: center;
}

.CusRadio input:checked~.checkmark {
    background: #EFF3FF;
    border: 1.5px solid #2A5BEE;
    color: #2A5BEE;
}

.CusRadio input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.RentalAppliSuccessSec {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-top: 80px;
}

.SuccessImg {
    width: 120px;
    margin-bottom: 60px;
}

.SuccessText {
    width: 100%;
    font-size: 48px;
    text-align: center;
    width: 100%;
    font-size: 48px;
    text-align: center;
    color: #505050;
    font-weight: 300;
}

.PaymentSuccessTitle {
    font-family: 'Roboto', sans-serif;
    color: #44c4a1;
    font-size: 60px;
    font-weight: 100;
    text-align: center;
    width: 100%;
}

.PaymentSuccessTitleSub {
    font-family: 'Roboto', sans-serif;
    color: #505050;
    font-size: 28px;
    font-weight: 400;
    text-align: center;
    width: 100%;
}

form {
    width: 100%;
}

@media screen and (min-width: 360px) {
    .FooterWrap {
        width: 90%;
        padding: 0 0px;
        margin: 0 auto;
    }

    .footerMainLeft,
    .footerMainRight {
        width: 100%;
    }

    .footerMainRight div {
        width: 100%;
        text-align: center;
    }

    .footerMainRight ul {
        padding-bottom: 20px;
        padding-left: 0;
    }

    .footerMainLeft img {
        object-position: center;
        margin: 0 auto 60px auto;
    }

    .RentalAppliArea {
        width: 90%;
        padding: 64px 0;
    }

    .ToggleBUHalf,
    .FormGroupHalf {
        width: 100%;
    }

    .RentallAppliSubmitBU img {
        display: none;
    }
}

@media screen and (min-width: 576px) {
    .RentallAppliSubmitBU img {
        display: block;
    }
}

@media screen and (min-width: 768px) {

    .ToggleBUHalf,
    .FormGroupHalf {
        width: 48%;
    }

    .footerMainRight>div:last-of-type {
        padding-top: 40px;
    }
}

@media screen and (min-width: 992px) {

    /* .footerMainLeft {
        width: 20%;
    }

    .footerMainRight {
        width: 75%;
    } */
    .RentalAppliArea {
        width: 900px;
        padding: 64px;
    }

    .FooterWrap {
        width: 100%;
        padding: 0 78px;
        margin: 0 auto;
    }

    .footerMainRight div {
        width: auto;
        text-align: left;
    }
}

@media screen and (min-width: 1200px) {
    .RentalAppliArea {
        width: 1000px;
    }

    .FooterWrap {
        width: 100%;
        padding: 0 168px;
    }
}

@media screen and (min-width: 1366px) {
    .footerWrap {
        padding: 0 168px;
    }

    .footerMainLeft {
        width: 20%;
    }

    .footerMainRight {
        width: 78%;
    }

    .footerMainLeft img {
        object-position: left;
        margin: 0;
    }
}

@media screen and (min-width: 1500px) {
    .footerMainLeft {
        width: 20%;
    }

    .footerMainRight {
        width: 75%;
    }
}