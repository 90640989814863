.pageTitleWrap {
  width: 100%;
  padding: 1.8rem 1.8rem 1.2rem 1.8rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.pageTitle {
  margin: 0;
  color: #505050;
  font-size: 18px !important;
  font-weight: 600 !important;
  line-height: 27px !important;
  font-family: "Inter", sans-serif !important;
  letter-spacing: 0.01em !important;
}

.breadcrumb {
  background: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  border-radius: 0.25rem;
  font-size: 13px;
  color: #ff8271;
  align-self: center;
  font-weight: 500;
}

.breadcrumb li.breadcrumbItem.active {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 25ch;
  color: #505050;
  font-weight: 400;
}

.breadcrumbItem a {
  text-decoration: none;
  color: #505050;
}

.breadcrumbItem a:hover {
  text-decoration: underline;
}

.breadcrumbItem + .breadcrumbItem {
  padding-left: 0.5rem;
}

.breadcrumb li.breadcrumbItem + .breadcrumbItem::before {
  /* font-size: 14px; */
  display: inline-block;
  padding-right: 0.5rem;
  color: #6c757d;
  content: "/";
}

.breadcrumb_menuIconsSize {
  width: 14px;
  height: auto;
  padding-right: 5px;
}

.breadcrumbItem span svg {
  width: 16px;
  height: 16px;
  stroke-width: 2;
  margin-right: 8px;
  margin-top: -4px;
}

.actionClass a {
  color: #666666;
}

.imgDiv img {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  border: 2px solid white;
  object-fit: cover;
  display: block;
  margin: 0 auto;
}

.imgDiv a {
  text-decoration: none;
}

.allClientsNameInner {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.allClientsNameInner span {
  padding-left: 10px;
  font-size: 14px;
  font-weight: 500;
  color: #505050;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 5px;
}

.allClientsNameInner span:hover {
  color: #1565c0 !important;
}

.MuiAvatar-img {
  color: transparent;
  width: 100%;
  height: 100%;
  object-fit: cover;
  text-align: center;
  text-indent: 10000px;
}

.modal {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  /* display: none; */
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  max-width: 500px;
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal-content {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.e-input-group:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::before,
.e-input-group.e-float-icon-left:not(.e-float-input) .e-input-in-wrap::after,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::before,
.e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::before,
.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-float-input)
  .e-input-in-wrap::after {
  background: #1565c0;
}

.mainCard {
  padding: 0px;
  border-radius: 0.5rem;
  margin: 0px 1.8rem 0 1.8rem;
  position: relative;
  /* background: white; */
  border: none;
  /* box-shadow: 0 0px 7px 2px rgba(62, 85, 120, 0.1); */
  margin-bottom: 20px;
}

.actionClass svg {
  margin-right: 10px;
  stroke-width: 1px;
  stroke: #000;
  width: 21px;
  height: 21px;
  cursor: pointer;
}

.actionClass button {
  background: none;
  border: none;
}

.clientDoubleButton {
  width: 110px;
  float: right;
  display: flex;
  /* justify-content: flex-end; */
}

.TableAddBU {
  float: right;
}

.TableAddBU a {
  align-items: center;
  padding: 9px 14px;
  background: #ea983e;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
}

.TableAddBUSecondary {
  float: right;
}

.TableAddBUSecondary button {
  align-items: center;
  padding: 9px 14px;
  background: #230058;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 5px;
  transition: all 0.3s;
  outline: none;
  border: none;
}

.TableAddBUSecondary button:hover {
  background: #56328c;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
}

.customFieldsetTitle {
  padding: 15px;
  font-size: 15px;
  font-weight: 500;
  color: #1565c0;
}

.noMarginBottom {
  margin-bottom: 0 !important;
  width: 100%;
  margin: 0;
  padding-left: 5px;
  padding-right: 16px;
}

.noPaddingTop li {
  padding-top: 0 !important;
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.noPaddingBottom li {
  padding-bottom: 0 !important;
}

.ReferStatusBU {
  padding: 5px 8px 5px 13px !important;
  background: #e2e2e2 !important;
  border: 0px solid #d5d5d5 !important;
  border-radius: 20px !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  /* color: #9f9f9f; */
  transition: all 0.3s !important;
  margin: 0 !important;
  line-height: unset !important;
  /* height: 26px !important; */
  display: flex;
  align-items: center;
}

.ReferStatusBUNew {
  padding-right: 10px !important;
}

.ReferStatusBU:after {
  display: none !important;
}

.ReferStatusBU:focus {
  border: none;
  box-shadow: none !important;
}

.ReferStatusBU svg {
  width: 14px;
  height: 14px;
  stroke-width: 2;
  margin-bottom: 0px;
  margin-left: 8px;
  margin-right: 5px;
}

.ReferStatusBU svg.DownArrowStatus {
  width: 18px;
  height: 18px;
  margin-right: 0px;
  margin-bottom: 0px;
}

.ReferStatusBU.StatusBuRed,
.ReferStatusBU.StatusBuRed:active,
.ReferStatusBU.StatusBuRed:checked {
  background-color: #f44336 !important;
  border-color: #f44336 !important;
  color: #fff !important;
}

.ReferStatusBU.StatusBuYellow,
.ReferStatusBU.StatusBuYellow:active,
.ReferStatusBU.StatusBuYellow:checked {
  background-color: #ffbb33 !important;
  border-color: #ffbb33 !important;
  color: #000 !important;
}

.ReferStatusBU.StatusBuGreen,
.ReferStatusBU.StatusBuGreen:active,
.ReferStatusBU.StatusBuGreen:checked {
  background-color: #17dd1b !important;
  border-color: #17dd1b !important;
  color: #fff !important;
}

.ReferStatusBU.Open,
.ReferStatusBU.Open:active,
.ReferStatusBU.Open:checked {
  background: #5026884d !important;
  color: #502688 !important;
  border-color: #5026884d !important;
}

.ReferStatusBU.InProgress,
.ReferStatusBU.InProgress:active,
.ReferStatusBU.InProgress:checked {
  background-color: #fff3e8 !important;
  border-color: #fff3e8 !important;
  color: #e99a37 !important;
}

.ReferStatusBU.Resolved,
.ReferStatusBU.Resolved:active,
.ReferStatusBU.Resolved:checked {
  background-color: #e8fff2 !important;
  border-color: #e8fff2 !important;
  color: #5ac55e !important;
}

.ReferStatusBU.Cancelled,
.ReferStatusBU.Cancelled:active,
.ReferStatusBU.Cancelled:checked {
  background: #ffe8e8 !important;
  color: #e23725 !important;
  border-color: #ffe8e8 !important;
}

.ApplicantFilterSec {
  width: 280px;
  display: flex;
  flex-wrap: wrap;
  padding: 15px 20px;
  align-items: center;
}

.ApplicantFilterSec p {
  font-size: 14px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: table;
  width: 100%;
}

.ClearFilter {
  font-size: 13px;
  font-weight: 500;
  color: #ff8271;
  width: 55px;
  margin-left: 0;
  display: table-cell;
  vertical-align: middle;
}

.ApplicantFilterSec .formControl {
  margin-top: 10px;
}

.NotesRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -12px;
  min-height: 640px;
  align-content: space-between;
}

.NotesCard {
  width: 100%;
  border-radius: 6px;
  padding: 15px 25px;
  margin: 10px 0;
  /*overflow: hidden;*/
  cursor: pointer;
  position: relative;
  box-shadow: 0 0 20px 0 #f5f5f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
}

.NotesCard:hover {
  box-shadow: 0 0 20px 0 #f2f2f2;
}

.InsideConText {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  width: 25%;
  padding: 8px 15px;
}

.InsideConUnit {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  /*width: 25%;*/
  padding: 8px 15px;
  text-align: center;
}

.NotesSearchArea {
  width: 100%;
  display: flex;
}

.NotesSearchMain {
  width: 100%;
  position: relative;
  border-bottom: 1px solid #efefef;
  margin-bottom: 10px;
  margin-top: 15px;
}

.searchInput {
  width: 100%;
  color: #8c8c8c;
  border: none;
  font-size: 13px;
  font-weight: 500;
  height: 24px;
  padding-right: 30px;
}

.NotesSearchMain svg {
  position: absolute;
  right: 0;
  bottom: 0;
  color: #8c8c8c;
  width: 15px;
}

.AddNoteBu {
  -webkit-align-items: center;
  align-items: center;
  padding: 9px 14px;
  background: #ff8271;
  display: -webkit-flex;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s ease-in-out;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.AddNoteBu:hover,
.AddNoteBu:active,
.AddNoteBu:focus {
  background: #e97565;
  background-color: #e97565;
  color: #fff;
}

.AddNoteBu svg {
  width: 22px;
  height: 22px;
  margin-right: 10px;
}

.FilterBu {
  align-items: center;
  padding: 9px 14px;
  background: #502688;
  display: flex;
  min-height: 0;
  color: #fff;
  border-radius: 8px;
  transition: all 0.3s;
  outline: none;
  border: none;
  height: 42px;
  margin-left: 10px;
  margin-right: 10px;
}

.FilterBu svg {
  min-width: 20px;
  height: 20px;
  margin-right: 0px;
}

.FilterBu:hover,
.FilterBu:active,
.FilterBu:focus {
  background: #230058;
  background-color: #230058;
  color: #fff;
}

.BodyCon {
  display: flex;
}

.BodyFilterArea {
  min-width: 280px;
  max-width: 280px;
  background: #fff;
  position: fixed;
  right: 0;
  height: 100vh;
  padding: 90px 30px 120px 30px;
  overflow: auto;
  top: 60px;
  box-shadow: 0 0px 7px 2px rgb(62 85 120 / 10%);
  transition: all ease-in-out 0.5s;
  z-index: 10;
}

.FilterTitle {
  font-size: 14px;
  line-height: 30px;
  font-weight: 600;
  color: #340380;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 10px;
}

.FilterTitle svg {
  width: 20px;
  height: 20px;
  margin-bottom: 4px;
}

.FilterList {
  margin-bottom: 20px;
  font-size: 0.875rem !important;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.formControl {
  display: block;
  width: 100%;
  min-width: 150px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: transparent;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  position: relative;
  z-index: 2;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 0px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.ApplicantDiv {
  padding-top: 0px;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 25%;
}

/*.ApplicantDiv::before {
  content: '';
  position: absolute;
  width: 100%;
  height: 1px;
  background: #f1f1f1;
    top: 25px;
  left: 0;
}*/

.ApplicantDivTitle {
  margin: 0;
  font-size: 14px;
  color: #ff8271;
  font-weight: 400;
  width: auto;
  display: inline-block;
  /* background: #fff; */
  padding: 8px 0;
  position: relative;
  /* z-index: 1; */
}

.ApplicantDiv .InsideConUnit:first-child {
  text-align: left;
}

.ApplicantDiv .InsideConUnit:last-child {
  text-align: right;
}

.ApplicantName {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #333;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ApplicantNameDe {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500;
  color: #666;
  margin-bottom: 0;
  padding: 8px 0px;
  text-align: left;
}

.ApplicantDivHalf {
  width: 30%;
}

.ApplicantDivHalf:last-child {
  width: 65%;
}

.ApplicantAction {
  display: flex;
  align-items: center;
  width: 20%;
}

.mainCardDiv {
  width: 100%;
  padding-right: 280px;
  padding-bottom: 80px;
}

.FilterList {
  margin-bottom: 20px;
}

.FilterLabel {
  font-size: 13px;
  line-height: 35px;
  font-weight: 500;
  color: #333333;
  margin: 0;
}

.FilterOnOffBU {
  position: absolute;
  left: -30px;
  top: 70px;
  /* height: 30px; */
  background: #230058;
  color: #fff;
  padding: 4px 8px;
  /* border-radius: 5px; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.FilterOnOffBU svg {
  width: 18px;
  height: 18px;
  margin-top: 4px;
}

.DatePickerCustom {
  position: relative;
}

.DatePickerCustom svg {
  position: absolute;
  top: 8px;
  color: #757575;
  width: 18px;
  height: 18px;
  right: 10px;
}

.MainReqConDropBU {
  padding: 0 !important;
  width: 160px !important;
  margin-right: -10px;
  position: relative !important;
}

.TagDiv {
  font-size: 12px;
  background: #999;
  color: #fff;
  padding: 2px 8px;
  border-radius: 5px;
}

.TagDiv.Red {
  background: #fb746a;
}

.TagDiv.Yellow {
  background: #ffbb33;
}

.TagDiv.Common {
  background: #eee4ff;
  color: #230058;
}

.NoDataMain {
  width: calc(100% - 24px);
  height: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: center;
  background: #fff;
  position: relative;
  margin: auto;
}

.NoDataIMG {
  width: 150px;
  height: 150px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 35px;
  margin: 0 auto 40px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.NoDataIMG img {
  width: 100%;
  opacity: .4;
}

.NoDataText {
  text-align: center;
  font-size: 36px;
  padding: 0px 0 !important;
  font-weight: 700;
  color: rgba(0, 0, 0, 0.5);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataTextSub {
  text-align: center;
  font-size: 22px;
  padding: 0px 0 !important;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.3);
  line-height: unset;
  text-transform: capitalize;
  width: 100%;
}

.NoDataMainAdd {
  width: 100%;
  margin: 50px 0;
}

.NoDataAddIMG {
  width: 110px;
  height: 110px;
  background: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 50%;
  padding: 30px;
  margin: auto;
}

.NoDataAddText {
  text-align: center;
  font-size: 30px;
  padding: 20px 0 0 0 !important;
  font-weight: 700;
  color: #e5e5e5;
  line-height: unset;
  text-transform: capitalize;
}

.FilterShowingDiv {
  background: #f1f1f1;
  width: 100%;
  margin-top: 5px;
  padding: 10px 15px;
  border-radius: 5px;
}

.TitleFilterPill {
  font-size: 13px;
  font-weight: 500;
  color: #666;
  min-width: 70px;
  margin: 0;
  display: inline-block;
}

.FilterPill {
  margin-top: 5px;
  margin-right: 5px;
  background: #230058;
  color: #fff;
  padding: 4px 6px;
  display: inline-block;
  width: max-content;
  border-radius: 3px;
  font-size: 13px;
}

.FilterPill span {
  max-width: 190px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: inline-block;
  margin-bottom: -5px;
}

.FilterPill a {
  color: #fff;
  border-left: 1px solid #49149a;
  margin-left: 7px;
  padding-left: 3px;
  display: inline-block;
}

.FilterPill a:hover {
  color: #fff;
}

.FilterPill a svg {
  width: 17px;
  height: 17px;
  margin-bottom: 1px;
}

.ActionsDiv {
  margin-right: 15px;
}

.ActionsDiv svg {
  width: 20px;
  height: 20px;
  color: #8a8a8a;
}

button.TableAddBUSecondary {
  align-items: center;
  padding: 9px 14px;
  background: #fff !important;
  display: flex;
  min-height: 0;
  border: 1px solid #e1e1e1 !important;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #8482a1 !important;
  margin: 0 !important;
  margin: 0 10px !important;
  border-radius: 8px;
}

button.TableAddBUSecondary:hover,
button.TableAddBUSecondary:active {
  background: #fff !important;
  color: #8482a1 !important;
  border: 1px solid #ced4da !important;
}

button.TableAddBUSecondary:after {
  display: none;
}

.TableAddBUSecondary svg {
  width: 20px;
  height: 20px;
  margin-left: 12px;
}

.SearchFilterSec {
  /* width: 100%; */
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 -10px;
  padding: 5px 0 20px 0;
  justify-content: space-between;
}

.SearchCon {
  width: 50%;
  padding: 0 10px;
}

.SearchConSec {
  width: 100%;
  border: 1px solid #e1e1e1;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 8px 8px;
  background: #fff;
}

.SearchConSec input {
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  width: 100%;
  border: none;
}

.SearchConSec svg {
  width: 20px;
  opacity: 0.8;
  margin-right: 10px;
}

.FilterSec {
  width: 50%;
  display: flex;
}

.FilterCard {
  width: 33%;
  padding: 0 10px;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterCardDP {
  width: 100%;
  padding: 10px 0;
  font-size: 0.875rem !important;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
}

.FilterBUCard {
  width: 30%;
  padding: 0 10px;
  max-width: 150px;
}

.FilterCard.Select > div > div {
  min-height: 42px;
  border-color: #e1e1e1;
  border-radius: 8px;
}

.DeviceCardFirstSec {
  width: 45%;
  display: flex;
  align-items: center;
}

.DeviceIconSec {
  width: 44px;
  height: 44px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  background-color: #fbf7fc;
}

.DeviceTitle {
  font-size: 14px;
  font-weight: 500;
  color: #333333;
  line-height: 150%;
  margin: 0;
}

.DeviceText {
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  line-height: 160%;
  margin: 0;
}

.DeviceText span {
  margin: 0 12px 0 0;
  padding: 0 12px 0 0;
  position: relative;
  display: inline-block;
}

.DeviceText span:last-child {
  margin: 0;
  padding: 0;
}

.DeviceText span:after {
  content: "";
  position: absolute;
  width: 4px;
  height: 4px;
  background: #b7b7b7;
  border-radius: 50%;
  right: -2px;
  top: 50%;
  -webkit-transform: translate(0px, -50%);
  transform: translate(0px, -50%);
}

.DeviceText span:last-child:after {
  display: none;
}

.DeviceCardSecondSec {
  width: 25%;
}

.RightAlign {
  text-align: right;
}

.StatusPill {
  padding: 2.5px 10px 2.5px 10px;
  background: #e2e2e2;
  border: 0px solid #d5d5d5;
  border-radius: 20px;
  font-size: 13px;
  font-weight: 500;
  margin: 0 0 2px 0;
  line-height: unset;
  display: inline-block;
}

.StatusPill.Red,
.StatusPill.Red:active,
.StatusPill.Red:checked {
  background: #ffe8e8;
  color: #e23725;
  border-color: #ffe8e8;
}

.StatusPill.Green,
.StatusPill.Green:active,
.StatusPill.Green:checked {
  background-color: #e8fff2;
  border-color: #e8fff2;
  color: #5ac55e;
}

.AddDevice {
  width: 100%;
  border-radius: 8px;
  padding: 20px 30px;
  margin: 10px 0;
  box-shadow: 0px 0px 20px 0px rgba(245, 245, 245, 1);
}

.AddDeviceTitle {
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: #505050;
  width: 100%;
  margin-bottom: 5px;
}

.AddDeviceForm {
  width: 750px;
}

.AddDeviceForm {
  width: 100%;
}

.AddDeviceFormRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 0px;
  padding-top: 15px;
  padding-bottom: 0px;
  justify-content: space-between;
}

.AddDeviceFormTitle {
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #2c2c2c;
  margin-bottom: 0px;
  width: 100%;
  padding: 0 0px;
}

.HalfSecHalf {
  width: calc(50% - 10px) !important;
  padding: 14px 0px !important;
}

.OneThirdSec {
  width: calc(50% - 10px) !important;
  padding: 14px 0px !important;
}

.FormMessage {
  font-size: 12px;
  margin-bottom: 0;
}

.ButtonSec {
  width: 100%;
  padding-top: 20px;
  display: flex;
}

.AddBU {
  padding: 15px 25px;
  background-color: #ff8271;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #fff;
  text-align: center;
  /* vertical-align: middle; */
  border: 1px solid #ff8271;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.AddBU:hover {
  background-color: #e16e5f;
  border: 1px solid #e16e5f;
}

.CancelBU {
  padding: 15px 25px;
  background-color: #fff;
  margin: 15px 15px 0 0;
  font-size: 14px;
  width: auto;
  font-weight: 600;
  line-height: 14px;
  border-radius: 0.5rem;
  color: #343434;
  border: 1px solid #e1e1e1;
  text-align: center;
  /* vertical-align: middle; */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  outline: none;
}

.CancelBU:hover {
  background-color: #f1ecf3;
  /* border: 1px solid #230058;
  color: #fff; */
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
  margin-bottom: 0;
}

.AddDeviceContent {
  margin-right: -25px !important;
}

.AddDeviceFormArea {
  padding: 0 15px 0 0;
}

.AddDeviceDatePick {
  width: 230px;
  float: right;
  display: flex;
  justify-content: space-between;
}

.FilterFieldSec {
  width: 100%;
  display: flex;
}

.Width200 {
  min-width: 200px;
}

.Width150 {
  min-width: 150px;
}

.BackAddBU {
  width: 35px;
  height: 35px;
  display: none;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
  border-radius: 4px;
  color: #505050 !important;
  background: #f0f0f0 !important;
}

.BackAddBU svg {
  width: 20px !important;
  height: auto !important;
  stroke-width: 2 !important;
}

.AddDeviceSec {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 25px;
  padding-bottom: 14px;
}

.AddDeviceSec .SearchConSec {
  width: calc(100% - 250px);
  margin-right: 20px;
}

.AddDeviceRow {
  display: flex;
  flex-wrap: wrap;
  margin: 5px -12px 25px -12px;
  padding: 0 4px;
  align-content: space-between;
  max-height: 550px;
  overflow: auto;
}

.AddDeviceList {
  width: 33.33%;
  padding: 0;
}

.AddDeviceListUnder {
  width: 100%;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  border-radius: 8px;
  padding: 16px;
}

.AddDeviceListUnder.Active {
  background: #502688;
}

.AddDeviceListHeadSec {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.AddDeviceListUnder.Active .AddDeviceListHeadSec {
  border-bottom: 1px solid #8b71b2;
}

.AddDeviceListIconSec {
  min-width: 50px;
  width: 60px;
  min-height: 50px;
  height: 60px;
  border-radius: 50%;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.AddDeviceListUnder.Active .AddDeviceListIconSec {
  background-color: rgb(35 0 88 / 21%);
}

.AddDeviceListIconSec img {
  width: 32px;
  height: 32px;
  object-fit: contain;
  object-position: center;
}

.AddDeviceListUnder.Active .AddDeviceListIconSec img {
  filter: brightness(0) invert(1);
}

.AddDeviceListTitleSec {
  width: calc(100% - 76px);
}

.AddDeviceListTitle {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 5px 0;
  color: #505050;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AddDeviceListTitle span {
  width: calc(100% - 70px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AddDeviceListTitleTwo {
  font-size: 16px;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 5px 0;
  color: #505050;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.AddDeviceListUnder.Active .AddDeviceListTitle,
.AddDeviceListUnder.Active .AddDeviceListTitleTwo {
  color: #fff;
}

.AddDeviceListBodySec {
  display: flex;
  flex-wrap: wrap;
}

.AddDeviceListBodySec ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.AddDeviceListBodySec ul li {
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: center;
}

.AddDeviceListBodySec ul li .Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
  min-width: 120px;
}

.AddDeviceListBodySec ul li .Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  color: #343434;
}

.AddDeviceCheckbox {
  width: 100%;
  padding: 0 12px;
}

.AddDeviceCheckbox span {
  font-weight: 500 !important;
  font-size: 14px !important;
}

.DeviceCheckbox {
  width: 42px;
  height: 42px;
  position: absolute;
  top: -20px;
  right: -30px;
}

.DeviceCheckbox label span {
  background-color: transparent !important;
}

.ProspectCard {
  width: 25%;
  padding: 15px 12px;
}

.ProspectCardUnder {
  width: 100%;
  background: #fff;
  box-shadow: 0 0 20px 0 #1f025412;
  border-radius: 8px;
  padding: 16px;
  transition: all 0.3s ease-in-out;
}

.ProspectCardUnder:hover {
  box-shadow: 0 0 28px 0 #1f02541f;
}

.ProspectCardHeadSection {
  display: flex;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 16px;
  margin-bottom: 8px;
  position: relative;
}

.ProspectCardHeadSection .ProsIMGSec {
  min-width: 60px;
  width: 70px;
  min-height: 60px;
  height: 70px;
  border-radius: 8px;
  margin-right: 16px;
  overflow: hidden;
  background-color: #fbf7fc;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.ProsIMGSec .ProspectCardImg {
  width: 35px;
  height: 35px;
  object-fit: contain;
  object-position: center;
}

.ProsIMGSec p {
  font-size: 24px;
  font-weight: 600;
  line-height: 150%;
  margin: 0;
  color: #502688;
  letter-spacing: 1px;
}

.ProspectCardHeadSection .HeadConSec {
  width: calc(100% - 86px);
}

.ProsUserName {
  font-size: 17px;
  font-weight: 600;
  line-height: 150%;
  margin: 0 0 7px 0;
  color: #505050;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ProsUserName.LimitName {
  width: calc(100% - 85px);
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  text-wrap: nowrap;
  margin: 0 12px 0 0;
}

.ProsUserMandateSec {
  width: 100%;
  display: flex;
  padding-top: 4px;
}

.MandateCheckSec {
  width: 24px;
  height: 24px;
  background-color: #dfe2e6;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
}

.MandateCheckSec:last-child {
  margin-right: 0;
}

.MandateCheckSec.Active {
  background-color: #27c27b;
}

.MandateCheckSec.Fail {
  background-color: #ff8271;
}

.MandateCheckSec svg {
  width: 14px;
  height: auto;
  color: #fff;
  stroke-width: 3px;
}

.ProspectCardBodySec {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dfe2e6;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.ProspectCardBodySec ul {
  margin: 0;
  padding: 0;
  width: 100%;
}

.ProspectCardBodySec ul li {
  list-style: none;
  padding: 8px 0;
  display: flex;
  align-items: flex-start;
}

.ProspectCardBodySec ul li p.Title {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
  min-width: 110px;
}

.ProspectCardBodySec ul li p.Content {
  font-size: 14px;
  font-weight: 500;
  line-height: 21px;
  margin: 0;
  color: #343434;
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ProspectCardFootSec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 5px;
}

.ProsCardDate {
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  margin: 0;
  color: #666666;
}

.AddDeviceListUnder.Active .ProsCardDate {
  color: #fff;
}

.OpenBadge {
  background-color: rgb(232, 255, 242);
  color: #5ac55e !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  width: auto !important;
}

.OfflineBadge {
  background-color: #ffe8e8;
  color: #e23725 !important;
  font-weight: 500 !important;
  font-size: 12px;
  padding: 5px 12px !important;
  border-radius: 15px;
  margin: 0;
  height: 25px;
  line-height: normal;
  width: auto !important;
}

.AddDeviceListUnder .ProspectCardFootSec .MainReqConDropBU .ResetRoomSelect {
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  background-color: transparent;
  color: #000;
  top: 5px;
  right: 30px;
}

.AddDeviceListUnder.Active .ProspectCardFootSec .MainReqConDropBU .ResetRoomSelect {
  display: flex;
}

.ResetRoomSelect svg {
  width: 16px;
  height: 16px;
}

.AddDeviceCardRoom {
  display: flex;
  align-items: center;
}

.AddDeviceCardRoom p {
  font-size: 14px;
  color: #fff;
  margin: 0 8px 0 0;
}

.AddDeviceCardRoom button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
  background-color: transparent;
  color: #fff;
}

.AddDeviceCardRoom button svg {
  width: 16px;
  height: 16px;
}

.DeviceSelectedMSG {
  width: 100%;
  text-align: right;
  font-size: 14px;
  color: #343434;
  margin: 0;
}

.ModalFormGroup {
  max-width: 1100px;
}

.ModalFormGroupTwo {
  max-width: 100%;
}

.SelectedDeviceList {
  margin: 0;
  padding: 5px 0 20px 0 !important;
  width: 100%;
}

.SelectedDeviceList li {
  display: flex;
  align-items: center;
  padding: 12px 0;
}

.SelectedDeviceList li p {
  font-size: 14px;
  font-weight: 500;
  margin: 0 0 0 8px;
}

.SelectRoomStepTwo {
  padding: 0 0 0 5px !important;
  width: 150px !important;
  margin-right: 0px;
  position: relative !important;
}

.ActionsBU {
  width: auto;
  height: 42px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #fff !important;
  background-color: #502688 !important;
  margin: 0 10px !important;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
}

.ActionsBUIcon {
  width: auto;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #6d6d6d !important;
  background-color: transparent !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 15px !important;
  border-radius: 8px !important;
}

.ActionsBU.Red {
  background-color: #ff8271 !important;
  padding: 11px 20px 11px 15px !important;
}

.ActionsBULine {
  width: 200px;
  height: 44px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  font-size: 14px !important;
  line-height: 20px !important;
  font-weight: 400 !important;
  color: #502688 !important;
  background-color: #fff !important;
  margin: 0;
  border: none !important;
  padding: 11px 15px 11px 20px !important;
  border-radius: 8px !important;
  border: 1px solid #502688 !important;
}

.ActionsBU:hover,
.btn-check:active + .btn-primary.ActionsBU,
.btn-check:checked + .btn-primary.ActionsBU,
.ActionsBU.btn-primary.active,
.ActionsBU.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU {
  background-color: #230058;
  border: none;
}

.ActionsBUIcon:hover,
.btn-check:active + .btn-primary.ActionsBUIcon,
.btn-check:checked + .btn-primary.ActionsBUIcon,
.ActionsBUIcon.btn-primary.active,
.ActionsBUIcon.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBUIcon {
  background-color: transparent;
  border: none;
}

.ActionsBU.Red:hover,
.btn-check:active + .btn-primary.ActionsBU.Red,
.btn-check:checked + .btn-primary.ActionsBU.Red,
.ActionsBU.Red.btn-primary.active,
.ActionsBU.Red.btn-primary:active,
.show > .btn-primary.dropdown-toggle.ActionsBU.Red {
  background-color: #ed7464;
  border: none;
}

.ActionsBU:focus,
.ActionsBULine:focus {
  outline: none;
}

.ActionsBU svg,
.ActionsBULine svg {
  min-width: 16px;
  min-height: 16px;
  width: 18px;
  height: 18px;
  margin-bottom: -2px;
  margin-left: 10px;
}

.ActionsBUIcon svg {
  min-width: 16px;
  min-height: 16px;
  width: 22px;
  height: 22px;
  margin-bottom: 0;
  margin-left: 0;
}

.ActionsBU.Red svg {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-left: 0;
}

.ActionsBU:after,
.ActionsBULine:after {
  display: none !important;
}

.ActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 158px;
}

.ActionsBUListItem.Delete,
.ActionsBUListItem.Delete:hover,
.ActionsBUListItem.Delete:focus {
  color: #f97665;
}

.ActionsBUListItem:hover,
.ActionsBUListItem:focus,
.ActionsBUListItem:active {
  color: #343434;
  background: #f8f8f8;
}

.ActionsBUListItem:last-child {
  border: none;
}

.EditActionsBUListItem {
  font-size: 14px;
  font-weight: 500 !important;
  color: #343434 !important;
  /* border-bottom: 1px solid #e0e0e0; */
  padding: 10px 10px !important;
  min-width: 80px;
  display: flex;
  align-items: center;
  font-family: "Inter", sans-serif !important;
}

.EditActionsBUListItemSmall {
  font-size: 13px;
  padding: 8px 10px !important;
}

.EditActionsBUListItem.Delete,
.EditActionsBUListItem.Delete:hover,
.EditActionsBUListItem.Delete:focus,
.EditActionsBUListItem.Delete:active {
  color: #f44336 !important;
}

.EditActionsBUListItem svg {
  width: 17px;
  height: auto;
  margin-right: 12px;
  stroke-width: 2px;
  color: #343434;
}

.EditActionsBUListItem img {
  width: 17px;
  height: auto;
  margin-right: 12px;
}

.EditActionsBUListItem.Delete svg {
  color: #f44336;
}

.EditActionsBUListItem:hover,
.EditActionsBUListItem:focus,
.EditActionsBUListItem:active,
.EditActionsBUListItemSmall:hover,
.EditActionsBUListItemSmall:focus,
.EditActionsBUListItemSmall:active {
  color: #343434 !important;
  background: #f8f8f8 !important;
}

.ButtonAction {
  width: 100%;
  padding-top: 0px;
  display: flex;
  align-items: center;
}

@media screen and (min-width: 360px) {
  .ProspectCard {
    width: 100%;
  }
  .ApplicantDiv {
    width: 25%;
  }

  .ApplicantAction {
    width: 20%;
  }

  .mainCardDiv {
    padding-right: 0px;
  }

  .FilterOnOffBU {
    display: block;
  }

  .BodyFilterArea {
    right: -280px;
  }

  .BodyFilterArea.Open {
    right: 0px;
  }
  .AddDeviceList {
    width: 100%;
    padding: 12px 12px;
  }
}

@media screen and (min-width: 360px) {
  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .NotesCard {
    padding: 15px 15px;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 100%;
    justify-content: unset;
    padding: 0 5px;
  }
}

@media screen and (min-width: 480px) {
  .FilterPill span {
    max-width: 100%;
    display: unset;
    margin-bottom: 0px;
  }
}

@media screen and (min-width: 576px) {
  .ApplicantDiv {
    width: 100%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .MainReqConDivSmall {
    width: 100%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 60%;
    justify-content: flex-start;
    padding: 0 5px;
  }
}

@media screen and (min-width: 768px) {
  .ProspectCard {
    width: 50%;
  }
  .ApplicantDiv {
    width: 35%;
    padding: 0px 15px;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 24%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 70%;
  }

  .MainReqConDivBig {
    width: 100%;
  }

  .MainReqConAction {
    width: 24%;
    /* justify-content: flex-end; */
  }
  .AddDeviceList {
    width: 50%;
    padding: 12px 12px;
  }
  .ModalFormGroupTwo {
    min-width: 400px;
  }
  .ModalFormGroup {
    max-width: 750px;
    min-width: 686px;
  }
}

@media screen and (min-width: 960px) {
  .ModalFormGroup {
    max-width: 750px;
    min-width: 686px;
  }
}

@media screen and (min-width: 1100px) {
  .MainReqConDivSmall {
    width: 30%;
  }

  .MainReqConDivBig {
    width: 50%;
  }

  .MainReqConAction {
    width: 20%;
    justify-content: flex-start;
  }

  .SearchCon {
    width: 25%;
  }

  .FilterSec {
    width: 75%;
  }

  .FilterCard {
    padding: 0 5px;
  }
}

@media screen and (min-width: 1200px) {
  .ApplicantDiv {
    width: 50%;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .SearchCon {
    width: 100%;
  }

  .FilterSec {
    width: 100%;
    margin: 15px 0 0 0;
  }

  .FilterCard {
    padding: 0 10px;
  }

  .FilterTitle {
    display: none;
  }

  .FilterBu {
    display: none;
  }
}

@media screen and (min-width: 1280px) {
  .MainReqConDivSmall {
    width: 38%;
  }

  .MainReqConDivBig {
    width: 44%;
  }

  .MainReqConAction {
    width: 17%;
    /* justify-content: flex-end; */
  }
}

@media screen and (min-width: 1300px) {
  .ApplicantDiv {
    width: 35%;
  }

  .ApplicantDivHalf {
    width: 100%;
  }

  .ApplicantDivHalf:last-child {
    width: 100%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .AddDeviceList {
    width: 33.33%;
    padding: 12px 12px;
  }
  .ModalFormGroup {
    max-width: 1100px;
    min-width: 1036px;
  }
}

@media screen and (min-width: 1360px) {
  .ProspectCard {
    width: 33.33%;
  }
}

@media screen and (min-width: 1500px) {
  .ApplicantDiv {
    width: 40%;
  }

  .ApplicantDivHalf {
    width: 30%;
  }

  .ApplicantDivHalf:last-child {
    width: 65%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  /* .mainCardDiv {
    padding-right: 280px;
  } */

  .FilterOnOffBU {
    display: none;
  }

  .BodyFilterArea {
    right: 0px;
  }

  .MainReqConDivSmall {
    width: 38%;
  }

  .MainReqConDivBig {
    width: 44%;
  }

  .MainReqConAction {
    width: 18%;
  }

  .SearchCon {
    width: 30%;
  }

  .FilterSec {
    width: 70%;
    margin: 0;
  }

  .FilterTitle svg {
    display: none;
  }
  .MainReqConDropBU {
    padding: 0 0px;
  }
}

@media screen and (min-width: 1600px) {
  .ProspectCard {
    width: 25%;
  }
  .ApplicantDiv {
    width: 35%;
  }

  .ApplicantAction {
    width: 100%;
    flex-wrap: wrap;
  }

  .MainReqConDivSmall {
    width: 38%;
  }

  .MainReqConDivBig {
    width: 46%;
  }

  .MainReqConAction {
    width: 16%;
  }

  .NotesCard {
    padding: 15px 18px;
  }
  .MainReqConDropBU {
    padding: 0 0px;
  }
}

@media screen and (min-width: 1800px) {
  .ApplicantDiv {
    width: 30%;
  }

  .ApplicantAction {
    width: 15%;
    flex-wrap: wrap;
    /* justify-content: flex-end; */
  }

  .MainReqConDivSmall {
    width: 30%;
  }

  .MainReqConDivBig {
    width: 45%;
  }

  .MainReqConAction {
    width: 14%;
  }

  .SearchCon {
    width: 40%;
  }

  .FilterSec {
    width: 60%;
  }
}

@media screen and (min-width: 1700px) {
  .imgDiv img {
    margin: 0;
  }

  .allClientsNameInner span {
    width: 250px;
    text-align: left;
    display: inline;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  .FilterSec {
    width: 197px;
  }
  .SearchCon {
    width: calc(100% - 197px);
  }
  .FilterFieldSec {
    width: 280px;
    height: calc(100% - 64px);
    display: flex;
    flex-wrap: wrap;
    position: fixed;
    background: #fff;
    top: 64px;
    z-index: 98;
    right: -300px;
    align-content: flex-start;
    padding: 40px 15px;
    box-shadow: -3px 0px 20px rgb(62 85 120 / 8%);
    border-left: 1px solid #ebebeb;
    transition: all 0.2s ease-in-out;
  }
  .FilterFieldSec.Open {
    right: 0;
  }
  .FilterCard {
    width: 100%;
    padding: 10px 10px;
  }
  .ActionsBU {
    margin: 0 4px;
  }
}

@media (max-width: 991px) {
  .ProspectCardHeadSection {
    flex-wrap: wrap;
    justify-content: center;
  }
  .ProspectCardHeadSection .HeadConSec {
    width: 100%;
  }
  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 90px;
    width: 90px;
    min-height: 90px;
    height: 90px;
    border-radius: 50%;
    margin-right: 0px;
    margin-bottom: 16px;
  }
  .ProsUserName.LimitName {
    width: 100%;
    margin: 0 0 0 0;
    text-align: center;
  }
  .ProspectNonAppliedBadge,
  .ProspectAppliedBadge,
  .ProspectScreenedBadge,
  .ProspectApprovedBadge,
  .ProspectDeclinedBadge {
    position: absolute;
    top: 0;
    left: 0;
  }
  .ProsUserName {
    justify-content: center;
  }
  .ProsUserMandateSec {
    justify-content: center;
  }
  .ReffStatus {
    position: absolute;
    top: 0;
    right: 0;
  }
}

@media (max-width: 959px) {
  .breadcrumb {
    display: none;
  }
  .pageTitleWrap {
    padding: 1rem 1.8rem 0.5rem 1.8rem;
  }
  .SearchFilterSec {
    padding: 5px 0 10px 0;
  }
  .FilterFieldSec {
    height: calc(100% - 0px);
    top: 0; 
  }
}

@media (max-width: 767px) {
  .AddNoteBu span {
    display: none;
  }
  .AddNoteBu svg {
    margin-right: 0;
  }
  .AddNoteBu,
  .FilterBu {
    margin: 0 5px;
  }
  .FilterSec {
    width: 122px;
  }
  .SearchCon {
    width: calc(100% - 122px);
  }
  .BackAddBU {
    display: flex !important;
  }
  .ReqAddCard {
    position: fixed;
    height: 100%;
    width: 100%;
    background: #fff;
    top: 0;
    left: 0;
    overflow: auto;
    margin: 0;
    padding: 40px 40px;
    z-index: 999;
  }
  .ActionsBULine {
    width: auto;
    height: 44px;
    color: #fff !important;
    background-color: #502688 !important;
    padding: 11px 15px 11px 15px !important;
    border: 1px solid #502688 !important;
  }
  .ActionsBULine svg {
    min-width: 16px;
    min-height: 16px;
    width: 18px;
    height: 18px;
    margin-bottom: 0px;
    margin-left: 0px;
  }
  .AddBuText {
    display: none;
  }
  .ActionsBU.Red {
    padding: 11px 14px 11px 14px !important;
  }
  .ActionsBU svg {
    min-width: 16px;
    min-height: 16px;
    width: 22px;
    height: 22px;
    margin: 0px !important;
  }
}

@media screen and (max-width: 575px) {
  .FileUploadSmall .ImgFile {
    width: 25%;
    height: 140px;
  }
  .FormHalfSec {
    width: 100%;
  }
}

@media (max-width: 479px) {
  .mainCard {
    padding: 0;
    margin: 0 0.8rem;
    background: transparent;
    box-shadow: unset;
  }
  .ProspectCardHeadSection .ProsIMGSec {
    min-width: 60px;
    width: 70px;
    min-height: 60px;
    height: 70px;
  }
  .ProsIMGSec .ProspectCardImg {
    width: 34px;
  }
  .pageTitleWrap {
    padding: 1rem 0.8rem 0.5rem 0.8rem;
  }
  .pageTitle {
    width: 100%;
    order: 2;
  }
  .breadcrumb {
    width: 100%;
    order: 1;
    padding-bottom: 12px;
  }
  .FileUploadSmall .ImgFile {
    width: 33.33%;
    height: 120px;
  }
  .ReqAddCard {
    padding: 30px;
  }
  .AddDeviceSec {
    flex-wrap: wrap;
  }
  .AddDeviceSec .SearchConSec {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 20px;
  }
  .AddDeviceDatePick {
    width: 100%;
  }
  .OneThirdSec {
    width: 100%;
  }
}

