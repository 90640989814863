/* @import url('https://api.fontshare.com/v2/css?f[]=satoshi@1&display=swap');

*,
body {
  font-family: 'Satoshi', sans-serif;
} */

.Header {
  width: 100%;
}

.Container {
  width: 1360px;
  margin: 0 auto;
}

.HeadRow {
  display: flex;
  justify-content: space-between;
  padding: 15px 0;
  border-bottom: 1px solid #e5e5e5;
}

.Logo {
  max-width: 240px;
  max-height: 50px;
}

.Logo img {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
}

.RightSec {
  display: flex;
  align-items: center;
}

.MenuArea {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.MenuArea ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.MenuArea ul li {
  list-style: none;
}

.MenuArea ul li a {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #000;
  display: block;
  padding: 7px 20px;
}

.MenuHamBU {
  width: 30px;
  height: 30px;
  color: #000;
}

.MenuHamBU svg {
  width: 30px;
  height: 30px;
}

.ContactBUHead {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #ff8271;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 8px 0;
  transition: all .3s ease-in-out;
  margin-left: 15px;
}

.ContactBUHead svg {
  width: 20px;
  margin-left: 8px;
}

.ContactBUHead:hover {
  color: #fff;
  background: #e87767;
}

.BannerArea {
  width: 100%;
  padding: 80px 0;
  position: relative;
  overflow: hidden;
  /* top: 82px; */
  background: #fff;
  margin-bottom: 190px;
}

.Row {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AlignCenter {
  align-items: center;
}

.BannerTextArea {
  width: 44%;
  padding: 50px 0 0 0;
}

.BannerTitleText {
  font-size: 65px;
  font-weight: 800;
  line-height: 76px;
  color: #13052a;
  letter-spacing: -3px;
  margin-bottom: 30px;
}

.BannerConText {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
}

.LearnMBUBanner {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #32007e;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 8px 0;
  transition: all .3s ease-in-out;
  margin-top: 40px;
}

.LearnMBUBanner svg {
  width: 20px;
  margin-left: 8px;
}

.LearnMBUBanner:hover {
  color: #fff;
  background: #2a0068;
}

.BannerIMGArea {
  width: 38%;
  position: absolute;
  right: 0;
  background: #F7F7F7;
  border-bottom: 3px solid #000;
  top: 0;
  height: 100%;
}

.BannerMainIMG {
  width: 530px;
  height: auto;
  position: absolute;
  left: -32%;
  bottom: 0px;
}

.BannerMainIMG2 {
  width: auto;
  height: auto;
  position: absolute;
  right: 230px;
  top: 50px;
}

.BannerMainIMG3 {
  width: auto;
  height: auto;
  position: absolute;
  left: 380px;
  top: -15px;
}

.WelcomeArea {
  width: 100%;
  padding: 60px 0 120px 0;
  position: relative;
}

.WelcomRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}

.PositionRelative {
  position: relative;
}

.WelcomeIMGSec {
  width: 52%;
  position: absolute;
  left: -63px;
}

.WelcomeIMGSec img {
  width: 100%;
  height: auto;
}

.WelcomeConSec {
  width: 60%;
  background: #f8f8f9;
  border-radius: 8px;
  padding: 80px 60px 80px 180px;
  display: flex;
  flex-wrap: wrap;
}

.WelcomSmallHead {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  opacity: .5;
}

.WelcomBigHead {
  font-size: 65px;
  font-weight: 800;
  line-height: 74px;
  color: #13052a;
  letter-spacing: -1px;
  margin-bottom: 30px;
}

.LearnMLink {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #32007e;
  letter-spacing: -1px;
  transition: all .3s ease-in-out;
  position: relative;
  padding: 5px 0;
}

.LearnMLink svg {
  width: 20px;
  margin-left: 8px;
}

.LearnMLink:hover {
  color: #2a0068;
}

.LearnMLink:after {
  position: absolute;
  transition: 0.3s;
  content: "";
  width: 0;
  right: 0;
  left: auto;
  bottom: 0;
  height: 2px;
  background: #2a0068;
}

.LearnMLink:hover:after {
  width: 100%;
  left: 0;
}

.scrollReminderTick {
  animation: scrollTick 2.5s;
  animation-iteration-count: infinite;
  background-color: transparent;
  display: block;
  height: 62px;
  margin-left: -2px;
  position: absolute;
  width: 31px;
  background-image: url(./mouseScroll.png);
  z-index: 2;
}

@keyframes scrollTick {
  from {
    transform: translateY(-5px);
  }

  to {
    transform: translateY(29px);
  }
}

.ScrollDiv {
  height: 100px;
  margin: 50px 0 0 0;
  position: relative;
}

.ScrollDiv:after {
  content: '';
  position: absolute;
  width: 2px;
  height: 100%;
  background: #b9b7be;
  left: 12px;
  top: 0;
  z-index: 1;
  border-radius: 50%;
  opacity: .7;
}

.features {
  width: 100%;
  padding: 40px 0;
  overflow: hidden;
}

.featuresTitleSec {
  width: 100%;
  max-width: 660px;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.featuresTitleB {
  font-size: 72px;
  font-weight: 800;
  line-height: 80px;
  color: #13052a;
  letter-spacing: -3px;
  margin-bottom: 50px;
  text-align: center;
}

.featuresTitleS {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 40px;
  text-align: center;
}

.featuresGraSec {
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: 80px 0 180px 0;
}

.featuresGraImgSec {
  width: 100%;
  max-width: 780px;
  height: auto;
  position: relative;
}

.featuresGraImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.featuresSmallSec {
  width: 240px;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  position: absolute;
}

.featuresSmallSec.IconSec1 {
  top: 0px;
  left: 30px;
}

.featuresSmallSec.IconSec2 {
  bottom: 80px;
  left: 60px;
}

.featuresSmallSec.IconSec3 {
  top: 0px;
  right: 30px;
}

.featuresSmallSec.IconSec4 {
  bottom: 80px;
  right: 60px;
}

.IconSec {
  width: 90px;
  height: 90px;
  background-color: #ff8271;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.IconSec img {
  max-width: 48px;
  max-height: 50px;
  object-fit: contain;
  object-position: center;
}

.featuresSmallTitle {
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 0px;
  text-align: center;
  width: 100%;
}

.featuresCardRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
}

.featuresCardSec {
  width: 100%;
  padding: 100px 0 40px 0;
}

.featuresCard {
  width: 25%;
  padding: 15px;
}

.featuresCardUnder {
  width: 100%;
  height: 100%;
  border: 1px solid #dadada;
  overflow: hidden;
  border-radius: 8px;
  transition: all .3s ease-in-out;
}

.featuresCardUnder:hover {
  box-shadow: 0 50px 35px 8px #33333330;
  transform: translate(0px, -70px);
}

.featuresCardImgSec {
  width: 100%;
  height: 280px;
  position: relative;
}

.featuresCardImgSec img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.featuresCardImgSec:after {
  content: '';
  width: 100%;
  height: 125px;
  background: rgb(255, 255, 255);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 1) 48%);
  position: absolute;
  bottom: 0;
  left: 0;
}

.featuresCardImgText {
  font-size: 24px;
  font-weight: 800;
  line-height: 26px;
  color: #fff;
  letter-spacing: -1px;
  margin-bottom: 0px;
  text-align: left;
  width: auto;
  position: absolute;
  bottom: 18px;
  left: 25px;
  right: 25px;
  z-index: 1;
  padding: 0 0 12px 0;
}

.featuresCardImgText:after {
  content: '';
  position: absolute;
  width: 50px;
  height: 2px;
  background-color: #ff8271;
  left: 0;
  bottom: 0;
}

.featuresCardBodySec {
  width: 100%;
  padding: 25px 25px;
  position: relative;
}

.featuresCardBodyList {
  width: 100%;
  padding: 10px 0;
  display: flex;
}

.featuresCardBodyList img {
  min-width: 22px;
  width: 22px;
  min-height: 22px;
  height: 22px;
  object-fit: contain;
  object-position: center;
  margin-right: 12px;
}

.featuresCardBodyList .ListText {
  font-size: 17px;
  font-weight: 600;
  line-height: normal;
  color: #000;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;
}

.featuresFull {
  width: 100%;
  position: relative;
  height: 620px;
  margin: 40px 0;
  overflow: hidden;
}

.featuresFull img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  position: absolute;
  top: 0;
  left: 0;
}

.featuresFullRow {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}

.Height100 {
  height: 100%;
}

.featuresFullCon {
  width: 100%;
  max-width: 450px;
}

.featuresFullTitle {
  font-size: 68px;
  font-weight: 800;
  line-height: 71px;
  color: #13052a;
  letter-spacing: -1px;
  margin-bottom: 30px;
  text-align: left;
}

.featuresFullText {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
}

.featuresFullBU {
  font-size: 18px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  background: #ff8271;
  width: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 9px;
  padding: 8px 0;
  transition: all .3s ease-in-out;
  margin-top: 30px;
}

.featuresFullBU svg {
  width: 20px;
  margin-left: 8px;
}

.featuresFullBU:hover {
  color: #fff;
  background: #e87767;
}

.OurBlog {
  width: 100%;
  padding: 80px 0;
}

.BlogTitleSmall {
  font-size: 22px;
  font-weight: 500;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 20px;
  opacity: .5;
  text-align: center;
}

.BlogTitleBig {
  font-size: 72px;
  font-weight: 800;
  line-height: 80px;
  color: #13052a;
  letter-spacing: -3px;
  margin-bottom: 50px;
  text-align: center;
}

.OurBlogRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -10px;
}

.OurBlogCard {
  width: 33.33%;
  padding: 10px;
}

.OurBlogCardUnder {
  width: 100%;
  height: auto;
}

.BlogImg {
  width: 100%;
  height: 310px;
  object-fit: cover;
  object-position: center;
  border-radius: 12px;
}

.OurBlogCon {
  width: 100%;
  padding: 25px 0;
}

.OurBlogDate {
  font-size: 12px;
  font-weight: 700;
  line-height: 35px;
  color: #fff;
  letter-spacing: 2px;
  margin-bottom: 20px;
  text-align: center;
  background: #13052a;
  text-transform: uppercase;
  border-radius: 20px;
  width: fit-content;
  padding: 0 25px;
}

.OurBlogCard:nth-child(even) .OurBlogDate {
  background: #ff8271;
}

.OurBlogText {
  font-size: 26px;
  font-weight: 800;
  line-height: 35px;
  color: #000;
  letter-spacing: -1px;
  margin-bottom: 20px;
  text-align: left;
  padding-right: 15px;
}

.FooterRow {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -15px;
  border-top: 1px solid #e5e5e5;
  padding: 100px 0 80px 0;
}

.FooterLogoSec {
  width: 30%;
  padding: 15px;
}

.FooterLogoSec img {
  width: 100%;
  max-width: 240px;
  height: auto;
}

.FooterConSec {
  width: 70%;
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.FooterConSecSmall {
  width: 22%;
}

.FooterConSecBig {
  width: 32%;
  display: flex;
  align-items: center;
}

.FootListTitle {
  font-size: 21px;
  font-weight: 800;
  line-height: normal;
  color: #13052a;
  letter-spacing: -1px;
  margin-bottom: 10px;
  text-align: left;
}

.FootListLink {
  font-size: 17px;
  font-weight: 400;
  line-height: 36px;
  color: #13052a;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;
  opacity: .8;
  width: 100%;
  display: block;
  transition: all .3s ease-in-out;
}

.FootListLink:hover {
  color: #000;
  opacity: 1;
}

.FootListLinkBig {
  font-size: 22px;
  font-weight: 800;
  line-height: normal;
  color: #13052a;
  letter-spacing: 0px;
  margin-bottom: 10px;
  text-align: left;
  width: 100%;
  display: block;
  transition: all .3s ease-in-out;
}

.LastFootRow {
  display: flex;
  justify-content: space-between;
  padding-bottom: 15px;
}

.CopyRightText {
  font-size: 17px;
  font-weight: 400;
  line-height: normal;
  color: #13052a;
  letter-spacing: 0px;
  margin-bottom: 0px;
  text-align: left;
  opacity: .8;
}

.SocialIcon a {
  padding: 0 6px;
}

.SocialIcon img {
  width: 18px;
}

.ConTactFormSec {
  width: 100%;
  background: #f7f7f7;
  padding: 130px 0;
}

.ContactTitle {
  color: #13052a;
  font-size: 36px;
  font-weight: 800;
  text-align: center;
}

.GetInTouchRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 90px;
}

.FormSec {
  width: 50%;
}

.FormGroup {
  width: 100%;
  padding-bottom: 15px;
  font-size: .875rem;
  position: relative;
}

.FilterLabel {
  font-size: 13px;
  line-height: 30px;
  font-weight: 700;
  color: #333333;
  margin: 0;
}

.FilterLabel span {
  color: #f00
}

.formControl {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 44px;
  padding: 0.375rem 0.75rem;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.formControlTextArea {
  display: block;
  width: 100%;
  min-width: 80px;
  height: 250px;
  padding: 0.375rem 0.75rem;
  resize: none;
  font-size: .875rem;
  font-weight: 600;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e1e1;
  border-radius: 6px;
  transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

.formControl:hover,
.formControl:focus,
.formControlTextArea:hover,
.formControlTextArea:focus {
  border: 1px solid #13052a;
}

.ConFormSubBU {
  font-size: 20px;
  font-weight: 600;
  line-height: 30px;
  color: #fff;
  background: #ff8271;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 16px 0 16px 0;
  position: relative;
  transition: all .3s ease-in-out;
  margin-top: 30px;
  border: none;
}

.ConFormSubBU:hover {
  background: #32007e;
}

.ConDeSec {
  width: 32%;
}

.ContactUsConDiv {
  border-bottom: 1px solid #ff8271;
  padding-bottom: 30px;
  margin-bottom: 40px;
  width: 100%;
}

.ContactUsConDiv:last-child {
  border-bottom: 0px solid #ff8271;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.ContactUsConDiv img {
  width: 62px;
  height: 62px;
  object-fit: contain;
  object-position: center;
}

.ContactUsConDiv h2 {
  color: #080c13;
  font-weight: 700;
  font-size: 20px;
  margin: 0;
  padding: 20px 0 15px;
}

.ContactUsConDiv p {
  color: #080c13;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  line-height: 32px;
}

.ContactUsNo {
  font-size: 18px;
  line-height: normal;
  font-weight: 500;
  color: #080c13 !important;
  cursor: pointer;
  margin-top: 0;
  display: inline-block;
  -webkit-transition: all .3s;
  transition: all .3s;
}

.ContactUsNo:hover {
  color: #ff8271 !important;
}

.ContactUsLink {
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  color: #ff8271 !important;
  cursor: pointer;
  margin-top: 15px;
  display: inline-block;
}

.ErrorM {
  color: #f44336;
  font-size: 12px;
  width: 100%;
  margin-top: 4px;
}

.SubmittedMessage {
  font-size: 20px;
  font-weight: 700;
  color: #ff8271;
  margin-bottom: 25px;
}

@media (min-width: 360px) {
  .Container {
    width: Calc(100% - 30px);
  }

  .FooterLogoSec {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: center;
  }

  .FooterConSec {
    width: 100%;
  }

  .FooterConSecSmall {
    width: 100%;
    margin-bottom: 15px;
  }

  .FooterConSecBig {
    width: 100%;
    justify-content: center;
  }

  .FootListLinkBig {
    font-size: 20px;
    text-align: center;
  }

  .FootListLinkBig {
    font-size: 20px;
  }

  .FootListTitle {
    text-align: center;
  }

  .FootListLink {
    text-align: center;
  }

  .LastFootRow {
    flex-wrap: wrap;
    justify-content: center;
  }

  .FormSec {
    width: 100%;
  }

  .ConDeSec {
    width: 100%;
    margin-top: 60px;
  }

  .CopyRightText {
    font-size: 16px;
    margin-bottom: 10px;
    text-align: center;
    width: 100%;
  }

  .BannerTextArea {
    width: 100%;
    padding: 0px 0 0 0;
    position: relative;
    z-index: 1;
  }

  .BannerIMGArea {
    width: 70%;
    filter: grayscale(.8);
    opacity: .1;
  }

  .BannerTitleText {
    font-size: 55px;
    line-height: 66px;
  }
}

@media (min-width: 480px) {}

@media (min-width: 576px) {
  .BannerTitleText {
    font-size: 65px;
    line-height: 76px;
  }

  .CopyRightText {
    font-size: 17px;
    margin-bottom: 0px;
    text-align: left;
    width: auto;
  }

  .LastFootRow {
    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .BannerIMGArea {
    width: 60%;
  }
}

@media (min-width: 768px) {
  .FormSec {
    width: 55%;
  }

  .ConDeSec {
    width: 45%;
    margin-top: 0px;
  }

  .BannerTextArea {
    width: 60%;
    padding: 30px 0 0 0;
  }

  .BannerIMGArea {
    width: 30%;
    filter: grayscale(0);
    opacity: 1;
  }

  .ConFormSubBU {
    width: 95%;
  }
}

@media (min-width: 992px) {
  .BannerTextArea {
    width: 44%;
    padding: 50px 0 0 0;
  }

  .FootListTitle {
    text-align: left;
  }

  .FootListLink {
    text-align: left;
  }

  .FooterLogoSec {
    width: 25%;
    display: unset;
    padding: 0 15px;
  }

  .FooterConSec {
    width: 75%;
  }

  .FooterConSecSmall {
    width: 20%;
    margin-bottom: 0;
  }

  .FooterConSecBig {
    width: 36%;
    justify-content: right;
  }

  .FootListLinkBig {
    font-size: 20px;
    text-align: left;
  }

  .ConDeSec {
    width: 40%;
  }

  .BannerIMGArea {
    width: 38%;
  }

  .BannerMainIMG {
    left: -42%;
  }
}

@media (min-width: 1100px) {}

@media (min-width: 1200px) {
  .BannerMainIMG {
    left: -32%;
  }

  .ConDeSec {
    width: 45%;
    padding-left: 120px;
  }

  .Container {
    width: 1140px;
  }

  .FooterLogoSec {
    width: 30%;
  }

  .FooterConSec {
    width: 70%;
  }

  .FooterConSecSmall {
    width: 22%;
  }

  .FooterConSecBig {
    width: 32%;
  }

  .FootListLinkBig {
    font-size: 22px;
  }
}

@media (min-width: 1300px) {}

@media (min-width: 1400px) {
  /* .Container {
    width: 1360px;
  } */
}

@media (min-width: 1500px) {}

@media (min-width: 1600px) {}

@media (min-width: 1700px) {}

@media (min-width: 1800px) {}